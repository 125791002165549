<template>
  <div class="h-full bg-white pl-7 w-full wrapper ">
    <PageHeader :title="$t('properties.edit.title')"
                :breadcrumbs="breadcrumbs"
                :showBottomLine="false"
                :pagination="navRecords.length > 0 && getCurrentNavIndex > 0"
                :totalRecordCount="navRecords.length"
                :currentRecordIndex="getCurrentNavIndex"
                @page="changeNavPage"
    />

    <!-- Data Acquisition modal loader -->
    <ModalDialog v-show="toggleDataAcquisitionLoader" :showClose="false"
                 :headerText="$t('properties.parcel.loading')">
      <template v-slot:body>
        <img class="ml-64 mt-2.5 mb-5 animate-spin" width="75" src="@/assets/loading.svg" alt=""/>
      </template>
    </ModalDialog>

    <!-- Data Acquisition Abo Required modal -->
    <ModalDialog v-show="toggleDataAcquisitionAboRequired" :showClose="true"
                 @close="toggleDataAcquisitionAboRequired=false"
                 :headerText="$t('properties.parcel.modal_required_abo_title')">
      <template v-slot:body>
        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-3.5" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 mt-5 w-full">
                <p class="text-left mb-5 text-sm">
                  {{ $t('properties.parcel.modal_required_abo_text') }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/billingTrial'" class="button-external w-1/3 mt-4 mb-1">
                  {{ $t('properties.parcel.modal_required_abo_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- next declaration type modal -->
    <ModalDialog
        v-show="nextDeclarationTypeDialog"
        :headerText="markDlgTitle"
        @close="nextDeclarationTypeDialog = false"
    >
      <template v-slot:body>
        <div class="flex flex-col w-full">
          <div class="flex justify-start text-left pb-3">
            <label>
              {{ markDlgDescription }}
            </label>
          </div>

          <InputSelect
            class="mt-2 w-full"
            v-model="nextDeclarationType"
            :selected-value="nextDeclarationType"
            :label="$t('properties.relevant.title-1')"
            :options="nextDeclarationTypeOptions"
            @on-item-selected="selectNextDeclarationTypeOpt"
            placeholder=""
            overlay-append-to="body"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button
              @click="nextDeclarationTypeDialog = false"
              :text="$t('buttons.cancel')"
              :secondary="true"
            />
            <Button
              :text="markDlgButton"
              @click="selectNextDeclarationType()"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- settingsNextDeclarationDialog modal -->
    <ModalDialog
        v-show="settingsNextDeclarationDialog"
        :headerText="$t('properties.tooltips.settings_next_declaration_header')"
        @close="settingsNextDeclarationDialog = false"
    >
      <template v-slot:body>
        <div class="flex flex-col w-full">
          <div class="flex justify-start text-left pb-3">
            <label>
              {{ $t('properties.tooltips.settings_next_declaration_desc') }}
            </label>
          </div>

          <InputSelect
            class="mt-2 w-full"
            v-model="nextDeclarationType"
            :selected-value="nextDeclarationType"
            :label="$t('properties.tooltips.settings_next_declaration_input_reason')"
            :options="nextDeclarationTypeOptions"
            @on-item-selected="selectNextDeclarationTypeOpt"
            placeholder=""
            overlay-append-to="body"
          />

          <div class="flex justify-around space-x-8 ">
            <InputSelect
              class="mt-2 w-full"
              :label="$t('properties.tooltips.settings_next_declaration_input_year')"
              :options="nextDeclarationReferenceDateOptions"
              v-model="nextDeclarationReferenceDate"
              :selected-value="nextDeclarationReferenceDate"
              :placeholder="$t('general.please_select')"
              overlay-append-to="body"
            />
          </div>

          <div
            v-if="nextDeclarationType === '1002'"
            class="w-full"
          >
            <div class="flex w-fit justify-start items-center text-sm p-3 mt-4 mx-auto bg-gray_light rounded">
              <img
                class="image"
                :src="getAssetPath('info_gray.svg')"
              />
              <label class="text-left ml-3">
                {{ $t('properties.tooltips.settings_next_declaration_info_1002') }}
              </label>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button
              @click="settingsNextDeclarationDialog = false"
              :text="$t('buttons.cancel')"
              :secondary="true"
            />
            <Button
              v-if="nextDeclarationType === '1002' && lastDeclarationUid > 0"
              @click="settingsNextDeclarationAttributePerpetuation"
              :text="$t('properties.tooltips.settings_next_declaration_elster_message_button')"
            />
            <Button
              @click="settingsNextDeclaration()"
              :text="$t('buttons.save')"
            />
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- cancel next declaration type modal -->
    <ModalDialog
        v-show="cancelNextDeclarationTypeDialog"
        :headerText="$t('properties.relevant.header-2')"
        @close="cancelNextDeclarationTypeDialog = false"
    >
      <template v-slot:body>
        <div class="flex justify-around space-x-10">
          <div class="flex justify-start text-left pb-3">
            <label>
              {{ $t('properties.relevant.desc-2') }}
            </label>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button @click="cancelNextDeclarationTypeDialog = false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="cancelNextDeclarationType()"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition Not Active Modal -->
    <ModalDialog v-show="toggleDataAcquisitionModalServiceNotActive" :showClose="true"
                 @close="toggleDataAcquisitionModalServiceNotActive = false"
                 :headerText="$t('properties.parcel.modal_data_acquisition_error_not_active_title')">
      <template v-slot:body>

        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-8 h-20" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-8" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 w-full">
                <p class="text-left mb-5 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisition_error_not_active_text') }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/systemsettings?tab=data_acquisition'"
                             class="w-1/3 mt-4 mb-1 button-external text-base">
                  {{ $t('properties.parcel.modal_data_acquisition_error_not_active_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition Over Limit modal -->
    <ModalDialog v-show="toggleDataAcquisitionModalOverLimit" :showClose="true"
                 @close="toggleDataAcquisitionModalOverLimit=false"
                 :headerText="$t('properties.parcel.modal_data_acquisition_error_limit_title')">
      <template v-slot:body>
        <div class="flex flex-col w-full justify-between mt-2 mb-2 p-message-warn">
          <div class="w-full">
            <div class="flex justify-start items-center pt-8 h-20 px-8" style="background-color: #fff4ce;">
              <img class="image ml-2 mb-7" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4 w-full">
                <p class="text-left mb-5 text-sm">
                  {{
                    $t('properties.parcel.modal_data_acquisition_error_limit_text').replace('%s', userLimitAmount)
                  }}
                </p>
              </div>
            </div>
            <div class="flex justify-end space-x-10 mr-6 w-full">
              <div class="flex mb-0.5 justify-end w-full space-x-4">
                <router-link :to="'/setting/systemsettings?tab=data_acquisition'"
                             class="w-1/3 mt-4 mb-1 button-external text-base">
                  {{ $t('properties.parcel.modal_data_acquisition_error_limit_button') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Acquisition modal -->
    <ModalDialog
      v-show="toggleDataAcquisitionModal"
      :headerText="$t('properties.data_acquisitions.title')"
      modalSize="x-large"
      @close="toggleDataAcquisitionModal = false"
      :showClose="true">
      <template v-slot:body>
        <div class="flex flex-col w-full mt-2 mb-4 h-96"
             style="height: 900px; max-width: calc(99% - (10px)); padding: 2px 2px 2px 2px;box-sizing: border-box;">
          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4" v-if="propertyData.step2.federalStateUid === '13'">
                <div class="text-left mb-4" style="color: rgb(34, 157, 86);">
                  {{ $t('properties.parcel.modal_data_acquisitions_important_note_header') }}
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_important_note_header_title') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_important_note_header_text_1') }}
                </div>
                <div class="text-left mb-4 text-sm" v-html="$t('properties.parcel.modal_data_acquisitions_important_note_header_text_2')">
                </div>
              </div>

              <div v-else class="text ml-4">
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_1') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_1_1') }}
                </div>
                <div class="mb-5 ml-4">
                  <ul class="text-left ul-list list-disctext-xs">
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.district') }}</li>
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.district_number') }}</li>
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_number') }} </li>
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_counter') }} </li>
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.parcel_denominator') }}</li>
                    <li>{{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_liste.ground_value') }}</li>
                  </ul>
                </div>
                <div class="text-left mb-4 text-sm" v-html="$t('properties.parcel.modal_data_acquisitions_info_retrieve_text_1_2_1')">
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_2') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_2_1') }}<br/>
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_2_2') }}
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_title_3') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_3_1') }}
                </div>
                <div class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_info_retrieve_text_3_2') }}<br/>
                  <a target="_blank" class="text-base label-chk text-sm text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/">
                    https://hilfe.grundsteuer-digital.de/faq/wie-kann-ich-als-steuerberater-liegenschaftsinformationen-digital-abrufen/
                  </a>
                </div>
                <div class="text-left mb-2">
                  <Button @click="confirmOpenDataAacquisitionAPI"
                          class="text-base skendata-button"
                          :text="$t('properties.parcel.modal_data_acquisitions_info_retrieve_button')">
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text_bold text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_1') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_2') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  <strong>{{
                      $t('properties.parcel.modal_data_acquisitions_land_register_title_2')
                    }}:</strong>
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_3') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_4') }} <br/>
                  <a target="_blank" class="text-base label-chk text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://taxtech-shop.de/"
                     target="_blank"
                     class="button-external text-base">
                    {{ $t('properties.parcel.modal_data_acquisitions_land_register_button') }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="w-full" v-if="propertyData.step2.federalStateUid !== '13'">
            <div class="flex justify-start items-center pt-4 mt-4 mb-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_text_1') }}
                </p>
                <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland1') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland2') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland3') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland4') }}</li>
                  <li>{{ $t('properties.parcel.modal_data_acquisitions_geoviewer_list.bundesland5') }}</li>
                </ul>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_geoviewer_text_2') }}
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/"
                     target="_blank" class="button-external text-base">{{
                      $t('properties.parcel.modal_data_acquisitions_geoviewer_button')
                    }} </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Feature of BW modal -->
    <ModalDialog
        v-show="showFeatureForBWModal"
        :headerText="$t('properties.data_acquisitions.title')"
        modalSize="large"
        @close="showFeatureForBWModal=false"
        :showClose="true">
      <template v-slot:body>
        <div class="flex flex-col w-full mt-2 mb-4 h-96"
             style="overflow-y: auto; height: 431px; max-width: calc(99% - (10px)); padding: 2px ;
             box-sizing: border-box;">

          <div class="w-full">
            <div class="flex justify-start items-center pt-4" style="background-color: #fff4ce;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <div class="text-left mb-4 text-sm text_bold" style="color: #229d56;">
                  {{ $t('properties.modal_data_acquisitions_overview.block_4.title_0') }}
                </div>
                <div class="text-left mb-4 text-sm text_bold">
                  {{ $t('properties.modal_data_acquisitions_overview.block_4.title_1') }}
                </div>
                <div class="text-left mb-4 text-sm" v-html="$t('properties.modal_data_acquisitions_overview.block_4.text_1')">
                </div>

                <div class="text-left mb-4">
                  <Button @click="confirmOpenDataAacquisitionAPI"
                          class="text-base"
                          :text="$t('properties.modal_data_acquisitions_overview.block_4.button')">
                  </Button>
                </div>

                <div class="text-left mb-4 text-sm" v-html="$t('properties.modal_data_acquisitions_overview.block_4.text_2')">
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="flex justify-start items-center pt-4 mt-4" style="background-color: #f2f2f2;">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <div class="text ml-4">
                <p class="text-left mb-4 text_bold text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_title') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_1') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_2') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  <strong>{{ $t('properties.parcel.modal_data_acquisitions_land_register_title_2') }}:</strong>
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_3') }}
                </p>
                <p class="text-left mb-4 text-sm">
                  {{ $t('properties.parcel.modal_data_acquisitions_land_register_text_4') }} <br/>
                  <a target="_blank" class="text-base label-chk text-info"
                     href="https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/">https://hilfe.grundsteuer-digital.de/faq/serviceleistungen-entgelte/</a>
                </p>
                <p class="text-left mb-4 mt-6 text-sm">
                  <a href="https://taxtech-shop.de/"
                     target="_blank"
                     class="button-external text-base">
                    {{ $t('properties.parcel.modal_data_acquisitions_land_register_button') }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- multi synchronize modal -->
    <ModalDialog
        v-show="multiSynchronizeDialog"
        :headerText="$t('properties.synchronize')"
        @close="closeMultiSynchronizeDialog()"
        modalSize="small"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="flex justify-start mt-8 pb-3">
            <label>
              {{ $t('properties.multi_synchronize_description') }}
            </label>
          </div>

          <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="referenceChanged">
            <InputCheck :text="$t('properties.step2.reference')" :isChecked="referenceSyncChecked" @check="referenceSyncCheck"/>
          </div>
          <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="extraDetailsChanged">
            <InputCheck :text="$t('properties.step2.extraDetails')" :isChecked="extraDetailsSyncChecked" @check="extraDetailsSyncCheck"/>
          </div>
          <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="assignedUserChanged">
            <InputCheck :text="$t('declarations.add_declaration.steps.step1.assignedUser')" :isChecked="assignedUserSyncChecked" @check="assignedUserSyncCheck"/>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button @click="closeMultiSynchronizeDialog()" :text="$t('properties.synchronize_no')" :secondary="true"></Button>
            <Button :text="$t('properties.synchronize_yes')" @click="saveMultiSynchronizeDialog()" :disabled="saveMultiSyncBtnDisabled"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- duplicate modal -->
    <ModalDialog
        v-show="duplicateDialog && !isReadOnly"
        :headerText="$t('properties.duplicate')"
        @close="duplicateDialog=false"
        modalSize="small"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="flex justify-start mt-8">
            <label>
              {{ $t('properties.duplicate_description') }}
            </label>
          </div>

          <div class="flex justify-around space-x-10 mt-4">
            <InputSelect
                class="mt-2 w-full"
                v-model="duplicateClientUid"
                :selected-value="duplicateClientUid"
                :isError="isClientError"
                :errorMessage="clientError"
                :label="$t('properties.step1.client')"
                :options="clientList"
                :search-fn="clientListSearch"
                :total-record-count="totalClientsCount"
                :sort="true"
                :filterable="true"
                :isRequired=true
                placeholder=""
                overlay-append-to="body"
                @on-item-selected="selectclientUid"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button @click="duplicateDialog=false" :text="$t('general.no')" :secondary="true"></Button>
            <Button :text="$t('general.yes')" @click="saveDuplicateModalData()"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- take over owner modal -->
    <ModalDialog
        v-show="takeOverOwnersDialog && !isReadOnly"
        :headerText="$t('properties.owners_menu.take_over')"
        @close="takeOverOwnersDialog=false"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="flex justify-around space-x-10 mt-8">
            <InputSelect
                class="mt-2 w-full"
                v-model="propertyForOwner"
                :selected-value="propertyForOwner"
                :options="propertyOptions"
                @on-item-selected="selectProperty"
                placeholder=""
                overlay-append-to="body"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-8  ">
          <div class="flex mt-4 mb-6 space-x-4 ">
            <Button
              @click="takeOverOwnersDialog=false"
              :text="$t('general.cancel')"
              :secondary="true"
            ></Button>
            <Button
              :text="$t('general.take_over')"
              @click="takeOverFromProperty()"
              :disabled="takeOverBtnDisabled"
            ></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- import parcel modal -->
    <ModalDialog
        v-show="importParcelDialog && !isReadOnly"
        :headerText="$t('properties.parcel_import')"
        @close="importParcelDialog=false"
    >
      <template v-slot:body>
        <div class="box flex justify-start items-center mt-6">
          <img class="image ml-2" src="@/assets/info_gray.svg"/>
          <label class="text ml-2">
            <p>{{ $t('properties.parcel_import_help') }}</p>
            <p><a href="https://hilfe.grundsteuer-digital.de/faq/importvorlage-gemarkung-und-flurstuecke/"
                  class="download-template-link" target="_blank">{{ $t('properties.download_template') }}</a></p>
          </label>
        </div>
        <div class="flex flex-col mt-6 mb-10 w-full">
          <InputUpload class="w-full" :label="$t('clients.import.select')" :isRequired="true" @select="fileSelect"
                       accept=".xls,.xlsx,.csv"/>
          <div class="w-full pr-6 invisible"></div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-5 mb-5">
          <div class="flex space-x-4 ">
            <Button @click="importParcelDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button @click="importModalData('parcel')" :text="$t('general.submit')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add parcel modal -->
    <ModalDialog
        v-show="addParcelDialog && !isReadOnly"
        :headerText="$t('properties.parcels_menu.add_parcel')"
        @close="addParcelDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4" style="height: 600px; max-width: calc(99% - (10px));">
          <div class="flex flex-col justify-start mt-8">
            <div class="flex justify-between">
              <label class="title">{{ $t('properties.step3.district_title') }}</label>
              <a class="link"
                 href="https://hilfe.grundsteuer-digital.de/faq/wie-erfasse-ich-daten-aus-dem-grundbuchauszug-auf-der-grundsteuerdigital-plattform/"
                 target="_blank">{{ $t('properties.step3.tutorial_link') }}</a>
            </div>

            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2.community')"
                :options="communityList"
                dataKey="name"
                v-model="addModalSaveData.parcel.community"
                :selected-value="addModalSaveData.parcel.community"
                :filterable="true"
                :auto-complete="false"
                @on-item-selected="getPossiblesParcels"
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.district')"
                :options="parcelList"
                v-model="addModalSaveData.parcel.parcel"
                :selected-value="addModalSaveData.parcel.parcel"
                :filterable="true"
                :auto-complete="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_tooltip_parcel"
                :popoverLink="popoverLink_step9_district"
                :isRequired="modalSaveRequired.parcel"
                :isError="addModalSaveErrors.parcel.invalid || addModalParcelLengthError"
                :errorMessage="addModalParcelParcelErrorMessage"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :maxLength="6"
                       :label="$t('properties.step3.landRegisterSheet')"
                       v-model="addModalSaveData.parcel.landRegisterSheet"
                       placeholder=""
                       isRegex="[0-9]{1,5}[A-Za-z0-9]{0,1}"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_tooltip_landRegisterSheet"
                       :popoverLink="popoverLink_step3_landRegisterSheet"/>
            <InputText class="mt-2 w-full"
                       :label="$t('properties.step3.corridor')"
                       v-model="addModalSaveData.parcel.corridor"
                       placeholder=""
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :maxLength="3"
                       :disabled="corridorDisabled"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_corridor"
                       :popoverLink="popoverLink_corridor"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :allowLeadingZero="true"
                       :isOnlyNumber="true"
                       :maxLength="5"
                       :label="$t('properties.step3.parcelDataCounter')"
                       v-model="addModalSaveData.parcel.parcelDataCounter"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_counter_step3"
                       :popoverLink="popoverLink_counter"/>
            <InputText class="mt-2 w-full"
                       :maxLength="4"
                       :isAlphaNumric="true"
                       :label="$t('properties.step3.parcelDataDenominator')"
                       v-model="addModalSaveData.parcel.parcelDataDenominator"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_counter_step3"
                       :popoverLink="popoverLink_counter"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :isOnlyNumber="true"
                       :maxLength="maxlength"
                       :label="$t('properties.step3.areaOfTheLand')"
                       v-model="addModalSaveData.parcel.areaOfTheLand"
                       placeholder=""/>
            <InputText class="mt-2 w-full"
                       :isFloat="true"
                       :decimalNumber="decimalNumber"
                       :maxLength="maxFloatField" :label="$t('properties.step3.shareOfOwnershipCounter')"
                       v-model="addModalSaveData.parcel.shareOfOwnershipCounter"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_shareOfOwnershipCounter"
                       :popoverLink="popoverLink_step3_shareOfOwnershipCounter"
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3 mb-10">
            <InputText class="mt-2 w-full"
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :maxLength="7"
                       :label="$t('properties.step3.shareOfOwnershipDenominator')"
                       v-model="addModalSaveData.parcel.shareOfOwnershipDenominator"
                       placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_shareOfOwnershipCounter"
                       :popoverLink="popoverLink_step3_shareOfOwnershipCounter"
            />
            <InputText class="mt-2 w-full"
                       v-if="calculatedAreaShow"
                       :label="$t('properties.step3.calculatedArea')"
                       v-model="addModalSaveData.parcel.calculatedArea"
                       placeholder=""
                       :disabled="true"
            />
            <InputSelect class="mt-2 w-full"
                         v-else
                         v-model="addModalSaveData.parcel.containedInArea"
                         :selected-value="addModalSaveData.parcel.containedInArea"
                         :label="$t('properties.step3.containedInArea')"
                         :options="contained_area_options"
                         :disabled="containedInAreaDisabled"
                         overlay-append-to="body"
                         placeholder=""
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-6  ">
          <div class="flex mt-4 mb-8 space-x-4 ">
            <Button @click="addParcelDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('parcel')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit parcel modal -->
    <ModalDialog
        v-show="editParcelDialog"
        :headerText="$t('properties.parcels_menu.edit_parcel')"
        @close="editParcelDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4" style=" height: 600px; max-width: calc(99% - (10px));">
          <div class="flex flex-col justify-start mt-8">
            <label class="title">{{ $t('properties.step3.district_title') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2.community')"
                :options="communityList"
                dataKey="name"
                v-model="editModalSaveData.parcel.community"
                :selected-value="editModalSaveData.parcel.community"
                :filterable="true"
                :auto-complete="false"
                :disabled="isReadOnly"
                @on-item-selected="getPossiblesParcels"
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.district')"
                :options="parcelList"
                v-model="editModalSaveData.parcel.parcel"
                :selected-value="editModalSaveData.parcel.parcel"
                :disabled="isReadOnly"
                :filterable="true"
                :auto-complete="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_tooltip_parcel"
                :popoverLink="popoverLink_step9_district"
                :isRequired="modalSaveRequired.parcel"
                :isError="editModalSaveErrors.parcel.invalid || editModalParcelLengthError"
                :errorMessage="editModalParcelParcelErrorMessage"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :maxLength="6"
                       :label="$t('properties.step3.landRegisterSheet')"
                       v-model="editModalSaveData.parcel.landRegisterSheet"
                       placeholder=""
                       isRegex="[0-9]{1,5}[A-Za-z0-9]{0,1}"
                       :showHelpIconPopover="true"
                       :disabled="isReadOnly"
                       :popoverTexts="popoverTexts_tooltip_landRegisterSheet"
                       :popoverLink="popoverLink_step3_landRegisterSheet"/>
            <InputText class="mt-2 w-full"
                       :label="$t('properties.step3.corridor')"
                       v-model="editModalSaveData.parcel.corridor"
                       placeholder=""
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :maxLength="3"
                       :disabled="isReadOnly || corridorDisabled"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_corridor"
                       :popoverLink="popoverLink_corridor"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :allowLeadingZero="true"
                       :isOnlyNumber="true"
                       :maxLength="5"
                       :label="$t('properties.step3.parcelDataCounter')"
                       v-model="editModalSaveData.parcel.parcelDataCounter"
                       placeholder=""
                       :disabled="isReadOnly"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_counter_step3"
                       :popoverLink="popoverLink_counter"/>
            <InputText class="mt-2 w-full"
                       :maxLength="4"
                       :label="$t('properties.step3.parcelDataDenominator')"
                       v-model="editModalSaveData.parcel.parcelDataDenominator"
                       placeholder=""
                       :isAlphaNumric="true"
                       :disabled="isReadOnly"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_counter_step3"
                       :popoverLink="popoverLink_counter"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :isOnlyPositive="true"
                       :isOnlyNumber="true"
                       :maxLength="maxlength"
                       :disabled="isReadOnly"
                       :label="$t('properties.step3.areaOfTheLand')"
                       v-model="editModalSaveData.parcel.areaOfTheLand"
                       placeholder=""/>
            <InputText class="mt-2 w-full"
                       :isFloat="true"
                       :decimalNumber="decimalNumber"
                       :maxLength="maxFloatField" :label="$t('properties.step3.shareOfOwnershipCounter')"
                       v-model="editModalSaveData.parcel.shareOfOwnershipCounter"
                       placeholder=""
                       :disabled="isReadOnly"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_shareOfOwnershipCounter"
                       :popoverLink="popoverLink_step3_shareOfOwnershipCounter"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full"
                       :maxLength="7"
                       :isOnlyNumber="true"
                       :isOnlyPositive="true"
                       :label="$t('properties.step3.shareOfOwnershipDenominator')"
                       v-model="editModalSaveData.parcel.shareOfOwnershipDenominator"
                       placeholder=""
                       :disabled="isReadOnly"
                       :showHelpIconPopover="true"
                       :popoverTexts="popoverTexts_shareOfOwnershipCounter"
                       :popoverLink="popoverLink_step3_shareOfOwnershipCounter"/>
            <InputText
                v-if="calculatedAreaShow"
                class="mt-2 w-full"
                :label="$t('properties.step3.calculatedArea')"
                v-model="editModalSaveData.parcel.calculatedArea"
                placeholder=""
                :disabled="true"
            />

            <InputSelect
                v-else
                class="mt-2 w-full z-10"
                v-model="editModalSaveData.parcel.containedInArea"
                :selected-value="editModalSaveData.parcel.containedInArea"
                :label="$t('properties.step3.containedInArea')"
                :options="contained_area_options"
                :disabled="containedInAreaDisabled || isReadOnly"
                overlay-append-to="body"
                placeholder=""
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-6  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="editParcelDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('parcel')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add owner modal -->
    <ModalDialog
      v-if="addOwnerDialog && !isReadOnly"
      :headerText="$t('properties.owners_menu.add_owner')"
      @close="addOwnerDialog=false"
      modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 max-h-700 pt-4">
          <div class="flex justify-between">
            <div class="flex justify-start mt-4">
              <label class="title">{{ $t('properties.step4.information_other_owners') }}</label>
              <VDropdown>
                <img class="help_icon cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                <!-- This will be the content of the popover -->
                <template #popper>
                  <div class="tooltip-content">
                    <div class="space-y-4">
                      <p class=" popover-text" v-for="text in [$t('properties.step4.owner_tooltip')]" v-html="text"
                         v-bind:key="text">
                      </p>
                    </div>
                  </div>
                  <!-- You can put other components too -->

                </template>
              </VDropdown>
            </div>
          </div>
          <div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <!--      section 1-->
          <div class="flex justify-around space-x-10 mt-4">
            <div class="flex justify-around space-x-8 w-full" style="align-items: end;text-align: left;">
              <label class="sub-heading text-left mb-6 w-full">{{ $t('properties.step4.owners_stakholders') }}</label>
            </div>

            <div class="flex justify-around space-x-8 w-full" style="align-items: center;">
              <InputSelect
                v-if="showPeoplePool"
                class="w-full"
                :label="$t('clients.edit.tabs.peoplepool')"
                :options="getClientPersons"
                placeholder=""
                :disabled="isReadOnly || getClientPersons.length === 0"
                :showHelpIconPopover="true"
                :popoverTexts="$t('properties.step4.people_pool_select_help')"
                @on-item-selected="selectClientPerson"
              />

              <div class="flex justify-around space-x-8 w-half" style="align-items: end;text-align: left;">
                <a class="primary-color w-full link" @click="fillAddressFromClient" style="white-space: nowrap;">
                  {{ $t('properties.step2.take_over_from_client') }}
                </a>
              </div>
            </div>
          </div>

          <div class="flex justify-around space-x-10 mt-4">
            <InputSelect
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.salutation"
                :selected-value="addModalSaveData.owner.salutation"
                :label="$t('properties.step4.salutation')"
                overlay-append-to="body"
                :options="owner_salutation_options"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.title"
                :maxLength="15"
                :label="$t('properties.step4.title')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date1"
                v-model="addModalSaveData.owner.dateOfBirth"
                :label="$t('properties.step4.dateOfBirth')"
                placeholder=""
                :dob="true"
            />
            <div class="w-full invisible"></div>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.firstName"
                :label="$t('properties.step4.firstName')"
                :maxLength="25"
                :isRequired="modalSaveRequired.firstName"
                :isError="addModalSaveErrors.firstName.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.lastName"
                :maxLength="25"
                :label="$t('properties.step4.lastName')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.street"
                :maxLength="25"
                :label="$t('properties.step4.street')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.houseNumber"
                :maxLength="39"
                :label="$t('properties.step4.houseNumber')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <div class="mt-2 w-full">
              <InputText
                v-model="addModalSaveData.owner.zip"
                @blur="searchZip('addOwnerDialog')"
                :isZip="true"
                :zipCountry="ownerCountry"
                :label="$t('properties.step4.zip')"
                placeholder=""
              />
              <div class="box_dialog flex justify-start items-center mt-2 mb-2">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-left text-sm ml-2 mb-1">
                  <p>{{ $t('properties.step4.owner_info_for_zip') }}</p>
                </label>
              </div>
            </div>
            <InputSelect
                class="mt-2 w-full"
                :auto-complete="true"
                :options="cityList.addOwnerDialog"
                :loading="zipSearch.addOwnerDialog"
                :filterable="true"
                overlay-append-to="body"
                v-model="addModalSaveData.owner.city"
                :selected-value="addModalSaveData.owner.city"
                :label="$t('properties.step4.city')"
                :maxLength="25"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
              class="mt-2 w-full"
              v-model="addModalSaveData.owner.postbox"
              :maxLength="6"
              :label="$t('properties.step4.postbox')"
              placeholder=""
            />

            <InputText
              class="mt-2 w-full"
              v-model="addModalSaveData.owner.phone"
              :maxLength="16"
              :label="$t('properties.step4.phone')"
              placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.federalStateUid"
                :label="$t('properties.step9.gemeindeBundesland')"
                :filterable="true"
                :options="stateList"
                :placeholder="$t('general.please_select')"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step4.countryUid')"
                v-model="addModalSaveData.owner.countryUid"
                :selected-value="addModalSaveData.owner.countryUid"
                :filterable="true"
                :options="countryList"
                :placeholder="$t('general.please_select')"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.financeAgency"
                :maxLength="25"
                :label="$t('properties.step4.financeAgency')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.taxNumber"
                :maxLength="13"
                :label="$t('properties.step4.taxNumber')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.taxIdentificationNumber"
                :isOnlyNumber="true"
                :maxLength="11"
                :label="$t('properties.step4.taxIdentificationNumber')"
                placeholder=""
            />
          </div>

          <!--section2-->
          <label class="sub-heading text-left mb-4 mt-10">{{ $t('properties.step4.share_in_the_property') }}</label>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full" :isOnlyNumber="true"
                       :maxLength="ownerAreaOfTheLandCounterMaxLength"
                       :isOnlyPositive="true"
                       :label="$t('properties.step4.area_of_the_land_counter')"
                       v-model="addModalSaveData.owner.area_of_the_land_counter" placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="$t('properties.step4.area_of_the_land_counter_help')"
                       :popoverLink="$t('properties.step4.area_of_the_land_counter_help_link')"
            />
            <InputText class="mt-2 w-full" :isOnlyNumber="true"
                       :maxLength="ownerAreaOfTheLandDenominatorMaxLength"
                       :isOnlyPositive="true"
                       :label="$t('properties.step4.area_of_the_land_denominator')"
                       v-model="addModalSaveData.owner.area_of_the_land_denominator" placeholder=""/>
          </div>

          <!--section 3-->
          <label class="sub-heading text-left mb-4 mt-10" style="margin-top: 40px;">{{ $t('properties.step4.legally_represented_by') }}</label>

          <div class="flex justify-around space-x-10">
            <div class="flex justify-start items-center mt-2 mb-2 w-full">
              <div class="box_dialog flex justify-start items-center">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-left text-sm ml-2 mb-1">
                  <p>{{ $t('properties.step4.owner_info_for_representative') }}</p>
                </label>
              </div>
            </div>

            <InputSelect
                v-if="showPeoplePool"
                class="w-full mt-2"
                :label="$t('clients.edit.tabs.peoplepool')"
                :options="getClientPersons"
                placeholder=""
                :disabled="isReadOnly || getClientPersons.length === 0"
                :showHelpIconPopover="true"
                :popoverTexts="$t('properties.step4.people_pool_select_help')"
                @on-item-selected="selectClientRepresentativePerson"
              />
          </div>

          <div class="flex justify-around space-x-10 mt-4">
            <InputSelect
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.salutation"
                :selected-value="addModalSaveData.owner.representative.salutation"
                :label="$t('properties.step4.salutation')"
                :options="representative_salutation_options"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.title"
                :maxLength="15"
                :label="$t('properties.step4.title')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date1"
                v-model="addModalSaveData.owner.representative.dateOfBirth"
                :label="$t('properties.step4.dateOfBirth')"
                placeholder=""
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.firstName"
                :maxLength="25"
                :label="$t('properties.step4.firstName')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.lastName"
                :maxLength="25"
                :label="$t('properties.step4.lastName')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.street"
                :maxLength="25"
                :label="$t('properties.step4.street')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.houseNumber"
                :maxLength="39"
                :label="$t('properties.step4.houseNumber')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.zip"
                @blur="searchZip('addOwnerDialogRepresentative')"
                :isZip="true"
                :zipCountry="representativeCountry"
                :label="$t('properties.step4.zip')"
                placeholder=""
            />
            <InputSelect
                class="mt-2 w-full"
                :auto-complete="true"
                :options="cityList.addOwnerDialogRepresentative"
                :loading="zipSearch.addOwnerDialogRepresentative"
                :filterable="true"
                overlay-append-to="body"
                v-model="addModalSaveData.owner.representative.city"
                :selected-value="addModalSaveData.owner.representative.city"
                :label="$t('properties.step4.city')"
                :maxLength="25"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full mb-5"
                :label="$t('properties.step4.countryUid')"
                v-model="addModalSaveData.owner.representative.countryUid"
                :selected-value="addModalSaveData.owner.representative.countryUid"
                :filterable="true"
                :options="countryList"
                :placeholder="$t('general.please_select')"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.postbox"
                :maxLength="6"
                :label="$t('properties.step4.postbox')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.owner.representative.phone"
                :maxLength="16"
                :label="$t('properties.step4.phone')"
                placeholder=""
            />
            <div class="w-full invisible"></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="addOwnerDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('owner')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit owner modal -->
    <ModalDialog
      v-if="editOwnerDialog"
      :headerText="$t('properties.owners_menu.edit_owner')"
      @close="editOwnerDialog=false"
      modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4  max-h-700 pt-4">
          <div class="flex  justify-start mt-8">

            <label class="title">{{ $t('properties.step4.information_other_owners') }}</label>

            <VDropdown>
              <img class="help_icon cursor-pointer" :src="getAssetPath('help_green.svg')"/>
              <!-- This will be the content of the popover -->
              <template #popper>
                <div class="tooltip-content">
                  <div class="space-y-4">
                    <p class=" popover-text" v-for="text in [$t('properties.step4.owner_tooltip')]" v-html="text"
                       v-bind:key="text">
                    </p>
                  </div>
                </div>
                <!-- You can put other components too -->

              </template>
            </VDropdown>

          </div>
          <div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <!--      section 1-->
          <label class="sub-heading text-left mb-6 mt-10">{{ $t('properties.step4.owners_stakholders') }}</label>
          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                v-model="editModalSaveData.owner.salutation"
                :disabled="isReadOnly"
                :selected-value="editModalSaveData.owner.salutation"
                :label="$t('properties.step4.salutation')"
                overlay-append-to="body"
                :options="owner_salutation_options"
                :placeholder="$t('general.please_select')"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.owner.title"
                :maxLength="15"
                :label="$t('properties.step4.title')"
                placeholder=""
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date2"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.dateOfBirth"
                :label="$t('properties.step4.dateOfBirth')"
                placeholder=""
            />
            <div class="w-full pr-6 invisible"></div>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.firstName"
                :label="$t('properties.step4.firstName')"
                :isRequired="modalSaveRequired.firstName"
                :maxLength="25"
                :isError="editModalSaveErrors.firstName.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.lastName"
                :maxLength="25"
                :label="$t('properties.step4.lastName')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.street"
                :maxLength="25"
                :label="$t('properties.step4.street')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.houseNumber"
                :maxLength="39"
                :label="$t('properties.step4.houseNumber')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <div class="mt-2 w-full">
              <InputText
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.zip"
                @blur="searchZip('editOwnerDialog')"
                :isZip="true"
                :zipCountry="editOwnerCountry"
                :label="$t('properties.step4.zip')"
                placeholder=""
              />
              <div class="box_dialog flex justify-start items-center mt-2 mb-2">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-left text-sm ml-2 mb-1">
                  <p>{{ $t('properties.step4.owner_info_for_zip') }}</p>
                </label>
              </div>
            </div>
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                :auto-complete="true"
                :options="cityList.editOwnerDialog"
                :loading="zipSearch.editOwnerDialog"
                :filterable="true"
                overlay-append-to="body"
                v-model="editModalSaveData.owner.city"
                :selected-value="editModalSaveData.owner.city"
                :label="$t('properties.step4.city')"
                :maxLength="25"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
              class="mt-2 w-full"
              :disabled="isReadOnly"
              v-model="editModalSaveData.owner.postbox"
              :maxLength="6"
              :label="$t('properties.step4.postbox')"
              placeholder=""
            />
            <InputText
              class="mt-2 w-full"
              :disabled="isReadOnly"
              v-model="editModalSaveData.owner.phone"
              :maxLength="16"
              :label="$t('properties.step4.phone')"
              placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.federalStateUid"
                :selected-value="editModalSaveData.owner.federalStateUid"
                :label="$t('properties.step9.gemeindeBundesland')"
                :filterable="true"
                :options="stateList"
                :placeholder="$t('general.please_select')"
            />
            <div class="w-full pr-6 invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                :label="$t('properties.step4.countryUid')"
                v-model="editModalSaveData.owner.countryUid"
                :selected-value="editModalSaveData.owner.countryUid"
                :filterable="true"
                :options="countryList"
                :placeholder="$t('general.please_select')"
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.financeAgency"
                :maxLength="25"
                :label="$t('properties.step4.financeAgency')"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.taxNumber"
                :maxLength="13"
                :label="$t('properties.step4.taxNumber')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.taxIdentificationNumber"
                :isOnlyNumber="true"
                :maxLength="11"
                :label="$t('properties.step4.taxIdentificationNumber')"
                placeholder=""
            />
          </div>

          <!--section2-->
          <label class="sub-heading text-left mb-4 mt-10">{{ $t('properties.step4.share_in_the_property') }}</label>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText class="mt-2 w-full" :isOnlyNumber="true"
                       :maxLength="ownerAreaOfTheLandCounterMaxLength"
                       :disabled="isReadOnly"
                       :isOnlyPositive="true"
                       :label="$t('properties.step4.area_of_the_land_counter')"
                       v-model="editModalSaveData.owner.area_of_the_land_counter" placeholder=""
                       :showHelpIconPopover="true"
                       :popoverTexts="$t('properties.step4.area_of_the_land_counter_help')"
                       :popoverLink="$t('properties.step4.area_of_the_land_counter_help_link')"
            />
            <InputText class="mt-2 w-full" :isOnlyNumber="true"
                       :maxLength="ownerAreaOfTheLandDenominatorMaxLength"
                       :isOnlyPositive="true"
                       :label="$t('properties.step4.area_of_the_land_denominator')"
                       v-model="editModalSaveData.owner.area_of_the_land_denominator" placeholder=""
                       :disabled="isReadOnly"
            />
          </div>

          <!--section 3-->
          <label class="sub-heading text-left mb-4 mt-10" style="margin-top: 40px;">{{ $t('properties.step4.legally_represented_by') }}</label>

          <div class="flex justify-around space-x-10">
            <div class="box_dialog flex justify-start items-center mt-2 mb-2 w-full">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <label class="text-left text-sm ml-2 mb-1">
                <p>{{ $t('properties.step4.owner_info_for_representative') }}</p>
              </label>
            </div>
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.salutation"
                :selected-value="editModalSaveData.owner.representative.salutation"
                :label="$t('properties.step4.salutation')"
                :options="representative_salutation_options"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.title"
                :label="$t('properties.step4.title')"
                :maxLength="15"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date1"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.dateOfBirth"
                :label="$t('properties.step4.dateOfBirth')"
                placeholder=""
            />
            <div class="w-full pr-6 invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.firstName"
                :label="$t('properties.step4.firstName')"
                :maxLength="25"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.lastName"
                :label="$t('properties.step4.lastName')"
                :maxLength="25"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.street"
                :maxLength="25"
                :label="$t('properties.step4.street')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.houseNumber"
                :maxLength="39"
                :label="$t('properties.step4.houseNumber')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.zip"
                @blur="searchZip('editOwnerDialogRepresentative')"
                :isZip="true"
                :zipCountry="editRepresentativeCountry"
                :label="$t('properties.step4.zip')"
                placeholder=""
            />
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                :auto-complete="true"
                :options="cityList.editOwnerDialogRepresentative"
                :loading="zipSearch.editOwnerDialogRepresentative"
                :filterable="true"
                overlay-append-to="body"
                v-model="editModalSaveData.owner.representative.city"
                :selected-value="editModalSaveData.owner.representative.city"
                :label="$t('properties.step4.city')"
                :maxLength="25"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :disabled="isReadOnly"
                :label="$t('properties.step4.countryUid')"
                v-model="editModalSaveData.owner.representative.countryUid"
                :selected-value="editModalSaveData.owner.representative.countryUid"
                :filterable="true"
                :options="countryList"
                :placeholder="$t('general.please_select')"
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.postbox"
                :maxLength="6"
                :label="$t('properties.step4.postbox')"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.owner.representative.phone"
                :maxLength="16"
                :label="$t('properties.step4.phone')"
                placeholder=""
            />
            <div class="w-full pr-6 invisible"></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="editOwnerDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('owner')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- building service modal -->
    <ModalDialog
      v-show="buildingServiceDialog"
      :headerText="$t('properties.buildingparts_menu.building_service')"
      @close="buildingServiceDialog=false"
      modalSize="x-large"
      class="land-modal"
    >
    <template v-slot:body>
      <div class="flex flex-col justify-start space-y-4 max-h-700 pt-4">
        <p class="mb-2 sub-heading">
          {{ $t('properties.building_service_header') }}
        </p>
        <div class="box flex justify-start items-center mb-10">
          <img class="image ml-2" src="@/assets/info_gray.svg"/>
          <div class="ml-4">
            <div class="mb-3 dheader text-left sub-heading">
              {{ $t('properties.living_space_app') }}
            </div>
            <div class="text-info mb-3 text-left" v-html="$t('properties.building_service_desc')">
            </div>
            <div class="mb-2">
              <Button @click="furtherBuildingService" :text="$t('properties.further_information')" :secondary="true"></Button>
            </div>
          </div>
        </div>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="buildingServiceDialog=false" :text="$t('buttons.close')" :secondary="true"></Button>
          </div>
        </div>
      </div>
    </template>
  </ModalDialog>

    <!-- add buildingpart modal -->
    <ModalDialog
        v-show="addBuildingPartDialog && !isReadOnly"
        :headerText="$t('properties.buildingparts_menu.add_buildingpart')"
        @close="addBuildingPartDialog=false"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 max-h-700 pt-4">
          <div class="flex justify-around space-x-10">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.livingSpaceDescription"
                :label="$t('properties.step5.livingSpaceDescription')"
                :isRequired="true"
                :isError="errors.livingSpaceDescription.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
                :maxLength="25"
                :showHelpIcon="true"
                :tooltip="$t('properties.step5.livingSpaceDescriptionHelp')"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.livingSpaceArea"
                :label="$t('properties.step5.livingSpaceArea')"
                :isOnlyNumber="true"
                :isError="errors.livingSpaceArea.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
                :maxLength="9"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.usableSpaceArea"
                :isOnlyNumber="true"
                :label="$t('properties.step5.usableSpaceArea')"
                placeholder=""
                :maxLength="9"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.areaOfTheLandCivilDefense"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense')"
                placeholder=""
                :maxLength="9"
                :infoText="showCivilDefenseInfoBox ? $t('properties.step5.civilDefenseInfoText') : ''"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                v-if="propertyData.step2.federalStateUid === '7'"
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.areaOfTheLandCivilDefenseLiving"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense_living')"
                placeholder=""
                :maxLength="9"
                @input="(val) => calculateAreaOfTheLandCivilDefenseUsageAndLiving(val, 'addModalSaveData', 'areaOfTheLandCivilDefenseUsage')"
            />
            <InputText
                v-if="propertyData.step2.federalStateUid === '7'"
                class="mt-2 w-full"
                v-model="addModalSaveData.buildingPart.areaOfTheLandCivilDefenseUsage"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense_usage')"
                placeholder=""
                :maxLength="9"
                @input="(val) => calculateAreaOfTheLandCivilDefenseUsageAndLiving(val, 'addModalSaveData', 'areaOfTheLandCivilDefenseLiving')"
            />
          </div>
          <div v-if="isExempted">
            <div class="flex flex-col mb-12"
                 v-for="(benefitItem, benefitIndex) in addModalSaveData.buildingPart.benefits"
                 :key="'benefits'+benefitIndex">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title text-left self-start">{{
                      $t('properties.step5.benefit') + " " + (benefitIndex + 1)
                    }} </label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDelete(benefitIndex, 'benefit', 'buildingPart')"/>
                </div>
                <hr class="mt-3 hr-line-user"/>
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.benefitDescription"
                    :label="$t('properties.step5.benefitDescription')"
                    placeholder=""
                    :maxLength="25"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.benefitLivingSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.benefitLivingSpaceArea')"
                    placeholder=""
                    :maxLength="15"
                />
              </div>

              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.benefitUsableSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.benefitUsableSpaceArea')"
                    placeholder=""
                    :isOnlyPositive="benefitUsableSpaceAreaPositive"
                    :maxLength="benefitUsableSpaceAreaMaxLength"
                />
                <InputSelect
                    class="mt-2 w-full max-width-half"
                    v-model="benefitItem.benefit"
                    :selected-value="addModalSaveData.benefit"
                    :label="$t('properties.step5.benefit')"
                    :options="benefit_options"
                    placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end" v-if="isExempted">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreBenefit('buildingPart')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div v-if="isExempted">
            <div class="flex flex-col mb-12"
                 v-for="(exemptionItem, exemptionIndex) in addModalSaveData.buildingPart.exemptions"
                 :key="'exemptions'+exemptionIndex">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title text-left self-start">{{
                      $t('properties.step5.exemption') + " " + (exemptionIndex + 1)
                    }} </label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDelete(exemptionIndex, 'exemption', 'buildingPart')"/>
                </div>
                <hr class="mt-3 hr-line-user"/>
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionDescription"
                    :label="$t('properties.step5.exemptionDescription')"
                    placeholder=""
                    :maxLength="999"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionLivingSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.exemptionLivingSpaceArea')"
                    placeholder=""
                    :maxLength="15"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionUsableSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.exemptionUsableSpaceArea')"
                    placeholder=""
                    :maxLength="15"
                />
                <InputSelect
                    class="mt-2 w-full max-width-half"
                    v-model="exemptionItem.exemption"
                    :selected-value="exemptionItem.exemption"
                    :label="$t('properties.step5.exemption')"
                    :options="exemption_options"
                    placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end" v-if="isExempted">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreExemption('buildingPart')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>
          <!-- <div class="flex justify-around space-x-10 mt-3">
            <InputDate
                class="mt-2 w-full"
                dateId="dateApplicationForNewRegistration"
                v-model="addModalSaveData.dateApplicationForNewRegistration"
                :label="$t('properties.step5.dateApplicationForNewRegistration')"
                placeholder=""
                :isOnlyNumber="true"
            />
            <div class="w-full pr-6 invisible"></div>
          </div> -->
          <div class="flex justify-end  mt-4  ">
            <div class="flex my-6 space-x-4 ">
              <Button @click="addBuildingPartDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
              <Button :text="$t('buttons.save')" @click="addModalData('buildingpart')"></Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit buildingpart modal -->
    <ModalDialog
        v-show="editBuildingPartDialog"
        :headerText="$t('properties.buildingparts_menu.edit_buildingpart')"
        @close="editBuildingPartDialog=false"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 max-h-700 pt-4">
          <InputText
              class="display-none"
              v-model="editModalSaveData.buildingPart.id"
              placeholder=""
              :disabled="isReadOnly"
          />
          <div class="flex justify-around space-x-10">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.livingSpaceDescription"
                :label="$t('properties.step5.livingSpaceDescription')"
                :isRequired="true"
                :isError="errors.livingSpaceDescription.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
                :maxLength="25"
                :showHelpIcon="true"
                :tooltip="$t('properties.step5.livingSpaceDescriptionHelp')"
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.livingSpaceArea"
                :label="$t('properties.step5.livingSpaceArea')"
                :isOnlyNumber="true"
                :isError="errors.livingSpaceArea.invalid"
                :errorMessage="$t('general.errors.required')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_livingSpaceArea"
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.usableSpaceArea"
                :isOnlyNumber="true"
                :maxLength="9"
                :label="$t('properties.step5.usableSpaceArea')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_usableSpaceArea"
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.areaOfTheLandCivilDefense"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense')"
                placeholder=""
                :maxLength="9"
                :disabled="isReadOnly"
                :infoText="showCivilDefenseInfoBox ? $t('properties.step5.civilDefenseInfoText') : ''"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                v-if="propertyData.step2.federalStateUid === '7'"
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.areaOfTheLandCivilDefenseLiving"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense_living')"
                placeholder=""
                :maxLength="9"
                @input="(val) => calculateAreaOfTheLandCivilDefenseUsageAndLiving(val, 'editModalSaveData', 'areaOfTheLandCivilDefenseUsage')"
            />
            <InputText
                v-if="propertyData.step2.federalStateUid === '7'"
                class="mt-2 w-full"
                v-model="editModalSaveData.buildingPart.areaOfTheLandCivilDefenseUsage"
                :isOnlyNumber="true"
                :label="$t('properties.step5.areaOfTheLandCivilDefense_usage')"
                placeholder=""
                :maxLength="9"
                @input="(val) => calculateAreaOfTheLandCivilDefenseUsageAndLiving(val, 'editModalSaveData', 'areaOfTheLandCivilDefenseLiving')"
            />

          </div>
          <div class="flex flex-col mb-12"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.buildingPart.benefits"
               :key="'benefits'+benefitIndex">
            <div class="flex flex-col justify-start mt-5">
              <div class="flex justify-between">
                <label class="title text-left self-start">{{
                    $t('properties.step5.benefit') + " " + (parseInt(benefitIndex) + 1)
                  }} </label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg" v-show="!isReadOnly"
                     @click="handleDeleteBuildingPart(benefitIndex, 'benefit', 'edit')"/>
              </div>
              <hr class="mt-3 hr-line-user"/>
            </div>
            <InputText
                class="display-none"
                v-model="benefitItem.prim_uid"
                placeholder=""
                :disabled="isReadOnly"
            />
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.benefitDescription"
                  :label="$t('properties.step5.benefitDescription')"
                  :maxLength="25"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.benefitLivingSpaceArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step5.benefitLivingSpaceArea')"
                  placeholder=""
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="15"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.benefitUsableSpaceArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step5.benefitUsableSpaceArea')"
                  placeholder=""
                  :disabled="!isExempted || isReadOnly"
                  :isOnlyPositive="benefitUsableSpaceAreaPositive"
                  :maxLength="benefitUsableSpaceAreaMaxLength"
              />
              <InputSelect
                  class="mt-2 w-full max-width-half"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step5.benefit')"
                  :options="benefit_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
              />
            </div>
          </div>

          <div class="flex justify-end" v-if="isExempted && editModalSaveData.buildingPart.benefits.length < 99">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreBenefit('buildingPart' , 'edit')" v-show="!isReadOnly">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-12"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.buildingPart.exemptions"
               :key="'exemptions'+exemptionIndex">
            <div v-if="exemptionItem.prim_uid || exemptionItem.showSection === true">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title text-left self-start">{{
                      $t('properties.step5.exemption') + " " + (parseInt(exemptionIndex) + 1)
                    }} </label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg" v-show="!isReadOnly"
                       @click="handleDeleteBuildingPart(exemptionIndex, 'exemption', 'edit')"/>
                </div>
                <hr class="mt-3 hr-line-user"/>
              </div>
              <InputText
                  class="display-none"
                  v-model="exemptionItem.prim_uid"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionDescription"
                    :label="$t('properties.step5.exemptionDescription')"
                    placeholder=""
                    :disabled="!isExempted || isReadOnly"
                    :maxLength="999"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionLivingSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.exemptionLivingSpaceArea')"
                    placeholder=""
                    :disabled="!isExempted || isReadOnly"
                    :maxLength="15"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemptionUsableSpaceArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step5.exemptionUsableSpaceArea')"
                    placeholder=""
                    :disabled="!isExempted || isReadOnly"
                    :maxLength="15"
                />
                <InputSelect
                    class="mt-2 w-full max-width-half"
                    v-model="exemptionItem.exemption"
                    :selected-value="exemptionItem.exemption"
                    :label="$t('properties.step5.exemption')"
                    :options="exemption_options"
                    :disabled="!isExempted || isReadOnly"
                    placeholder=""
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end" v-if="isExempted && editModalSaveData.buildingPart.exemptions.length < 99">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreExemption('buildingPart', 'edit')" v-show="!isReadOnly">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="editBuildingPartDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('buildingpart')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add residential modal -->
    <ModalDialog
        v-show="addResidentialDialog && !isReadOnly"
        :headerText="$t('properties.residentials_menu.add_residential')"
        @close="addResidentialDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start   space-y-4  pt-4"
             style=" overflow-y: auto; height: calc(100vh - 195px); padding-right: 8px;">

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.general_information') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.yearOfConstruction"
                :label="$t('properties.step6.yearOfConstruction')"
                placeholder=""
                :maxLength="4"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_yearOfConstruction"
                :popoverLink="popoverLink_yearOfConstruction"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.coreRenovationYear"
                :label="$t('properties.step6.coreRenovationYear')"
                placeholder=""
                :maxLength="4"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_coreRenovationYear"
                :popoverLink="popoverLink_coreRenovationYear"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.demolitionObligationYear"
                :maxLength="4"
                :isOnlyNumber="true"
                :label="$t('properties.step6.demolitionObligationYear')"
                placeholder=""
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_demolitionObligationYear"
                :popoverLink="popoverLink_demolitionObligationYear"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.garageUnderground') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.garageUnderground.amountGarages"
                :isOnlyNumber="true"
                :maxLength="4"
                :label="$t('properties.step6.amountGarages')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_amountGarages"
                :popoverLink="popoverLink_amountGarages"
            />
            <div class="w-full invisible"></div>
          </div>


          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in addModalSaveData.residential.garageUnderground.garageUndergroundExemption"
               :key="'garageUndergroundExemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalGarageUndergroundExemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBelow60Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :maxLength="6"
                  :label="$t('properties.step6.apartmentsBelow60TaxFreeArea')"
                  :disabled="!isExempted"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_taxFreeArea"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalGarageUndergroundExemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in addModalSaveData.residential.garageUnderground.garageUndergroundBenefit"
               :key="'garageUndergroundBenefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalGarageUndergroundBenefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  :maxLength="25"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBelow60Benefit')"
                  :disabled="!isExempted"
                  :options="benefit_options_residential"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :maxLength="6"
                  :label="$t('properties.step6.apartmentsBelow60DiscountedArea')"
                  :disabled="!isExempted"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_discountedArea"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalGarageUndergroundBenefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsBelow60') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsBelow60.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsBelow60Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsBelow60.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsBelow60TotalLivingSpace')"
                placeholder=""
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption"
               :key="'apartmentsBelow60Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsBelow60Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBelow60Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBelow60TaxFreeArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsBelow60Exemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit"
               :key="'apartmentsBelow60Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsBelow60Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBelow60Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBelow60DiscountedArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsBelow60Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsBetween60100') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsBetween60100.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsBetween60100Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsBetween60100.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsBetween60100TotalLivingSpace')"
                placeholder=""
            />
          </div>

          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption"
               :key="'apartmentsBetween60100Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsBetween60100Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBetween60100Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBetween60100TaxFreeArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsBetween60100Exemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit"
               :key="'apartmentsBetween60100Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsBetween60100Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBetween60100Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBetween60100DiscountedArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsBetween60100Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsAbove100') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsAbove100.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsAbove100Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsAbove100.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsAbove100TotalLivingSpace')"
                placeholder=""
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption"
               :key="'apartmentsAbove100Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsAbove100Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsAbove100Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsAbove100TaxFreeArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsAbove100Exemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>
          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit"
               :key="'apartmentsAbove100Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsAbove100Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsAbove100Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsAbove100DiscountedArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsAbove100Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsOther') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsOther.counter"
                :isOnlyNumber="true"
                :label="$t('properties.step6.apartmentsOtherCounter')"
                placeholder=""
                :maxLength="3"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.residential.apartmentsOther.totalLivingSpace"
                :isOnlyNumber="true"
                :label="$t('properties.step6.apartmentsOtherTotalLivingSpace')"
                placeholder=""
                :maxLength="6"
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in addModalSaveData.residential.apartmentsOther.apartmentsOtherExemption"
               :key="'apartmentsOtherExemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsOtherExemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsOtherExemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsOtherTaxFreeArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsOtherExemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in addModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit"
               :key="'apartmentsOtherBenefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteAddModalApartmentsOtherBenefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsOtherBenefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsOtherDiscountedArea')"
                  :disabled="!isExempted"
                  placeholder=""
                  :maxLength="6"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalApartmentsOtherBenefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step6.otherSpaces') }}</label>
            <hr class=" mt-3 mb-6 hr-line-user"/>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(usableSpaceItem, usableSpaceIndex) in addModalSaveData.residential.usableSpaces"
               :key="'usableSpace'+usableSpaceIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.usableSpaces') }} {{
                    usableSpaceIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                  @click="handleDeleteAddModalUsableSpaces(usableSpaceIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.locationOfTheRoomsDesignation"
                :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                :disabled="disableOtherSpacesFields"
                placeholder=""
                :maxLength="25"
              />
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.usageType"
                :label="$t('properties.step6.usageType')"
                :disabled="disableOtherSpacesFields"
                placeholder=""
                :maxLength="25"
              />
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.totalSpace"
                :isOnlyNumber="true"
                :label="$t('properties.step6.totalSpace')"
                :disabled="disableOtherSpacesFields"
                placeholder=""
                :maxLength="5"
              />
            </div>

            <div class="flex flex-col mb-1"
                 v-for="(exemptionItem, exemptionIndex) in usableSpaceItem.usableSpacesExemption"
                 :key="'usableSpacesExemption'+exemptionIndex">
              <div class="flex flex-col justify-start mt-6">
                <div class="flex justify-between">
                  <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                    {{ exemptionIndex + 1 }}</label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteAddModalUsableSpacesExemption(usableSpaceIndex, exemptionIndex)"/>
                </div>
              </div>
              <div class="flex justify-around space-x-10 mt-1">
                <InputSelect
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemption"
                    :label="$t('properties.step6.exemption')"
                    :options="residential_exemption_options"
                    :disabled="!isExempted || disableOtherSpacesFields"
                    placeholder=""
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.taxFreeArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step6.taxFreeArea')"
                    :disabled="!isExempted || disableOtherSpacesFields"
                    placeholder=""
                />
              </div>
            </div>
            <div class="flex justify-end mt-3">
            <!-- <div class="flex justify-end mt-3" v-show="isExempted"> -->
              <div class="flex justify-center items-center cursor-pointer"
                   @click="addMoreAddModalUsableSpacesExemption(usableSpaceIndex)">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
              </div>
            </div>

            <div class="flex flex-col mb-1"
                 v-for="(benefitItem, benefitIndex) in usableSpaceItem.usableSpacesBenefit"
                 :key="'usableSpacesBenefit'+benefitIndex">
              <div class="flex flex-col justify-start mt-6">
                <div class="flex justify-between">
                  <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                      benefitIndex + 1
                    }}</label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteAddModalUsableSpacesBenefit(usableSpaceIndex, benefitIndex)"/>
                </div>
              </div>
              <div class="flex justify-around space-x-10 mt-1">
                <InputSelect
                    class="mt-2 w-full max-width-half"
                    v-model="benefitItem.benefit"
                    :label="$t('properties.step6.benefit')"
                    :options="benefit_options_residential"
                    :disabled="!isExempted || disableOtherSpacesFields"
                    placeholder=""
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.discountedArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step6.discountedArea')"
                    :disabled="!isExempted || disableOtherSpacesFields"
                    placeholder=""
                />
              </div>
            </div>
            <div class="flex justify-end mt-3">
            <!-- <div class="flex justify-end mt-3" v-show="isExempted"> -->
              <div class="flex justify-center items-center cursor-pointer"
                   @click="addMoreAddModalUsableSpacesBenefit(usableSpaceIndex)">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-3">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreAddModalUsableSpaces()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_usable_space') }}</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="addResidentialDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('residential')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit residential modal -->
    <ModalDialog
        v-show="editResidentialDialog"
        :headerText="$t('properties.residentials_menu.edit_residential')"
        @close="editResidentialDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 pt-4">
          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.general_information') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.yearOfConstruction"
                :maxLength="4"
                :isOnlyNumber="true"
                :label="$t('properties.step6.yearOfConstruction')"
                placeholder=""
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_yearOfConstruction"
                :popoverLink="popoverLink_yearOfConstruction"
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.coreRenovationYear"
                :maxLength="4"
                :isOnlyNumber="true"
                :label="$t('properties.step6.coreRenovationYear')"
                placeholder=""
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_coreRenovationYear"
                :popoverLink="popoverLink_coreRenovationYear"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.demolitionObligationYear"
                :maxLength="4"
                :isOnlyNumber="true"
                :label="$t('properties.step6.demolitionObligationYear')"
                placeholder=""
                :isOnlyPositive="true"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_demolitionObligationYear"
                :popoverLink="popoverLink_demolitionObligationYear"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.garageUnderground') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.garageUnderground.amountGarages"
                :isOnlyNumber="true"
                :maxLength="4"
                :label="$t('properties.step6.amountGarages')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_amountGarages"
                :popoverLink="popoverLink_amountGarages"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.residential.garageUnderground.garageUndergroundExemption"
               :key="'garageUndergroundExemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalGarageUndergroundExemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  :maxLength="25"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBelow60Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :maxLength="6"
                  :label="$t('properties.step6.apartmentsBelow60TaxFreeArea')"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalGarageUndergroundExemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.residential.garageUnderground.garageUndergroundBenefit"
               :key="'garageUndergroundBenefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalGarageUndergroundBenefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBelow60Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :maxLength="6"
                  :label="$t('properties.step6.apartmentsBelow60DiscountedArea')"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalGarageUndergroundBenefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsBelow60') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsBelow60.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsBelow60Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsBelow60.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsBelow60TotalLivingSpace')"
                placeholder=""
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption"
               :key="'apartmentsBelow60Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsBelow60Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBelow60Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :maxLength="6"
                  :label="$t('properties.step6.apartmentsBelow60TaxFreeArea')"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsBelow60Exemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit"
               :key="'apartmentsBelow60Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsBelow60Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBelow60Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBelow60DiscountedArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsBelow60Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsBetween60100') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsBetween60100.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsBetween60100Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsBetween60100.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsBetween60100TotalLivingSpace')"
                placeholder=""
            />
          </div>

          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption"
               :key="'apartmentsBetween60100Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsBetween60100Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsBetween60100Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBetween60100TaxFreeArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsBetween60100Exemption()">
              <img class="plus" v-if="!isReadOnly" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit"
               :key="'apartmentsBetween60100Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsBetween60100Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsBetween60100Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsBetween60100DiscountedArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsBetween60100Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsAbove100') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsAbove100.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsAbove100Counter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsAbove100.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsAbove100TotalLivingSpace')"
                placeholder=""
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption"
               :key="'apartmentsAbove100Exemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsAbove100Exemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsAbove100Exemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsAbove100TaxFreeArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsAbove100Exemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>
          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit"
               :key="'apartmentsAbove100Benefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsAbove100Benefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsAbove100Benefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsAbove100DiscountedArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsAbove100Benefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title text-left self-start">{{ $t('properties.step6.apartmentsOther') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsOther.counter"
                :isOnlyNumber="true"
                :maxLength="3"
                :label="$t('properties.step6.apartmentsOtherCounter')"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                :disabled="isReadOnly"
                v-model="editModalSaveData.residential.apartmentsOther.totalLivingSpace"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step6.apartmentsOtherTotalLivingSpace')"
                placeholder=""
            />
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.residential.apartmentsOther.apartmentsOtherExemption"
               :key="'apartmentsOtherExemption'+exemptionIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex + 1 }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsOtherExemption(exemptionIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step6.apartmentsOtherExemption')"
                  :options="residential_exemption_options"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsOtherTaxFreeArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsOtherExemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit"
               :key="'apartmentsOtherBenefit'+benefitIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                    benefitIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteEditModalApartmentsOtherBenefit(benefitIndex)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step6.apartmentsOtherBenefit')"
                  :options="benefit_options_residential"
                  :disabled="!isExempted || isReadOnly"
                  placeholder=""
                  overlay-append-to="body"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step6.apartmentsOtherDiscountedArea')"
                  :disabled="!isExempted || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-show="isExempted && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer"
                 @click="addMoreEditModalApartmentsOtherBenefit()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step6.otherSpaces') }}</label>
            <hr class=" mt-3 mb-6 hr-line-user"/>
          </div>

          <div class="flex flex-col mb-1"
               v-for="(usableSpaceItem, usableSpaceIndex) in editModalSaveData.residential.usableSpaces"
               :key="'usableSpace'+usableSpaceIndex">
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.usableSpaces') }} {{
                    usableSpaceIndex + 1
                  }}</label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                  @click="handleDeleteEditModalUsableSpaces(usableSpaceIndex)"/>
              </div>
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.locationOfTheRoomsDesignation"
                :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                :disabled="isReadOnly || disableOtherSpacesFields"
                placeholder=""
                :maxLength="25"
              />
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.usageType"
                :label="$t('properties.step6.usageType')"
                :disabled="isReadOnly || disableOtherSpacesFields"
                placeholder=""
                :maxLength="25"
              />
              <InputText
                class="mt-2 w-full"
                v-model="usableSpaceItem.totalSpace"
                :isOnlyNumber="true"
                :label="$t('properties.step6.totalSpace')"
                :disabled="isReadOnly || disableOtherSpacesFields"
                placeholder=""
                :maxLength="5"
              />
            </div>

            <div class="flex flex-col mb-1"
                 v-for="(exemptionItem, exemptionIndex) in usableSpaceItem.usableSpacesExemption"
                 :key="'usableSpacesExemption'+exemptionIndex">
              <div class="flex flex-col justify-start mt-6">
                <div class="flex justify-between">
                  <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                    {{ exemptionIndex + 1 }}</label>
                  <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteEditModalUsableSpacesExemption(usableSpaceIndex, exemptionIndex)"/>
                </div>
              </div>
              <div class="flex justify-around space-x-10 mt-1">
                <InputSelect
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemption"
                    :selected-value="exemptionItem.exemption"
                    :label="$t('properties.step6.exemption')"
                    :options="residential_exemption_options"
                    :disabled="!isExempted || isReadOnly || disableOtherSpacesFields"
                    placeholder=""
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.taxFreeArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step6.taxFreeArea')"
                    :disabled="!isExempted || isReadOnly || disableOtherSpacesFields"
                    placeholder=""
                />
              </div>
            </div>
            <div class="flex justify-end mt-3">
              <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                   @click="addMoreEditModalUsableSpacesExemption(usableSpaceIndex)">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
              </div>
            </div>
            <div class="flex flex-col mb-1"
                 v-for="(benefitItem, benefitIndex) in usableSpaceItem.usableSpacesBenefit"
                 :key="'usableSpacesBenefit'+benefitIndex">
              <div class="flex flex-col justify-start mt-6">
                <div class="flex justify-between">
                  <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }} {{
                      benefitIndex + 1
                    }}</label>
                  <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteEditModalUsableSpacesBenefit(usableSpaceIndex, benefitIndex)"/>
                </div>
              </div>
              <div class="flex justify-around space-x-10 mt-1">
                <InputSelect
                    class="mt-2 w-full max-width-half"
                    v-model="benefitItem.benefit"
                    :selected-value="benefitItem.benefit"
                    :label="$t('properties.step6.benefit')"
                    :options="benefit_options_residential"
                    :disabled="!isExempted || isReadOnly || disableOtherSpacesFields"
                    placeholder=""
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.discountedArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step6.discountedArea')"
                    :disabled="!isExempted || isReadOnly || disableOtherSpacesFields"
                    placeholder=""
                />
              </div>
            </div>
            <div class="flex justify-end mt-3">
              <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                   @click="addMoreEditModalUsableSpacesBenefit(usableSpaceIndex)">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-3">
            <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                 @click="addMoreEditModalUsableSpaces()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_usable_space') }}</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 mb-10">
            <Button @click="editResidentialDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('residential')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add nonresidential modal -->
    <ModalDialog
        v-show="addNonresidentialDialog && !isReadOnly"
        :headerText="$t('properties.nonresidentials_menu.add_nonresidential')"
        @close="closeNonResidential('add')"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 pt-4" style=" height: 616px">

          <div class="flex justify-around space-x-10">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.locationPlanNumber"
                :label="$t('properties.step7.locationPlanNumber')"
                :maxLength="8"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_locationPlanNumber"
            />
            <InputSelect
                class="mt-2 w-full "
                style="max-width: calc(50% - 20px);"
                v-model="addModalSaveData.nonResidentials.buildingType"
                :selected-value="addModalSaveData.nonResidentials.buildingType"
                :label="$t('properties.step7.buildingType')"
                :options="building_type_options"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_buildingType"
                :popoverLink="popoverLink_buildingType"
                :isError="addModalSaveErrors.nonResidentialBuildingType.invalid"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.yearOfConstruction"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="4"
                :label="$t('properties.step7.yearOfConstruction')"
                placeholder=""
                :errorMessage="$t('general.invalidYear')"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_yearOfConstruction"
                :popoverLink="popoverLink_yearOfConstruction"
                :isError="addModalSaveErrors.nonResidentialYearOfConstruction.invalid"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.coreRenovationYear"
                :isOnlyNumber="true"
                :maxLength="4"
                :isOnlyPositive="true"
                :label="$t('properties.step7.coreRenovationYear')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_coreRenovationYear"
                :popoverLink="popoverLink_coreRenovationYear"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.demolitionObligationYear"
                :isOnlyNumber="true"
                :maxLength="4"
                :isOnlyPositive="true"
                :label="$t('properties.step7.demolitionObligationYear')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_demolitionObligationYear"
                :popoverLink="popoverLink_demolitionObligationYear"
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.areaOfTheLand"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step7.areaOfTheLand')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_areaOfTheLand"
                :popoverLink="popoverLink_areaOfTheLand"
                :isError="addModalSaveErrors.nonResidentialAreaOfTheLand.invalid"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.nonResidentials.areaOfTheLandCivilDefense"
                :isOnlyNumber="true"
                :maxLength="6"
                :label="$t('properties.step7.areaOfTheLandCivilDefense')"
                placeholder=""
                :infoText="showCivilDefenseInfoBox ? $t('properties.step7.civilDefenseInfo') : ''"
            />
            <div class="mt-2 w-full invisible"/>
          </div>

          <div v-if="isExempted">
            <div class="flex flex-col mb-12 mb-3"
                 v-for="(exemptionItem, exemptionIndex) in addModalSaveData.nonResidentials.exemptions"
                 :key="'exemptions'+exemptionIndex">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title text-left self-start">{{
                      $t('properties.step7.exemption') + " " + (exemptionIndex + 1)
                    }} </label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDelete(exemptionIndex, 'exemption', 'nonResidentials')"/>
                </div>
                <hr class=" mt-3 mb-4 hr-line-user"/>
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.locationOfTheRoomsDesignation"
                    :maxLength="25"
                    :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                    placeholder=""
                />
                <InputSelect
                    class="mt-2 w-full"
                    v-model="exemptionItem.exemption"
                    :selected-value="exemptionItem.exemption"
                    :label="$t('properties.step7.exemption').toString()"
                    :options="exemption_options"
                    placeholder=""
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_exemption"
                    :popoverLink="popoverLink_exemption"
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="exemptionItem.taxFreeArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step7.taxFreeArea')"
                    placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end" v-if="isExempted">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreExemption('nonresidential')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div v-if="isExempted">
            <div class="flex flex-col mb-12 mb-3"
                 v-for="(benefitItem, benefitIndex) in addModalSaveData.nonResidentials.benefits"
                 :key="'benefits'+benefitIndex">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title text-left self-start">{{
                      $t('properties.step7.benefit') + " " + (benefitIndex + 1)
                    }} </label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDelete(benefitIndex, 'benefit', 'nonResidentials')"/>
                </div>
                <hr class=" mt-3 mb-4 hr-line-user"/>
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.locationOfTheRoomsDesignation"
                    :maxLength="25"
                    :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                    placeholder=""
                />
                <InputSelect
                    class="mt-2 w-full"
                    v-model="benefitItem.benefit"
                    :selected-value="addModalSaveData.benefit"
                    :label="$t('properties.step7.benefit').toString()"
                    :options="benefit_options_non_residential"
                    placeholder=""
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_step7_benefit"
                    :popoverLink="popoverLink_benefit"
                    overlay-append-to="body"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="benefitItem.discountedArea"
                    :isOnlyNumber="true"
                    :label="$t('properties.step7.discountedArea')"
                    placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end" v-if="isExempted">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreBenefit('nonResidentials')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="closeNonResidential('add')" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('nonresidential')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit nonresidential modal -->
    <ModalDialog
        v-show="editNonresidentialDialog"
        :headerText="$t('properties.nonresidentials_menu.edit_nonresidential')"
        @close="closeNonResidential('edit')"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 pt-4">
          <InputText
              class="display-none"
              v-model="editModalSaveData.nonResidentials.id"
              placeholder=""
          />
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.locationPlanNumber"
                :label="$t('properties.step7.locationPlanNumber')"
                :maxLength="8"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_locationPlanNumber"
                :disabled="isReadOnly"
            />
            <InputSelect
                class="mt-2 w-full"
                style="max-width: calc(50% - 20px);"
                v-model="editModalSaveData.nonResidentials.buildingType"
                :selected-value="editModalSaveData.nonResidentials.buildingType"
                :label="$t('properties.step7.buildingType').toString()"
                :options="building_type_options"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_buildingType"
                :popoverLink="popoverLink_buildingType"
                :disabled="isReadOnly"
                :isError="editModalSaveErrors.nonResidentialBuildingType.invalid"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.yearOfConstruction"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="4"
                :label="$t('properties.step7.yearOfConstruction')"
                placeholder=""
                :errorMessage="$t('general.invalidYear')"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_yearOfConstruction"
                :popoverLink="popoverLink_yearOfConstruction"
                :disabled="isReadOnly"
                :isError="editModalSaveErrors.nonResidentialYearOfConstruction.invalid"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.coreRenovationYear"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :label="$t('properties.step7.coreRenovationYear')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_coreRenovationYear"
                :popoverLink="popoverLink_coreRenovationYear"
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.demolitionObligationYear"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :label="$t('properties.step7.demolitionObligationYear')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_demolitionObligationYear"
                :popoverLink="popoverLink_demolitionObligationYear"
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.areaOfTheLand"
                :isOnlyNumber="true"
                :label="$t('properties.step7.areaOfTheLand')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step7_areaOfTheLand"
                :popoverLink="popoverLink_areaOfTheLand"
                :disabled="isReadOnly"
                :isError="editModalSaveErrors.nonResidentialAreaOfTheLand.invalid"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.nonResidentials.areaOfTheLandCivilDefense"
                :isOnlyNumber="true"
                :label="$t('properties.step7.areaOfTheLandCivilDefense')"
                placeholder=""
                :disabled="isReadOnly"
                :infoText="showCivilDefenseInfoBox ? $t('properties.step7.civilDefenseInfo') : ''"
            />
            <div class="mt-2 w-full invisible"/>
          </div>

          <div class="flex flex-col mb-12 mb-3"
               v-for="(exemptionItem, exemptionIndex) in editModalSaveData.nonResidentials.exemptions"
               :key="'exemptions'+exemptionIndex">
            <div class="flex flex-col justify-start mt-5">
              <div class="flex justify-between">
                <label class="title text-left self-start">{{
                    $t('properties.step7.exemption') + " " + (parseInt(exemptionIndex) + 1)
                  }} </label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteNonRes(exemptionIndex, 'exemption', 'edit')"/>
              </div>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <InputText
                class="display-none"
                v-model="exemptionItem.prim_uid"
                placeholder=""
            />
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.locationOfTheRoomsDesignation"
                  :maxLength="25"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  placeholder=""
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_step7_coreRenovationYear"
                  :popoverLink="popoverLink_coreRenovationYear"
                  :disabled="!isExempted || isReadOnly"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="exemptionItem.exemption"
                  :selected-value="exemptionItem.exemption"
                  :label="$t('properties.step7.exemption').toString()"
                  :options="exemption_options"
                  placeholder=""
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_step7_demolitionObligationYear"
                  :popoverLink="popoverLink_demolitionObligationYear"
                  :disabled="!isExempted || isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem.taxFreeArea"
                  :isOnlyNumber="true"
                  :label="$t('properties.step7.taxFreeArea')"
                  placeholder=""
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_step7_areaOfTheLand"
                  :popoverLink="popoverLink_areaOfTheLand"
                  :disabled="!isExempted || isReadOnly"
              />
            </div>
          </div>
          <div class="flex justify-end" v-if="isExempted || editModalSaveData.nonResidentials.exemptions.length < 99">
            <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                 @click="addMoreExemption('nonResidentials' , 'edit')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col mb-12 mb-3"
               v-for="(benefitItem, benefitIndex) in editModalSaveData.nonResidentials.benefits"
               :key="'benefits'+benefitIndex">
            <div class="flex flex-col justify-start mt-5">
              <div class="flex justify-between">
                <label class="title text-left self-start">{{
                    $t('properties.step7.benefit') + " " + (parseInt(benefitIndex) + 1)
                  }} </label>
                <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteNonRes(benefitIndex, 'benefit', 'edit')"/>
              </div>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <InputText
                class="display-none"
                v-model="benefitItem.prim_uid"
                placeholder=""
            />
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.locationOfTheRoomsDesignation"
                  :maxLength="25"
                  :label="$t('properties.step6.locationOfTheRoomsDesignation')"
                  placeholder=""
                  :disabled="!isExempted || isReadOnly"
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="benefitItem.benefit"
                  :selected-value="benefitItem.benefit"
                  :label="$t('properties.step7.benefit').toString()"
                  :options="benefit_options_non_residential"
                  placeholder=""
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_step7_benefit"
                  :popoverLink="popoverLink_benefit"
                  :disabled="!isExempted || isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem.discountedArea"
                  :isOnlyNumber="true"
                  :maxLength="15"
                  :label="$t('properties.step7.discountedArea')"
                  placeholder=""
                  :disabled="!isExempted || isReadOnly"
              />
            </div>
          </div>
          <div class="flex justify-end" v-if="isExempted || editModalSaveData.nonResidentials.benefits.length < 99">
            <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                 @click="addMoreBenefit('nonResidentials' , 'edit')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="closeNonResidential('edit')" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('nonresidential')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add leasehold modal -->
    <ModalDialog
        v-show="addLeaseholdDialog && !isReadOnly"
        :headerText="$t('properties.leaseholds_menu.add_leasehold')"
        @close="addLeaseholdDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 overflow-auto max-h-700 pt-4">
          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title">{{ $t('properties.step8.leaseholds') }}</label>
            </div>
            <div class="flex justify-end mt-8 link">
              <a class="primary-color mr-4" @click="fillAddressFromClientLh">
                {{ $t('properties.step2.take_over_from_client') }}
              </a>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full max-width-one-of-third"
                v-model="addModalSaveData.leasehold.formOfAddress"
                :label="$t('properties.step8.formOfAddress')"
                :options="form_of_address_options"
                overlay-append-to="body"
                placeholder=""
                :disabled="formOfAddressDisabled"
            />
            <InputText
                class="mt-2 w-full max-width-one-of-third"
                v-model="addModalSaveData.leasehold.titleAcademicDegree"
                :label="$t('properties.step8.titleAcademicDegree')"
                placeholder=""
                :maxLength="15"
                :disabled="titleAcademicDegreeDisabled"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.firstName"
                :label="$t('properties.step8.firstName')"
                :maxLength="25"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.lastName"
                :label="$t('properties.step8.lastName')"
                :maxLength="25"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full invisible"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.street"
                :label="$t('properties.step8.street')"
                :maxLength="25"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.houseNumber"
                :label="$t('properties.step8.houseNumber')"
                :maxLength="39"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.zip"
                :label="$t('properties.step8.zip')"
                @blur="searchZip('leaseholdAddDialog')"
                :isZip="true"
                :zipCountry="leaseholdCountry"
                placeholder=""
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :auto-complete="true"
                :options="cityList.leaseholdAddDialog"
                :loading="zipSearch.leaseholdAddDialog"
                :filterable="true"
                overlay-append-to="body"
                v-model="addModalSaveData.leasehold.city"
                :selected-value="addModalSaveData.leasehold.city"
                :label="$t('properties.step8.city')"
                :maxLength="25"
                placeholder=""
            />
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.leasehold.postbox"
                :label="$t('properties.step8.postbox')"
                :maxLength="6"
                placeholder=""
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step8.countryUid')"
                v-model="addModalSaveData.leasehold.countryUid"
                :selected-value="defaultCountryId"
                :filterable="true"
                overlay-append-to="body"
                :options="countryList"
                :placeholder="$t('general.please_select')"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3 mb-10">
            <InputText
                class="mt-2 w-full max-width-one-of-third"
                v-model="addModalSaveData.leasehold.telephoneNumber"
                :label="$t('properties.step8.telephoneNumber')"
                placeholder=""
                :maxLength="25"
                :disabled="telephoneNumberDisabled"
            />
            <div class="w-full invisible"></div>
            <div class="w-full invisible"></div>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="addLeaseholdDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('leasehold')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="importModal && !isReadOnly" :headerText="$t('clients.import.title')" @close="importModal=false">
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="box flex justify-start items-center mt-6">
            <img class="image ml-2 self-center mr-2" src="@/assets/info_gray.svg"/>
            <label class="text ml-2">
              <p>{{ importModalLabel }} <a href="https://hilfe.grundsteuer-digital.de/faq/importvorlagen-land-und-forstwirtschaft" target="_blank">{{ importModalLink }}</a>.</p>
              <p class="mt-1" v-show="importModalLabel2 !== ''">
                {{ importModalLabel2 }}
              </p>
              <p class="mt-1" v-show="importModalLabel3 !== ''">
                {{ importModalLabel3 }}
              </p>
            </label>
          </div>
          <div class="flex justify-around mt-6 space-x-10">
            <InputUpload class="w-full" :label="$t('clients.import.select')" @select="fileSelect" accept=".xls,.xlsx,.csv" :isRequired=true />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-12">
          <div class="flex my-6 space-x-4 ">
            <Button @click="importModal=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button class="w-52" @click="handleLufImport" :text="$t('clients.import.upload_review')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit leasehold modal -->
    <ModalDialog
        v-show="editLeaseholdDialog"
        :headerText="$t('properties.leaseholds_menu.edit_leasehold')"
        @close="editLeaseholdDialog=false"
        modalSize="x-large"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4 pt-4">
          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title">{{ $t('properties.step8.leaseholds') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full max-width-one-of-third"
                v-model="editModalSaveData.leasehold.formOfAddress"
                :selected-value="editModalSaveData.leasehold.formOfAddress"
                :label="$t('properties.step8.formOfAddress')"
                :options="form_of_address_options"
                overlay-append-to="body"
                placeholder=""
                :disabled="formOfAddressDisabled || isReadOnly"
            />
            <InputText
                class="mt-2 w-full max-width-one-of-third"
                v-model="editModalSaveData.leasehold.titleAcademicDegree"
                :label="$t('properties.step8.titleAcademicDegree')"
                placeholder=""
                :maxLength="15"
                :disabled="titleAcademicDegreeDisabled || isReadOnly"
            />
            <div class="w-full invisible"></div>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.firstName"
                :label="$t('properties.step8.firstName')"
                :maxLength="25"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.lastName"
                :label="$t('properties.step8.lastName')"
                :maxLength="25"
                placeholder=""
                :disabled="isReadOnly"
            />
            <div class="mt-2 w-full invisible"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.street"
                :label="$t('properties.step8.street')"
                :maxLength="25"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.houseNumber"
                :label="$t('properties.step8.houseNumber')"
                :maxLength="39"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.zip"
                @blur="searchZip('leaseholdEditDialog')"
                :label="$t('properties.step8.zip')"
                :isZip="true"
                :zipCountry="editLeaseholdCountry"
                placeholder=""
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :auto-complete="true"
                :options="cityList.leaseholdEditDialog"
                :loading="zipSearch.leaseholdEditDialog"
                :filterable="true"
                overlay-append-to="body"
                :selectedValue="editModalSaveData.leasehold.city"
                v-model="editModalSaveData.leasehold.city"
                :label="$t('properties.step8.city')"
                :maxLength="25"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.leasehold.postbox"
                :label="$t('properties.step8.postbox')"
                :maxLength="6"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step8.countryUid')"
                v-model="editModalSaveData.leasehold.countryUid"
                :selected-value="editModalSaveData.leasehold.countryUid"
                :filterable="true"
                overlay-append-to="body"
                :options="countryList"
                :placeholder="$t('general.please_select')"
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3 mb-10">
            <InputText
                class="mt-2 w-full max-width-one-of-third"
                v-model="editModalSaveData.leasehold.telephoneNumber"
                :label="$t('properties.step8.telephoneNumber')"
                placeholder=""
                :maxLength="25"
                :disabled="telephoneNumberDisabled || isReadOnly"
            />
            <div class="w-full invisible"></div>
            <div class="w-full invisible"></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="editLeaseholdDialog=false" :text="$t('buttons.cancel')" :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('leasehold')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- add parcelsLandAndForestry modal -->
    <ModalDialog
        v-show="addParcelsLandAndForestryDialog && !isReadOnly"
        :headerText="$t('properties.parcelslandandforestry_menu.add_parcelslandandforestry')"
        @close="closeAddParcelsLandAndForestryDialog()"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">

          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title">{{ $t('properties.step9.parcelsLandAndForestryGemeinde') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :isError="errors.federalStateUid.invalid"
                :errorMessage="$t('general.errors.required')"
                v-model="addModalSaveData.parcelsLandAndForestry.federalStateUid"
                :label="$t('properties.step9.gemeindeBundesland')"
                :filterable="true"
                :options="stateList"
                :isRequired="true"
                :placeholder="$t('general.please_select')"
                :selected-value="addModalSaveData.parcelsLandAndForestry.federalStateUid"
                @on-item-selected="getPossibleCommunitiesLandAndForestry"
            />
            <InputSelect
                class="mt-2 w-full"
                v-model="addModalSaveData.parcelsLandAndForestry.community"
                :selected-value="addModalSaveData.parcelsLandAndForestry.community"
                :label="$t('properties.step9.parcelsLandAndForestryGemeinde')"
                :options="communityListLandAndForestry"
                :filterable="true"
                :auto-complete="true"
                :placeholder="$t('general.please_select')"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_parcel"
                :popoverLink="popoverLink_parcel"
                @on-item-selected="getPossiblesParcelsLandAndForestry"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="addModalSaveData.parcelsLandAndForestry.description"
                :label="$t('properties.step9.parcelsLandAndForestryDescription')"
                :isOnlyNumber="false"
                placeholder=""
                :showHelpIcon="true"
                :tooltip="$t('properties.step9.tooltip.description1')"
            />
            <div class="w-full pr-6 invisible"></div>
          </div>
          <div class="flex flex-col mt-8" v-for="(additemParcel, parcelIndex) in addModalSaveData.parcelsLandAndForestry.parcels"
               :key="'parcels'+parcelIndex">
            <div class="flex flex-col justify-start mt-5">
              <div class="flex justify-between">
                <label class="title">{{ $t('properties.step9.parcelsLandAndForestry') }}{{
                    parcelIndex === 0 ? 1 : parcelIndex + 1
                  }}</label>
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteItem(index = null, parcelIndex, 'parcel')"/>
              </div>
              <hr class="mt-3 hr-line-user"/>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputSelect
                  class="mt-2 w-full"
                  :label="$t('properties.step9.district')"
                  :options="parcelListLandAndForestry"
                  v-model="additemParcel.district"
                  :selected-value="additemParcel.district"
                  :filterable="true"
                  :auto-complete="true"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverText_step9_district"
                  :popoverLink="popoverLink_step9_district"
                  @on-item-selected="selectParcelDistrictAdd"
                  @change="changeParcelDistrictAdd(additemParcel)"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.district_number"
                  :label="$t('properties.step9.districtNumber')"
                  :isOnlyNumber="true"
                  placeholder=""
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverText_step9_districtNumber"
                  :popoverLink="popoverLink_step9_district_number"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.corridor"
                  :label="$t('properties.step9.corridor')"
                  :maxLength="3"
                  :isOnlyPositive="true"
                  :isOnlyNumber="true"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_corridor"
                  :popoverLink="popoverLink_corridor"
                  :disabled="step9CorridorDisabled"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.landRegisterSheet"
                  :label="$t('properties.step9.landRegisterSheet')"
                  :maxLength="6"
                  :disabled="step9LandRegisterSheetDisabled"
                  placeholder=""
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.counter"
                  :label="$t('properties.step9.counter')"
                  :isOnlyNumber="true"
                  :maxLength="5"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_counter"
                  :popoverLink="popoverLink_counter"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.denominator"
                  :label="$t('properties.step9.denominator')"
                  :maxLength="step9DenominatorMaxLength"
                  :isAlphaNumric="true"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_denomination"
                  :popoverLink="popoverLink_denomination"
                  placeholder=""
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="additemParcel.areaOfTheLand"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="step9AreaOfTheLandMaxLength"
                  :label="$t('properties.step9.areaOfTheLandone')"
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step9.tooltip.areaOfTheLand')"
                  placeholder=""
              />
              <div class="w-full invisible"></div>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
                <InputCheck
                  class="w-full mt-6 ml-2"
                  :text="$t('properties.step9.useParcelAsReferenceParcel')"
                  :isChecked="additemParcel.isReference"
                  @check="useParcelAsReferenceParcelAddCheck"
                  v-model="additemParcel.isReference"
                  @change="useParcelAsReferenceParcelAddChange(additemParcel)"
                  :showHelpIcon="true"
                  :helpText="$t('properties.step9.useParcelAsReferenceParcelDesc')"
                  :isDisabled="isReadOnly || isDisabledLuFParcelReferenceCheckbox(parcelIndex)"
                />
                <div class="w-full invisible"></div>
              </div>
            <div class="flex flex-col mt-8" v-for="(addItemUsageType, itemIndex) in additemParcel.usageTypes"
                 :key="'usageTypes'+itemIndex">
              <div class="flex flex-col justify-start">
                <div class="flex justify-between">
                  <label class="title">{{ $t('properties.step9.usageTypes') }} {{
                      itemIndex === 0 ? 1 : itemIndex + 1
                    }}</label>
                  <img class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteItem(parcelIndex, itemIndex, 'usage')"/>
                </div>
              </div>
              <div class="flex justify-around space-x-10 mt-2">
                <InputSelect
                    class="mt-2 w-full"
                    :label="$t('properties.step9.usageType')"
                    v-model="addItemUsageType.usageType"
                    :selected-value="addItemUsageType.usageType"
                    :options="usage_type_options"
                    :placeholder="$t('general.please_select')"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_usageType"
                    :popoverLink="popoverLink_usageType"
                    @input="checkUsageType(parcelIndex, itemIndex, addItemUsageType.usageType)"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="addItemUsageType.areaOfTheLand"
                    :label="$t('properties.step9.areaOfTheLand')"
                    :maxLength="8"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    placeholder=""
                    :disabled="addItemUsageType.areaOfTheLandDisabled"
                />
              </div>

              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="addItemUsageType.yieldMeasurementNumber"
                    :label="$t('properties.step9.yieldMeasurementNumber')"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    :maxLength="step9YieldMeasurementNumberMaxLength"
                    placeholder=""
                    :showHelpIcon="true"
                    :tooltip="$t('properties.step9.tooltip.yieldMeasurementNumber')"
                    :disabled="addItemUsageType.yieldMeasurementNumberDisabled"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="addItemUsageType.areaOfFarmBuildings"
                    :maxLength="8"
                    :label="$t('properties.step9.areaOfFarmBuildings')"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    placeholder=""
                    :showHelpIcon="true"
                    :tooltip="$t('properties.step9.tooltip.areaOfFarmBuildings')"
                    :disabled="addItemUsageType.areaOfFarmBuildingDisabled"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3 content-width-half">
                <InputText
                    class="mt-2 w-full"
                    v-model="addItemUsageType.flowRate"
                    :maxLength="4"
                    :label="$t('properties.step9.flowRate')"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    placeholder=""
                    :disabled="addItemUsageType.flowRateDisabled"
                />
                <InputSelect
                    class="mt-2 w-full custom-max-width"
                    :label="$t('properties.step9.parcelsLandAndForestryExemption')"
                    :filterable="true"
                    v-model="addItemUsageType.exemption"
                    :selected-value="addItemUsageType.exemption"
                    :options="landAndForestryExemptionListOpts"
                    :disabled="parcelsLandAndForestryExemptionDisabled"
                    :placeholder="$t('general.please_select')"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="addItemUsageType.exemption_area"
                    :label="$t('properties.step9.favoredArea')"
                    :disabled="favoredAreaDisabled"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    :maxLength="15"
                    placeholder=""
                />
                <div class="w-full pr-6 invisible"></div>
              </div>
            </div>
            <div class="flex justify-end mt-2 mr-4">
              <div class="flex justify-center items-center cursor-pointer"
                   @click="addItemUsageTypeMore(parcelIndex)">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step9.addUsageType') }}</label>
              </div>
            </div>
          </div>
          <hr class=" mt-3 mb-1 hr-line-user"/>
          <div class="flex justify-end mt-2 mr-4">
            <div class="flex justify-center items-center cursor-pointer" @click="addItemParcelItem()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step9.addFlurstück') }}</label>
            </div>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="closeAddParcelsLandAndForestryDialog()" :text="$t('buttons.cancel')"
                    :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="addModalData('parcelslandandforestry')"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- edit parcelsLandAndForestry modal -->
    <ModalDialog
        v-show="editParcelsLandAndForestryDialog"
        :headerText="$t('properties.parcelslandandforestry_menu.edit_parcelslandandforestry')"
        @close="closeEditParcelsLandAndForestryDialog()"
        modalSize="x-large"
        class="land-modal"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start space-y-4">
          <div class="flex flex-col justify-start">
            <div class="flex justify-between">
              <label class="title">{{ $t('properties.step9.parcelsLandAndForestryGemeinde') }}</label>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10">
            <InputSelect
                class="mt-2 w-full"
                :isError="errors.federalStateUid.invalid"
                :errorMessage="$t('general.errors.required')"
                v-model="editModalSaveData.parcelsLandAndForestry.federalStateUid"
                :label="$t('properties.step9.gemeindeBundesland')"
                :filterable="true"
                :options="stateList"
                :isRequired="true"
                :placeholder="$t('general.please_select')"
                :selected-value="propertyGemeindeFederalStateId"
                @on-item-selected="getPossibleCommunitiesLandAndForestry"
                :disabled="isReadOnly"
            />
            <InputSelect
                class="mt-2 w-full"
                v-model="editModalSaveData.parcelsLandAndForestry.community"
                :selected-value="editModalSaveData.parcelsLandAndForestry.community"
                :label="$t('properties.step9.parcelsLandAndForestryGemeinde')"
                :options="communityListLandAndForestry"
                :filterable="true"
                :auto-complete="true"
                :placeholder="$t('general.please_select')"
                @on-item-selected="getPossiblesParcelsLandAndForestry"
                :disabled="isReadOnly"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class="mt-2 w-full"
                v-model="editModalSaveData.parcelsLandAndForestry.description"
                :label="$t('properties.step9.parcelsLandAndForestryDescription')"
                :isOnlyNumber="false"
                placeholder=""
                :showHelpIcon="true"
                :tooltip="$t('properties.step9.tooltip.description1')"
                :disabled="isReadOnly"
            />
            <div class="w-full pr-6 invisible"></div>
          </div>
          <div class="flex flex-col mt-8" v-for="(itemParcel, parcelIndex) in editModalSaveData.parcelsLandAndForestry.parcels"
               :key="'parcels'+parcelIndex">
            <div v-if="itemParcel.prim_uid || itemParcel.showParcelSection === true">
              <div class="flex flex-col justify-start mt-5">
                <div class="flex justify-between">
                  <label class="title">{{ $t('properties.step9.parcelsLandAndForestry') }}{{
                      parcelIndex === 0 ? 1 : (parseInt(parcelIndex) + 1)
                    }}</label>
                  <img v-if="!isReadOnly" class="delete delete-icon" src="@/assets/delete_user_green.svg"
                       @click="handleDeleteItem(index = null, parcelIndex, 'parcel', 'edit')"/>
                </div>
                <hr class="mt-3 hr-line-user"/>
              </div>
              <InputText
                  class="mt-2 w-full display-none"
                  v-model="itemParcel.prim_uid"
                  placeholder=""
              />
              <div class="flex justify-around space-x-10 mt-3">
                <InputSelect
                    class="mt-2 w-full"
                    :label="$t('properties.step9.district')"
                    :options="parcelListLandAndForestry"
                    v-model="itemParcel.district"
                    :selected-value="itemParcel.district"
                    :filterable="true"
                    :auto-complete="true"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverText_step9_district"
                    :popoverLink="popoverLink_step9_district"
                    :disabled="isReadOnly"
                    @on-item-selected="selectParcelDistrictEdit"
                    @change="changeParcelDistrictEdit(itemParcel)"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.district_number"
                    :label="$t('properties.step9.districtNumber')"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverText_step9_districtNumber"
                    :popoverLink="popoverLink_step9_district_number"
                    placeholder=""
                    :disabled="isReadOnly"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.corridor"
                    :maxLength="3"
                    :isOnlyPositive="true"
                    :isOnlyNumber="true"
                    :label="$t('properties.step9.corridor')"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_corridor"
                    :popoverLink="popoverLink_corridor"
                    :disabled="step9CorridorDisabled || isReadOnly"
                    placeholder=""
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.landRegisterSheet"
                    :label="$t('properties.step9.landRegisterSheet')"
                    :maxLength="6"
                    :disabled="step9LandRegisterSheetDisabled || isReadOnly"
                    placeholder=""
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.counter"
                    :label="$t('properties.step9.counter')"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    :maxLength="5"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_counter"
                    :popoverLink="popoverLink_counter"
                    placeholder=""
                    :disabled="isReadOnly"
                />
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.denominator"
                    :label="$t('properties.step9.denominator')"
                    :maxLength="step9DenominatorMaxLength"
                    :isAlphaNumric="true"
                    :showHelpIconPopover="true"
                    :popoverTexts="popoverTexts_denomination"
                    :popoverLink="popoverLink_denomination"
                    placeholder=""
                    :disabled="isReadOnly"
                />
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputText
                    class="mt-2 w-full"
                    v-model="itemParcel.areaOfTheLand"
                    :isOnlyNumber="true"
                    :isOnlyPositive="true"
                    :maxLength="step9AreaOfTheLandMaxLength"
                    :label="$t('properties.step9.areaOfTheLandone')"
                    :showHelpIcon="true"
                    :tooltip="$t('properties.step9.tooltip.areaOfTheLand')"
                    placeholder=""
                    :disabled="isReadOnly"
                />
                <div class="w-full invisible"></div>
              </div>
              <div class="flex justify-around space-x-10 mt-3">
                <InputCheck
                  class="w-full mt-6 ml-2"
                  :text="$t('properties.step9.useParcelAsReferenceParcel')"
                  :isChecked="itemParcel.isReference"
                  @check="useParcelAsReferenceParcelEditCheck"
                  @change="useParcelAsReferenceParcelEditChange(itemParcel)"
                  :showHelpIcon="true"
                  :helpText="$t('properties.step9.useParcelAsReferenceParcelDesc')"
                  :isDisabled="isReadOnly || isDisabledLuFParcelReferenceCheckbox(parcelIndex)"
                />
                <div class="w-full invisible"></div>
              </div>

              <div class="flex flex-col mt-8" v-for="(itemUsageType, itemIndex) in itemParcel.usageTypes"
                   :key="'usageTypes'+itemIndex">
                <div v-if="itemUsageType.prim_uid || itemUsageType.showSection === true">
                  <div class="flex flex-col justify-start">
                    <div class="flex justify-between">
                      <label class="title">{{ $t('properties.step9.usageTypes') }}
                        {{ itemIndex === 0 ? 1 : (parseInt(itemIndex) + 1) }}</label>
                      <img class="delete delete-icon" v-if="!isReadOnly" src="@/assets/delete_user_green.svg"
                           @click="handleDeleteItem(parcelIndex, itemIndex, 'usage', 'edit')"/>
                    </div>
                  </div>

                  <div class="flex justify-around space-x-10 mt-2">
                    <InputSelect
                        class="mt-2 w-full"
                        :label="$t('properties.step9.usageType')"
                        v-model="itemUsageType.usageType"
                        :options="usage_type_options"
                        :placeholder="$t('general.please_select')"
                        :showHelpIconPopover="true"
                        :popoverTexts="popoverTexts_usageType"
                        :popoverLink="popoverLink_usageType"
                        :selected-value="itemUsageType.usageType"
                        @input="checkEditUsageType(parcelIndex, itemIndex, itemUsageType.usageType)"
                        :disabled="isReadOnly"
                    />
                    <InputText
                        class="mt-2 w-full"
                        v-model="itemUsageType.areaOfTheLand"
                        :label="$t('properties.step9.areaOfTheLand')"
                        :maxLength="8"
                        :isOnlyNumber="true"
                        :isOnlyPositive="true"
                        placeholder=""
                        :disabled="itemUsageType.areaOfTheLandDisabled || isReadOnly"
                    />
                  </div>

                  <div class="flex justify-around space-x-10 mt-3">
                    <InputText
                        class="mt-2 w-full"
                        v-model="itemUsageType.yieldMeasurementNumber"
                        :label="$t('properties.step9.yieldMeasurementNumber')"
                        :isOnlyNumber="true"
                        :isOnlyPositive="true"
                        :maxLength="step9YieldMeasurementNumberMaxLength"
                        placeholder=""
                        :showHelpIcon="true"
                        :tooltip="$t('properties.step9.tooltip.yieldMeasurementNumber')"
                        :disabled="itemUsageType.yieldMeasurementNumberDisabled || isReadOnly"
                    />
                    <InputText
                        class="mt-2 w-full"
                        v-model="itemUsageType.areaOfFarmBuildings"
                        :maxLength="8"
                        :label="$t('properties.step9.areaOfFarmBuildings')"
                        :isOnlyNumber="true"
                        :isOnlyPositive="true"
                        placeholder=""
                        :showHelpIcon="true"
                        :tooltip="$t('properties.step9.tooltip.areaOfFarmBuildings')"
                        :disabled="itemUsageType.areaOfFarmBuildingDisabled || isReadOnly"
                    />
                  </div>
                  <div class="flex justify-around space-x-10 mt-3 content-width-half">
                    <InputText
                        class="mt-2 w-full"
                        v-model="itemUsageType.flowRate"
                        :maxLength="4"
                        :label="$t('properties.step9.flowRate')"
                        :isOnlyNumber="true"
                        :isOnlyPositive="true"
                        placeholder=""
                        :disabled="itemUsageType.flowRateDisabled || isReadOnly"
                    />
                    <InputSelect
                        class="mt-2 w-full custom-max-width"
                        :label="$t('properties.step9.parcelsLandAndForestryExemption')"
                        :filterable="true"
                        v-model="itemUsageType.exemption"
                        :options="landAndForestryExemptionListOpts"
                        :placeholder="$t('general.please_select')"
                        :disabled="parcelsLandAndForestryExemptionDisabled || isReadOnly"
                        :selected-value="itemUsageType.exemption"
                    />
                  </div>
                  <div class="flex justify-around space-x-10 mt-3">
                    <InputText
                        class="mt-2 w-full"
                        v-model="itemUsageType.exemption_area"
                        :label="$t('properties.step9.favoredArea')"
                        :disabled="favoredAreaDisabled || isReadOnly"
                        :isOnlyNumber="true"
                        :isOnlyPositive="true"
                        :maxLength="15"
                        placeholder=""
                    />
                    <div class="w-full pr-6 invisible"></div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end mt-2 mr-4">
                <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly"
                     @click="addItemUsageTypeMore(parcelIndex, 'edit')">
                  <img class="plus" src="@/assets/plus_circle_green.svg"/>
                  <label class="ml-3 btext add-more-link">{{ $t('properties.step9.addUsageType') }}</label>
                </div>
              </div>
            </div>
          </div>
          <hr class=" mt-3 mb-1 hr-line-user"/>
          <div class="flex justify-end mt-2 mr-4">
            <div class="flex justify-center items-center cursor-pointer" v-if="!isReadOnly" @click="addItemParcelItem('edit')">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step9.addFlurstück') }}</label>
            </div>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end  mt-4  ">
          <div class="flex my-6 space-x-4 ">
            <Button @click="closeEditParcelsLandAndForestryDialog()" :text="$t('buttons.cancel')"
                    :secondary="true"></Button>
            <Button :text="$t('buttons.save')" @click="editModalData('parcelslandandforestry')" v-if="!isReadOnly"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="showInfoDlg" @close="closeInfoDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start mx-4">
          <div class="p-3 mt-2 info-msg">
            <img src="@/assets/note.svg" width="15px">
            <div class="pl-4 text-left" v-html="infoMessage"></div>
          </div>
          <div class="flex mt-6 mb-4  justify-end space-x-4">
            <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="closeInfoDlg"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog
        v-show="showMissingMandatoryFieldsDlg"
        :headerText="$t('properties.required_fields_missing')"
        @close="closeMissingMandatoryFieldsDlg"
    >
      <template v-slot:body>
        <div class="flex flex-col justify-start missing-fields">
          <div class="my-4 flex flex-row justify-center">
            <img src="@/assets/warning.svg" width="58px">
          </div>
          <div class="flex flex-row justify-start">
            <label class="text-left">
              {{
                $t('properties.required_fields_missing_info').replace('%state%', stateName).replace('%taxmodel%', taxModelForModal)
              }}
            </label>
          </div>
          <div class="my-4 flex flex-row justify-start" v-html="missingFieldsHtml">
          </div>
          <div class="flex flex-row justify-start">
            <label class="text-left">
              {{ $t('properties.required_fields_missing_desc') }}
            </label>
          </div>
          <div class="flex mt-6 mb-4  justify-end space-x-4">
            <Button :text="$t('buttons.ok')" @click="closeMissingMandatoryFieldsDlg"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog v-show="elsterValidationResultDialog" :headerText="$t('declarations.detail.elster_validation.title')"
                 @close="handleElsterValidationResultDialogClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start elster-dlg">
          <div class="flex justify-around mt-2 space-x-10">
            <div class="w-full mt-2 elster-result-header">
              <div class="flex flex-col justify-center items-center">
                <img src="@/assets/warning.svg" width="15%">
              </div>
              <p class="textbold mt-4">{{ $t('declarations.detail.elster_validation.failure_message') }}</p>
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10">
            <div class="w-full flex flex-col justify-start mt-2 mb-4">
              {{ $t('declarations.detail.elster_validation.error_info') }}
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationErrors.length > 0">
            <div class="w-full flex flex-col justify-start mt-2">
              <label class="label text-left">{{ $t('declarations.detail.elster_validation.validation_error') }}:</label>
              <ul class="ul-list">
                <li v-for="(error, index) in elsterValidationErrors" :key="index" v-html="renderUrlsAsLinks(error.readable)"></li>
              </ul>
            </div>
          </div>

          <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationHints.length > 0">
            <div class="w-full flex flex-col justify-start mt-2">
              <label class="label text-left">{{ $t('declarations.detail.elster_validation.notes') }}:</label>
              <ul class="ul-list">
                <li v-for="(hint, index) in elsterValidationHints" :key="index" v-html="renderUrlsAsLinks(hint.readable)"></li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-5 mb-5 justify-end space-x-4 mr-6">
          <Button :text="$t('buttons.ok')" @click="handleElsterValidationResultDialogClose"/>
        </div>
      </template>
    </ModalDialog>

    <!-- Import Error Dialog -->
    <ModalDialog v-show="showImportError" :headerText="$t('general.import_error')" @close="closeImportErrorDlg">
      <template v-slot:body>
        <div class="flex flex-col justify-start mt-2">
          <DataGrid
              grid-id="property_import_errors"
              :local-data="importErrors"
              grid-type="importError"
              default-sort-attr="row_no"
              :default-sort-dir="1"
              :show-select-col="false"
              :use-local-data="true"
              :state-ful="false"
              :reorderable-columns="false"
              :resizable-columns="false"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex mt-2 mb-4 mr-6 justify-end   space-x-4">
          <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeImportErrorDlg"/>
        </div>
      </template>
    </ModalDialog>

    <div class="flex justify-end">
      <label class="grey-right-text mr-8"> {{ $t('properties.edit.id') }}: {{ UId }}</label>
    </div>

    <hr class=" hr-line-user  mr-7  mt-1 "/>
    <div class="w-full h-full mr-8 pb-8" v-if="!rendering">
      <div class="pt-2 pb-3 info-msg flex flex-row  items-center mr-6 overflow-x-scroll">
        <div class="flex items-center px-5">
          <img src="@/assets/home-icon.svg" width="20px">
          <div class="pl-3 flex flex-col items-start">
            <label>
              {{ propertyTypeInfoBar }}:
            </label>
            <label class="link cursor-pointer mr-5" @click="getGoogleMapsLink">
              {{ address }}
            </label>
          </div>
        </div>

        <div class="px-5 flex flex-col items-start border-left">
          <label>
            {{ $t('properties.steps.step1') }}:
          </label>
          <label class="link cursor-pointer" @click="editClient">
            {{ propertyData.clientName }}
          </label>
        </div>

        <div class="flex flex-row">
          <div class="px-5 flex flex-col items-start border-left">
            <div class="flex flex-row">
              <label>
                {{ $t('properties.step2.bundesland') }}:
              </label>
            </div>
            <div class="flex flex-row">
              <label class="pr-2">
                {{ stateName }}
              </label>
              <label class="px-2 link cursor-pointer border-left-small" @click="showMissingMandatoryModal"
                     v-if="false">
                {{ taxModel }}
              </label>
              <label class="px-2 border-left" v-else>
                {{ taxModelText }}
              </label>
            </div>
          </div>
          <div class="flex flex-col justify-around" v-if="!isReadOnly">
            <div v-if="false">
              <img src="@/assets/warning.svg" width="15px" class="cursor-pointer" v-if="missingMandatoryField"
                   @click="showMissingMandatoryModal">
              <img src="@/assets/success.svg" width="15px" v-else
                   v-tooltip="$t('properties.tooltips.missing_mandatory_fields_success')">
            </div>
            <img src="@/assets/cog_gray.svg" class="ml-2 cursor-pointer mr-5" width="15px"
                 @click="showSettingsNextDeclarationDialog"
                 v-tooltip="$t('properties.tooltips.settings_next_declaration_header')">
            <img src="@/assets/elster_validate.svg" class="ml-2" width="15px"
                 v-if="elsterValidationStatus === 'success'" v-tooltip="$t('properties.tooltips.elster_success')">
            <img src="@/assets/elster_validate_error.svg" class="ml-2 cursor-pointer mr-5" width="15px"
                 v-if="elsterValidationStatus === 'failed'" @click="showElsterValidationError"
                 v-tooltip="$t('properties.tooltips.elster_failed')">
          </div>
        </div>

        <div class="flex" v-if="propertyData.step2.economicEntityType === '3'">
          <div class="px-5 flex flex-col items-start border-left ml-5">
            <label>
              {{ $t('properties.official_area') }}:
            </label>
            <label>
              {{ getSumOfOfficialArea | formatNumber }}
            </label>
          </div>
          <div class="px-5 flex flex-col items-start border-left ml-5" >
            <label :class="getSumOfAreaOfUse > getSumOfOfficialArea ? 'redtext' : ''">
              {{ $t('properties.area_of_use') }}:
            </label>
            <label :class="getSumOfAreaOfUse > getSumOfOfficialArea ? 'redtext' : ''">
              {{ getSumOfAreaOfUse | formatNumber }}
            </label>
          </div>
        </div>

        <div class="px-5 flex flex-col items-start border-left ml-5" v-else>
          <label>
            {{ $t('properties.property_area') }}:
          </label>
          <label>
            {{ parseInt(propertyData.step3.areaOfTheLandTotal) | formatArea }}
          </label>
        </div>

        <div class="px-5 flex flex-col items-start border-left">
          <label>
            {{ $t('properties.parcels') }}:
          </label>
          <label>
            {{ getParcelCnt | formatNumber }}
          </label>
        </div>

        <div class="px-5 flex flex-col items-start border-left margin-top-15">
          <div class=" flex justify-center items-center">
            <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
            <p class="text1 ml-2 w-96"> {{ $t('properties.edit.header_infobox') }}</p>
          </div>
        </div>

      </div>
      <AppTabs class="mt-10" :categories="availableTabs" :selected-tab="activeTab">
        <!-- tab1 basic-->
        <template #tab-pane-basic v-if="checkTabExistence('basic')">

          <div class="box flex justify-start items-center" v-show="showChangeManagementInfo">
            <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
            <label class="text ml-2">
              {{ $t('properties.change_management_info') }}
            </label>
          </div>

          <div class="box flex justify-start items-center" v-show="propertyData.freezed">
            <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
            <label class="text ml-2">
              {{
                $t('properties.freezed_version_info.line1', {
                  "version_number": propertyData.freezedVersion,
                  "property_uid": propertyData.uid
                })
              }}
              <router-link class="link" :to="'/declaration/edit?uid=' + propertyData.freezedDeclaration">
                {{ $t('declarations.detail.breadcrumbs.link1') }} {{ propertyData.freezedDeclarationNumber }}
              </router-link>
              {{ $t('properties.freezed_version_info.line2') }}
              <router-link class="link" :to="'/property/edit?uid=' + propertyData.freezedDeclarationPropertyUid">
                {{ $t('general.here') }}
              </router-link>
              .
              {{ $t('clients.freezed_version_info.line3') }}
            </label>
          </div>

          <div class="flex flex-col justify-start">
            <label class="title">{{ $t('properties.edit.address') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-1">
            <InputText
                class="mt-2 w-full"
                v-model="propertyData.step2.street"
                :label="$t('properties.step2.street')"
                :maxLength="25"
                placeholder=""
                :disabled="isReadOnly"
                @change="updateInfoBar"
            />
            <InputText
                class="mt-2 w-full"
                v-model="propertyData.step2.houseNumber"
                :label="$t('properties.step2.house')"
                :maxLength="39"
                placeholder=""
                :disabled="isReadOnly"
                @change="updateInfoBar"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-1">
            <InputText
                class="mt-2 w-full"
                v-model="propertyData.step2.zip"
                :label="$t('properties.step2.zip')"
                @blur="searchZip('basic')"
                :isZip="true"
                placeholder=""
                @change="updateInfoBar"
                :error-message="$t('general.errors.maxLength', [5])"
                :disabled="isReadOnly"
            />
            <InputSelect
                class="mt-2 w-full"
                :auto-complete="true"
                :label="$t('properties.step2.city')"
                :options="cityList.basic"
                v-model="propertyData.step2.city"
                :selected-value="propertyData.step2.city"
                :loading="zipSearch.basic"
                :filterable="true"
                :placeholder="$t('general.please_select')"
                :maxLength="25"
                :disabled="isReadOnly"
                @on-item-selected="selectCity"
                @change="updateInfoBar"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-1">
            <InputSelect
                class="mt-2 w-full"
                v-model="propertyData.step2.federalStateUid"
                :selected-value="propertyData.step2.federalStateUid"
                :isError="errors.federalStateUid.invalid"
                :errorMessage="$t('general.errors.required')"
                :label="$t('properties.step2.bundesland')"
                :filterable="true"
                :isRequired=true
                :options="stateList"
                :placeholder="$t('general.please_select')"
                :disabled="isReadOnly"
                @on-item-selected="selectState"
            />
            <div class="w-full">
              <InputSelect
                  class="mt-2 w-full"
                  :auto-complete="false"
                  :isError="errors.community.invalid"
                  :errorMessage="$t('general.errors.required')"
                  :label="$t('properties.step2.community')"
                  :options="communityList"
                  dataKey="name"
                  v-model="propertyData.step2.community"
                  :selected-value="propertyData.step2.community"
                  :loading="loadingCommunity"
                  :filterable="true"
                  :placeholder="$t('general.please_select')"
                  :disabled="isReadOnly"
                  @on-item-selected="selectCommunity"
                  :showHelpIconPopover="true"
                  :popoverTexts="popoverTexts_community"
                  :popoverLink="popoverLink_community"
              />
              <div class="p-3 mt-1 info-msg flex" v-if="!validCommunity">
                <img src="@/assets/note.svg" width="15px">
                <label class="pl-2 text-left" style="white-space: normal;">
                  {{ invalidCommunityInfo }}
                </label>
              </div>
            </div>

          </div>
          <div class="flex justify-around space-x-10 mt-1">
            <InputText
                class="mt-2 w-full"
                v-model="propertyData.step2.additional_information"
                :label="$t('properties.step2.additional_info')"
                :maxLimit="24"
                placeholder=""
                :disabled="isReadOnly"
            />   <div class="w-full invisible"/>
          </div>

          <div class="p-3 mt-8 mb-4 info-msg flex" v-if="helpInfo !== ''">
            <img src="@/assets/note.svg" width="15px">
            <label class="pl-2 text-left">
              {{ helpInfo }}
            </label>
          </div>

          <div class="flex flex-col justify-start mt-8">
            <label class="title">{{ $t('properties.details') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputText
                class=" w-full "
                v-model="propertyData.step2.reference"
                :label="$t('properties.step2.reference')"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_ew_az"
                :popoverLink="popoverLink_ew_az"
                :is-error="errors.reference.invalid"
                :maxLimit="30"
                :disabled="isReadOnly"
                :errorMessage="$t('general.errors.limitDesc').replace('%length%', propertyData.step2.reference.length).replace('%maxLimit%', 30)"
            />
            <InputText
                class=" w-full "
                v-model="propertyData.step2.name"
                :label="$t('properties.step2.name')"
                placeholder=""
                :isRequired=true
                :isError="errors.name.invalid"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_step2_name"
                :errorMessage="$t('general.errors.required')"
                :disabled="isReadOnly"
                @change="updateInfoBar"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2a.economic_entity_type')"
                v-model="propertyData.step2.economicEntityType"
                :selected-value="propertyData.step2.economicEntityType"
                :options="economic_entity_type_options"
                :placeholder="$t('general.please_select')"
                :is-required="true"
                :is-error="errors.economicEntityType.invalid"
                :error-message="$t('general.errors.required')"
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_economic_entity_type"
                :popoverLink="popoverLink_economic_entity_type"
                :disabled="isReadOnly"
                @on-item-selected="selectEconomicEntityType"
            />
            <div class=" flex flex-col  w-full">
              <div class="flex">
                <label class="text mt-2 lbl-exemption">{{ $t('properties.step2a.exemption_present') }}</label>
                <!--      help icon with HTML tooltip/popover-->
                <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                           :hideTriggers="['click']">
                  <!-- This will be the popover target (for the events and position) -->
                  <img class="help_icon self-center mt-2  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                  <!-- This will be the content of the popover -->
                  <template #popper>
                    <div class="tooltip-content">
                      <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_exemption_present"
                         v-bind:key="text">
                        {{ text }}
                      </p>
                      <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                       :href="popoverLink_exemption_present"
                                                                       target="_blank">{{
                          popoverLink_exemption_present
                        }}</a></h2>
                    </div>
                    <!-- You can put other components too -->

                  </template>
                </VDropdown>
              </div>
              <div class="flex space-x-4">
                <div class="flex justify-start items-center">
                  <RadioButton type="radio" :disabled="isReadOnly" v-model="propertyData.step2.exemption"
                               name="radio-button" value="1"/>
                  <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                </div>
                <div class="flex justify-start items-center ">
                  <RadioButton type="radio" :disabled="isReadOnly" v-model="propertyData.step2.exemption"
                               name="radio-button" value="0"/>
                  <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step2a.complexity')"
                v-model="propertyData.step2.complexity"
                :selected-value="propertyData.step2.complexity"
                :options="$t('properties.complexity_options')"
                :placeholder="$t('general.please_select')"
                :showHelpIconPopover="true"
                :popoverTexts="[$t('properties.step2a.complexity_help')]"
                :disabled="isReadOnly"
            />

            <InputSelect
              class="mt-2 w-full"
              :label="$t('declarations.add_declaration.steps.step1.assignedUser')"
              :options="userList"
              v-model="propertyData.step1.assignedUser"
              :selected-value="propertyData.step1.assignedUser"
              :disabled="isReadOnly"
              :filterable="false"
            />
          </div>
          <div class="flex flex-col justify-around mt-3">
            <InputText
                class="mt-2 w-full "
                bodyClass="textarea w-full"
                v-model="propertyData.step2.extraDetails"
                :label="$t('properties.step2.extraDetails')"
                :isMultiline="true"
                :maxLimit="999"
                :errorMessage="$t('general.errors.limitDesc').replace('%length%', propertyData.step2.extraDetails.length).replace('%maxLimit%', 999)"
                placeholder=""
                :disabled="isReadOnly"
            />
            <InputText
                class="mt-6 w-full "
                bodyClass="textarea w-full"
                v-model="propertyData.step2.internalNote"
                :label="$t('properties.step2.internalNote')"
                :isMultiline="true"
                :maxLimit="9999"
                :errorMessage="$t('general.errors.limitDesc').replace('%length%', propertyData.step2.internalNote.length).replace('%maxLimit%', 9999)"
                placeholder=""
                :disabled="isReadOnly"
            />
          </div>
          <deviatingOwner v-if="!$isBusiness" ref="compDeviatingOwner" :isReadOnly="isReadOnly" :property-data="propertyData"
                          @changeOwnershipStructure="changeOwnershipStructure"/>
        </template>

        <!-- tab 2 -->
        <template #tab-pane-property v-if="checkTabExistence('property')">
          <div class="flex flex-col justify-start">
            <div class="flex justify-start items-center">
              <label class="flex title mt-4">{{ $t('properties.steps.step3') }}</label>
              <Button
                  v-if="!isReadOnly"
                  @click="openSkenDataModal"
                  :text="$t('properties.data_acquisitions.title')"
                  class="flex ml-auto">
              </Button>
            </div>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                :disabled="isReadOnly"
                class="w-full max-width-half"
                :label="$t('properties.step3.ownership_structure')"
                v-model="propertyData.step3.ownershipStructure"
                :selected-value="propertyData.step3.ownershipStructure"
                :options="ownership_structure"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_ownership_relation"
                :popoverLink="popoverLink_ownership_relation"
                @on-item-selected="selectedOwnershipStructure"
            />
            <InputSelect
                class=" w-full"
                :label="$t('properties.step3.property_type')"
                v-model="propertyData.step3.propertyType"
                :selected-value="propertyData.step3.propertyType"
                :options="step3_property_type"
                @on-item-selected="showHideTabsAndFields"
                :disabled="propertyTypeDisabled || isReadOnly"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_type_of_land"
                :popoverLink="popoverLink_type_of_land"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-3">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.development_state')"
                v-model="propertyData.step3.developmentState"
                :selected-value="propertyData.step3.developmentState"
                :options="development_state"
                :disabled="developmentStateDisabled || isReadOnly"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_undeveloped_land"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <InputCheck
              class="w-full mt-2"
              :text="$t('properties.step3.deduction_checkbox')"
              :isChecked="propertyData.step3.deductionLandValueChecked === '1'"
              @check="deductionChecked"
              :showHelpIconPopover="true"
              :popoverTexts="popoverTexts_deduction_checkbox"
              :isDisabled="developmentStateDisabled || isReadOnly"
            />
            <div class="w-full invisible"></div>
          </div>

          <div class="flex justify-around space-x-10 mt-3">
            <div class=" flex flex-col  w-full">
              <div class="flex">
                <label class="text mt-5 mb-10">{{ $t('properties.step3.multi_communities') }}</label>
                <!--      help icon with HTML tooltip/popover-->
                <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                           :hideTriggers="['click']">
                  <!-- This will be the popover target (for the events and position) -->
                  <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                  <!-- This will be the content of the popover -->
                  <template #popper>
                    <div class="tooltip-content">
                      <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_multiple_communities"
                         v-bind:key="text">
                        {{ text }}
                      </p>

                      <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                       :href="popoverLink_multiple_communities"
                                                                       target="_blank">{{
                          popoverLink_multiple_communities
                        }}</a></h2>
                    </div>
                    <!-- You can put other components too -->

                  </template>
                </VDropdown>
              </div>
              <div class="flex space-x-4  mb-6">
                <div class="flex justify-start items-center">
                  <RadioButton type="radio" :disabled="isReadOnly" v-model="propertyData.step3.multiCommunities"
                               name="radio-button"
                               value="1"/>
                  <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                </div>
                <div class="flex justify-start items-center ">
                  <RadioButton type="radio" :disabled="isReadOnly" v-model="propertyData.step3.multiCommunities"
                               name="radio-button"
                               value="0"/>
                  <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                </div>
              </div>
            </div>
            <div class=" flex flex-col  w-full">
              <div class="flex">
                <label class="text mt-5 mb-10">{{ $t('properties.step3.leasehold') }}</label>
                <!--      help icon with HTML tooltip/popover-->
                <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                           :hideTriggers="['click']">
                  <!-- This will be the popover target (for the events and position) -->
                  <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                  <!-- This will be the content of the popover -->
                  <template #popper>
                    <div class="tooltip-content">
                      <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_lease_hold" v-bind:key="text">
                        {{ text }}
                      </p>

                      <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                       :href="popoverLink_lease_hold"
                                                                       target="_blank">{{ popoverLink_lease_hold }}</a>
                      </h2>

                    </div>
                    <!-- You can put other components too -->

                  </template>
                </VDropdown>
              </div>
              <div class="flex space-x-4  mb-6">
                <div class="flex justify-start items-center">
                  <RadioButton type="radio" v-model="propertyData.step3.leasehold"
                               :disabled="leaseholdDisabled || isReadOnly"
                               name="radio-button3" value="1"/>
                  <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                </div>
                <div class="flex justify-start items-center ">
                  <RadioButton type="radio" v-model="propertyData.step3.leasehold"
                               :disabled="leaseholdDisabled || isReadOnly"
                               name="radio-button3" value="0"/>
                  <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-around space-x-10">
            <div class="w-full">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-5 mb-10">{{ $t('properties.step3.buildingOnForeignGround') }}</label>
                  <!--      help icon with HTML tooltip/popover-->
                  <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                             :hideTriggers="['click']">
                    <!-- This will be the popover target (for the events and position) -->
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <!-- This will be the content of the popover -->
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_building_on_foreign"
                           v-bind:key="text">
                          {{ text }}
                        </p>
                        <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text"
                                                                         :href="popoverLink_foreign_land"
                                                                         target="_blank">{{
                            popoverLink_foreign_land
                          }}</a></h2>

                      </div>
                      <!-- You can put other components too -->

                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-2">
                  <div class="flex justify-start items-center">
                    <RadioButton type="radio" v-model="propertyData.step3.buildingOnForeignGround"
                                 :disabled="buildingOnForeignGroundDisabled || isReadOnly" name="radio-button4"
                                 value="1"/>
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center ">
                    <RadioButton type="radio" v-model="propertyData.step3.buildingOnForeignGround"
                                 :disabled="buildingOnForeignGroundDisabled || isReadOnly" name="radio-button4"
                                 value="0"/>
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-5 mb-10">{{ $t('properties.step3.propertyResidentialPurposes') }}</label>
                  <!--      help icon with HTML tooltip/popover-->
                  <VDropdown offset="2" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                             :hideTriggers="['click']">
                    <!-- This will be the popover target (for the events and position) -->
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <!-- This will be the content of the popover -->
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text"
                           v-for="text in [$t('properties.step3.tooltip.tooltip_propertyResidentialPurposes')]"
                           v-bind:key="text">
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-2">
                  <div class="flex justify-start items-center">
                    <RadioButton
                        type="radio"
                        v-model="propertyData.step3.propertyResidentialPurposes"
                        name="radio-button5"
                        value="1"
                        :disabled="propertyResidentialPurposesDisabled || isReadOnly"
                    />
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center ">
                    <RadioButton
                        type="radio"
                        v-model="propertyData.step3.propertyResidentialPurposes"
                        name="radio-button5"
                        value="0"
                        :disabled="propertyResidentialPurposesDisabled || isReadOnly"
                    />
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InputText
              class="mt-2 w-full hidden"
              :isOnlyNumber="true"
              v-model="propertyData.step3.liftingRate"
              :label="$t('properties.step3.liftingRate')"
              placeholder=""
              :disabled="isReadOnly"
          />
          <div class="flex justify-around space-x-10">
            <div class=" flex flex-col  w-full">
              <div class="flex">
                <label class="text mt-5 mb-10">{{ $t('properties.step3.buildingsOnThirdPartyOwners') }}</label>
              </div>
              <div class="flex space-x-4  mb-2">
                <div class="flex justify-start items-center">
                  <RadioButton type="radio" v-model="propertyData.step3.buildingsOnThirdPartyOwners"
                               :disabled="buildingsOnThirdPartyOwnersDisabled || isReadOnly" name="radio-button5"
                               value="1" @change="buildingsOnThirdPartyOwnersChangePopupHandler" />
                  <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                </div>
                <div class="flex justify-start items-center ">
                  <RadioButton type="radio" v-model="propertyData.step3.buildingsOnThirdPartyOwners"
                               :disabled="buildingsOnThirdPartyOwnersDisabled || isReadOnly" name="radio-button5"
                               value="0" @change="buildingsOnThirdPartyOwnersChangePopupHandler" />
                  <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-start space-x-10 mt-3">
            <div class="mt-3 w-1/2">
              <InputDate class="mt-2 col-6" :label="$t('properties.step3.registration_land_registry')"
                         v-model="propertyData.step3.registrationLandRegistry"
                         :disabled="registrationLandRegistryDisabled || isReadOnly" :isOnlyNumber="true"
                         placeholder=""/>
            </div>
            <div class="mt-3 w-1/2">
              <InputText
                class="mt-2 w-full hidden"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                v-model="propertyData.step3.liftingRate"
                :label="$t('properties.step3.liftingRate')"
                placeholder=""
                :disabled="isReadOnly"
              />
            </div>
          </div>
          <div v-if="districtShow">
            <div class="flex justify-between mt-12">
              <label class="title text-left self-start">{{ $t('properties.step3.district_title') }}</label>
              <label class="grey-right-text" :class="{ redtext: isInvalidAreaSum }">{{
                  $t('properties.step3.sum_of_areas')
                }}: {{ sumOfAreas }}m²</label>
            </div>

            <hr class=" mt-3 mb-4 hr-line-user"/>

            <div class="mt-2">
              <div class="box flex justify-start items-center" v-if="grundstucksadresse_info_box !== ''"
                   v-show="address_info_box">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-left ml-2">
                  {{ grundstucksadresse_info_box }}
                </label>
              </div>
              <AppGrid :grid-id="parcelGridId"
                       data-endpoint="parcel/list"
                       :columns="parcelColumns"
                       :default-sort-dir="1"
                       :selectable="true"
                       selection-data-key="prim_uid"
                       :standard-filters="standardFilters"
                       :server-params="serverParams"
                       :menus="menus"
                       :show-select-col="false"
                       :show-filter="false"
                       :show-view-menu="false"
                       :state-ful="false"
                       :show-create-record="false"
                       :auto-load="false"
                       :is-read-only="isReadOnly"
                       @col-click="handleColClick"
                       @menu-clicked="handleMenuClick"
              />
            </div>
          </div>

          <div v-show="firstAreaInfoShow" class="mb-8">
            <div class="flex flex-col justify-start mt-12 mb-2">
              <label class="sub-heading">{{ $t('properties.step3.area_information_of_property') }}</label>
              <div class="box-no-margin-bottom flex justify-start items-center mt-3"
                   v-show="propertyData.step2.federalStateUid==='7'||propertyData.step2.federalStateUid==='9'">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-info ml-2">
                  {{ $t('properties.step3.area_information_of_property_info_box') }}
                  <br/>
                  <br/>
                  {{ $t('properties.step3.area_information_of_property_info_box_1') }}
                </label>
              </div>
              <div class="box flex justify-start items-center mt-3">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-info ml-2">
                  {{ $t('properties.step3.area_information_of_property_info_box_2') }}
                </label>
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <label class="grey-left-text" :class="{ redtext: isInvalidAreaSum }">{{
                  $t('properties.step3.sum_of_areas')
                }}: {{ sumOfAreas }}m²</label>
            </div>
            <div class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText class="mt-2 w-full"
                         @input="checkSumAreasGlobal"
                         v-model="propertyData.step3.areaOfTheLand1"
                         :label="$t('properties.step3.area_of_the_land_property')"
                         placeholder=""
                         :showHelpIconPopover="true"
                         :popoverTexts="popoverTexts_area_of_the_land_1"
                         :isOnlyNumber="true"
                         :isOnlyPositive="true"
                         :maxLength="9"
                         :disabled="isReadOnly || areaOfTheLandDisabled"
              />
              <div class="mt-2 w-full flex justify-center items-center">
                <InputText class="w-full"
                           :decimalNumber="2"
                           :maxValue="maxAreaOfLandValue1"
                           :isFloat="true"
                           v-model="propertyData.step3.areaOfTheLandValue1"
                           :label="$t('properties.step3.area_of_the_land_value_property')"
                           :disabled="areaOfTheLandValueDisabled1 || isReadOnly"
                           placeholder=""
                           :showHelpIconPopover="true"
                           :popoverTexts="popoverTexts_area_of_the_land_value_property"
                           :popoverLink="popoverLink_area_of_the_land_value_property"
                />
              </div>
            </div>

            <div v-if="showAreaOfTheLandExemptionSection" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionDescription1"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_description')"
                  placeholder=""
                  :maxLength="999"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionArea1"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="11"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_area')"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemption1"
                  :selected-value="propertyData.step3.areaOfTheLandTaxExemption1"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption')"
                  :options="area_of_the_land_tax_exemption_options"
                  placeholder=""
                  overlay-append-to="body"
              />
            </div>

            <div class="flex justify-around space-x-10 mb-4" v-if="propertyData.step2.federalStateUid === '7'">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
                  <VDropdown :showTriggers="['click','hover']"
                             :popperTriggers="['hover','click']"
                             :hideTriggers="['click']"
                  >
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text"
                           v-for="text in popoverTexts_outdoor_property"
                           v-bind:key="text"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-4">
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea1"
                                 name="radio-button"
                                 value="1"
                    />
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea1"
                                 name="radio-button"
                                 value="0"
                    />
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="secondAreaInfoShow === true" class="mb-8">
            <div class="flex justify-around space-x-10 mb-4">
              <InputText class="mt-2 w-full"
                         @input="checkSumAreasGlobal"
                         v-model="propertyData.step3.areaOfTheLand2"
                         :label="$t('properties.step3.area_of_the_land_property')"
                         placeholder=""
                         :showHelpIconPopover="true"
                         :popoverTexts="popoverTexts_area_of_the_land_1"
                         :isOnlyNumber="true"
                         :isOnlyPositive="true"
                         :maxLength="9"
                         :disabled="isReadOnly || areaOfTheLandDisabled"
              />
              <div class="mt-2 w-full flex justify-center items-center">
                <InputText class="w-full"
                           :decimalNumber="2"
                           :maxValue="maxAreaOfLandValue1"
                           :isFloat="true"
                           v-model="propertyData.step3.areaOfTheLandValue2"
                           :label="$t('properties.step3.area_of_the_land_value_property')"
                           :disabled="areaOfTheLandValueDisabled1 || isReadOnly"
                           placeholder=""
                           :showHelpIconPopover="true"
                           :popoverTexts="popoverTexts_area_of_the_land_value_property"
                           :popoverLink="popoverLink_area_of_the_land_value_property"
                />
              </div>
            </div>

            <div v-if="showAreaOfTheLandExemptionSection" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionDescription2"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_description')"
                  placeholder=""
                  :maxLength="999"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionArea2"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="11"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_area')"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemption2"
                  :selected-value="propertyData.step3.areaOfTheLandTaxExemption2"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption')"
                  :options="area_of_the_land_tax_exemption_options"
                  placeholder=""
                  overlay-append-to="body"
              />
            </div>

            <div class="flex justify-around space-x-10" v-if="propertyData.step2.federalStateUid === '7'">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
                  <VDropdown :showTriggers="['click','hover']"
                             :popperTriggers="['hover','click']"
                             :hideTriggers="['click']"
                  >
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text"
                           v-for="text in popoverTexts_outdoor_property"
                           v-bind:key="text"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-4">
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea2"
                                 name="radio-button"
                                 value="1"
                    />
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea2"
                                 name="radio-button"
                                 value="0"
                    />
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-2 mb-2" v-if="secondAreaInfoShow === true && !isReadOnly">
            <div class="flex justify-end">
              <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideSecondAreaInfo"/>
            </div>
          </div>

          <hr class=" mt-3 mb-4 hr-line-user"
              v-if="secondAreaInfoShow === false && addMoreAreaLinkShow === true && !isReadOnly"/>

          <div class="flex justify-end mb-4"
               v-if="secondAreaInfoShow === false && addMoreAreaLinkShow === true && !isReadOnly">
            <div class="flex justify-center items-center cursor-pointer" v-show="firstAreaInfoShow"
                 @click="showSecondAreaInfo">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
            </div>
          </div>
          <div v-if="thirdAreaInfoShow === true" class="mb-8">
            <div class="flex justify-around space-x-10 mb-4">
              <InputText class="mt-2 w-full"
                         @input="checkSumAreasGlobal"
                         v-model="propertyData.step3.areaOfTheLand3"
                         :label="$t('properties.step3.area_of_the_land_property')"
                         placeholder=""
                         :showHelpIconPopover="true"
                         :popoverTexts="popoverTexts_area_of_the_land_1"
                         :isOnlyNumber="true"
                         :isOnlyPositive="true"
                         :maxLength="9"
                         :disabled="isReadOnly || areaOfTheLandDisabled"
              />
              <div class="mt-2 w-full flex justify-center items-center">
                <InputText class="w-full"
                           :isFloat="true"
                           :decimalNumber="2"
                           :maxValue="maxAreaOfLandValue1"
                           v-model="propertyData.step3.areaOfTheLandValue3"
                           :label="$t('properties.step3.area_of_the_land_value_property')"
                           :disabled="areaOfTheLandValueDisabled1 || isReadOnly"
                           placeholder=""
                           :showHelpIconPopover="true"
                           :popoverTexts="popoverTexts_area_of_the_land_value_property"
                           :popoverLink="popoverLink_area_of_the_land_value_property"
                />
              </div>
            </div>

            <div v-if="showAreaOfTheLandExemptionSection" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionDescription3"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_description')"
                  placeholder=""
                  :maxLength="999"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionArea3"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="11"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_area')"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemption3"
                  :selected-value="propertyData.step3.areaOfTheLandTaxExemption3"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption')"
                  :options="area_of_the_land_tax_exemption_options"
                  placeholder=""
                  overlay-append-to="body"
              />
            </div>

            <div class="flex justify-around space-x-10" v-if="propertyData.step2.federalStateUid === '7'">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
                  <VDropdown :showTriggers="['click','hover']"
                             :popperTriggers="['hover','click']"
                             :hideTriggers="['click']"
                  >
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text"
                           v-for="text in popoverTexts_outdoor_property"
                           v-bind:key="text"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-4">
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea3"
                                 name="radio-button"
                                 value="1"
                    />
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea3"
                                 name="radio-button"
                                 value="0"
                    />
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-2 mb-2" v-if="thirdAreaInfoShow === true && !isReadOnly">
            <div class="flex justify-end">
              <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideThirdAreaInfo"/>
            </div>
          </div>

          <div v-if="fourthAreaInfoShow === true" class="mb-8">
            <div class="flex justify-around space-x-10 mb-4">
              <InputText class="mt-2 w-full"
                         @input="checkSumAreasGlobal"
                         v-model="propertyData.step3.areaOfTheLand4"
                         :label="$t('properties.step3.area_of_the_land_property')"
                         placeholder=""
                         :showHelpIconPopover="true"
                         :popoverTexts="popoverTexts_area_of_the_land_1"
                         :isOnlyNumber="true"
                         :isOnlyPositive="true"
                         :maxLength="9"
                         :disabled="isReadOnly || areaOfTheLandDisabled"
              />
              <div class="mt-2 w-full flex justify-center items-center">
                <InputText class="w-full"
                           :isFloat="true"
                           :decimalNumber="2"
                           :maxValue="maxAreaOfLandValue1"
                           v-model="propertyData.step3.areaOfTheLandValue4"
                           :label="$t('properties.step3.area_of_the_land_value_property')"
                           :disabled="areaOfTheLandValueDisabled1 || isReadOnly"
                           placeholder=""
                           :showHelpIconPopover="true"
                           :popoverTexts="popoverTexts_area_of_the_land_value_property"
                           :popoverLink="popoverLink_area_of_the_land_value_property"
                />
              </div>
            </div>

            <div v-if="showAreaOfTheLandExemptionSection" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionDescription4"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_description')"
                  placeholder=""
                  :maxLength="999"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemptionArea4"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="11"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption_area')"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step3.areaOfTheLandTaxExemption4"
                  :selected-value="propertyData.step3.areaOfTheLandTaxExemption4"
                  :label="$t('properties.step3.area_of_the_land_tax_exemption')"
                  :options="area_of_the_land_tax_exemption_options"
                  placeholder=""
                  overlay-append-to="body"
              />
            </div>

            <div class="flex justify-around space-x-10" v-if="propertyData.step2.federalStateUid === '7'">
              <div class=" flex flex-col w-full">
                <div class="flex">
                  <label class="text mt-2">{{ $t('properties.step3.is_property_outdoors') }}</label>
                  <VDropdown :showTriggers="['click','hover']"
                             :popperTriggers="['hover','click']"
                             :hideTriggers="['click']"
                  >
                    <img class="help_icon self-center mt-5  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                    <template #popper>
                      <div class="tooltip-content">
                        <p class="space-y-2 mb-1 popover-text"
                           v-for="text in popoverTexts_outdoor_property"
                           v-bind:key="text"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div class="flex space-x-4  mb-4">
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea4"
                                 name="radio-button"
                                 value="1"
                    />
                    <span class="ml-3 textradio">{{ $t('general.yes') }}</span>
                  </div>
                  <div class="flex justify-start items-center relative">
                    <RadioButton type="radio"
                                 :disabled="isReadOnly"
                                 v-model="propertyData.step3.locatedInOutdoorArea4"
                                 name="radio-button"
                                 value="0"
                    />
                    <span class="ml-3 textradio">{{ $t('general.no') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-2 mb-2" v-if="fourthAreaInfoShow === true && !isReadOnly">
            <div class="flex justify-end">
              <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideFourthAreaInfo"/>
            </div>
          </div>
          <div class="flex justify-end mb-4"
               v-if="(propertyData.step2.federalStateUid === '7' || propertyData.step2.federalStateUid === '9') && thirdAreaInfoShow === false && secondAreaInfoShow === true && addMoreAreaLinkShow === true && !isReadOnly"
          >
            <div class="flex justify-center items-center cursor-pointer" v-show="firstAreaInfoShow"
                 @click="showThirdAreaInfo">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
            </div>
          </div>

          <div class="flex justify-end mb-4"
               v-if="(propertyData.step2.federalStateUid === '7' || propertyData.step2.federalStateUid === '9') && fourthAreaInfoShow === false && thirdAreaInfoShow === true && secondAreaInfoShow === true && addMoreAreaLinkShow === true && !isReadOnly"
          >
            <div class="flex justify-center items-center cursor-pointer" v-show="firstAreaInfoShow"
                 @click="showFourthAreaInfo">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
            </div>
          </div>

          <subArea v-show="showSubAreaComp === true" ref="compSubArea"  :federalStateUid="propertyData.step2.federalStateUid" :isReadOnly="isReadOnly" :property-data="propertyData"/>

          <div class="flex flex-col justify-start mt-12 mb-2">
            <label class="sub-heading">{{ $t('properties.step3.property_residential_purposes_headline') }}</label>
          </div>
          <div class="flex justify-start space-x-10 mt-3 pr-10">
            <div class=" mt-3 w-1/2">
              <InputText
                class="mt-2 w-full"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="9"
                :label="$t('properties.step3.total_property_part')"
                v-model="propertyData.step3.totalPropertyPart"
                placeholder=""
                :disabled="totalPropertyPartDisabled || isReadOnly"
              />
            </div>
          </div>
        </template>

        <!-- exemption and benefit tab -->
        <template #tab-pane-exemptionbenefit v-if="checkTabExistence('exemptionbenefit')">
          <div class="flex flex-col justify-start">
            <label class="title">{{ $t('properties.step3a.exemptions_benefits') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex justify-around space-x-10 mt-2">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.usage_type')"
                v-model="propertyData.step3a.taxExemption"
                :selected-value="propertyData.step3a.taxExemption"
                :options="usage_type"
                :disabled="!isExempted || isReadOnly"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_type_of_use"
                :popoverLink="popoverLink_type_of_use"
            />
            <InputSelect
                class="flex flex-col mt-2 w-full max-width-half"
                :label="$t('properties.step3.benefit')"
                v-model="propertyData.step3a.benefit"
                :selected-value="propertyData.step3a.benefit"
                :options="benefit_options"
                :disabled="!isExempted || isReadOnly"
                placeholder=""
                :showHelpIconPopover="true"
                :popoverTexts="popoverTexts_benefit"
            />
          </div>
          <div class="flex justify-around space-x-10 mt-2">
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3a.non_separable_part_real_estate')"
                v-model="propertyData.step3a.nonSeparablePartRealEstate"
                :selected-value="propertyData.step3a.nonSeparablePartRealEstate"
                :options="usage_type"
                :disabled="isReadOnly"
                placeholder=""
            />
            <InputSelect
                class="mt-2 w-full"
                :label="$t('properties.step3.property_type')"
                v-model="propertyData.step3a.propertyExemptionBenefitType"
                :selected-value="propertyData.step3a.propertyExemptionBenefitType"
                :options="step3_property_exemption_benefit_type_options"
                :disabled="propertyExemptionBenefitTypeDisabled || isReadOnly"
                placeholder=""
            />
          </div>

          <div class="flex justify-around space-x-10 mt-2">
            <InputCheck
              class="mt-4 w-full"
              :is-disabled="!show_monument_identification()"
              :text=" $t('properties.listed_buildings')" @check="checked_monument_identification"
              :isChecked="is_checked_monument_identification()"
            />
            <div
              class="mt-2 w-full"

            />
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step3a.reduction_desc') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-2">
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date1"
                v-model="propertyData.step3a.expirationFirstGrantPeriod"
                :label="$t('properties.step3a.expiration_first_grant_period')"
                :disabled="expirationFirstGrantPeriodDisabled || isReadOnly"
                placeholder=""
            />
            <InputDate
                class="mt-2 w-full"
                dateId="gebu_date2"
                v-model="propertyData.step3a.expirationLastGrantPeriod"
                :label="$t('properties.step3a.expiration_last_grant_period')"
                :disabled="expirationLastGrantPeriodDisabled || isReadOnly"
                placeholder=""
            />
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step3a.tax_privileged_purposes') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>

          <div class="flex justify-around space-x-10 mt-2">
            <InputText
                class="mt-2 w-full"
                v-model="propertyData.step3a.expectedExemptPurposes"
                :label="$t('properties.step3a.expected_exempt_purposes')"
                :isOnlyPositive="true"
                :isOnlyNumber="true"
                :disabled="expectedExemptPurposesDisabled || isReadOnly"
                placeholder=""
                :maxValue="100"
            />
            <InputSelect
                class="flex flex-col mt-2 w-full"
                :label="$t('properties.step3a.tax_exemption')"
                v-model="propertyData.step3a.taxPrivilegedPurposes"
                :selected-value="propertyData.step3a.taxPrivilegedPurposes"
                :options="usage_type"
                :disabled="taxPrivilegedPurposesDisabled || isReadOnly"
                placeholder=""
            />
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step3a.unbuilt_real_estate_exemption_description') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem1, exemptionIndex1) in propertyData.step3a.unbuiltRealEstateExemptions"
               :key="'unbuiltRealEstateExemptions'+exemptionIndex1">
            <div class="flex flex-col justify-start mt-12">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex1 + 1 }}</label>
                <img v-if="!isReadOnly" class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteUnbuiltRealEstateExemption(exemptionIndex1)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1 mb-5">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem1.unbuiltRealEstateExemptionDescription"
                  :label="$t('properties.step3a.designation')"
                  :disabled="unbuiltRealEstateExemptionDescriptionDisabled || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem1.taxFreeArea"
                  :isOnlyPositive="true"
                  :isOnlyNumber="true"
                  :label="$t('properties.step3a.tax_free_area')"
                  :disabled="taxFreeAreaDisabled || isReadOnly"
                  :maxLength="15"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="exemptionItem1.unbuiltRealEstateTaxExemption"
                  :selected-value="exemptionItem1.unbuiltRealEstateTaxExemption"
                  :label="$t('properties.step3a.tax_exemption')"
                  :options="usage_type"
                  :disabled="unbuiltRealEstateTaxExemptionDisabled || isReadOnly"
                  placeholder=""
              />
            </div>

            <div class="flex justify-items-start space-x-10 mt-1">
              <div class="box flex justify-start items-center max-width-two-of-third mb-2">
                <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
                <label class="text-left ml-2">{{ $t('properties.step3a.tax_exemption_parcel_info') }}</label>
              </div>
            </div>

            <div class="flex justify-items-start space-x-10 mt-1">
              <InputSelect
                  class="mt-2 w-full max-width-two-of-third"
                  v-model="exemptionItem1.propertyParcelUid"
                  :selected-value="exemptionItem1.propertyParcelUid"
                  :label="$t('properties.step3a.tax_exemption_parcel')"
                  :options="tax_exemption_parcel_options"
                  :disabled="propertyParcelUidDisabled || isReadOnly"
                  :filterable="true"
                  :placeholder="$t('general.please_select')"
              />
            </div>
          </div>
          <div class="flex justify-end mt-3" v-if="!isReadOnly">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreUnbuiltRealEstateExemption()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step3a.use_of_definable_part') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex flex-col mb-1"
               v-for="(exemptionItem2, exemptionIndex2) in propertyData.step3a.useOfDefinableParts"
               :key="'useOfDefinableParts'+exemptionIndex2">
            <div class="box-no-margin-bottom flex justify-start items-center" v-if="exemptionIndex2 === 0 && !useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled">
              <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
              <label class="text-left ml-2">{{ $t('properties.step3.exemption_benefit_info') }}</label>
            </div>
            <div class="flex flex-col justify-start mt-12">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.exemption') }}
                  {{ exemptionIndex2 + 1 }}</label>
                <img v-if="!isReadOnly" class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteUseOfDefinableParts(exemptionIndex2)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem2.spatiallyDefinableExemptionPartDescription"
                  :label="$t('properties.step3a.designation')"
                  :disabled="useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="exemptionItem2.spatiallyDefinableExemptionArea"
                  :isOnlyPositive="true"
                  :isOnlyNumber="true"
                  :label="$t('properties.step3a.tax_free_area')"
                  :disabled="useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled || isReadOnly"
                  :maxLength="useOfDefinablePartSpatiallyDefinableExemptionAreaMaxLength"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="exemptionItem2.spatiallyDefinableTaxExemptionPart"
                  :selected-value="exemptionItem2.spatiallyDefinableTaxExemptionPart"
                  :label="$t('properties.step3a.tax_exemption')"
                  :options="spatiallyDefinablePartExemptionOptions"
                  :disabled="useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled || isReadOnly"
                  placeholder=""
              />
            </div>

            <div class="flex justify-around space-x-10 mt-1  w-full" v-if="!useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled">
              <div class="flex w-full mt-2 auto-width nowrap">
                <InputRadio type="radio"
                          :name="'groupExemption' + exemptionIndex2"
                          v-model="exemptionItem2.spatiallyDefinableExemptionLandOrLiving"
                          rValue="1"
                          :text="$t('properties.step3a.tax_land_or_living_1')"
                          :disabled="isReadOnly"
                          :checked="exemptionItem2.spatiallyDefinableExemptionLandOrLiving == '1'"
                          :showHelpIconPopover="true"
                          :popoverTexts="popoverTexts_spatiallyDefinableExemptionLandOrLiving"
                />
              </div>
              <div class="flex w-full mt-2">
                <InputRadio type="radio"
                            :name="'groupExemption' + exemptionIndex2"
                            v-model="exemptionItem2.spatiallyDefinableExemptionLandOrLiving"
                            rValue="2"
                            :text="$t('properties.step3a.tax_land_or_living_2')"
                            :disabled="isReadOnly"
                            :checked="exemptionItem2.spatiallyDefinableExemptionLandOrLiving == '2'"
                            :showHelpIconPopover="true"
                            :popoverTexts="popoverTexts_spatiallyDefinableExemptionLandOrLiving"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-3" v-if="!isReadOnly">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreUseOfDefinableParts()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_exemption') }}</label>
            </div>
          </div>

          <div class="flex flex-col justify-start mt-12">
            <label class="title">{{ $t('properties.step3a.tax_break_conditions') }}</label>
            <hr class=" mt-3 mb-4 hr-line-user"/>
          </div>
          <div class="flex flex-col mb-1"
               v-for="(benefitItem1, benefitIndex1) in propertyData.step3a.taxBreakConditions"
               :key="'taxBreakConditions'+benefitIndex1">
            <div class="box-no-margin-bottom flex justify-start items-center" v-if="benefitIndex1 === 0 && !useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled">
              <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
              <label class="text-left ml-2">{{ $t('properties.step3.exemption_benefit_info') }}</label>
            </div>
            <div class="flex flex-col justify-start mt-6">
              <div class="flex justify-between">
                <label class="sub-heading text-left self-start">{{ $t('properties.step6.benefit') }}
                  {{ benefitIndex1 + 1 }}</label>
                <img v-if="!isReadOnly" class="delete delete-icon" src="@/assets/delete_user_green.svg"
                     @click="handleDeleteTaxBreakConditions(benefitIndex1)"/>
              </div>
            </div>
            <div class="flex justify-around space-x-10 mt-1">
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem1.spatiallyDefinableExemptionPartDescription"
                  :label="$t('properties.step3a.designation')"
                  :disabled="taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled || isReadOnly"
                  :maxLength="25"
                  placeholder=""
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="benefitItem1.spatiallyDefinableExemptionArea"
                  :isOnlyPositive="true"
                  :isOnlyNumber="true"
                  :label="$t('properties.step3a.discounted_area')"
                  :disabled="taxBreakConditionSpatiallyDefinableExemptionAreaDisabled || isReadOnly"
                  :maxLength="6"
                  placeholder=""
              />
              <InputSelect
                  class="mt-2 w-full max-width-one-of-third"
                  v-model="benefitItem1.spatiallyDefinableTaxExemptionPart"
                  :selected-value="benefitItem1.spatiallyDefinableTaxExemptionPart"
                  :label="$t('properties.step3a.benefit')"
                  :options="spatiallyDefinablePartBenefitOptions"
                  :disabled="taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled || isReadOnly"
                  placeholder=""
              />
            </div>
            <div class="flex justify-around space-x-10 mt-1  w-full" v-if="!useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled">
              <div class="flex w-full mt-2 auto-width nowrap">
                <InputRadio type="radio"
                            :name="'groupBenefit' + benefitIndex1"
                            v-model="benefitItem1.spatiallyDefinableExemptionLandOrLiving"
                            rValue="1"
                            :text="$t('properties.step3a.tax_land_or_living_1')"
                            :disabled="isReadOnly"
                            :checked="benefitItem1.spatiallyDefinableExemptionLandOrLiving == '1'"
                            :showHelpIconPopover="true"
                            :popoverTexts="popoverTexts_spatiallyDefinableExemptionLandOrLiving"
                />
              </div>
              <div class="flex w-full mt-2">
                <InputRadio type="radio"
                            :name="'groupBenefit' + benefitIndex1"
                            v-model="benefitItem1.spatiallyDefinableExemptionLandOrLiving"
                            rValue="2"
                            :text="$t('properties.step3a.tax_land_or_living_2')"
                            :disabled="isReadOnly"
                            :checked="benefitItem1.spatiallyDefinableExemptionLandOrLiving == '2'"
                            :showHelpIconPopover="true"
                            :popoverTexts="popoverTexts_spatiallyDefinableExemptionLandOrLiving"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-3" v-if="!isReadOnly">
            <div class="flex justify-center items-center cursor-pointer" @click="addMoreTaxBreakConditions()">
              <img class="plus" src="@/assets/plus_circle_green.svg"/>
              <label class="ml-3 btext add-more-link">{{ $t('properties.step6.add_more_benefit') }}</label>
            </div>
          </div>
        </template>

        <!-- tab 3 -->
        <template #tab-pane-community v-if="checkTabExistence('community')">
          <div>
            <div class="box flex justify-start items-center mb-10">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <div class="flex flex-col">
                <label class="text-info ml-2">
                  {{ $t('properties.step4.info_box.title') }}
                </label>
                <ul class="text-left list list-disc mb-5 text-xs ml-5">
                  <li> {{ $t('properties.step4.info_box.item1') }}</li>
                  <li> {{ $t('properties.step4.info_box.item2') }}</li>
                  <li> {{ $t('properties.step4.info_box.item3') }}</li>
                </ul>
              </div>
            </div>
            <div class="flex flex-col justify-start">
              <label class="title">{{ $t('properties.steps.step4') }}</label>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <div class="flex flex-col w-full mt-6">
              <inputCheck class="mb-3 w-full" :text="$t('properties.step2.take_over_from_client')"
                          :disabled="isReadOnly"
                          @check="copyAddressFromClient"/>
            </div>
            <div class="flex justify-around space-x-10">
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step4.salutation"
                  :selected-value="propertyData.step4.salutation"
                  :label="$t('properties.step4.salutation')"
                  :options="ownership_salutation_options"
                  :placeholder="$t('general.please_select')"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step4.name"
                  :label="$t('properties.step4.name')"
                  placeholder=""
                  :maxLength="step4.maxlength"
                  :disabled="isReadOnly"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step4.street"
                  :label="$t('properties.step4.street')"
                  placeholder=""
                  :maxLength="25"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step4.houseNumber"
                  :label="$t('properties.step4.houseNumber')"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step4.zip"
                  :label="$t('properties.step4.zip')"
                  @blur="searchZip('community')"
                  placeholder=""
                  :isZip="true"
                  :zipCountry="ownershipCountry"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step4.postbox"
                  :label="$t('properties.step4.postbox')"
                  placeholder=""
                  :maxLength="6"
                  :isOnlyNumber="true"
                  :isError="errors.step4Postbox.invalid"
                  :errorMessage="$t('general.errors.required_number')"
                  :disabled="isReadOnly"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputSelect
                  class="mt-2 w-full"
                  v-model="propertyData.step4.city"
                  :selected-value="propertyData.step4.city"
                  :auto-complete="true"
                  :options="cityList.community"
                  :loading="zipSearch.community"
                  :filterable="true"
                  :label="$t('properties.step4.city')"
                  placeholder=""
                  :maxLength="25"
                  :disabled="isReadOnly"
              />
              <InputSelect
                  class="mt-2 w-full"
                  :label="$t('properties.step4.countryUid')"
                  v-model="propertyData.step4.countryUid"
                  :selected-value="propertyData.step4.countryUid"
                  :filterable="true"
                  :options="countryList"
                  :placeholder="$t('general.please_select')"
                  :disabled="isReadOnly"
              />
            </div>
          </div>
        </template>

        <!-- owners tab -->
        <template #tab-pane-owner v-if="checkTabExistence('owner')">
          <div class="box flex justify-start items-center mb-10">
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <div class="flex flex-col">
              <label class="text-info ml-5">
                {{ $t('properties.owner_tab.info_box.title') }}
              </label>
              <ul class="text-left list list-disc mb-5 text-xs ml-5">
                <li> {{ $t('properties.owner_tab.info_box.item1') }}</li>
                <li> {{ $t('properties.owner_tab.info_box.item2') }}</li>
                <li> {{ $t('properties.owner_tab.info_box.item3') }}</li>
                <li> {{ $t('properties.owner_tab.info_box.item4') }}</li>
              </ul>
            </div>
          </div>
          <hr class=" mt-3 mb-4 hr-line-user"/>
          <div class="mt-2">
            <AppGrid :grid-id="ownerGridId"
                     data-endpoint="owner/list"
                     :columns="ownerColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="getOwnerMenus"
                     :show-select-col="false"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handleColClick"
                     @menu-clicked="handleOwnerMenuClick"
            />
          </div>
        </template>

        <!-- step building parts -->
        <template #tab-pane-buildingpart v-if="checkTabExistence('buildingpart')">
          <hr class=" hr-line-user  mr-7 mb-3 mt-4 "/>
          <div class="mt-2">
            <AppGrid :grid-id="buildingPartsGridId"
                     data-endpoint="buildingpart/list"
                     :columns="buildingPartsColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="buildingPartsMenus"
                     :show-select-col="false"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handleBuildingPartsColClick"
                     @menu-clicked="handleBuildingPartsMenuClick"
            />
          </div>
        </template>

        <!-- tab 4 -->
        <template #tab-pane-residential v-if="checkTabExistence('residential')">
          <hr class=" hr-line-user  mr-7 mb-3 mt-4 "/>
          <div class="mt-2">
            <AppGrid :grid-id="residentialGridId"
                     data-endpoint="residential/list"
                     :columns="residentialColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="residentialMenus"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handleColClick"
                     @menu-clicked="handleResidentialMenuClick"
            />
          </div>
        </template>

        <!--  step7 -->
        <template #tab-pane-non_residential v-if="checkTabExistence('non_residential')">
          <hr class=" hr-line-user  mr-7 mb-3 mt-4 "/>
          <div class="mt-2">
            <AppGrid :grid-id="nonresidentialGridId"
                     data-endpoint="nonresidential/list"
                     :columns="nonresidentialColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="nonresidentialMenus"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handleColClick"
                     @menu-clicked="handleNonresidentialMenuClick"
            />
          </div>
        </template>

        <!--  step8 -->
        <template #tab-pane-heritable v-if="checkTabExistence('heritable')">
          <div class="p-3 mt-2 info-msg-label-wrap flex">
            <img src="@/assets/note.svg" width="15px">
            <label class="pl-4 text-left">
              {{ $t('properties.leaseholds_info') }}
              <p class="white-space-normal">
              <span style="font-weight:600;">{{ $t('properties.leaseholds_info_1') }}</span>{{ $t('properties.leaseholds_info_2') }}
              </p>
            </label>
          </div>
          <hr class=" hr-line-user  mr-7 mb-3 mt-6"/>
          <div class="mt-2">
            <AppGrid :grid-id="leaseholdGridId"
                     data-endpoint="leasehold/list"
                     :columns="leaseholdColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="leaseholdMenus"
                     :show-select-col="false"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @col-click="handleColClick"
                     @menu-clicked="handleLeaseholdMenuClick"
            />
          </div>
        </template>

        <!-- step9 -->
        <template #tab-pane-aggriculture v-if="checkTabExistence('aggriculture')">
          <div class="flex flex-col">
            <div class="flex flex-col justify-start">
              <div class="flex justify-between">
                <label class="title">{{ $t('properties.step9.parcelsLandAndForestryExemption') }}</label>
              </div>
              <hr class=" mt-3 mb-1 hr-line-user"/>
            </div>
            <div class="flex justify-around space-x-10 half__div">
              <InputSelect
                  class="mt-2 w-half"
                  v-model="propertyData.lafTotalExemption"
                  :label="$t('properties.step9.exemptionBefreiung')"
                  :selected-value="propertyData.lafTotalExemption"
                  :filterable="true"
                  :options="landAndForestryExemptionListOpts"
                  :disabled="exemptionBefreiungDisabled || isReadOnly"
                  :placeholder="$t('general.please_select')"
              />
              <InputSelect
                  class="mt-2 w-half half__div__right"
                  v-model="propertyData.lafExemptionNonDelimitablePart"
                  :label="$t('properties.step9.exemptionBefreiungNicht')"
                  :selected-value="propertyData.lafExemptionNonDelimitablePart"
                  :filterable="true"
                  :options="landAndForestryExemptionListOpts"
                  :disabled="exemptionBefreiungNichtDisabled || isReadOnly"
                  :placeholder="$t('general.please_select')"
              />
            </div>
          </div>
          <hr class=" hr-line-user  mr-7 mb-3 mt-4 "/>
          <div class="mt-2">
            <AppGrid :grid-id="parcelsLandAndForestryGridId"
                     data-endpoint="parcelslandandforestry/list"
                     :columns="parcelsLandAndForestryColumns"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="parcelsLandAndForestryMenus"
                     :show-select-col="false"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful-filter="true"
                     :show-create-record="false"
                     :auto-load="false"
                     :is-read-only="isReadOnly"
                     @menu-clicked="handleParcelsLandAndForestryMenuClick"
                     @col-click="handleParcelsLandAndForestryColClick"
                     @on-extra-data-loaded="handleParcelsLandAndForestryExtraDataLoaded"
            />
          </div>
        </template>

        <!-- tab 9 step10-->
        <template #tab-pane-animal v-if="checkTabExistence('animal')">
          <div class="mt-18 flex flex-col">
            <div class="box flex justify-start items-center mb-10">
              <img class="image ml-2" src="@/assets/info_gray.svg"/>
              <label class="text-info ml-2">
                {{ $t('properties.step10.info_box_text') }}
              </label>
            </div>

              <div class="flex justify-between">
                <div class="flex">
                <label class="title">{{ $t('properties.step10.agricultural_use') }}</label>
                <img class="help_icon" :src="getAssetPath('help_green.svg')"
                     v-tooltip.right="$t('properties.step10.tooltip.agricultural_use')"/>
                </div>
                <hr class=" mt-3 mb-4 hr-line-user"/>
                <Button class="w-52 " @click="openImportDialog('animals')" :text="$t('properties.add_options.csv_import')"></Button>
              </div>

              <hr class=" mt-3 mb-2 hr-line-user"/>

              <div class="box flex justify-start items-center mt-1 mb-3">
                <img class="image ml-2" src="@/assets/info_gray.svg"/>
                <label class="text-info ml-2">
                  {{ agriculture_sum_info_text }}
                </label>
              </div>

            <div class="flex justify-around space-x-10">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.ownedSpace"
                  :label="$t('properties.step10.ownedSpace')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.minusLeasedAreas"
                  :label="$t('properties.step10.minusLeasedAreas')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.plusLeasedAreas"
                  :label="$t('properties.step10.plusLeasedAreas')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.selfCultivatedAreas"
                  :label="$t('properties.step10.selfCultivatedAreas')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>

            <!--      section two-->
            <div class="flex flex-col justify-start mt-10">
              <div class="flex">
                <label class="title">{{ $t('properties.step10.animal_species') }}</label>
                <img class="help_icon" :src="getAssetPath('help_green.svg')"
                     v-tooltip.right="$t('properties.step10.tooltip.animal_species')"/>
              </div>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.alpacas"
                  :label="$t('properties.step10.alpacas')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.damAnimal_below1Year"
                  :label="$t('properties.step10.damAnimal_below1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>

            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.damAnimal_above1Year"
                  :label="$t('properties.step10.damAnimal_above1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.layingHens_layingHensIncludeBreeding"
                  :label="$t('properties.step10.layingHens_layingHensIncludeBreeding')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.layingHens_purchasedLayingHens"
                  :label="$t('properties.step10.layingHens_purchasedLayingHens')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.poultry"
                  :label="$t('properties.step10.poultry')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.breedingRabbits"
                  :label="$t('properties.step10.breedingRabbits')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.llamas"
                  :label="$t('properties.step10.llamas')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.horses_below3YearsOrSmallHorses"
                  :label="$t('properties.step10.horses_below3YearsOrSmallHorses')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.horses_horses"
                  :label="$t('properties.step10.horses_horses')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.beef_below1Year"
                  :label="$t('properties.step10.beef_below1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.beef_below1Year')"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.beef_1to2Year"
                  :label="$t('properties.step10.beef_1to2Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.beef_above2Year"
                  :label="$t('properties.step10.beef_above2Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.fatteningAnimalsLessThan1Year"
                  :label="$t('properties.step10.fatteningAnimalsLessThan1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.fatteningAnimalsLessThan1Year')"
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.cows"
                  :label="$t('properties.step10.cows')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.breedingBulls"
                  :label="$t('properties.step10.breedingBulls')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.sheep_below1Year"
                  :label="$t('properties.step10.sheep_below1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.sheep_above1Year"
                  :label="$t('properties.step10.sheep_above1Year')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.breedingPigs"
                  :label="$t('properties.step10.breedingPigs')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.breedingPigs')"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.ostriches_above14Months"
                  :label="$t('properties.step10.ostriches_above14Months')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.ostriches_below14Months"
                  :label="$t('properties.step10.ostriches_below14Months')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.goats"
                  :label="$t('properties.step10.goats')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>

            <!--      section three-->
            <div class="flex flex-col justify-start mt-10">
              <div class="flex">
                <label class="title">{{ $t('properties.step10.animal_species_after_generation') }}</label>
                <img class="help_icon" :src="getAssetPath('help_green.svg')"
                     v-tooltip.right="$t('properties.step10.tooltip.animal_species_after_generation')"/>
              </div>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.youngChickensForFattening_below6Passes"
                  :label="$t('properties.step10.youngChickensForFattening_below6Passes')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.youngChickensForFattening_above6Passes"
                  :label="$t('properties.step10.youngChickensForFattening_above6Passes')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pullets"
                  :label="$t('properties.step10.pullets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.fatteningDucks_count"
                  :label="$t('properties.step10.fatteningDucks_count')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.fatteningDucks_raisingPhase"
                  :label="$t('properties.step10.fatteningDucks_raisingPhase')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.fatteningDucks_fatteningPhase"
                  :label="$t('properties.step10.fatteningDucks_fatteningPhase')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.turkeys_selfRaised"
                  :label="$t('properties.step10.turkeys_selfRaised')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.turkeys_selfRaised')"
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.turkeys_bought"
                  :label="$t('properties.step10.turkeys_bought')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.turkeys_youngTurkeys"
                  :label="$t('properties.step10.turkeys_youngTurkeys')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.fattenedGeese"
                  :label="$t('properties.step10.fattenedGeese')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.mastRabbit"
                  :label="$t('properties.step10.mastRabbit')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.beefCattle"
                  :label="$t('properties.step10.beefCattle')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.beefCattle')"
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_lightPiglets"
                  :label="$t('properties.step10.pigs_lightPiglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_piglets"
                  :label="$t('properties.step10.pigs_piglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_heavyPiglets"
                  :label="$t('properties.step10.pigs_heavyPiglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_runner"
                  :label="$t('properties.step10.pigs_runner')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_heavyRunner"
                  :label="$t('properties.step10.pigs_heavyRunner')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_fatteningPigs"
                  :label="$t('properties.step10.pigs_fatteningPigs')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.pigs_youngBreedingPigs"
                  :label="$t('properties.step10.pigs_youngBreedingPigs')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :showHelpIcon="true"
                  :tooltip="$t('properties.step10.tooltip.pigs_youngBreedingPigs')"
                  :disabled="isReadOnly"
              />
              <div class="w-full invisible"></div>
            </div>

            <!--      section four-->
            <div class="flex flex-col justify-start mt-10">
              <div class="flex">
                <label class="title">{{ $t('properties.step10.bought_animals') }}</label>
                <img class="help_icon" :src="getAssetPath('help_green.svg')"
                     v-tooltip.right="$t('properties.step10.tooltip.bought_animals')"/>
              </div>
              <hr class=" mt-3 mb-4 hr-line-user"/>
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.boughtPigs_lightPiglets"
                  :label="$t('properties.step10.boughtPigs_lightPiglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.boughtPigs_piglets"
                  :label="$t('properties.step10.boughtPigs_piglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.boughtPigs_heavyPiglets"
                  :label="$t('properties.step10.boughtPigs_heavyPiglets')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.boughtPigs_runner"
                  :label="$t('properties.step10.boughtPigs_runner')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
            </div>
            <div class="flex justify-around space-x-10 mt-3">
              <InputText
                  class="mt-2 w-full"
                  v-model="propertyData.step10.boughtPigs_heavyRunner"
                  :label="$t('properties.step10.boughtPigs_heavyRunner')"
                  :isOnlyNumber="true"
                  :maxLength="9"
                  placeholder=""
                  :disabled="isReadOnly"
              />
              <div class="w-full invisible"></div>
            </div>
          </div>
        </template>

        <!-- tab9-->
        <template #tab-pane-document v-if="checkTabExistence('document')">
          <div>
            <hr class="hr-line-user mb-2"/>
            <Document grid-id="property_documents"
                      :parent-id="propertyData.id"
                      :parent-object="propertyData"
                      parent-type="PROPERTY"
                      :viewer-breadcrumbs="viewerBreadcrumbs"
                      :datev-client="isDatevClient"
                      :read-only="isReadOnly"
            />
          </div>
        </template>

        <!-- tab 10-->
        <template #tab-pane-declaration v-if="checkTabExistence('declaration')">
          <div class="mt-2">
            <AppGrid :grid-id="declarationGridId"
                     data-endpoint="declaration/list"
                     :columns="declarationColumns"
                     default-sort-attr="uid"
                     :default-sort-dir="1"
                     :selectable="true"
                     selection-data-key="prim_uid"
                     :standard-filters="standardFilters"
                     :server-params="serverParams"
                     :menus="declarationMenus"
                     :show-filter="false"
                     :show-view-menu="false"
                     :state-ful="false"
                     :show-create-record="false"
                     :auto-load="false"
                     @menu-clicked="handleDeclarationMenuClick"
                     @col-click="handleDeclarationColClick"
            />

          </div>
        </template>

        <!-- tab11 Protocol -->
        <template #tab-pane-protocol v-if="checkTabExistence('protocol')">
          <div class="py-2 align-left sm:px-2 lg:px-2">
            <VersionGrid
                :parent-id="parseInt(propertyData.id)"
                parent-type="properties"
                :show-search="true"
                :show-filter="true"
                :initial-search="initialProtocolSearch"
            />
          </div>
        </template>

        <template #tab-footer>
          <div class="flex justify-between space-x-6 mt-5">
            <div class="flex justify-start space-x-6 w-full">
              <ButtonWithOption
                @click="handleTabsOtherButtonClick"
                class="button-label text-center bg-green text-white "
                primary
                :text="$t('buttons.other')"
                :options="otherButtonOptions"
              ></ButtonWithOption>
            </div>
            <div class="flex justify-end space-x-6 w-full">
              <Button @click="handleTabsBtnClick('cancel')" class=" button-label  text-center   label__text-black "
                      :secondary="true"
                      :text="$t('buttons.cancel')"></Button>
              <ButtonWithOption
                v-if="!isReadOnly && propertyData.archived === 0"
                @click="handleTabsBtnClick"
                class="button-label text-center bg-green text-white "
                primary
                :text="$t('buttons.save')"
                :options="saveButtonOptions"
              ></ButtonWithOption>
              <Button
                      v-if="!isReadOnly && propertyData.archived === 1"
                      @click="restorePropertyModalShow"
                      :icon="getAssetPath('floppy_white.svg')"
                      :text="$t('buttons.restore')">
              </Button>
            </div>
          </div>
        </template>
      </AppTabs>
    </div>
    <div class="w-full h-full mr-8 pb-8" v-else style="min-height: 600px;" />
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import DataGrid from "@/components/DataGrid";
import AppTabs from "@/components/AppTabs";
import InputSelect from "../inputs/InputSelect";
import InputCheck from "../inputs/InputCheck"
import RadioButton from 'primevue/radiobutton';
import {PropertyService} from "@/services/property.service";
import {ClientService} from "@/services/client.service";
import Document from "../Document";
import VersionGrid from "../VersionGrid";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {requiredIf} from "vuelidate/lib/validators";
import Tooltip from 'primevue/tooltip';
import {taxModels} from "@/core/constants";
import ModalDialog from "@/components/ModalDialog";
import deviatingOwner from "@/components/properties/components/deviatingOwner";
import subArea from "@/components/properties/components/subArea";
import {S3Service} from "@/services/s3.service";
import InputUpload from "../inputs/InputUpload";
import {UserService} from "../../services/user.service";
import moment from 'moment';

export default {
  name: "edit",
  components: {
    ModalDialog,
    InputSelect,
    InputCheck,
    RadioButton,
    AppTabs,
    Document,
    VersionGrid,
    AppGrid,
    deviatingOwner,
    InputUpload,
    DataGrid,
    subArea
  },
  directives: {
    'tooltip': Tooltip
  },
  props: {
    set: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      popoverTexts_community: [
        this.$t('properties.step2.tooltip_community_text1'),
        this.$t('properties.step2.tooltip_community_text2')
      ],
      popoverLink_community: "https://www.statistikportal.de/de/gemeindeverzeichnis",
      didShowClientComment: false,
      navRecords: [],
      benefits: [],
      loading: true,
      validAreaOfTheLand: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
      validYieldMeasurementNumber: ['1', '21', '23'],
      validAreaOfFarmBuilding: ['29', '30', '31', '32', '33', '34'],
      validFlowRate: ['20'],
      rendering: true,
      maxlength: 1,
      maxFloatField: 1,
      decimalNumber: 0,
      skenDataAddress: '',
      popoverTexts_step2_name: [
        this.$t('properties.step2.name_help'),
      ],
      popoverTexts_ew_az: [
        this.$t('properties.step3.tooltip.tooltip_client_text1'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_ownership_relation: [
        this.$t('properties.step3.tooltip.tooltip_client_text13'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_type_of_land: [
        this.$t('properties.step3.tooltip.tooltip_client_text12'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_type_of_use: [
        this.$t('properties.step3.tooltip.tooltip_client_text11'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_undeveloped_land: [
        this.$t('properties.step3.tooltip.tooltip_client_text14'),
        this.$t('properties.step3.tooltip.tooltip_client_text15')
      ],
      popoverTexts_multiple_communities: [
        this.$t('properties.step3.tooltip.tooltip_client_text10'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_outdoor_property: [
        this.$t('properties.step3.tooltip.tooltip_client_text16')
      ],
      popoverTexts_lease_hold: [
        this.$t('properties.step3.tooltip.tooltip_client_text8_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text8_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_building_on_foreign: [
        this.$t('properties.step3.tooltip.tooltip_client_text9_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text9_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_area_of_the_land_value_property: [
        this.$t('properties.step3.tooltip.tooltip_client_text_areaOfTheLandValue1_1'),
        this.$t('properties.step3.tooltip.tooltip_client_text_areaOfTheLandValue1_2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_area_of_the_land_property: [
        this.$t('properties.step3.tooltip.tooltip_client_area_of_the_land_property'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_area_of_the_land_1: [
        this.$t('properties.step3.tooltip.tooltip_client_area_of_the_land_1')
      ],
      popoverTexts_benefit: [
        this.$t('properties.step3.tooltip.tooltip_client_text5'),
      ],
      popoverTexts_denomination: [
        this.$t('properties.step9.tooltip.denomination1'),
        this.$t('properties.step9.tooltip.denomination2'),
        this.$t('properties.step9.tooltip.denomination3'),
        this.$t('properties.step9.tooltip.denomination4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_usageType: [
        this.$t('properties.step9.tooltip.usageType1'),
        this.$t('properties.step9.tooltip.usageType2'),
      ],
      popoverTexts_deduction_checkbox: [
        this.$t('properties.step3.deduction_checkbox_help_text'),
      ],
      popoverTexts_corridor: [
        this.$t('properties.step9.tooltip.corridor1'),
        this.$t('properties.step9.tooltip.corridor2'),
        this.$t('properties.step9.tooltip.corridor3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_counter: [
        this.$t('properties.step9.tooltip.counter1'),
        this.$t('properties.step9.tooltip.counter2'),
        this.$t('properties.step9.tooltip.counter3'),
        this.$t('properties.step9.tooltip.counter4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_yearOfConstruction: [
        this.$t('properties.step6.tooltip.yearOfConstruction1'),
        this.$t('properties.step6.tooltip.yearOfConstruction2'),
        this.$t('properties.step6.tooltip.yearOfConstruction3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_coreRenovationYear: [
        this.$t('properties.step6.tooltip.coreRenovationYear1'),
        this.$t('properties.step6.tooltip.coreRenovationYear2'),
        this.$t('properties.step6.tooltip.coreRenovationYear3'),
        this.$t('properties.step6.tooltip.coreRenovationYear4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_demolitionObligationYear: [
        this.$t('properties.step6.tooltip.demolitionObligationYear1'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_amountGarages: [
        this.$t('properties.step6.tooltip.amountGarages1'),
        this.$t('properties.step6.tooltip.amountGarages2'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_usableSpaceArea: [
        this.$t('properties.step5.tooltip.usableSpaceArea1'),
        this.$t('properties.step5.tooltip.usableSpaceArea2'),
        this.$t('properties.step5.tooltip.usableSpaceArea3'),

      ],
      popoverTexts_livingSpaceArea: [
        this.$t('properties.step5.tooltip.livingSpaceArea1'),
        this.$t('properties.step5.tooltip.livingSpaceArea2'),
        this.$t('properties.step5.tooltip.livingSpaceArea3'),

      ],
      popoverTexts_exemption_present: [
        this.$t('properties.step2a.exemption_present_tooltip'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],

      popoverTexts_taxFreeArea: [
        this.$t('properties.step6.tooltip.taxFreeArea')
      ],
      popoverTexts_discountedArea: [
        this.$t('properties.step6.tooltip.discountedArea')
      ],
      popoverTexts_economic_entity_type: [
        this.$t('properties.step2a.tooltip.economic_entity_type_1'),
        this.$t('properties.step2a.tooltip.economic_entity_type_2'),
        this.$t('properties.step2a.tooltip.economic_entity_type_3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_corridor: [
        this.$t('properties.step3.tooltip.tooltip_corridor_1'),
        this.$t('properties.step3.tooltip.tooltip_corridor_2'),
        this.$t('properties.step3.tooltip.tooltip_corridor_3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_counter: [
        this.$t('properties.step3.tooltip.tooltip_counter_1'),
        this.$t('properties.step3.tooltip.tooltip_counter_2'),
        this.$t('properties.step3.tooltip.tooltip_counter_3'),
        this.$t('properties.step3.tooltip.tooltip_counter_4'),
        this.$t('properties.step3.tooltip.tooltip_counter_5'),
        this.$t('properties.step3.tooltip.tooltip_counter_6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_denominator: [
        this.$t('properties.step3.tooltip.tooltip_denominator_1'),
        this.$t('properties.step3.tooltip.tooltip_denominator_2'),
        this.$t('properties.step3.tooltip.tooltip_denominator_3'),
        this.$t('properties.step3.tooltip.tooltip_denominator_4'),
        this.$t('properties.step3.tooltip.tooltip_denominator_5'),
        this.$t('properties.step3.tooltip.tooltip_denominator_6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_shareOfOwnershipCounter: [
        this.$t('properties.step3.tooltip.tooltip_shareOfOwnershipCounter'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_shareOfOwnershipDenominator: [
        this.$t('properties.step3.tooltip.tooltip_shareOfOwnershipDenominator'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverText_step9_district: [
        this.$t('properties.step3.tooltip.tooltip_client_text4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverText_step9_districtNumber: [
        this.$t('properties.step9.tooltip.district_number'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step3_landRegisterSheet: [
        this.$t('properties.step3.tooltip.tooltip_landRegisterSheet'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_locationPlanNumber: [
        this.$t('properties.step7.tooltip.locationPlanNumber'),
      ],
      popoverTexts_buildingType: [
        this.$t('properties.step7.tooltip.buildingType1'),
        this.$t('properties.step7.tooltip.buildingType2'),
        this.$t('properties.step7.tooltip.buildingType3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step7_yearOfConstruction: [
        this.$t('properties.step7.tooltip.yearOfConstruction1'),
        this.$t('properties.step7.tooltip.yearOfConstruction2'),
        this.$t('properties.step7.tooltip.yearOfConstruction3'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step7_coreRenovationYear: [
        this.$t('properties.step7.tooltip.coreRenovationYear1'),
        this.$t('properties.step7.tooltip.coreRenovationYear2'),
        this.$t('properties.step7.tooltip.coreRenovationYear3'),
        this.$t('properties.step7.tooltip.coreRenovationYear4'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step7_demolitionObligationYear: [
        this.$t('properties.step7.tooltip.demolitionObligationYear'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step7_areaOfTheLand: [
        this.$t('properties.step7.tooltip.areaOfTheLand1'),
        this.$t('properties.step7.tooltip.areaOfTheLand2'),
        this.$t('properties.step7.tooltip.areaOfTheLand3'),
        this.$t('properties.step7.tooltip.areaOfTheLand4'),
        this.$t('properties.step7.tooltip.areaOfTheLand5'),
        this.$t('properties.step7.tooltip.areaOfTheLand6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_exemption: [
        this.$t('properties.step7.tooltip.exemption'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_step7_benefit: [
        this.$t('properties.step7.tooltip.benefit'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_parcel: [
        this.$t('properties.step9.tooltip.parcel'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_counter_step3: [
        this.$t('properties.step3.tooltip.tooltip_counter_1'),
        this.$t('properties.step3.tooltip.tooltip_counter_2'),
        this.$t('properties.step3.tooltip.tooltip_counter_3'),
        this.$t('properties.step3.tooltip.tooltip_counter_4'),
        this.$t('properties.step3.tooltip.tooltip_counter_5'),
        this.$t('properties.step3.tooltip.tooltip_counter_6'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_shareOfOwnershipCounter: [
        this.$t('properties.step3.tooltip.tooltip_shareOfOwnershipCounter'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_tooltip_corridor: [
        this.$t('properties.step9.tooltip.corridor1'),
        this.$t('properties.step9.tooltip.corridor1'),
        this.$t('properties.step9.tooltip.corridor1'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_tooltip_landRegisterSheet: [
        this.$t('properties.step3.tooltip.tooltip_landRegisterSheet'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_tooltip_parcel: [
        this.$t('properties.step9.tooltip.parcel'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_spatiallyDefinableExemptionLandOrLiving: [
        this.$t('properties.step3.tooltip.tooltip_spatiallyDefinableExemptionLandOrLiving'),
      ],

      popoverLink_ew_az: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
      popoverLink_ownership_relation: "https://hilfe.grundsteuer-digital.de/faq/eigentumsverhaeltnisse/ ",
      popoverLink_type_of_land: "https://hilfe.grundsteuer-digital.de/faq/art-des-grundstuecks/",
      popoverLink_type_of_use: "https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/",
      popoverLink_area_of_the_land_property: "https://hilfe.grundsteuer-digital.de/faq/grundstuecksflaeche-in-m%c2%b2/",
      popoverLink_area_of_the_land_value_property: "https://hilfe.grundsteuer-digital.de/faq/bodenrichtwert/",
      popoverLink_denomination: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_usageType: "https://hilfe.grundsteuer-digital.de/faq/arten-der-nutzungen-im-einzelnen-fur-betriebe-der-land-und-forstwirtschaft/",
      popoverLink_corridor: "https://hilfe.grundsteuer-digital.de/faq/flur/",
      popoverLink_counter: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_yearOfConstruction: "https://hilfe.grundsteuer-digital.de/faq/baujahr-des-gebaeudes/",
      popoverLink_coreRenovationYear: "https://hilfe.grundsteuer-digital.de/faq/jahr-der-kernsanierung/",
      popoverLink_demolitionObligationYear: "https://hilfe.grundsteuer-digital.de/faq/abbruchverpflichtung/",
      popoverLink_amountGarages: "https://hilfe.grundsteuer-digital.de/faq/garagen/",
      popoverLink_foreign_land: "https://hilfe.grundsteuer-digital.de/faq/gebaeude-auf-fremden-grund/",
      popoverLink_lease_hold: "https://hilfe.grundsteuer-digital.de/faq/erbbaurecht/",
      popoverLink_exemption_present: "https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/",
      popoverLink_multiple_communities: "https://hilfe.grundsteuer-digital.de/faq/grundbesitz-ueber-mehrere-gemeinden/",
      popoverLink_economic_entity_type: "https://hilfe.grundsteuer-digital.de/faq/art-der-wirtschaftlichen-einheit/",
      popoverLink_step3_corridor: "https://hilfe.grundsteuer-digital.de/faq/flur/",
      popoverLink_step3_counter: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_step3_denominator: "https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/",
      popoverLink_step3_shareOfOwnershipCounter: "https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/",
      popoverLink_step3_shareOfOwnershipDenominator: "https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/",
      popoverLink_step9_district: "https://hilfe.grundsteuer-digital.de/faq/gemarkung/",
      popoverLink_step9_district_number: "https://hilfe.grundsteuer-digital.de/faq/gemarkungsnummer/",
      popoverLink_step3_landRegisterSheet: "https://hilfe.grundsteuer-digital.de/faq/grundbuchblatt/",
      popoverLink_buildingType: "https://hilfe.grundsteuer-digital.de/faq/gebaeudeart/",
      popoverLink_areaOfTheLand: "https://hilfe.grundsteuer-digital.de/faq/bruttogrundflaeche-des-gebaeudes-in-m%c2%b2/",
      popoverLink_exemption: "https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/",
      popoverLink_benefit: "https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/",
      popoverLink_parcel: "https://hilfe.grundsteuer-digital.de/faq/%20gemarkung/",
      landAndForestryExemptionList: this.$t('properties.land_and_forestry_exemption_options'),
      landAndForestryExemptionListOpts: this.$t('properties.step9.exemption_befreiung'),
      service: new PropertyService(),
      clientService: new ClientService(),
      userService: new UserService(),
      monument_identification: false,
      s3Service: new S3Service(),
      //This is only to avoid errors while rendering before the property data is fetched.
      //This should be an instance of a property object. But this is not implemented in a clean way
      propertyData: {step2 : {}},
      initialPropertyDataString: null,
      clientList: [],
      totalClientsCount: 0,

      UId: '',
      stateList: [],
      countryList: [],
      loadingCommunity: false,

      isInvalidAreaSum: false,
      communityList: [],
      communityListLandAndForestry: [],
      parcelList: [],
      parcelListLandAndForestry: [],
      viewerBreadcrumbs: [],
      cityList: {
        "basic": [],
        "community": [],
        "leaseholdAddDialog": [],
        "leaseholdEditDialog": [],
        "addOwnerDialog": [],
        "addOwnerDialogRepresentative": [],
        "editOwnerDialog": [],
        "editOwnerDialogRepresentative": []
      },
      zipSearch: {
        "basic": false,
        "community": false,
        "leaseholdAddDialog": false,
        "leaseholdEditDialog": false,
        "addOwnerDialog": false,
        "addOwnerDialogRepresentative": false,
        "editOwnerDialog": false,
        "editOwnerDialogRepresentative": false
      },
      step4: {
        maxlength: 1
      },
      address_info_box: false,
      grundstucksadresse_info_box: '',
      breadcrumbs: [
        {
          title: this.$t('components.properties'),
          link: '/property/list'
        }, {
          title: this.$t('components.properties_menu.edit_property'),
          link: ''
        }
      ],
      property_uid: 0,
      pro_id: 0,
      errors: {
        "step2Zip": {
          invalid: false
        },
        "step4Postbox": {
          invalid: false
        },
        "step4Zip": {
          invalid: false
        },
        "federalStateUid": {
          invalid: false
        },
        "community": {
          invalid: false
        },
        "street": {
          invalid: false
        },
        "houseNumber": {
          invalid: false
        },
        "city": {
          invalid: false
        },
        "zip": {
          invalid: false
        },
        "name": {
          invalid: false
        },
        reference: {
          invalid: false
        },
        economicEntityType: {
          invalid: false
        },
        livingSpaceDescription: {
          invalid: false
        },
        livingSpaceArea: {
          invalid: false
        }
      },

      addModalSaveErrors: {
        "parcel": {
          invalid: false,
        },
        "firstName": {
          invalid: false,
        },
        "nonResidentialAreaOfTheLand": {
          invalid: false,
        },
        "nonResidentialYearOfConstruction": {
          invalid: false,
        },
        "nonResidentialBuildingType": {
          invalid: false,
        }
      },
      editModalSaveErrors: {
        "parcel": {
          invalid: false,
        },
        "firstName": {
          invalid: false,
        },
        "nonResidentialAreaOfTheLand": {
          invalid: false,
        },
        "nonResidentialYearOfConstruction": {
          invalid: false,
        },
        "nonResidentialBuildingType": {
          invalid: false,
        }
      },

      importModalLabel: '',
      importModalLabel2: '',
      importModalLabel3: '',
      importModalLink: '',
      importModal: false,
      importModalType: '',

      showImportError: false,
      importErrors: [],
      elsterValidationStatus: '',
      elsterValidationResultDialog: false,
      elsterValidationErrors: [],
      elsterValidationHints: [],
      mainMandatoryFields: [],
      modalMandatoryFields: {},
      missingFields: [],
      missingMandatoryField: true,
      showMissingMandatoryFieldsDlg: false,
      missingFieldsHtml: '',

      step6FirstRowYearOfConstruction: '',

      editDeclarationDialog: false,
      addDeclarationDialog: false,

      modalSaveRequired: {
        "parcel": false,
        "firstName": false,
      },

      activeTab: '',
      tabWithFields:[
        {
          name: this.$t('properties.basic'),
          slug: 'basic',
          fields:[
            {
              name: this.$t('clients.attrs.postcode'),
              slug: 'step2zip'
            },
            {
              name: this.$t('properties.step2.name'),
              slug: 'name'
            },
            {
              name: this.$t('properties.step2a.economic_entity_type'),
              slug: 'economicEntityType'
            },
          ]
        },
          {
          name: this.$t('properties.steps.step4'),
          slug: 'community',
          fields:[
            {
              name: this.$t('properties.step4.zip'),
              slug: 'step4zip'
            },
            {
              name: this.$t('properties.step4.postbox'),
              slug: 'step4Postbox'
            },
          ]
        },
      ],
      availableTabs: [
        {
          name: this.$t('properties.basic'),
          slug: 'basic',
          component: 'StepAddress'
        }, {
          name: this.$t('properties.steps.step3'),
          slug: 'property',
          component: 'StepPlot'
        }, {
          name: this.$t('properties.steps.step3a'),
          slug: 'exemptionbenefit',
          component: 'StepExemptionBenefit'
        }, {
          name: this.$t('properties.steps.step4'),
          slug: 'community',
          component: 'StepCommunity'
        }, {
          name: this.$t('properties.steps.step_owner'),
          slug: 'owner',
          component: 'StepOwner'
        }, {
          name: this.$t('properties.steps.step5'),
          slug: 'buildingpart',
          component: 'StepBuilding'
        }, {
          name: this.$t('properties.steps.step6'),
          slug: 'residential',
          component: 'StepResidential'
        }, {
          name: this.$t('properties.steps.step7'),
          slug: 'non_residential',
          component: 'StepNonResidential'
        }, {
          name: this.$t('properties.steps.step8'),
          slug: 'heritable',
          component: 'StepLeasehold'
        }, {
          name: this.$t('properties.steps.step9'),
          slug: 'aggriculture',
          component: 'StepAgriculture'
        }, {
          name: this.$t('properties.steps.step10'),
          slug: 'animal',
          component: 'StepAnimal'
        }, {
          name: this.$t('properties.documents'),
          showTopLine: true,
          slug: 'document'
        }, {
          name: this.$t('properties.declaration'),
          showTopLine: true,
          slug: 'declaration'
        }, {
          name: this.$t('properties.protocol'),
          showTopLine: true,
          slug: 'protocol'
        },
      ],

      economic_entity_type_options: this.$t('properties.step2a.economic_entity_types.default'),

      ownership_structure: this.$t('properties.step3.ownership_structure_options'),
      step3_property_type: this.$t('properties.step3.property_type_options.Bundesmodell'),
      step3_property_exemption_benefit_type_options: this.$t('properties.step3.property_exemption_benefit_type_options'),
      usage_type: this.$t('properties.step3.usage_type_options'),
      usage_type_options: this.$t('properties.step9.usage_type_options'),
      development_state: this.$t('properties.step3.development_state_options'),
      benefit_options: [],
      benefit_options_with_monument: [],
      benefit_options_non_residential: [],
      benefit_options_residential: [],
      tax_exemption_parcel_options: [],
      tax_exemption_parcel_first_option: '0',

      building_type_options: this.$t('properties.step7.building_type_options'),
      contained_area_options: this.$t('properties.step3.contained_area_options'),
      ownership_salutation_options: [],
      owner_salutation_options: this.$t('properties.step4.salutation_options'),
      representative_salutation_options: [],
      salutation_options: this.$t('properties.step4.salutation_options'),

      disableOtherSpacesFields: true,

      exemption_options: this.$t('properties.exemption_options'),
      residential_exemption_options: this.$t('properties.residential_exemption_options'),
      area_of_the_land_tax_exemption_options: this.$t('properties.area_of_the_land_tax_exemption_options'),

      chips: [],
      currentInput: '',
      currentCategory: 'basic',

      clientObj: [],
      stateObj: [],
      taxModelObj: [],

      address: '',
      stateName: '',
      taxModel: '',
      taxModelText: '',
      propertyTypeInfoBar: '',

      taxModelForModal: '',

      taxModels: taxModels,

      expirationFirstGrantPeriodDisabled: false,
      expirationLastGrantPeriodDisabled: false,
      expectedExemptPurposesDisabled: false,
      taxPrivilegedPurposesDisabled: false,
      unbuiltRealEstateExemptionDescriptionDisabled: false,
      taxFreeAreaDisabled: false,
      unbuiltRealEstateTaxExemptionDisabled: false,
      propertyParcelUidDisabled: false,
      useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled: false,
      useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled: false,
      useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled: false,
      useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled: false,
      taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled: false,
      taxBreakConditionSpatiallyDefinableExemptionAreaDisabled: false,
      taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled: false,

      useOfDefinablePartSpatiallyDefinableExemptionAreaMaxLength: 15,

      propertyTypeDisabled: false,
      propertyExemptionBenefitTypeDisabled: false,
      developmentStateDisabled: false,
      areaOfTheLandDisabled: false,
      areaOfTheLandValueDisabled1: false,
      areaOfTheLandValueDisabled2: false,
      maxAreaOfLandValue1: 999999.99,
      firstAreaInfoShow: true,
      secondAreaInfoShow: false,
      thirdAreaInfoShow: false,
      fourthAreaInfoShow: false,
      addMoreAreaLinkShow: true,
      totalPropertyPartDisabled: false,
      registrationLandRegistryDisabled: false,
      showSubAreaComp: false,

      showInfoDlg: false,
      infoMessage: '',
      loading1: false,
      btnDisabled1: false,
      loading2: false,
      btnDisabled2: false,

      sumOfAreas: 0, // sum of parcels

      benefitUsableSpaceAreaPositive: false,
      benefitUsableSpaceAreaMaxLength: 15,

      serverParams: {
        "parentType": 'property',
        "parentId": '0'
      },

      parcelGridId: "parcels",
      standardFilters: [],
      parcelColumns: [
        {
          field: 'community',
          header: this.$t('properties.step2.community'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'parcel',
          header: this.$t('properties.step3.district'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'landRegisterSheet',
          header: this.$t('properties.step3.landRegisterSheet'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'corridor',
          header: this.$t('properties.step3.corridor'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'parcelDataCounter',
          header: this.$t('properties.step3.parcelDataCounter'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'parcelDataDenominator',
          header: this.$t('properties.step3.parcelDataDenominator'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'areaOfTheLand',
          header: this.$t('properties.step3.areaOfTheLand'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'shareOfOwnershipCounter',
          header: this.$t('properties.step3.shareOfOwnershipCounter'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'shareOfOwnershipDenominator',
          header: this.$t('properties.step3.shareOfOwnershipDenominator'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'containedInArea',
          header: this.$t('properties.step3.containedInArea'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        },
      ],

      containedInAreaDisabled: false,

      selectedRecords: [],

      menus: [
        {
          id: "cm.add_parcel",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.parcels_menu.add_parcel')
        }, {
          id: "cm.edit_parcel",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.parcels_menu.edit_parcel'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.duplicate_parcel",
          "icon": this.getAssetPath('clients_green.svg'),
          "icon_disabled": this.getAssetPath('clients_disabled.svg'),
          "title": this.$t('properties.parcels_menu.duplicate_parcel'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.edit_skendata",
          "icon": this.getAssetPath('data_acquisition.svg'),
          "title": this.$t('properties.parcels_menu.edit_skendata')
        }, {
          id: "cm.delete_parcel",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.parcels_menu.delete_parcel'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.import_parcel",
          "icon": this.getAssetPath('import_green.svg'),
          "title": this.$t('buttons.import')
        }
      ],

      ownerGridId: "owners",
      ownerColumns: [
        {
          field: 'salutation',
          header: this.$t('properties.step4.salutation'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'firstName',
          header: this.$t('properties.step4.firstName'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'lastName',
          header: this.$t('properties.step4.lastName'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'title',
          header: this.$t('properties.step4.title'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'dateOfBirth',
          header: this.$t('properties.step4.dateOfBirth'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'street',
          header: this.$t('properties.step4.street'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'houseNumber',
          header: this.$t('properties.step4.houseNumber'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'phone',
          header: this.$t('properties.step4.phone'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'zip',
          header: this.$t('properties.step4.zip'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'postbox',
          header: this.$t('properties.step4.postbox'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'city',
          header: this.$t('properties.step4.city'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'federalStateName',
          header: this.$t('properties.step9.gemeindeBundesland'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'countryName',
          header: this.$t('properties.step4.countryUid'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'financeAgency',
          header: this.$t('properties.step4.financeAgency'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'taxNumber',
          header: this.$t('properties.step4.taxNumber'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'taxIdentificationNumber',
          header: this.$t('properties.step4.taxIdentificationNumber'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'area_of_the_land_counter',
          header: this.$t('properties.step4.area_of_the_land_counter'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }, {
          field: 'area_of_the_land_denominator',
          header: this.$t('properties.step4.area_of_the_land_denominator'),
          isChecked: false,
          elType: 'text',
          clickable: true,
        }
      ],
      ownerMenus: [
        {
          id: "cm.add_owner",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.owners_menu.add_owner')
        }, {
          id: "cm.edit_owner",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.owners_menu.edit_owner'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_owner",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.owners_menu.delete_owner'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.take_over",
          "icon": this.getAssetPath('take_over.svg'),
          "icon_disabled": this.getAssetPath('take_over_gray.svg'),
          "title": this.$t('properties.owners_menu.take_over'),
          "disabled": true
        }
      ],

      buildingPartsGridId: "buildingparts",
      buildingPartsColumns: [
        {
          field: 'livingSpaceDescription',
          header: this.$t('properties.step5.livingSpaceDescription'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'livingSpaceArea',
          header: this.$t('properties.step5.livingSpaceArea'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'usableSpaceArea',
          header: this.$t('properties.step5.usableSpaceArea'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'areaOfTheLandCivilDefense',
          header: this.$t('properties.step5.areaOfTheLandCivilDefense'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }
      ],
      buildingPartsMenus: [
        {
          id: "cm.add_buildingpart",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.buildingparts_menu.add_buildingpart')
        }, {
          id: "cm.edit_buildingpart",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.buildingparts_menu.edit_buildingpart'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_buildingpart",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.buildingparts_menu.delete_buildingpart'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.building_service",
          "icon": this.getAssetPath('building_service.svg'),
          "title": this.$t('properties.buildingparts_menu.building_service')
        }
      ],

      residentialGridId: "residentials",
      residentialColumns: [
        {
          field: 'yearOfConstruction',
          header: this.$t('properties.step6.yearOfConstruction'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'coreRenovationYear',
          header: this.$t('properties.step6.coreRenovationYear'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'demolitionObligationYear',
          header: this.$t('properties.step6.demolitionObligationYear'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'amountGarages',
          header: this.$t('properties.step6.amountGarages'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'ab60Counter',
          header: this.$t('properties.step6.apartmentsBelow60Counter'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'ab60TotalLivingSpace',
          header: this.$t('properties.step6.apartmentsBelow60TotalLivingSpace'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'ab60100Counter',
          header: this.$t('properties.step6.apartmentsBetween60100Counter'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'ab60100TotalLivingSpace',
          header: this.$t('properties.step6.apartmentsBetween60100TotalLivingSpace'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'aa100Counter',
          header: this.$t('properties.step6.apartmentsAbove100Counter'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'aa100TotalLivingSpace',
          header: this.$t('properties.step6.apartmentsAbove100TotalLivingSpace'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'aoCounter',
          header: this.$t('properties.step6.apartmentsOtherCounter'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }, {
          field: 'aoTotalLivingSpace',
          header: this.$t('properties.step6.apartmentsOtherTotalLivingSpace'),
          isChecked: true,
          elType: 'text',
          clickable: true,
        }
      ],
      residentialMenus: [
        {
          id: "cm.add_residential",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.residentials_menu.add_residential')
        }, {
          id: "cm.edit_residential",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.residentials_menu.edit_residential'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_residential",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.residentials_menu.delete_residential'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.building_service",
          "icon": this.getAssetPath('building_service.svg'),
          "title": this.$t('properties.residentials_menu.building_service')
        }
      ],

      nonresidentialGridId: "nonresidentials",
      nonresidentialColumns: [
        {
          field: 'locationPlanNumber',
          header: this.$t('properties.step7.locationPlanNumber'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'buildingType',
          header: this.$t('properties.step7.buildingType'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'yearOfConstruction',
          header: this.$t('properties.step7.yearOfConstruction'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'coreRenovationYear',
          header: this.$t('properties.step7.coreRenovationYear'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'demolitionObligationYear',
          header: this.$t('properties.step7.demolitionObligationYear'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'areaOfTheLand',
          header: this.$t('properties.step7.areaOfTheLand'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'areaOfTheLandCivilDefense',
          header: this.$t('properties.step7.areaOfTheLandCivilDefense'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }
      ],
      nonresidentialMenus: [
        {
          id: "cm.add_nonresidential",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.nonresidentials_menu.add_nonresidential')
        }, {
          id: "cm.edit_nonresidential",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.nonresidentials_menu.edit_nonresidential'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_nonresidential",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.nonresidentials_menu.delete_nonresidential'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.building_service",
          "icon": this.getAssetPath('building_service.svg'),
          "title": this.$t('properties.nonresidentials_menu.building_service')
        }
      ],

      leaseholdGridId: "leaseholds",
      leaseholdColumns: [
        {
          field: 'firstName',
          header: this.$t('properties.step8.firstName'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'lastName',
          header: this.$t('properties.step8.lastName'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'street',
          header: this.$t('properties.step8.street'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'houseNumber',
          header: this.$t('properties.step8.houseNumber'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'zip',
          header: this.$t('properties.step8.zip'),
          isChecked: true,
          elType: 'text',
          clickable: true
        }, {
          field: 'city',
          header: this.$t('properties.step8.city'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'postbox',
          header: this.$t('properties.step8.postbox'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'countryName',
          header: this.$t('properties.step8.countryUid'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'formOfAddress',
          header: this.$t('properties.step8.formOfAddress'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'titleAcademicDegree',
          header: this.$t('properties.step8.titleAcademicDegree'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }, {
          field: 'telephoneNumber',
          header: this.$t('properties.step8.telephoneNumber'),
          isChecked: false,
          elType: 'text',
          clickable: true
        }
      ],
      leaseholdMenus: [
        {
          id: "cm.add_leasehold",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.leaseholds_menu.add_leasehold')
        }, {
          id: "cm.edit_leasehold",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.leaseholds_menu.edit_leasehold'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_leasehold",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.leaseholds_menu.delete_leasehold'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }
      ],

      parcelsLandAndForestryGridId: "parcelsLandAndForestry",
      parcelsLandAndForestryColumns: [
        {
          field: 'description',
          header: this.$t('properties.step9.parcelsLandAndForestryDescription'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'federalStateName',
          header: this.$t('properties.step9.gemeindeBundesland'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'community',
          header: this.$t('properties.step9.parcelsLandAndForestryGemeinde'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
      ],
      parcelsLandAndForestryMenus: [
        {
          id: "cm.add_parcelslandandforestry",
          "icon": this.getAssetPath('add_user.svg'),
          "title": this.$t('properties.parcelslandandforestry_menu.add_parcelslandandforestry')
        }, {
          id: "cm.edit_parcelslandandforestry",
          "icon": this.getAssetPath('edit_user.svg'),
          "icon_disabled": this.getAssetPath('edit_user_gray.svg'),
          "title": this.$t('properties.parcelslandandforestry_menu.edit_parcelslandandforestry'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.import_parcelslandandforestry",
          "icon": this.getAssetPath('import_green.svg'),
          "title": this.$t('properties.parcelslandandforestry_menu.import_parcelslandandforestry')
        }, {
          id: "cm.delete_parcelslandandforestry",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('properties.parcelslandandforestry_menu.delete_parcelslandandforestry'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }
      ],

      declarationGridId: "declarations",
      declarationColumns: [
        {
          field: 'uid',
          header: this.$t('declarations.grid.id'),
          isChecked: true,
          clickable: true
        }, {
          field: 'client',
          header: this.$t('declarations.grid.client'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'status',
          header: this.$t('declarations.grid.status'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'address',
          header: this.$t('declarations.grid.address'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'type',
          header: this.$t('declarations.grid.type'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'assigned_user_name',
          header: this.$t('declarations.grid.assigned_user'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'declaration_number',
          header: this.$t('declarations.grid.declaration_number'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'reference_date',
          header: this.$t('declarations.add_declaration.steps.step2.reference_date'),
          isChecked: true,
          clickable: true,
        }, {
          field: 'internal_note',
          header: this.$t('declarations.detail.internal_note'),
          isChecked: true,
          clickable: true,
        }
      ],
      declarationMenus: [
        {
          id: "cm.add_declaration",
          "icon": this.getAssetPath('add_declaration_green.svg'),
          "title": this.$t('declarations.menus.add')
        }, {
          id: "cm.edit_declaration",
          "icon": this.getAssetPath('edit_declaration_green.svg'),
          "icon_disabled": this.getAssetPath('edit-declaration-gray.svg'),
          "title": this.$t('declarations.menus.edit'),
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        }, {
          id: "cm.delete_declaration",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('declarations.menus.delete'),
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        }
      ],
      addModalSaveData: this.getDefaultAddModal(),
      editModalSaveData: this.getDefaultEditModal(),
      deleteData: {},

      importParcelDialog: false,
      excelData: [],
      selectedFile: [],

      addParcelDialog: false,
      editParcelDialog: false,

      addOwnerDialog: false,
      editOwnerDialog: false,

      addBuildingPartDialog: false,
      editBuildingPartDialog: false,
      buildingServiceDialog: false,

      addResidentialDialog: false,
      editResidentialDialog: false,

      addNonresidentialDialog: false,
      editNonresidentialDialog: false,

      addLeaseholdDialog: false,
      editLeaseholdDialog: false,

      addParcelsLandAndForestryDialog: false,
      editParcelsLandAndForestryDialog: false,

      buildingOnForeignGroundDisabled: false,
      buildingsOnThirdPartyOwnersDisabled: false,
      leaseholdDisabled: false,
      propertyResidentialPurposesDisabled: false,
      dateApplicationForNewRegistrationDisabled: false,
      corridorDisabled: false,

      step9CorridorDisabled: false,
      step9LandRegisterSheetDisabled: false,

      duplicateDialog: false,
      duplicateClientUid: "0",
      propertyGemeindeFederalStateId: "0",
      clientError: '',
      isClientError: false,

      formOfAddressDisabled: false,
      titleAcademicDegreeDisabled: false,
      telephoneNumberDisabled: false,

      form_of_address_options: [],
      sumOfAreaOfTheLand: 0,
      toggleDataAcquisitionModal: false,
      toggleDataAcquisitionLoader: false,
      toggleDataAcquisitionAboRequired: false,
      toggleDataAcquisitionModalOverLimit: false,
      toggleDataAcquisitionModalServiceNotActive: false,
      showFeatureForBWModal: false,
      helpInfo: '',

      step9DenominatorMaxLength: 15,
      step9AreaOfTheLandMaxLength: 9,
      step9YieldMeasurementNumberMaxLength: 9,
      userLimitAmount: 0,
      editSkendataParcelId: 0,
      editSkendataProcessId: '',
      isReadOnly: false,
      isFreezed: false,

      exemptionBefreiungDisabled: false,
      exemptionBefreiungNichtDisabled: false,
      parcelsLandAndForestryExemptionDisabled: false,
      favoredAreaDisabled: false,

      stepResidentialShow: true,

      districtShow: true,
      ownerAreaOfTheLandCounterMaxLength: 10,
      ownerAreaOfTheLandDenominatorMaxLength: 10,

      originalStep2Reference: '',
      synchronizeDialog: false,
      referenceSynchronize: false,
      btnKey: '',

      originalExtraDetails: '',
      extraDetailsSynchronizeDialog: false,
      extraDetailsSynchronize: false,

      multiSynchronizeDialog: false,
      referenceSyncChecked: false,
      extraDetailsSyncChecked: false,

      originalAssignedUser: '',
      assignedUserSynchronizeDialog: false,
      assignedUserSynchronize: false,
      assignedUserSyncChecked: false,

      referenceChanged: false,
      extraDetailsChanged: false,
      assignedUserChanged: false,

      takeOverOwnersDialog: false,
      propertyForOwner: 0,
      propertyOptions: [],
      takeOverBtnDisabled: true,

      expirationFirstGrantPeriod: '',
      expirationLastGrantPeriod: '',

      agriculture_sum_info_text: this.$t('properties.step10.agriculture_sum_info_text').replace('%sum%', 0),

      sumOfAreaDiffConfirmed: false,

      isReferenceParcelInfo: {
        parcelslandandforestry_parcel_uid: 0,
        parcelslandandforestry_uid: 0
      },

      useParcelAsReferenceParcelAddChecked: false,
      useParcelAsReferenceParcelEditChecked: false,

      selectedParcelDistrictObj: null,
      originalFederalStateUid: 0,

      didUseOneTimeSelectedTab: false,
      didUseOneTimeSelectedProtocolTimestamp: false,

      validCommunity: true,
      invalidCommunityInfo: this.$t('properties.invalid_community_info'),
      initialProtocolSearch: '',

      showNextDeclarationTypeBtn: false,
      showCancelNextDeclarationTypeBtn: false,
      nextDeclarationTypeDialog: false,
      cancelNextDeclarationTypeDialog: false,
      settingsNextDeclarationDialog: false,
      nextDeclarationType: '0',
      nextDeclarationTypeOptions: [],
      nextDeclarationReferenceDate: '',
      nextDeclarationReferenceDateOptions: [],

      showCivilDefenseInfoBox: true,

      saveButtonOptions: [
        {
          icon: require('@/assets/button_edit.svg'),
          label: this.$t('buttons.options.save_continue'),
          handler: 'handleSaveContinue',
          default: true,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_add.svg'),
          label: this.$t('buttons.options.save_new'),
          handler: 'handleSaveNew',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: require('@/assets/button_list.svg'),
          label: this.$t('buttons.options.save_list'),
          handler: 'handleSaveList',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        }
      ],

      showPeoplePool: false,
      showChangeManagementInfo: false,

      userList: [],

      markDlgTitle: '',
      markDlgDescription: '',
      markDlgButton: '',

      lastDeclarationUid: 0
    }
  },
  beforeMount() {
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });
    this.$api.get_country_list().then((list) => {
      this.countryList = list;
    });
    this.clientService.listMyClients().then((clients) => {
      if (clients && clients.list) {
        this.totalClientsCount = clients.totalRecordCount;
        this.clientList = clients.list.map((o) => {
          /**
           * @param {{client_name:string}} o
           */
          return {
            "name": o.client_id + ' - ' + o.client_name,
            "onlyname": o.client_name,
            "code": o.prim_uid
          }
        });
      }
    });

    if (this.$route.query.fulfillment_id) {
      this.toggleDataAcquisitionLoader = true;
      setTimeout(() => {
        this.sendSkendataToApi();
      }, 2500);
    }
  },
  mounted() {
    let declaration_reference_date_options = [];
    for (var i = 2022; i <= 2050; i++) {
      declaration_reference_date_options.push({
        name: i,
        code: i,
      });
    }
    this.nextDeclarationReferenceDateOptions = declaration_reference_date_options;

    if(this.isReadOnlyProperty) {
      this.isReadOnly = true;
    }

    if(this.$route.params.nav_records && this.$route.params.nav_records.length > 0) {
      this.navRecords = this.$route.params.nav_records;
    }

    this.userService.listAll().then(async (list) => {
      this.userList = list.reduce((filtered, item) => {
        if (item.statusKey === 'active' && (item.roleKey === 'administrator' || item.roleKey === 'user')) {
          filtered.push({
            name: item.name,
            code: item.id
          });
        }

        return filtered;
      }, []);

      this.userList.unshift({
        name: 'Bitte auswählen',
        code: '0'
      });
    });

    this.resetEditModalData()
    this.startEdit();
    this.setMaxFieldLength();
    this.setImportData([]);
  },
  beforeDestroy() {
    this.stopObserver();
  },
  computed: {
    ...mapGetters("property", ["getAvailableFlowSteps", "getClientPersons"]),
    ...mapGetters("record_observer", ["triggerRefreshRecord"]),
    showAreaOfTheLandExemptionSection() {
      return (
          this.isExempted
          && this.taxModel === this.taxModels[0].model
      )
    },
    isReadOnlyProperty() {
      return !(
        typeof this.getCurrentUser().permissions !== 'undefined'
        && typeof this.getCurrentUser().permissions.manage_property !== 'undefined'
        && parseInt(this.getCurrentUser().permissions.manage_property) === 1
      );
    },
    getSumOfAreaOfTheLand() {
      let intVal1 = parseInt(this.propertyData.step3.areaOfTheLand1, 10);
      if (Number.isNaN(intVal1)) {
        intVal1 = 0;
      }
      let intVal2 = parseInt(this.propertyData.step3.areaOfTheLand2, 10);
      if (Number.isNaN(intVal2)) {
        intVal2 = 0;
      }
      let sum = intVal1 + intVal2;

      return sum !== 0 ? sum : '-';
    },
    getSumOfAreaForHeader() {
      if (this.sumOfAreas !== 'undefined') {
        return this.sumOfAreas;
      }

      if (["2","6"].includes(this.propertyData.step2.federalStateUid)) {
        let val = this.calculateSumOfAreas(this.propertyData.step3.parcels);
        return val !== 0 ? val : '-';
      } else {
       return this.getSumOfAreaOfTheLand;
      }
    },
    getSumOfOfficialArea() {
      let sumOfOfficialArea = 0;
      const communities = this.propertyData.step9.gemeindes ? this.$api.parse_object_data(this.propertyData.step9.gemeindes) : [];

      communities.forEach((community) => {
        if (community.parcels){
          const parcels = this.$api.parse_object_data(community.parcels);

          sumOfOfficialArea = parcels.reduce((accumulator, currentParcel) => {
            return accumulator + (isNaN(parseInt(currentParcel.areaOfTheLand)) ? 0 : parseInt(currentParcel.areaOfTheLand));
          }, sumOfOfficialArea)
        }
      });

      return sumOfOfficialArea > 0 ? sumOfOfficialArea : '-';
    },
    getSumOfAreaOfUse() {
      var sumOfAreaOfUse = 0;
      var gemeindes = this.propertyData.step9.gemeindes ? this.$api.parse_object_data(this.propertyData.step9.gemeindes) : [];
      if (gemeindes.length > 0) {
        for (var i = 0; i < gemeindes.length; i++) {
          if (typeof gemeindes[i].parcels !== 'undefined') {
            var parcels = this.$api.parse_object_data(gemeindes[i].parcels);
            if (parcels.length > 0) {
              for (var j = 0; j < parcels.length; j++) {
                if (typeof parcels[j].usageTypes !== 'undefined') {
                  var usageTypes = this.$api.parse_object_data(parcels[j].usageTypes);
                  if (usageTypes.length > 0) {
                    for (var k = 0; k < usageTypes.length; k++) {
                      let usageAreaOfTheLand = parseInt(usageTypes[k].areaOfTheLand);
                      if (!isNaN(usageAreaOfTheLand)) {
                        sumOfAreaOfUse += usageAreaOfTheLand;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      return (sumOfAreaOfUse > 0) ? sumOfAreaOfUse : '-';
    },
    getParcelCnt() {
      var parcelCnt = 0;
      if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
        var gemeindes = this.propertyData.step9.gemeindes ? this.$api.parse_object_data(this.propertyData.step9.gemeindes) : [];
        if (gemeindes.length > 0) {
          for (var i = 0; i < gemeindes.length; i++) {
            if (typeof gemeindes[i].parcels !== 'undefined') {
              var parcels = this.$api.parse_object_data(gemeindes[i].parcels);
              parcelCnt += parcels.length;
            }
          }
        }
      } else {
        parcelCnt = Object.keys(this.propertyData.step3.parcels).length;
      }

      return (parcelCnt > 0) ? parcelCnt : '-';
    },
    isExempted() {
      return this.propertyData
          && this.propertyData.step2
          && this.propertyData.step2.exemption
          && this.propertyData.step2.exemption === "1";
    },
    ownershipCountry() {
      return (typeof this.propertyData.step4.countryUid !== 'undefined') ? parseInt(this.propertyData.step4.countryUid) : 50;
    },
    ownerCountry() {
      return (typeof this.addModalSaveData.owner.countryUid !== 'undefined') ? parseInt(this.addModalSaveData.owner.countryUid) : 50;
    },
    calculatedAreaShow() {
      return ['7', '9'].includes(this.propertyData.step2.federalStateUid);
    },
    editOwnerCountry() {
      return (typeof this.editModalSaveData.owner.countryUid !== 'undefined') ? parseInt(this.editModalSaveData.owner.countryUid) : 50;
    },
    representativeCountry() {
      return (typeof this.addModalSaveData.owner.representative.countryUid !== 'undefined') ? parseInt(this.addModalSaveData.owner.representative.countryUid) : 50;
    },
    editRepresentativeCountry() {
      return (typeof this.editModalSaveData.owner.representative.countryUid !== 'undefined') ? parseInt(this.editModalSaveData.owner.representative.countryUid) : 5;
    },
    leaseholdCountry() {
      return (typeof this.addModalSaveData.owner.countryUid !== 'undefined') ? parseInt(this.addModalSaveData.owner.countryUid) : 50;
    },
    editLeaseholdCountry() {
      return (typeof this.editModalSaveData.owner.countryUid !== 'undefined') ? parseInt(this.editModalSaveData.owner.countryUid) : 50;
    },
    saveMultiSyncBtnDisabled() {
      return !(this.referenceSyncChecked || this.extraDetailsSyncChecked || this.assignedUserSyncChecked)
    },
    isDatevClient() {
      let clientData = this.getClientData();
      return typeof clientData.external_name !== 'undefined' && clientData.external_name === 'datev';
    },
    getOwnerMenus() {
      let ownerMenus = this.ownerMenus;
      ownerMenus = ownerMenus.map((o) => {
          if(o.id === 'cm.take_over') {
            o.disabled = parseInt(this.propertyData.otherPropertiesCount) > 0 ? false : true;
          }

          return o;
        });

      return ownerMenus;
    },
    getCurrentNavIndex() {
      if(this.navRecords.length > 0 && parseInt(this.property_uid) > 0) {
        let nevRecordIndex = this.navRecords.findIndex(o => parseInt(o) === parseInt(this.property_uid));
        if(nevRecordIndex >= 0) {
          return nevRecordIndex + 1;
        }
      }

      return 0;
    },
    spatiallyDefinablePartExemptionOptions() {
      let spatiallyDefinablePartExemptionOptions = _.clone(this.$t('properties.step3.usage_type_options'));
      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        spatiallyDefinablePartExemptionOptions.splice(1, 0, {
          "name": "Flächenanteil, für den die Voraussetzung einer Befreiung nicht vorliegt",
          "code": "0"
        })
      }

      return spatiallyDefinablePartExemptionOptions;


    },
    spatiallyDefinablePartBenefitOptions() {
      let spatiallyDefinablePartBenefitOptions = _.clone(this.benefit_options_with_monument);

      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        spatiallyDefinablePartBenefitOptions.splice(1, 0, {
          "name": "Flächenanteil, für den die Voraussetzung einer Vergünstigung nicht vorliegt",
          "code": "0"
        });
      }

      return spatiallyDefinablePartBenefitOptions;
    },
    otherButtonOptions() {
      return [
        {
          icon: this.getAssetPath('archive_black.svg'),
          label: this.$t('buttons.archive'),
          handler: 'archivePropertyModalShow',
          default: false,
          blackText: true,
          show: !this.isReadOnly && this.propertyData.archived === 0,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('add_declaration_black.svg'),
          label: this.$t('buttons.start_declaration'),
          handler: 'startDeclaration',
          default: false,
          blackText: true,
          show: !this.isReadOnly && this.propertyData.declarationCount === 0,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('duplicate_black.svg'),
          label: this.$t('buttons.duplicate'),
          handler: 'duplicate',
          default: false,
          blackText: true,
          show: !this.isReadOnly,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('check_black.svg'),
          label: this.$t('properties.relevant.button-1'),
          handler: 'showNextDeclarationTypeDialog',
          default: false,
          blackText: true,
          show: this.showNextDeclarationTypeBtn && !this.isFreezed,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('close-chip.svg'),
          label: this.$t('properties.relevant.button-2'),
          handler: 'showCancelNextDeclarationTypeDialog',
          default: false,
          blackText: true,
          show: this.showCancelNextDeclarationTypeBtn,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('message_icon.svg'),
          label: this.$t('properties.relevant.button-3'),
          handler: 'settingsNextDeclarationAttributePerpetuation',
          default: false,
          blackText: true,
          show: (this.propertyData.nextDeclarationType === '1002' && this.lastDeclarationUid > 0),
          fontSize: '14',
        }
      ];
    },
    addModalParcelLengthError() {
      let federalStateUid = parseInt(this.propertyData.step2.federalStateUid);

      // No length restriction in these federal states
      if ([2, 6, 7, 9].includes(federalStateUid)) {
        return false;
      }

      if (this.addModalSaveData.parcel.parcel.length > 25) {
        return true;
      }

      return false;
    },
    editModalParcelLengthError() {
      let federalStateUid = parseInt(this.propertyData.step2.federalStateUid);

      // No length restriction in these federal states
      if ([2, 6, 7, 9].includes(federalStateUid)) {
        return false;
      }

      if (this.editModalSaveData.parcel.parcel.length > 25) {
        return true;
      }

      return false;
    },
    addModalParcelParcelErrorMessage() {
      if (this.addModalParcelLengthError) {
        return this.$t('properties.parcel_length_error');
      }

      if (this.addModalSaveErrors.parcel.invalid) {
        return this.$t('general.required');
      }

      return '';
    },
    editModalParcelParcelErrorMessage() {
      if (this.editModalParcelLengthError) {
        return this.$t('properties.parcel_length_error');
      }

      if (this.editModalSaveErrors.parcel.invalid) {
        return this.$t('general.required');
      }

      return '';
    }
  },
  validations: {
    addModalSaveData: {
      parcel: {
        required: requiredIf(function () {
          return this.addParcelDialog;
        })
      },
      firstName: {
        required: requiredIf(function () {
          return this.addOwnerDialog;
        })
      }
    },
    editModalSaveData: {
      id: {
        required: true
      },
      parcel: {
        required: requiredIf(function () {
          return this.editParcelDialog;
        })
      },
      firstName: {
        required: requiredIf(function () {
          return this.editOwnerDialog;
        })
      }
    }
  },
  watch: {
    propertyData: {
      handler(val) {
        this.showHideTabsAndFields();
        this.stateHandler();
        this.buildingsOnThirdPartyOwnersChangeHandler();

        this.setPropertyDataChanged(JSON.stringify(val) !== this.initialPropertyDataString);

        let iCalcSelfCultivatedAreas = parseInt(val.step10.ownedSpace) - parseInt(val.step10.minusLeasedAreas) + parseInt(val.step10.plusLeasedAreas)
        if (isNaN(iCalcSelfCultivatedAreas)) {
          val.step10.selfCultivatedAreas = '';
        } else {
          val.step10.selfCultivatedAreas = iCalcSelfCultivatedAreas;
        }

        this.disableOtherSpacesFields = val.step3.propertyType !== '4';
      },
      deep: true
    },
    getAvailableFlowSteps(val) {
      this.availableTabs = this.availableTabs.map((o) => {
        if (typeof o.component !== 'undefined') {
          let index = val.findIndex(item => item.component === o.component);
          if (index !== -1) {
            o.hide = false;
          } else {
            o.hide = true;
          }
        } else if (this.isFreezed && (o.slug === 'document' || o.slug === 'declaration' || o.slug === 'protocol')) {
          o.hide = true;
        } else {
          o.hide = false;
        }

        return o;
      });
    },
    '$route'(to) {
      if (this.property_uid && this.property_uid !== to.query.uid) {
        this.startEdit();
      }
    },
    triggerRefreshRecord(newValue) {
      if (newValue) {
        this.startEdit();
      }
    },
    addModalSaveData: {
      handler(val) {
        if (this.addModalSaveData.parcel.shareOfOwnershipDenominator > 0) {
          const areaOfTheLand = this.addModalSaveData.parcel.areaOfTheLand;
          const counter = parseFloat(this.addModalSaveData.parcel.shareOfOwnershipCounter.replace(',', '.'));
          const denominator = parseFloat(this.addModalSaveData.parcel.shareOfOwnershipDenominator.replace(',', '.'));

          const calcArea = areaOfTheLand * (counter/denominator);

          this.addModalSaveData.parcel.calculatedArea = isNaN(calcArea) ? 0 : calcArea.toLocaleString('DE');
        } else {
          this.addModalSaveData.parcel.calculatedArea = '';
        }
      },
      deep: true
    },

    editModalSaveData: {
      handler(val) {
        if (this.editModalSaveData.parcel.shareOfOwnershipDenominator > 0) {
          const areaOfTheLand = this.editModalSaveData.parcel.areaOfTheLand;
          const counter = parseFloat(this.editModalSaveData.parcel.shareOfOwnershipCounter.replace(',', '.'));
          const denominator = parseFloat(this.editModalSaveData.parcel.shareOfOwnershipDenominator.replace(',', '.'));

          const calcArea = areaOfTheLand * (counter/denominator);

          this.editModalSaveData.parcel.calculatedArea = isNaN(calcArea) ? 0 : calcArea.toLocaleString('DE');
        } else {
          this.editModalSaveData.parcel.calculatedArea = '';
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations("property", ["init", "setProperty", "setStepExemptionBenefitShow", "setStepCommunityShow", "setStepOwnerShow", "setStepBuildingShow", "setStepResidentialShow", "setStepNonResidentialShow", "setStepLeaseholdShow", "setStepAgricultureShow", "setStepAnimalShow", "refreshSteps", "setCurrentTaxModel", "setImportData", "setExcelError", "setImportAttr", "setPropertyUid", "setPropertyDataChanged", "saveClientPersons"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapMutations("declaration", ["setClientId", "setPropertyId", "setFederalStateId"]),
    ...mapGetters('client', ["getClientData"]),
    ...mapMutations("client", ["setClientData"]),
    ...mapActions("record_observer", ["startObserver", "stopObserver"]),
    ...mapGetters("property", ["getImportAttr"]),
    ...mapGetters('user', ["getCurrentUser"]),
    ...mapMutations("user", ["saveAccessLog"]),
    changeNavPage(currentPage) {
      if(currentPage && this.navRecords.length > 0 && this.navRecords[currentPage-1]) {
        let navPropertyUid = parseInt(this.navRecords[currentPage-1]);
        if(navPropertyUid > 0) {
          this.stopObserver();
          this.$router.push('/property/edit?uid=' + navPropertyUid);
        }
      }
    },
    getGoogleMapsLink() {
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.address)
    },
    getDefaultAddModal() {
      return this.getDefaultModalData();
    },
    getDefaultEditModal() {
      return this.getDefaultModalData();
    },
    getDefaultModalData() {
      return structuredClone({
        parcel: {
          community: '',
          parcel: '',
          landRegisterSheet: '',
          corridor: '',
          parcelDataCounter: '',
          parcelDataDenominator: '',
          areaOfTheLand: '',
          shareOfOwnershipCounter: '',
          shareOfOwnershipDenominator: '',
          calculatedArea: 0,
          containedInArea: '',
        },
        parcelsLandAndForestry: {
          federalStateUid: '',
          community: '',
          description: '',
          parcels: [{
            prim_uid: '',
            corridor: '',
            counter: '',
            denominator: '',
            areaOfTheLand: '',
            district: '',
            districtNumber: '',
            showParcelSection: false,
            isReference: false,
            usageTypes: [{
              usageType: '0',
              areaOfTheLand: '',
              yieldMeasurementNumber: '',
              areaOfFarmBuildings: '',
              flowRate: '',
              exemption: '',
              exemption_area: '',
              areaOfTheLandDisabled: false,
              yieldMeasurementNumberDisabled: true,
              areaOfFarmBuildingDisabled: true,
              flowRateDisabled: true,
              showSection: true
            }],
          }],
        },
        residential: {
          yearOfConstruction: '',
          coreRenovationYear: '',
          demolitionObligationYear: '',
          exemption: '0',
          benefit: '0',
          apartmentsBelow60: {
            counter: '',
            totalLivingSpace: '',
            apartmentsBelow60Exemption: [{
              locationOfTheRoomsDesignation: '',
              exemption: '0',
              taxFreeArea: ''
            }],
            apartmentsBelow60Benefit: [{
              locationOfTheRoomsDesignation: '',
              benefit: '0',
              discountedArea: ''
            }]
          },
          apartmentsBetween60100: {
            counter: '',
            totalLivingSpace: '',
            apartmentsBetween60100Exemption: [{
              locationOfTheRoomsDesignation: '',
              exemption: '0',
              taxFreeArea: ''
            }],
            apartmentsBetween60100Benefit: [{
              locationOfTheRoomsDesignation: '',
              benefit: '0',
              discountedArea: ''
            }]
          },
          apartmentsAbove100: {
            counter: '',
            totalLivingSpace: '',
            apartmentsAbove100Exemption: [{
              locationOfTheRoomsDesignation: '',
              exemption: '0',
              taxFreeArea: ''
            }],
            apartmentsAbove100Benefit: [{
              locationOfTheRoomsDesignation: '',
              benefit: '0',
              discountedArea: ''
            }]
          },
          apartmentsOther: {
            counter: '',
            totalLivingSpace: '',
            apartmentsOtherExemption: [{
              locationOfTheRoomsDesignation: '',
              exemption: '0',
              taxFreeArea: ''
            }],
            apartmentsOtherBenefit: [{
              locationOfTheRoomsDesignation: '',
              benefit: '0',
              discountedArea: ''
            }]
          },
          usableSpaces: [{
            usageType: '',
            totalSpace: '',
            locationOfTheRoomsDesignation: '',
            usableSpacesExemption: [{
              exemption: '0',
              taxFreeArea: ''
            }],
            usableSpacesBenefit: [{
              benefit: '0',
              discountedArea: ''
            }]
          }],
          garageUnderground: {
            amountGarages: '',
            garageUndergroundExemption: [{
              locationOfTheRoomsDesignation: '',
              exemption: '0',
              taxFreeArea: ''
            }],
            garageUndergroundBenefit: [{
              locationOfTheRoomsDesignation: '',
              benefit: '0',
              discountedArea: ''
            }]
          },
        },
        buildingPart: {
          prim_uid: '',
          areaOfTheLandCivilDefense: '',
          areaOfTheLandCivilDefenseLiving: '',
          areaOfTheLandCivilDefenseUsage: '',
          usableSpaceArea: '',
          livingSpaceArea: '',
          livingSpaceDescription: '',
          benefits: [{
            prim_uid: '',
            benefit: '0',
            benefitUsableSpaceArea: '',
            benefitLivingSpaceArea: '',
            benefitDescription: '',
            showSection: false
          }],
          exemptions: [{
            prim_uid: '',
            exemption: '0',
            exemptionUsableSpaceArea: '',
            exemptionLivingSpaceArea: '',
            exemptionDescription: '',
            showSection: false
          }]
        },
        nonResidentials: {
          prim_uid: '',
          locationPlanNumber: '',
          buildingType: '',
          yearOfConstruction: '',
          coreRenovationYear: '',
          demolitionObligationYear: '',
          areaOfTheLand: '',
          areaOfTheLandCivilDefense: '',
          exemptions: [{
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          }],
          benefits: [{
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          }]
        },
        owner: {
          salutation: '0',
          title: '',
          dateOfBirth: '',
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          phone: '',
          zip: '',
          postbox: '',
          city: '',
          federalStateUid: '',
          countryUid: '50',
          financeAgency: '',
          taxNumber: '',
          taxIdentificationNumber: '',
          area_of_the_land_counter: '',
          area_of_the_land_denominator: '',
          representative: {
            salutation: '0',
            title: '',
            dateOfBirth: '',
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            zip: '',
            postbox: '',
            city: '',
            countryUid: '50',
            phone: '',
          },
        },
        leasehold: {
          salutation: '0',
          countryUid: '0',
          formOfAddress: '',
          titleAcademicDegree: '',
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          telephoneNumber: '',
          zip: '',
          postbox: '',
          city: '',
        },
      });
    },
    resetEditModalData() {
      this.editModalSaveData = this.getDefaultEditModal();
    },
    oneTimeSelectedProtocolTimestamp() {
      if (this.$route.params.oneTimeSelectedProtocolTimestamp && !this.didUseOneTimeSelectedProtocolTimestamp) {
        this.didUseOneTimeSelectedProtocolTimestamp = true;
        return this.$route.params.oneTimeSelectedProtocolTimestamp;
      } else {
        return 0;
      }
    },
    startEdit() {
      this.rendering = true;
      this.stopObserver();
      this.init();
      if(!this.isReadOnlyProperty) {
        this.isReadOnly = false;
      }
      this.elsterValidationStatus = '';
      this.elsterValidationResultDialog = false;
      this.elsterValidationErrors = [];
      this.elsterValidationHints = [];
      this.property_uid = this.$route.query.uid;
      this.serverParams.parentId = this.property_uid;
      this.propertyData = {
        lafTotalExemption: '',
        lafExemptionNonDelimitablePart: '',
        step2: {
          federalStateUid: '',
          community: '',
          street: '',
          houseNumber: '',
          zip: '',
          city: '',
          reference: '',
          name: '',
          extraDetails: '',
          internalNote: '',
          additional_information : ''
        },
        step3: {
          ownershipStructure: "-1",
          propertyType: "0",
          developmentState: '',
          deductionLandValueChecked: '0',
          multiCommunities: "0",
          leasehold: "0",
          buildingOnForeignGround: "0",
          buildingsOnThirdPartyOwners: "0",
          areaOfTheLand1: '',
          areaOfTheLandValue1: '',
          areaOfTheLandTaxExemptionDescription1: '',
          areaOfTheLandTaxExemptionArea1: '',
          areaOfTheLandTaxExemption1: '0',
          locatedInOutdoorArea1: '0',
          areaOfTheLand2: '',
          areaOfTheLandValue2: '',
          areaOfTheLandTaxExemptionDescription2: '',
          areaOfTheLandTaxExemptionArea2: '',
          areaOfTheLandTaxExemption2: '0',
          locatedInOutdoorArea2: '0',
          liftingRate: '',
          propertyResidentialPurposes: '',
          totalPropertyPart: '',
          registrationLandRegistry: '',
          benefit: '',
          parcels: []
        },
        step3a: {
          propertyExemptionBenefitType: '0',
          taxExemption: '0',
          monument_identification : false,
          benefit: '0',
          nonSeparablePartRealEstate: '0',
          expirationFirstGrantPeriod: '',
          expirationLastGrantPeriod: '',
          expectedExemptPurposes: '',
          taxPrivilegedPurposes: '0',
          unbuiltRealEstateExemptions: [{
            primUid: '0',
            unbuiltRealEstateExemptionDescription: '',
            taxFreeArea: '',
            unbuiltRealEstateTaxExemption: '0',
            propertyParcelUid: '0'
          }],
          useOfDefinableParts: [{
            primUid: '0',
            spatiallyDefinableExemptionPartDescription: '',
            spatiallyDefinableExemptionArea: '',
            spatiallyDefinableExemptionLandOrLiving: '1',
            spatiallyDefinableTaxExemptionPart: ''
          }],
          taxBreakConditions: [{
            primUid: '0',
            spatiallyDefinableExemptionPartDescription: '',
            spatiallyDefinableExemptionArea: '',
            spatiallyDefinableExemptionLandOrLiving: '1',
            spatiallyDefinableTaxExemptionPart: ''
          }]
        },
        step4: {
          salutation: '0',
          name: '',
          street: '',
          houseNumber: '',
          zip: '',
          postbox: '',
          city: '',
          countryUid: '0'
        },
        step6: {
          yearOfConstruction: '',
          coreRenovationYear: '',
          demolitionObligationYear: '',
          amountGarages: '',
          apartmentsBelow60: {
            counter: '',
            totalLivingSpace: '',
            exemption: '0',
            taxFreeArea: '',
            benefit: '0',
            discountedArea: ''
          },
          apartmentsBetween60100: {
            counter: '',
            totalLivingSpace: '',
            exemption: '0',
            taxFreeArea: '',
            benefit: '0',
            discountedArea: ''
          },
          apartmentsAbove100: {
            counter: '',
            totalLivingSpace: '',
            exemption: '0',
            taxFreeArea: '',
            benefit: '0',
            discountedArea: ''
          },
          apartmentsOther: {
            counter: '',
            totalLivingSpace: '',
            exemption: '0',
            taxFreeArea: '',
            benefit: '0',
            discountedArea: ''
          },
          usableSpaces: []
        },
        step10: {
          ownedSpace: '',
          minusLeasedAreas: '',
          plusLeasedAreas: '',
          selfCultivatedAreas: '',
          alpacas: '',
          damAnimal_below1Year: '',
          damAnimal_above1Year: '',
          layingHens_layingHensIncludeBreeding: '',
          layingHens_purchasedLayingHens: '',
          poultry: '',
          breedingRabbits: '',
          llamas: '',
          horses_below3YearsOrSmallHorses: '',
          horses_horses: '',
          beef_below1Year: '',
          beef_1to2Year: '',
          beef_above2Year: '',
          fatteningAnimalsLessThan1Year: '',
          cows: '',
          breedingBulls: '',
          sheep_below1Year: '',
          sheep_above1Year: '',
          breedingPigs: '',
          ostriches_above14Months: '',
          ostriches_below14Months: '',
          goats: '',
          youngChickensForFattening_below6Passes: '',
          youngChickensForFattening_above6Passes: '',
          pullets: '',
          fatteningDucks_count: '',
          fatteningDucks_raisingPhase: '',
          fatteningDucks_fatteningPhase: '',
          turkeys_selfRaised: '',
          turkeys_bought: '',
          turkeys_youngTurkeys: '',
          fattenedGeese: '',
          mastRabbit: '',
          beefCattle: '',
          pigs_lightPiglets: '',
          pigs_piglets: '',
          pigs_heavyPiglets: '',
          pigs_runner: '',
          pigs_heavyRunner: '',
          pigs_fatteningPigs: '',
          pigs_youngBreedingPigs: '',
          boughtPigs_lightPiglets: '',
          boughtPigs_piglets: '',
          boughtPigs_heavyPiglets: '',
          boughtPigs_runner: '',
          boughtPigs_heavyRunner: ''
        }
      };

      this.resetAddModalData();
      this.getPropertyData();
    },
    getClientInfo(clientUid) {
      this.clientService.get(clientUid, false).then(clientData => {
        if (clientData) {
          this.setClientData(clientData);
        }
      });
    },
    getPropertyData() {
      return new Promise((resolve) => {
        this.service.get(this.property_uid).then(async (data) => {
          if (data.id) {
            this.saveAccessLog({
              "recordType": "property",
              "recordUid": data.id
            });
            this.startObserver({
              "recordType": "properties",
              "recordId": data.id,
              "recordTimestamp": data.recordTimestamp
            });
            if(typeof data.step4.name === 'undefined') {
              data.step4 = {
                salutation: '0',
                name: '',
                street: '',
                houseNumber: '',
                zip: '',
                postbox: '',
                city: '',
                countryUid: '0'
              }
            }
            this.propertyData = data;

            if (this.expirationFirstGrantPeriod !== '') {
              this.propertyData.step3a.expirationFirstGrantPeriod = this.expirationFirstGrantPeriod;
            }

            if (this.expirationLastGrantPeriod !== '') {
              this.propertyData.step3a.expirationLastGrantPeriod = this.expirationLastGrantPeriod;
            }

            this.originalFederalStateUid = parseInt(this.propertyData.step2.federalStateUid);
            this.setMaxlength(this.propertyData.step2.federalStateUid);

            // Set original step2 reference
            this.originalStep2Reference = this.propertyData.step2.reference;

            // Set original extra details
            this.originalExtraDetails = this.propertyData.step2.extraDetails;

            // Set original assigned user
            this.originalAssignedUser = this.propertyData.step1.assignedUser;

            this.isFreezed = !!data.freezed;
            if(data.editable) {
              if (!this.isReadOnlyProperty) {
                if (data.freezed) {
                  this.isReadOnly = true;
                } else {
                  this.elsterValidate(false);
                }
              }
            } else {
              this.isReadOnly = true;
            }

            if (this.$refs.compDeviatingOwner) {
              this.$refs.compDeviatingOwner.setData(data);
            }
            if (this.$refs.compSubArea) {
              this.$refs.compSubArea.setData(data);
            }

            if (this.propertyData.step3.areaOfTheLand2 !== '' || parseInt(this.propertyData.step3.areaOfTheLandValue2) > 0) {
              this.secondAreaInfoShow = true;
            }

            if (this.propertyData.step3.areaOfTheLand3 !== '' || parseInt(this.propertyData.step3.areaOfTheLandValue3) > 0) {
              this.thirdAreaInfoShow = true;
            }

            if (this.propertyData.step3.areaOfTheLand4 !== '' || parseInt(this.propertyData.step3.areaOfTheLandValue4) > 0) {
              this.fourthAreaInfoShow = true;
            }

            if (typeof data.step3.parcels !== 'undefined' && Object.keys(data.step3.parcels).length > 0) {
              this.sumOfAreas = this.calculateSumOfAreas(data.step3.parcels);
              this.checkSumAreasGlobal();
            }

            this.propertyData.step3a.unbuiltRealEstateExemptions = this.$api.parse_object_data(data.step3a.unbuiltRealEstateExemptions);
            this.propertyData.step3a.useOfDefinableParts = this.$api.parse_object_data(data.step3a.useOfDefinableParts);
            this.propertyData.step3a.taxBreakConditions = this.$api.parse_object_data(data.step3a.taxBreakConditions);
            this.setProperty(data);

            this.viewerBreadcrumbs = [
              {
                title: this.$t('dashboard.top_header.properties'),
                link: '/property/list'
              }, {
                title: this.$t('properties.edit.id') + ' - ' + this.propertyData.uid,
                link: '/property/edit/?uid=' + this.propertyData.id
              }, {
                title: this.$t('clients.edit.tabs.document'),
                backButtonHandler: true,
                handler: () => {
                  this.$router.push({
                    name: "Properties",
                    params: {
                      'comp': 'edit',
                      'selectedTab': 'document'
                    },
                    query: {
                      'uid': this.propertyData.id
                    }
                  });
                }
              }
            ];
            this.UId = this.propertyData.uid;
            this.duplicateClientUid = data.step1.clientUid;
            this.propertyGemeindeFederalStateId = data.federalStateUid;

            if (this.$route.params.oneTimeSelectedTab && !this.didUseOneTimeSelectedTab) {
              if (this.checkTabExistence(this.$route.params.oneTimeSelectedTab)) {
                if (this.$route.params.oneTimeSelectedProtocolTimestamp && !this.didUseOneTimeSelectedProtocolTimestamp) {
                let myDate = new Date(this.$route.params.oneTimeSelectedProtocolTimestamp * 1000);
                let myDateString = moment(myDate).format("DD.MM.YYYY HH:mm:ss");
                this.initialProtocolSearch = myDateString + " - " + myDateString;
                this.didUseOneTimeSelectedProtocolTimestamp = true;
              } else {
                this.initialProtocolSearch = "";
              }
              this.activeTab = this.$route.params.oneTimeSelectedTab;
              }
              this.didUseOneTimeSelectedTab = true;
            }


            if (this.$route.params.selectedTab) {
              if (this.checkTabExistence(this.$route.params.selectedTab)) {
                this.activeTab = this.$route.params.selectedTab;
              }
            }

            this.stateHandler();
            this.updateInfoBar();

            this.rendering = false;

            if (parseInt(this.propertyData.step1.clientUid) > 0) {
              this.getClientInfo(this.propertyData.step1.clientUid);
            }

            this.step6FirstRowYearOfConstruction = this.propertyData.step6[0] ? this.propertyData.step6[0].yearOfConstruction : '';

            //show client_comment once if query param &show_client_comment=1 is set
            if (!this.didShowClientComment) {
              let showClientComment = this.$route.query.show_client_comment
              if (showClientComment && data.clientComment !== '') {
                this.$api.showToast(this.$t('properties.label_client_comment') + ': \n\n' + data.clientComment, 'info');
              }
              this.didShowClientComment = true;
            }

            // calculate agriculture sum info
            var agriculture_sum_info = 0;
            var gemeindes = data.step9.gemeindes ? this.$api.parse_object_data(data.step9.gemeindes) : [];
            if (gemeindes.length > 0) {
              for (var i = 0; i < gemeindes.length; i++) {
                if (typeof gemeindes[i].parcels !== 'undefined') {
                  var parcels = this.$api.parse_object_data(gemeindes[i].parcels);
                  if (parcels.length > 0) {
                    for (var j = 0; j < parcels.length; j++) {
                      if (typeof parcels[j].usageTypes !== 'undefined') {
                        var usageTypes = this.$api.parse_object_data(parcels[j].usageTypes);
                        if (usageTypes.length > 0) {
                          for (var k = 0; k < usageTypes.length; k++) {
                            if (this.isInArray(usageTypes[k].usageType, ['1', '15', '16'])) {
                              if (usageTypes[k].areaOfTheLand != null) {
                              agriculture_sum_info += parseInt(usageTypes[k].areaOfTheLand);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            this.agriculture_sum_info_text = this.$t('properties.step10.agriculture_sum_info_text').replace('%sum%', agriculture_sum_info);

            if (parseInt(this.propertyData.declarationRelevantMarkedByUser) === 0) {
              this.showNextDeclarationTypeBtn = true;
              this.showCancelNextDeclarationTypeBtn = false;
            } else {
              this.showNextDeclarationTypeBtn = false;
              this.showCancelNextDeclarationTypeBtn = true;
            }

            let iCalcSelfCultivatedAreas = parseInt(this.propertyData.step10.ownedSpace) - parseInt(this.propertyData.step10.minusLeasedAreas) + parseInt(this.propertyData.step10.plusLeasedAreas)
            if (isNaN(iCalcSelfCultivatedAreas)) {
              this.propertyData.step10.selfCultivatedAreas = '';
            } else {
              this.propertyData.step10.selfCultivatedAreas = iCalcSelfCultivatedAreas;
            }

            if(this.propertyData.step3a.propertyExemptionBenefitType === '') {
              this.propertyData.step3a.propertyExemptionBenefitType = '0';
            }

            // Get property data string
            let propertyDataLocalCopy = _.clone(this.propertyData);
            this.initialPropertyDataString = JSON.stringify(propertyDataLocalCopy)

            this.initNextDeclarationTypeOptions();
            this.initTaxExemptionParcelOptions();
            this.getLastDeclarationId();

            this.fetchClientPersonData();

            this.showChangeManagementInfo = false;
            if (this.propertyData.archived === 0 && this.propertyData.declarationCountWithElsterTransferTicket > 0) {
                this.showChangeManagementInfo = true;
            }
          } else {
            this.$router.go(-1);
          }

          this.loading = false;

          return resolve();
        });
      });
    },
    initNextDeclarationTypeOptions() {
      if (parseInt(this.propertyData.step2.federalStateUid) == 6 || parseInt(this.propertyData.step2.federalStateUid) == 2 || parseInt(this.propertyData.step2.federalStateUid) == 9) {
        //types_bayern
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_bayern');
      } else if (parseInt(this.propertyData.step2.federalStateUid) == 1) {
        //types_baden
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_baden');
      } else if (parseInt(this.propertyData.step2.federalStateUid) == 7) {
        //types_hessen
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_hessen');
      } else {
        this.nextDeclarationTypeOptions = this.$t('declarations.add_declaration.type_options.types_all');
      }
    },

    getLastDeclarationId() {
      this.lastDeclarationUid = 0;
      let declarations = this.$api.parse_object_data(this.propertyData.declarations);
      if (declarations.length > 0) {
        declarations.map(o => {
          if (parseInt(o.prim_uid) > this.lastDeclarationUid) {
            this.lastDeclarationUid = parseInt(o.prim_uid);
          }
        });
      }
    },

    selectNextDeclarationTypeOpt(item) {
      if (item.code === '1002') { // Zurechnungsfortschreibung
        this.markDlgTitle = this.$t('properties.relevant.header-3');
        this.markDlgDescription = this.$t('properties.relevant.desc-3');
        this.markDlgButton = this.$t('properties.relevant.button-3');
      } else {
        this.markDlgTitle = this.$t('properties.relevant.header-1');
        this.markDlgDescription = this.$t('properties.relevant.desc-1');
        this.markDlgButton = this.$t('buttons.save');
      }
    },

    initTaxExemptionParcelOptions() {
      let taxExemptionParcelOptions = [{
        'name': 'Bitte auswählen',
        'code': '0'
      }];

      if (typeof this.propertyData.step3.parcels !== 'undefined' && Object.keys(this.propertyData.step3.parcels).length > 0) {
        let step3Parcels = this.propertyData.step3.parcels;
        for (let parcelIndex in step3Parcels) {
          let currentParcel = step3Parcels[parcelIndex];
          let nameParts = [];

          nameParts.push((currentParcel.community !== '') ? currentParcel.community : '-');
          nameParts.push((currentParcel.parcel !== '') ? currentParcel.parcel : '-');
          nameParts.push((currentParcel.landRegisterSheet !== '') ? currentParcel.landRegisterSheet : '-');
          nameParts.push((currentParcel.areaOfTheLand !== '') ? currentParcel.areaOfTheLand : '-');

          taxExemptionParcelOptions.push({
            'name': nameParts.join(', '),
            'code': currentParcel.id
          });
        }
      }

      this.tax_exemption_parcel_options = taxExemptionParcelOptions;

      if (this.tax_exemption_parcel_options.length > 1) {
        this.tax_exemption_parcel_first_option = this.tax_exemption_parcel_options[1].code;
      }
    },
    is_checked_monument_identification(){
      return this.propertyData.step3a.monument_identification === "1";
    },
    checked_monument_identification(checked){
      this.propertyData.step3a.monument_identification = checked ? "1" : "0";
    },
    deductionChecked(checked){
      this.propertyData.step3.deductionLandValueChecked = checked ? "1" : "0";
    },
    show_monument_identification(){
     const federalStateUid =  this.propertyData.step2.federalStateUid

      return this.taxModels.reduce((initValue,current) => {
        if(['Bundesmodell','Modifiziertes Bundesmodell','Modifiziertes Bodenwertmodell'].includes(current['model'])){
          initValue.push(...current['stateCodes']);
        }
        return initValue
      },[]).includes(federalStateUid)
    },
    elsterValidate(show_loader) {
      return new Promise((resolve) => {
        if (typeof show_loader === 'undefined') {
          show_loader = true;
        }

        this.elsterValidationStatus = '';
        this.elsterValidationResultDialog = false;
        this.elsterValidationErrors = [];
        this.elsterValidationHints = [];
        this.service.elsterValidate(this.property_uid, show_loader).then((validationResponse) => {
          if(validationResponse) {
            if (!validationResponse.success && validationResponse.errors !== null && validationResponse.errors !== undefined) {
              Object.values(validationResponse.errors).forEach(error => {
                if (error.type === 'error') {
                  this.elsterValidationErrors.push(error)
                } else if (error.type === 'hint') {
                  this.elsterValidationHints.push(error);
                }
              });

              this.elsterValidationStatus = validationResponse.success ? 'success' : 'failed';
              if (this.elsterValidationStatus === 'success') {
                this.missingMandatoryField = false
              }
            } else if(validationResponse.success) {
              this.elsterValidationStatus = 'success';
            } else {
              this.elsterValidationStatus = 'failed';
            }
          }

          return resolve();
        });
      });
    },
    getMaxLength(attr_key) {
      if (this.propertyData.step2 && typeof this.propertyData.step2.federalStateUid !== 'undefined') {
        let state_uid = parseInt(this.propertyData.step2.federalStateUid);
        if (attr_key === 'owner.area_of_the_land_counter') {
          let stateUids = [9, 2, 6];
          return stateUids.includes(state_uid) ? 9 : 8;
        } else if (attr_key === 'owner.area_of_the_land_denominator') {
          let stateUids = [9, 2, 6];
          if (state_uid === 7) {
            return 8;
          } else {
            return stateUids.includes(state_uid) ? 7 : 9;
          }
        }
      }

      return 10;
    },
    refreshSumOfArealand() {
      this.service.get(this.property_uid).then((data) => {
        if (data.id) {
          this.sumOfAreas = 0;
          if (typeof data.step3.parcels !== 'undefined') {
            this.propertyData.step3.parcels = data.step3.parcels;
            if (Object.keys(data.step3.parcels).length > 0) {
              this.sumOfAreas = this.calculateSumOfAreas(data.step3.parcels);
            }
          }
          this.checkSumAreasGlobal();
        }
      });
    },
    setEconomicEntityTypeOptions() {
      if (this.propertyData.step2.federalStateUid === '1') {
        this.economic_entity_type_options = this.$t('properties.step2a.economic_entity_types.baden-württemberg');
      } else {
        this.economic_entity_type_options = this.$t('properties.step2a.economic_entity_types.default');
      }
    },

    updateEconomicEntityTypeValue() {
      if (this.originalFederalStateUid !== parseInt(this.propertyData.step2.federalStateUid)) {
        if (this.originalFederalStateUid === 1 || parseInt(this.propertyData.step2.federalStateUid) === 1) {
          this.propertyData.step2.economicEntityType = '';
        }
        this.originalFederalStateUid = parseInt(this.propertyData.step2.federalStateUid);
      }
    },

    setHelpInfo() {
      /*if (this.propertyData.step2.federalStateUid === '2') {
        this.helpInfo = this.$t('properties.help_info.bayern');
      } else if (this.propertyData.step2.federalStateUid === '6') {
        this.helpInfo = this.$t('properties.help_info.hamburg');
      } else if (this.propertyData.step2.federalStateUid === '9') {
        this.helpInfo = this.$t('properties.help_info.niedersachsen');
      } else {
        this.helpInfo = '';
      }*/
      this.helpInfo = '';
    },

    buildingsOnThirdPartyOwnersChangeHandler() {
      this.districtShow = true;
      this.areaOfTheLandDisabled = false;

      if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
        this.districtShow = false;
        this.areaOfTheLandDisabled = true;
      }
    },

    buildingsOnThirdPartyOwnersChangePopupHandler() {
      if (parseInt(this.propertyData.step3.buildingsOnThirdPartyOwners) > 0 && Object.keys(this.propertyData.step3.parcels).length > 0) {
        this.$modal.show({
          text: this.$t('properties.step3.buildingsOnThirdPartyOwnersChangeInfo'),
          showClose: false,
          canEscape: false,
          showCancel: false,
          buttons: {
            ok: this.$t('buttons.ok'),
            delete: false
          },
        });
      }
    },

    stateHandler() {
      // Get State Name
      this.stateObj = this.stateList.filter(item => {
        if (item.code === this.propertyData.step2.federalStateUid) {
          return item;
        }
      });
      if (typeof this.stateObj[0] !== 'undefined' && typeof this.stateObj[0].name !== 'undefined') {
        this.stateName = this.stateObj[0].name;
      }

      // Get State Tax Model
      this.taxModelObj = this.taxModels.filter(item => {
        if (item.stateCodes.indexOf(this.propertyData.step2.federalStateUid) !== -1) {
          return item;
        }
      });

      if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
        this.taxModel = this.taxModelObj[0].model;
        this.taxModelForModal = this.taxModelObj[0].modelForModal;
        this.initMandatoryFields(this.taxModel);
        this.setCurrentTaxModel(this.taxModel);
        this.setEconomicEntityTypeOptions();
        this.setHelpInfo();
        this.updateTaxModelText();
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
        this.benefitUsableSpaceAreaMaxLength = 9;
        this.benefitUsableSpaceAreaPositive = true;
      } else {
        this.benefitUsableSpaceAreaMaxLength = 15;
        this.benefitUsableSpaceAreaPositive = false;
      }
    },
    updateTaxModelText() {
      if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
        this.taxModelText = 'Bundesmodell';
      } else {
        this.taxModelText = this.taxModel;
      }
    },
    checkSumAreasGlobal() {
      if (['2', '6'].includes(this.propertyData.step2.federalStateUid)) {
        this.isInvalidAreaSum = false;
        return;
      }

      let areaOfTheLand1 = parseInt(this.propertyData.step3.areaOfTheLand1);
      if (isNaN(areaOfTheLand1)) {
        areaOfTheLand1 = 0;
      }

      let areaOfTheLand2 = parseInt(this.propertyData.step3.areaOfTheLand2);
      if (isNaN(areaOfTheLand2)) {
        areaOfTheLand2 = 0;
      }

      let areaOfTheLand3 = parseInt(this.propertyData.step3.areaOfTheLand3);
      if (isNaN(areaOfTheLand3)) {
        areaOfTheLand3 = 0;
      }

      let areaOfTheLand4 = parseInt(this.propertyData.step3.areaOfTheLand4);
      if (isNaN(areaOfTheLand4)) {
        areaOfTheLand4 = 0;
      }

      let intAreaOfLand = areaOfTheLand1 + areaOfTheLand2  + areaOfTheLand3  + areaOfTheLand4 // sum of land
      let intSumOfAreas = parseInt(this.sumOfAreas); // sum of parcels
      this.isInvalidAreaSum = (intAreaOfLand > intSumOfAreas+1 || intAreaOfLand < intSumOfAreas-1);
    },
    addMoreUnbuiltRealEstateExemption() {
      this.propertyData.step3a.unbuiltRealEstateExemptions.push({
        primUid: '0',
        unbuiltRealEstateExemptionDescription: '',
        taxFreeArea: '',
        unbuiltRealEstateTaxExemption: '0',
        propertyParcelUid: '0'
      });
    },
    addMoreUseOfDefinableParts() {
      this.propertyData.step3a.useOfDefinableParts.push({
        primUid: '0',
        spatiallyDefinableExemptionPartDescription: '',
        spatiallyDefinableExemptionArea: '',
        spatiallyDefinableExemptionLandOrLiving: '1',
        spatiallyDefinableTaxExemptionPart: ''
      });
    },
    addMoreTaxBreakConditions() {
      this.propertyData.step3a.taxBreakConditions.push({
        primUid: '0',
        spatiallyDefinableExemptionPartDescription: '',
        spatiallyDefinableExemptionArea: '',
        spatiallyDefinableExemptionLandOrLiving: '1',
        spatiallyDefinableTaxExemptionPart: ''
      });
    },
    handleDeleteUnbuiltRealEstateExemption(index) {
      this.propertyData.step3a.unbuiltRealEstateExemptions.splice(index, 1);
    },
    handleDeleteUseOfDefinableParts(index) {
      this.propertyData.step3a.useOfDefinableParts.splice(index, 1);
    },
    handleDeleteTaxBreakConditions(index) {
      this.propertyData.step3a.taxBreakConditions.splice(index, 1);
    },
    addressInfoBox() {
      if (this.service.canShowStepNonResidential(this.propertyData.step2.federalStateUid)) {
        this.address_info_box = true;
        /*if (this.propertyData.step2.federalStateUid === '2') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_bayern');
        } else if (this.propertyData.step2.federalStateUid === '6') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_hamburg');
        } else if (this.propertyData.step2.federalStateUid === '9') {
          this.grundstucksadresse_info_box = this.$t('general.grundstucksadresse_info_box_niedersachsen');
        }*/
      } else {
        this.address_info_box = false;
      }
    },
    initMandatoryFields(taxModelName) {
      this.mainMandatoryFields.length = 0; // reset array
      if (taxModelName === 'Bundesmodell') {
        this.mainMandatoryFields = [
          {
            'name': this.$t('properties.step2.street'),
            'slug': 'street',
            'value': this.propertyData.step2.street
          },
          {
            'name': this.$t('properties.step2.zip'),
            'slug': 'zip',
            'value': this.propertyData.step2.zip
          },
          {
            'name': this.$t('properties.step2.city'),
            'slug': 'city',
            'value': this.propertyData.step2.city
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.ownership_structure'),
            'slug': 'ownershipStructure',
            'value': this.propertyData.step3.ownershipStructure
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.property_type'),
            'slug': 'propertyType',
            'value': this.propertyData.step3.propertyType
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.area_of_the_land_property'),
            'slug': 'areaOfTheLand1',
            'value': this.propertyData.step3.areaOfTheLand1
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.area_of_the_land_value_property'),
            'slug': 'areaOfTheLandValue1',
            'value': this.propertyData.step3.areaOfTheLandValue1
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.salutation'),
            'slug': 'salutation',
            'value': this.propertyData.step4.salutation
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.name'),
            'slug': 'name',
            'value': this.propertyData.step4.name
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.street'),
            'slug': 'street',
            'value': this.propertyData.step4.street
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.zip'),
            'slug': 'zip',
            'value': this.propertyData.step4.zip
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.city'),
            'slug': 'city',
            'value': this.propertyData.step4.city
          }
        ];

        if (this.stepResidentialShow) {
          this.mainMandatoryFields.push({
            'name': this.$t('properties.steps.step6') + ' > ' + this.$t('properties.step6.yearOfConstruction'),
            'slug': 'yearOfConstruction',
            'value': this.step6FirstRowYearOfConstruction
          });
        }

        this.modalMandatoryFields = {
          'parcel': [
            'parcel',
            'landRegisterSheet',
            'corridor',
            'parcelDataCounter',
            'parcelDataDenominator',
            'areaOfTheLand',
            'shareOfOwnershipCounter',
            'shareOfOwnershipDenominator'
          ],
          'owner': [
            'firstName',
            'lastName',
            'street',
            'zip',
            'city',
            'taxIdentificationNumber'
          ],
        };
      } else if (taxModelName === 'Modifiziertes Bodenwertmodell') {
        this.mainMandatoryFields = [
          {
            'name': this.$t('properties.step2.street'),
            'slug': 'street',
            'value': this.propertyData.step2.street
          },
          {
            'name': this.$t('properties.step2.zip'),
            'slug': 'zip',
            'value': this.propertyData.step2.zip
          },
          {
            'name': this.$t('properties.step2.city'),
            'slug': 'city',
            'value': this.propertyData.step2.city
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.ownership_structure'),
            'slug': 'ownershipStructure',
            'value': this.propertyData.step3.ownershipStructure
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.area_of_the_land_property'),
            'slug': 'areaOfTheLand1',
            'value': this.propertyData.step3.areaOfTheLand1
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.salutation'),
            'slug': 'salutation',
            'value': this.propertyData.step4.salutation
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.name'),
            'slug': 'name',
            'value': this.propertyData.step4.name
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.street'),
            'slug': 'street',
            'value': this.propertyData.step4.street
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.zip'),
            'slug': 'zip',
            'value': this.propertyData.step4.zip
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.city'),
            'slug': 'city',
            'value': this.propertyData.step4.city
          }
        ];

        this.modalMandatoryFields = {
          'parcel': [
            'parcel',
            'parcelDataCounter',
            'parcelDataDenominator',
            'areaOfTheLand',
            'shareOfOwnershipCounter',
            'shareOfOwnershipDenominator'
          ],
          'owner': [
            'firstName',
            'lastName',
            'street',
            'zip',
            'city',
            'taxIdentificationNumber'
          ],
        };
      } else if (taxModelName === 'Flächen-Faktor-Modell') {

        this.mainMandatoryFields = [
          {
            'name': this.$t('properties.step2.street'),
            'slug': 'street',
            'value': this.propertyData.step2.street
          },
          {
            'name': this.$t('properties.step2.zip'),
            'slug': 'zip',
            'value': this.propertyData.step2.zip
          },
          {
            'name': this.$t('properties.step2.city'),
            'slug': 'city',
            'value': this.propertyData.step2.city
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.ownership_structure'),
            'slug': 'ownershipStructure',
            'value': this.propertyData.step3.ownershipStructure
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.salutation'),
            'slug': 'salutation',
            'value': this.propertyData.step4.salutation
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.name'),
            'slug': 'name',
            'value': this.propertyData.step4.name
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.street'),
            'slug': 'street',
            'value': this.propertyData.step4.street
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.zip'),
            'slug': 'zip',
            'value': this.propertyData.step4.zip
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.city'),
            'slug': 'city',
            'value': this.propertyData.step4.city
          }
        ];

        this.modalMandatoryFields = {
          'parcel': [
            'parcel',
            'landRegisterSheet',
            'corridor',
            'parcelDataCounter',
            'parcelDataDenominator',
            'areaOfTheLand'
          ],
          'owner': [
            'firstName',
            'lastName',
            'street',
            'zip',
            'city',
            'taxIdentificationNumber'
          ],
        };
      } else if (taxModelName === 'Flächen-Lage-Modell') {
        this.mainMandatoryFields = [
          {
            'name': this.$t('properties.step2.street'),
            'slug': 'street',
            'value': this.propertyData.step2.street
          },
          {
            'name': this.$t('properties.step2.city'),
            'slug': 'city',
            'value': this.propertyData.step2.city
          },
          {
            'name': this.$t('properties.steps.step3') + ' > ' + this.$t('properties.step3.ownership_structure'),
            'slug': 'ownershipStructure',
            'value': this.propertyData.step3.ownershipStructure
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.salutation'),
            'slug': 'salutation',
            'value': this.propertyData.step4.salutation
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.name'),
            'slug': 'name',
            'value': this.propertyData.step4.name
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.street'),
            'slug': 'street',
            'value': this.propertyData.step4.street
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.zip'),
            'slug': 'zip',
            'value': this.propertyData.step4.zip
          },
          {
            'name': this.$t('properties.steps.step4') + ' > ' + this.$t('properties.step4.city'),
            'slug': 'city',
            'value': this.propertyData.step4.city
          }
        ];

        this.modalMandatoryFields = {
          'owner': [
            'firstName',
            'lastName',
            'street',
            'zip',
            'city'
          ],
        };
      }

      this.updateMandatoryFields();
      // check missing mandatory fields
      this.checkMandatoryFields();

      if (taxModelName !== '') {
        this.benefit_options = this.$t('properties.benefit_options.' + taxModelName);
        this.benefit_options_with_monument = this.benefit_options;
        if(this.show_monument_identification()){

          this.benefit_options =  this.benefit_options.filter((value) => value.code !== "6")
        }
        this.benefit_options_non_residential = this.$t('properties.benefit_options.Bundesmodell_Sachwert_Nichtwohngrundstücke');
        this.benefit_options_residential = this.$t('properties.benefit_options.Bundesmodell');

        this.ownership_salutation_options = this.$t('properties.step4.ownership_salutation_options.' + taxModelName);
        this.representative_salutation_options = this.$t('properties.step4.salutation_user_option');
      }

      if (this.propertyData.step2.federalStateUid === '2' || this.propertyData.step2.federalStateUid === '6' || this.propertyData.step2.federalStateUid === '9') {
        this.form_of_address_options = this.$t('properties.step8.form_of_address_options.' + this.propertyData.step2.federalStateUid);
        this.formOfAddressDisabled = false;
        this.titleAcademicDegreeDisabled = false;
        this.telephoneNumberDisabled = false;
      } else {
        this.form_of_address_options = [];
        this.formOfAddressDisabled = true;
        this.titleAcademicDegreeDisabled = true;
        this.telephoneNumberDisabled = true;
      }
      this.updateInfoBar();
    },
    inArray: function (needle, haystack) {
      let length = haystack.length;
      for (let i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
      }
      return false;
    },
    updateMandatoryFields() {
      /**
       * @param {{showOwnership:bool}}
       */
      let excludeHint = [];
      // remove hint info from modal when no Gemeinschaft selected
      if (!this.showOwnership) {
        excludeHint = ["name", "salutation", "street", "zip", "city"];
      }
      if(this.propertyData.step2.economicEntityType && parseInt(this.propertyData.step2.economicEntityType) === 3) {
        excludeHint.push('propertyType');
        excludeHint.push('areaOfTheLandValue1');
        excludeHint.push('areaOfTheLand1');
      }

      if (excludeHint.length > 0) {
        let updateMainMandatoryFields = [];
        this.mainMandatoryFields.forEach((arr_value) => {
          if (!this.inArray(arr_value.slug, excludeHint)) {
            updateMainMandatoryFields.push(arr_value);
          }
        });
        this.mainMandatoryFields = updateMainMandatoryFields;
      }
    },
    checkMandatoryFields() {
      this.missingMandatoryField = false;
      this.missingFields.length = 0;
      for (let i = 0; i < this.mainMandatoryFields.length; i++) {
        if (typeof this.mainMandatoryFields[i].value === 'undefined' || this.mainMandatoryFields[i].value === null || this.mainMandatoryFields[i].value.trim() === '' || this.mainMandatoryFields[i].value === '-1' || (this.mainMandatoryFields[i].slug === 'salutation' && this.mainMandatoryFields[i].value === '0') || (this.mainMandatoryFields[i].slug === 'yearOfConstruction' && (this.mainMandatoryFields[i].value === '0' || this.mainMandatoryFields[i].value === ''))) {
          this.missingFields.push(this.mainMandatoryFields[i]);
        }
      }

      if (this.missingFields.length > 0) {
        this.missingMandatoryField = true;
      }
    },
    updateInfoBar() {
      // Get address
      if (this.propertyData.step2.street.trim() !== '' || this.propertyData.step2.houseNumber.trim() !== '' || this.propertyData.step2.zip.trim() !== '' || this.propertyData.step2.city.trim() !== '') {
        this.address = this.propertyData.step2.street + ' ' + this.propertyData.step2.houseNumber + ', ' + this.propertyData.step2.zip + ' ' + this.propertyData.step2.city;
        this.address = this.address.trim();

        let lastChar = this.address.slice(-1);
        if (lastChar === ',') {
          this.address = this.address.slice(0, -1);
        }

        let firstChar = this.address.charAt(0);
        if (firstChar === ',') {
          this.address = this.address.substring(1);
        }

        if (this.address.trim() === '') {
          this.address = this.propertyData.step2.name;
        }
      } else {
        this.address = this.propertyData.step2.name;
      }

      this.propertyTypeInfoBar = this.$t('properties.step3.propertyTypeMapView.0');
      for (let i = 0; i < this.step3_property_type.length; i++) {
        if (this.step3_property_type[i]['code'] === this.propertyData.step3.propertyType) {
          this.propertyTypeInfoBar = this.step3_property_type[i]['name'];
        }
      }
    },
    hideSecondAreaInfo() {
      this.secondAreaInfoShow = false;
      this.propertyData.step3.areaOfTheLand2 = '';
      this.propertyData.step3.areaOfTheLandValue2 = '';
      this.propertyData.step3.locatedInOutdoorArea2 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription2 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea2 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption2 = '0';
    },
    showSecondAreaInfo() {
      this.propertyData.step3.areaOfTheLand2 = '';
      this.propertyData.step3.areaOfTheLandValue2 = '';
      this.propertyData.step3.locatedInOutdoorArea2 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription2 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea2 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption2 = '0';

      this.secondAreaInfoShow = true;
    },
    hideThirdAreaInfo() {
      this.thirdAreaInfoShow = false;
      this.propertyData.step3.areaOfTheLand3 = '';
      this.propertyData.step3.areaOfTheLandValue3 = '';
      this.propertyData.step3.locatedInOutdoorArea3 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription3 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea3 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption3 = '0';
    },
    showThirdAreaInfo() {
      this.propertyData.step3.areaOfTheLand3 = '';
      this.propertyData.step3.areaOfTheLandValue3 = '';
      this.propertyData.step3.locatedInOutdoorArea3 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription3 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea3 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption3 = '0';

      this.thirdAreaInfoShow = true;
    },
    hideFourthAreaInfo() {
      this.fourthAreaInfoShow = false;
      this.propertyData.step3.areaOfTheLand4 = '';
      this.propertyData.step3.areaOfTheLandValue4 = '';
      this.propertyData.step3.locatedInOutdoorArea4 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription4 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea4 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption4 = '0';
    },
    showFourthAreaInfo() {
      this.propertyData.step3.areaOfTheLand4 = '';
      this.propertyData.step3.areaOfTheLandValue4 = '';
      this.propertyData.step3.locatedInOutdoorArea4 = '0';

      this.propertyData.step3.areaOfTheLandTaxExemptionDescription4 = '';
      this.propertyData.step3.areaOfTheLandTaxExemptionArea4 = '';
      this.propertyData.step3.areaOfTheLandTaxExemption4 = '0';

      this.fourthAreaInfoShow = true;
    },
    showMissingMandatoryModal() {
      if (typeof this.taxModelObj[0] !== 'undefined' && typeof this.taxModelObj[0].model !== 'undefined') {
        this.taxModel = this.taxModelObj[0].model;
        this.taxModelForModal = this.taxModelObj[0].modelForModal;
        this.initMandatoryFields(this.taxModel);
        this.setCurrentTaxModel(this.taxModel);
        this.updateTaxModelText();
      }

      if (this.missingMandatoryField) {
        this.missingFieldsHtml = '';
        this.missingFieldsHtml += '<ul style="list-style-type: disc; list-style-position: inside;">';
        if (this.missingFields.length > 0) {
          for (let i = 0; i < this.missingFields.length; i++) {
            this.missingFieldsHtml += '<li class="text-left">';
            this.missingFieldsHtml += '<label class="text-left" style="font-size: 14px; color: #4b4b4b;">';
            this.missingFieldsHtml += this.missingFields[i].name;
            this.missingFieldsHtml += '</label>';
            this.missingFieldsHtml += '</li>';
          }
        }
        this.missingFieldsHtml += '</ul>';
        this.showMissingMandatoryFieldsDlg = true;
      } else {
        this.missingFieldsHtml = '';
      }
    },
    closeMissingMandatoryFieldsDlg() {
      this.showMissingMandatoryFieldsDlg = false;
    },
    setMaxlength(stateId) {
      const arr = ['1', '2', '6', '9'];
      this.step4.maxlength = arr.includes(stateId) ? 999 : 50;

      this.step9DenominatorMaxLength = stateId === '1' ? 15 : 4;
      this.step9AreaOfTheLandMaxLength = stateId === '7' ? 9 : 8;
      this.step9YieldMeasurementNumberMaxLength = stateId === '7' ? 6 : 9;

      const ownerAreaOfTheLandCounterArr = ['9', '2', '6'];
      this.ownerAreaOfTheLandCounterMaxLength = ownerAreaOfTheLandCounterArr.includes(stateId) ? 9 : 8;

      const ownerAreaOfTheLandDenominatorArr = ['9', '2', '6'];
      if (stateId === '7') {
        this.ownerAreaOfTheLandDenominatorMaxLength = 8;
      } else {
        this.ownerAreaOfTheLandDenominatorMaxLength = ownerAreaOfTheLandDenominatorArr.includes(stateId) ? 7 : 9;
      }
    },
    selectclientUid(item) {
      if (item.code === "0" || item.code === "") {
        this.isClientError = true;
      } else {
        this.isClientError = false;
      }
    },
    clientListSearch(params) {
      return this.clientService.listMyClients(params).then((clients) => {
        if (clients && clients.list) {
          return clients.list.map((o) => {
            return {
              "name": o.client_id + ' - ' + o.client_name,
              "onlyname": o.client_name,
              "code": o.prim_uid
            };
          })
        } else {
          return false;
        }
      });
    },
    saveDuplicateModalData() {
      this.isClientError = false
      if (this.duplicateClientUid === "0" || this.duplicateClientUid === "") {
        this.isClientError = true
        this.clientError = this.$t('general.errors.required')
        return; // if any of the validations fail, we don't move on
      }
      this.service.duplicate(this.property_uid, this.duplicateClientUid).then((propertyId) => {
        if (propertyId) {
          this.duplicateDialog = false;
          this.$router.push('/property/edit?uid=' + propertyId);
        }
      });
    },
    handleTabsBtnClick(btn_key) {
      if (this.isInvalidAreaSum && !this.sumOfAreaDiffConfirmed) {
        this.$modal.show({
          headerText: this.$t('properties.step3.sum_of_areas_modal.title'),
          text: this.$t('properties.step3.sum_of_areas_modal.description'),
          showClose: true,
          canEscape: true,
          buttons: {
            ok: this.$t('properties.step3.sum_of_areas_modal.button_2'),
            cancel: this.$t('properties.step3.sum_of_areas_modal.button_1')
          },
          onConfirm: () => {
            this.sumOfAreaDiffConfirmed = true;
            this.handleTabsBtnClick(btn_key);
            this.$modal.visible = false;
          }
        });
      } else {
        if (btn_key === "start_declaration") {
          this.setClientId(this.propertyData.step1.clientUid);
          this.setPropertyId(this.property_uid);
          this.$router.push({
            name: "Declaration",
            params: {
              'comp': 'AddDeclaration',
              'declarationNumber': this.propertyData.step2.reference,
              'declarationRelevantMarkedByUser': this.propertyData.declarationRelevantMarkedByUser,
              'nextDeclarationType': this.propertyData.nextDeclarationType,
              'nextDeclarationReferenceDate': this.propertyData.nextDeclarationReferenceDate
            }
          });
        } else if (btn_key === "duplicate") {
          this.duplicateDialog = true;
        } else if (btn_key === 'cancel') {
          if (this.propertyData.freezedDeclaration) {
            this.$router.push('/declaration/edit?uid=' + this.propertyData.freezedDeclaration);
          } else {
            this.$router.push('/property/list');
          }
        } else if (btn_key === 'saveContinue' || btn_key === 'saveNew' || btn_key === 'saveList' || btn_key === 'save') {
          this.btnKey = btn_key;
          this.checkSynchronize();
          if (!this.synchronizeDialog && !this.extraDetailsSynchronizeDialog && !this.assignedUserSynchronizeDialog && !this.multiSynchronizeDialog) {
            this.saveProperty(btn_key);
          }
        }
      }
    },
    handleTabsOtherButtonClick(btn_key) {
      if (btn_key === 'archivePropertyModalShow') {
        this.archivePropertyModalShow();
      } else if (btn_key === 'startDeclaration') {
        this.handleTabsBtnClick('start_declaration');
      } else if (btn_key === 'duplicate') {
        this.handleTabsBtnClick('duplicate');
      } else if (btn_key === 'showNextDeclarationTypeDialog') {
        this.showNextDeclarationTypeDialog();
      } else if (btn_key === 'showCancelNextDeclarationTypeDialog') {
        this.showCancelNextDeclarationTypeDialog();
      }
    },
    showErrorNotification(field){
      let outerField= this.tabWithFields.find(x=>x.fields.find((str) => str.slug === field))
      let field_index=this.tabWithFields.findIndex(x=>x.fields.find((str) => str.slug === field))
      let field_name=this.tabWithFields[field_index].fields.find((str) => str.slug === field)
      let message= this.$t('general.check_fields').replace('%tab%', outerField.name).replace('%field%', field_name.name)
      this.$api.showToast(message,'error');
    },
    async saveProperty(btn_key) {
      if (this.propertyData.step2.zip !== '' && this.propertyData.step2.zip.length > 5) {
        this.errors.step2Zip.invalid = true;
        this.showErrorNotification('step2Zip')
        return;
      } else {
        this.errors.step2Zip.invalid = false;
      }

      if (isNaN(this.propertyData.step4.postbox) && typeof this.propertyData.step4.postbox !== 'undefined') {
        this.errors.step4Postbox.invalid = true;
        this.propertyData.step4.postbox = '';
        this.showErrorNotification('step4Postbox')
        return;
      }
      if (this.propertyData.step2.federalStateUid === "0" || this.propertyData.step2.federalStateUid === "") {
        this.errors.federalStateUid.invalid = true
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.federalStateUid.invalid = false
      }
      if (this.propertyData.step2.name.trim() === "") {
        this.errors.name.invalid = true
        this.showErrorNotification('name')
        return; // if any of the validations fail, we don't move on
      } else {
        this.errors.name.invalid = false
      }

      if (typeof this.propertyData.step2.economicEntityType === 'undefined' || this.propertyData.step2.economicEntityType === '' || this.propertyData.step2.economicEntityType === '0') {
        this.errors.economicEntityType.invalid = true;
        this.showErrorNotification('economicEntityType')
        return;
      } else {
        this.errors.economicEntityType.invalid = false;
      }

      if (this.propertyData.step2.reference.length > 30) {
        this.errors.reference.invalid = true;
        return;
      } else {
        this.errors.reference.invalid = false;
      }
      if (this.$refs.compDeviatingOwner) {
        if (this.$refs.compDeviatingOwner.isValid()) {
          this.propertyData.deviatingOwner = this.$refs.compDeviatingOwner.getData();
        } else {
          this.$api.showToast(this.$t('properties.fill_required_fields_missing'), 'error');

          return false;
        }
      }

      this.propertyData.subArea = this.$refs.compSubArea.getData();

      if (this.propertyData.step2.extraDetails.length > 999 || this.propertyData.step2.internalNote.length > 9999) {
        return;
      }

      if(this.propertyData.step2.community !== '') {
        if(this.communityList.length > 0) {
          let matchedIndex = this.communityList.findIndex(o => o.code === this.propertyData.step2.community);
          if(matchedIndex < 0) {
            this.$api.showToast(this.$t('properties.community_error.basic_tab'), "error");
            return false;
          }
        } else {
          this.$api.showToast(this.$t('properties.community_error.basic_tab'), "error");
          return  false;
        }
      }

      this.setMaxlength(this.propertyData.step2.federalStateUid);
      if (!this.firstAreaInfoShow) {
        this.propertyData.step3.areaOfTheLandValue1 = '';
        this.propertyData.step3.areaOfTheLand1 = '';
        this.propertyData.step3.locatedInOutdoorArea1 = '0';

        this.propertyData.step3.areaOfTheLandTaxExemptionDescription1 = '';
        this.propertyData.step3.areaOfTheLandTaxExemptionArea1 = '';
        this.propertyData.step3.areaOfTheLandTaxExemption1 = '0';
      }

      if (!this.secondAreaInfoShow) {
        this.propertyData.step3.areaOfTheLandValue2 = '';
        this.propertyData.step3.areaOfTheLand2 = '';
        this.propertyData.step3.locatedInOutdoorArea2 = '0';

        this.propertyData.step3.areaOfTheLandTaxExemptionDescription2 = '';
        this.propertyData.step3.areaOfTheLandTaxExemptionArea2 = '';
        this.propertyData.step3.areaOfTheLandTaxExemption2 = '0';
      }

      if (!this.thirdAreaInfoShow) {
        this.propertyData.step3.areaOfTheLandValue3 = '';
        this.propertyData.step3.areaOfTheLand3 = '';
        this.propertyData.step3.locatedInOutdoorArea3 = '0';

        this.propertyData.step3.areaOfTheLandTaxExemptionDescription3 = '';
        this.propertyData.step3.areaOfTheLandTaxExemptionArea3 = '';
        this.propertyData.step3.areaOfTheLandTaxExemption3 = '0';
      }

      if (!this.fourthAreaInfoShow) {
        this.propertyData.step3.areaOfTheLandValue4 = '';
        this.propertyData.step3.areaOfTheLand4 = '';
        this.propertyData.step3.locatedInOutdoorArea4 = '0';

        this.propertyData.step3.areaOfTheLandTaxExemptionDescription4 = '';
        this.propertyData.step3.areaOfTheLandTaxExemptionArea4 = '';
        this.propertyData.step3.areaOfTheLandTaxExemption4 = '0';
      }

      this.propertyData = Object.assign({referenceSynchronize: this.referenceSynchronize}, this.propertyData);
      this.propertyData = Object.assign({extraDetailsSynchronize: this.extraDetailsSynchronize}, this.propertyData);
      this.propertyData = Object.assign({assignedUserSynchronize: this.assignedUserSynchronize}, this.propertyData);

      this.stopObserver();
      let res = await this.service.save(this.propertyData);
      if (res) {
        this.referenceSynchronize = false;
        this.extraDetailsSynchronize = false;
        this.assignedUserSynchronize = false;
        this.btnKey = '';

        this.startObserver({
          "recordType": "properties",
          "recordId": this.propertyData.id,
          "recordTimestamp": res.recordTimestamp
        });

        this.$api.showToast(this.$t('general.data_saved'))
        this.triggerReload(this.ownerGridId);
        this.triggerReload(this.residentialGridId);
        this.triggerReload(this.nonresidentialGridId);
        this.triggerReload(this.buildingPartsGridId);
        this.triggerReload(this.leaseholdGridId);
        this.triggerReload(this.parcelsLandAndForestryGridId);
        if (btn_key === 'saveList') {
          if (typeof this.$route.params.parent_type !== 'undefined' && this.$route.params.parent_type === 'CLIENT') {
            await this.$router.push("/client/edit?uid=" + this.$route.params.client_uid)
          } else {
            await this.$router.push('/property/list')
          }
        } else if (btn_key === 'saveNew') {
          await this.$router.push('/property/add')
        } else {
          this.expirationFirstGrantPeriod = this.propertyData.step3a.expirationFirstGrantPeriod;
          this.expirationLastGrantPeriod = this.propertyData.step3a.expirationLastGrantPeriod;

          await this.getPropertyData();
          await this.updateElsterValidationProtocolForDeclaration();
        }
      }
    },
    tabsButtonClicked(btn_key) {
      if (btn_key === 'cancel') {
        this.$router.push('/property/list');
      }
    },
    checkSynchronize() {
      this.referenceChanged = false;
      this.extraDetailsChanged = false;
      this.assignedUserChanged = false;

      if (this.propertyData.synchronizedDeclarationCount > 0) {
        let syncCount = 0;

        if (this.originalStep2Reference !== this.propertyData.step2.reference) {
          this.referenceChanged = true;
          syncCount++;
        }

        if (!this.referenceChanged && this.propertyData.synchronizedDeclarationReferences) {
          for (let key in this.propertyData.synchronizedDeclarationReferences) {
            let reference = this.propertyData.synchronizedDeclarationReferences[key];
            if (this.originalStep2Reference !== reference) {
              if (!this.referenceChanged) {
                this.referenceChanged = true;
                syncCount++;
              }
            }
          }
        }

        if (this.originalExtraDetails !== this.propertyData.step2.extraDetails) {
          this.extraDetailsChanged = true;
          syncCount++;
        }

        if (this.originalAssignedUser !== this.propertyData.step1.assignedUser) {
          this.assignedUserChanged = true;
          syncCount++;
        }

        if (this.referenceChanged && !this.extraDetailsChanged && !this.assignedUserChanged) {
          this.synchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('properties.synchronize'),
            text: this.$t('properties.synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('properties.synchronize_yes'),
              "cancel": this.$t('properties.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.referenceSynchronize = true;
              this.synchronizeDialog = false;
              this.saveProperty(this.btnKey).then(() => {
                this.triggerReload(this.declarationGridId);
              });
            },
            onCancel: () => {
              this.synchronizeDialog = false;
              this.saveProperty(this.btnKey);
            },
            onHide: () => {
              this.synchronizeDialog = false;
              this.saveProperty(this.btnKey);
            }
          });
        }

        if (!this.referenceChanged && this.extraDetailsChanged && !this.assignedUserChanged) {
          this.extraDetailsSynchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('properties.synchronize'),
            text: this.$t('properties.extra_details_synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('properties.synchronize_yes'),
              "cancel": this.$t('properties.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.extraDetailsSynchronize = true;
              this.extraDetailsSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            },
            onCancel: () => {
              this.extraDetailsSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            },
            onHide: () => {
              this.extraDetailsSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            }
          });
        }

        if (!this.referenceChanged && !this.extraDetailsChanged && this.assignedUserChanged) {
          this.assignedUserSynchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('properties.synchronize'),
            text: this.$t('properties.assigned_user_synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('properties.synchronize_yes'),
              "cancel": this.$t('properties.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.assignedUserSynchronize = true;
              this.assignedUserSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            },
            onCancel: () => {
              this.assignedUserSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            },
            onHide: () => {
              this.assignedUserSynchronizeDialog = false;
              this.saveProperty(this.btnKey);
            }
          });
        }

        if (syncCount > 1) {
          this.referenceSyncChecked = false;
          this.extraDetailsSyncChecked = false;
          this.assignedUserSyncChecked = false;
          this.multiSynchronizeDialog = true;
        }
      }
    },

    updateElsterValidationProtocolForDeclaration() {
      return new Promise(async (resolve) => {
        if (this.propertyData.declarationCount === 0) {
          return resolve();
        }

        if (['internal_approval_requested', 'approval_pending', 'elster_submission'].includes(this.propertyData.stage)) {
          await this.elsterValidate(false);

          if (this.elsterValidationStatus !== 'success') {
            return resolve();
          }

          this.$modal.show({
            headerText: this.$t('properties.synchronize_update_elster_validation_protocol'),
            text: this.$t('properties.synchronize_update_elster_validation_protocol_description'),
            showClose: true,
            buttons: {
              'ok': this.$t('properties.synchronize_yes'),
              'cancel': this.$t('properties.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.service.updateDeclarationsElsterValidationProtocol(this.property_uid, true)
                .then((response) => {
                  if (typeof response.success !== 'undefined' && response.success) {
                    let toastMessageType = 'info';
                    let toastMessage = this.$t('properties.synchronize_update_elster_validation_protocol_success')
                        .replace('%updated%', response.updated)
                        .replace('%total%', response.total);

                    if (response.failed > 0) {
                      toastMessageType = 'warn';
                      toastMessage = toastMessage + '\n' + this.$t('properties.synchronize_update_elster_validation_protocol_failed')
                        .replace('%failed%', response.failed);
                    }

                    this.$api.showToast(toastMessage, toastMessageType);
                  } else if (typeof response.message !== 'undefined') {
                    this.$api.showToast(response.message, 'error');
                  } else {
                    this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
                  }

                  return resolve();
                });
            },
            onCancel: () => {
              return resolve();
            },
            onHide: () => {
              return resolve();
            }
          });
        } else {
          // TODO: Try without executing a validation here first to check if it would be neccessary at all in other states
        }

        return resolve();
      });
    },

    referenceSyncCheck(checked) {
      this.referenceSyncChecked = !!checked;
    },

    extraDetailsSyncCheck(checked) {
      this.extraDetailsSyncChecked = !!checked;
    },

    assignedUserSyncCheck(checked) {
      this.assignedUserSyncChecked = !!checked;
    },

    closeMultiSynchronizeDialog() {
      this.referenceSynchronize = false;
      this.extraDetailsSynchronize = false;
      this.assignedUserSynchronize = false;
      this.multiSynchronizeDialog = false;
      this.saveProperty(this.btnKey);
    },

    saveMultiSynchronizeDialog() {
      if (this.referenceSyncChecked) {
        this.referenceSynchronize = true;
      }

      if (this.extraDetailsSyncChecked) {
        this.extraDetailsSynchronize = true;
      }

      if (this.assignedUserSyncChecked) {
        this.assignedUserSynchronize = true;
      }

      this.multiSynchronizeDialog = false;
      this.saveProperty(this.btnKey);
    },

    selectCommunity(item) {
      if (item.code === "0" || item.code === "") {
        this.errors.community.invalid = true;
      } else {
        this.errors.community.invalid = false;
      }

      this.validateCommunity(item.name);
    },
    selectCity(item) {
      if (item.code === "") {
        this.errors.city.invalid = true;
      } else {
        this.errors.city.invalid = false;
      }
    },
    async selectState() {
      this.stateHandler();
      this.showHideTabsAndFields();
      this.buildingsOnThirdPartyOwnersChangeHandler();
      this.updatePropertyTypeSelection();
      this.setEconomicEntityTypeOptions();
      this.updateEconomicEntityTypeValue();
      this.setHelpInfo();
      await this.getPossibleCommunities();
      this.getPossiblesParcels();
      this.setMaxlength(this.propertyData.step2.federalStateUid);
    },
    validateCommunity(communityName) {
      this.validCommunity = false;
      if (this.propertyData.step2.federalStateUid === '3') {
        if (communityName.toLowerCase() === 'berlin') {
          this.validCommunity = true;
        }
      } else {
        this.validCommunity = true;
      }
    },
    getPossibleCommunities() {
      this.communityList = [];

      this.stateObj = this.stateList.filter(item => {
        if (item.code === this.propertyData.step2.federalStateUid) {
          return item;
        }
      });

      if (typeof this.stateObj[0] !== 'undefined' && typeof this.stateObj[0].name !== 'undefined') {
        this.loadingCommunity = true;
        this.$api.get_community_list(this.stateObj[0].name).then((list) => {
          this.communityList = list.map((o) => {
            return {
              name: o.name + ' - AGS: ' + o.community_key,
              code: o.code,
            }
          });
          this.loadingCommunity = false;
          this.validateCommunity(this.propertyData.step2.community);
        })
      }
    },
    getPossibleCommunitiesLandAndForestry() {
      this.communityListLandAndForestry = [];

      this.stateObj = this.stateList.filter(item => {
        if (
            (this.addParcelsLandAndForestryDialog && item.code === this.addModalSaveData.parcelsLandAndForestry.federalStateUid)
            || (this.editParcelsLandAndForestryDialog && item.code === this.editModalSaveData.parcelsLandAndForestry.federalStateUid)
        ) {
          return item;
        }
      });

      this.step9CorridorDisabled = false;
      this.step9LandRegisterSheetDisabled = false;

      if (typeof this.stateObj[0] !== 'undefined' && typeof this.stateObj[0].name !== 'undefined') {
        this.loadingCommunity = true;
        this.$api.get_community_list(this.stateObj[0].name).then((list) => {
          this.communityListLandAndForestry = list;
          this.loadingCommunity = false;
        });

        // check disable status of corridor
        if (parseInt(this.stateObj[0].code) === 2 || parseInt(this.stateObj[0].code) === 6) {
          this.step9CorridorDisabled = true;
        }

        // check disable status of land register sheet
        if (parseInt(this.stateObj[0].code) === 2 || parseInt(this.stateObj[0].code) === 6 || parseInt(this.stateObj[0].code) === 7 || parseInt(this.stateObj[0].code) === 9) {
          this.step9LandRegisterSheetDisabled = false;
        } else {
          this.step9LandRegisterSheetDisabled = true;
        }
      }
    },
    getPossiblesParcels(e) {
      let community = '';
      this.parcelList = [];

      this.stateObj = this.stateList.filter(item => {
        if (item.code === this.propertyData.step2.federalStateUid) {
          return item;
        }
      });

      if (typeof this.stateObj[0] !== 'undefined' && typeof this.stateObj[0].name !== 'undefined') {
        if (this.addParcelDialog) {
          community = this.propertyData.step2.community;
        }

        if (this.editParcelDialog && typeof this.editModalSaveData.parcel.community !== 'undefined') {
          community = this.editModalSaveData.parcel.community;
        }
        if(e && e.code) {
          community = e.code;
        }

        if (community !== '') {
          this.$api.get_gemarkungen_list(this.stateObj[0].name, community).then((list) => {
            this.parcelList = list;
          });
        }
      }
    },
    getPossiblesParcelsLandAndForestry({name}) {
      let community = name;
      this.parcelListLandAndForestry = [];

      this.stateObj = this.stateList.filter(item => {
        if (
            (this.addParcelsLandAndForestryDialog && item.code === this.addModalSaveData.parcelsLandAndForestry.federalStateUid)
            || (this.editParcelsLandAndForestryDialog && item.code === this.editModalSaveData.parcelsLandAndForestry.federalStateUid)
        ) {
          return item;
        }
      });

      if (typeof this.stateObj[0] !== 'undefined' && typeof this.stateObj[0].name !== 'undefined') {
        if (!community && this.addParcelsLandAndForestryDialog) {
          community = this.addModalSaveData.parcelsLandAndForestry.community;
        }

        if (!community && this.editParcelsLandAndForestryDialog && typeof this.editModalSaveData.parcelsLandAndForestry.community !== 'undefined') {
          community = this.editModalSaveData.parcelsLandAndForestry.community;
        }

        if (community) {
          this.$api.get_gemarkungen_list(this.stateObj[0].name, community).then((list) => {
            this.parcelListLandAndForestry = list;
          });
        }
      }
    },
    selectEconomicEntityType() {
      this.showHideTabsAndFields();
      this.buildingsOnThirdPartyOwnersChangeHandler();
      this.updatePropertyTypeSelection();
      this.setHelpInfo();
      this.setMaxlength(this.propertyData.step2.federalStateUid);
      this.updateTaxModelText();
    },
    updatePropertyTypeSelection() {
      if (parseInt(this.propertyData.step2.federalStateUid) !== 1) {
        if (parseInt(this.propertyData.step2.economicEntityType) === 1) {
          this.propertyData.step3.propertyType = '1';
          if (parseInt(this.propertyData.step2.federalStateUid) === 6) {
            this.propertyData.step3.propertyType = '';
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 2) {
            this.propertyData.step3.propertyType = '';
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
            this.propertyData.step3.propertyType = '';
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 9) {
            this.propertyData.step3.propertyType = '';
          }
        }
      }
    },
    showHideTabsAndFields() {
      let stepExemptionBenefitShow = true;
      let stepCommunityShow = true;
      let stepOwnerShow = true;
      let stepBuildingShow = true;
      this.stepResidentialShow = true;
      let stepNonResidentialShow = true;
      let stepLeaseholdShow = true;
      let stepAgricultureShow = true;
      let stepAnimalShow = true;
      this.showPeoplePool = false;

      this.propertyExemptionBenefitTypeDisabled = false;

      this.expirationFirstGrantPeriodDisabled = false;
      this.expirationLastGrantPeriodDisabled = false;

      this.expectedExemptPurposesDisabled = false;
      this.taxPrivilegedPurposesDisabled = false;

      this.unbuiltRealEstateExemptionDescriptionDisabled = false;
      this.taxFreeAreaDisabled = false;
      this.unbuiltRealEstateTaxExemptionDisabled = false;
      this.propertyParcelUidDisabled = false;

      this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = false;
      this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = false;
      this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = false;
      this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = false;

      this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = false;
      this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = false;
      this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = false;

      if (this.isExempted) {
        switch (parseInt(this.propertyData.step2.federalStateUid)) {
          case 1:
            this.propertyExemptionBenefitTypeDisabled = true;

            this.expirationFirstGrantPeriodDisabled = true;
            this.expirationLastGrantPeriodDisabled = true;

            this.expectedExemptPurposesDisabled = true;
            this.taxPrivilegedPurposesDisabled = true;

            this.unbuiltRealEstateExemptionDescriptionDisabled = true;
            this.taxFreeAreaDisabled = true;
            this.unbuiltRealEstateTaxExemptionDisabled = true;
            this.propertyParcelUidDisabled = true;

            break;

          case 2:
          case 9:
            this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = true;

            this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = true;
            this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = true;

            break;

          case 3:
          case 4:
          case 5:
          case 8:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            this.propertyExemptionBenefitTypeDisabled = true;

            this.expirationFirstGrantPeriodDisabled = true;
            this.expirationLastGrantPeriodDisabled = true;

            this.expectedExemptPurposesDisabled = true;
            this.taxPrivilegedPurposesDisabled = true;

            this.unbuiltRealEstateExemptionDescriptionDisabled = true;
            this.taxFreeAreaDisabled = true;
            this.unbuiltRealEstateTaxExemptionDisabled = true;
            this.propertyParcelUidDisabled = true;

            this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = true;

            this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = true;
            this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = true;

            break;

          case 6:
            this.propertyExemptionBenefitTypeDisabled = true;

            this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = true;
            this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = true;

            this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = true;
            this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = true;

            break;

          case 7:
            this.propertyExemptionBenefitTypeDisabled = true;

            this.expirationFirstGrantPeriodDisabled = true;
            this.expirationLastGrantPeriodDisabled = true;

            this.expectedExemptPurposesDisabled = true;
            this.taxPrivilegedPurposesDisabled = true;

            this.unbuiltRealEstateExemptionDescriptionDisabled = true;
            this.taxFreeAreaDisabled = true;
            this.unbuiltRealEstateTaxExemptionDisabled = true;
            this.propertyParcelUidDisabled = true;

            this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = true;
            this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = true;
            this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = true;

            this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = true;

            break;
        }
      } else {
        this.propertyExemptionBenefitTypeDisabled = this.propertyData.step2.federalStateUid !== '2' && this.propertyData.step2.federalStateUid !== '9';

        if ((parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6 || parseInt(this.propertyData.step2.federalStateUid) === 7 || parseInt(this.propertyData.step2.federalStateUid) === 9) && parseInt(this.propertyData.step2.economicEntityType) === 2) {
          this.expirationFirstGrantPeriodDisabled = true;
          this.expirationLastGrantPeriodDisabled = true;
        } else {
          this.expirationFirstGrantPeriodDisabled = false;
          this.expirationLastGrantPeriodDisabled = false;
        }

        if (parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6 || parseInt(this.propertyData.step2.federalStateUid) === 9) {
          this.expectedExemptPurposesDisabled = false;
          this.taxPrivilegedPurposesDisabled = false;
          this.unbuiltRealEstateExemptionDescriptionDisabled = false;
          this.taxFreeAreaDisabled = false;
          this.unbuiltRealEstateTaxExemptionDisabled = false;
          this.propertyParcelUidDisabled = false;
          this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = false;
          this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = false;
          this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = false;
          this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = false;
        } else {
          this.expectedExemptPurposesDisabled = true;
          this.taxPrivilegedPurposesDisabled = true;
          this.unbuiltRealEstateExemptionDescriptionDisabled = true;
          this.taxFreeAreaDisabled = true;
          this.unbuiltRealEstateTaxExemptionDisabled = true;
          this.useOfDefinablePartSpatiallyDefinableExemptionPartDescriptionDisabled = true;
          this.useOfDefinablePartSpatiallyDefinableExemptionAreaDisabled = true;
          this.useOfDefinablePartSpatiallyDefinableExemptionLandOrLivingDisabled = true;
          this.useOfDefinablePartSpatiallyDefinableTaxExemptionPartDisabled = true;

          this.propertyParcelUidDisabled = true;
        }

        if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
          this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = false;
          this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = false;
          this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = false;
        } else {
          this.taxBreakConditionSpatiallyDefinableExemptionPartDescriptionDisabled = true;
          this.taxBreakConditionSpatiallyDefinableExemptionAreaDisabled = true;
          this.taxBreakConditionSpatiallyDefinableTaxExemptionPartDisabled = true;
        }
      }

      this.districtShow = true;

      this.registrationLandRegistryDisabled = this.service.isRegistrationLandRegistryDisabled(
          this.propertyData.step2.economicEntityType,
          this.propertyData.step3.propertyType,
          this.propertyData.step2.federalStateUid
      );

      this.propertyTypeDisabled = this.service.isPropertyTypeDisabled(
          this.propertyData.step2.economicEntityType,
          this.propertyData.step2.federalStateUid
      );

      if(this.propertyExemptionBenefitTypeDisabled) {
        this.propertyData.step3a.propertyExemptionBenefitType = '0';
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6) {
        this.containedInAreaDisabled = true;
      } else {
        this.containedInAreaDisabled = false;
      }

      if (this.propertyData.step2.exemption !== "1") {
        stepExemptionBenefitShow = false;
        if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
          this.exemptionBefreiungDisabled = true;
          this.exemptionBefreiungNichtDisabled = true;
          this.parcelsLandAndForestryExemptionDisabled = true;
          this.favoredAreaDisabled = true;

          this.districtShow = false;
        }
      } else {
        if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
          stepExemptionBenefitShow = false;
          this.exemptionBefreiungDisabled = false;
          this.exemptionBefreiungNichtDisabled = false;
          this.parcelsLandAndForestryExemptionDisabled = false;
          this.favoredAreaDisabled = false;

          this.districtShow = false;
        } else {
          stepExemptionBenefitShow = true;
        }
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        this.useOfDefinablePartSpatiallyDefinableExemptionAreaMaxLength = 6;
      } else {
        if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
          this.useOfDefinablePartSpatiallyDefinableExemptionAreaMaxLength = 9;
        } else {
          this.useOfDefinablePartSpatiallyDefinableExemptionAreaMaxLength = 15;
        }
      }

      this.developmentStateDisabled = false;
      this.buildingOnForeignGroundDisabled = false;
      this.propertyResidentialPurposesDisabled = false;
      this.totalPropertyPartDisabled = false;
      this.corridorDisabled = false;
      this.leaseholdDisabled = false;

      this.areaOfTheLandDisabled = false;
      this.areaOfTheLandValueDisabled1 = false;
      this.btnDisabled1 = false;
      this.addMoreAreaLinkShow = true;

      if (this.taxModel){
        this.step3_property_type = this.$t('properties.step3.property_type_options.' + this.taxModel);
      } else {
        this.step3_property_type = [];
      }

      this.updateInfoBar();

      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        stepBuildingShow = false;
        if (parseInt(this.propertyData.step2.economicEntityType) === 1) {
          this.stepResidentialShow = false;
          stepNonResidentialShow = false;
          stepAgricultureShow = false;
          stepAnimalShow = false;

          this.buildingOnForeignGroundDisabled = true;
          this.totalPropertyPartDisabled = true;
        }
      } else {
        if (parseInt(this.propertyData.step2.economicEntityType) === 2) {
          if (parseInt(this.propertyData.step2.federalStateUid) === 6) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.thirdAreaInfoShow = false;
            this.fourthAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepLeaseholdShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 2) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;

            if (this.taxModel){
              this.step3_property_type = this.$t('properties.step3.property_type_options.' + this.taxModel);
            } else {
              this.step3_property_type = [];
            }
            this.updateInfoBar();
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 9) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;

            if (this.taxModel){
              this.step3_property_type = this.$t('properties.step3.property_type_options.' + this.taxModel);
            } else {
              this.step3_property_type = [];
            }
            this.updateInfoBar();
          } else {
            stepBuildingShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;

            if (this.taxModel){
              this.step3_property_type = this.$t('properties.step3.property_type_options.' + this.taxModel);
            } else {
              this.step3_property_type = [];
            }
            this.updateInfoBar();
          }
        } else if (parseInt(this.propertyData.step2.economicEntityType) === 1) {
          stepBuildingShow = false;
          if (parseInt(this.propertyData.step2.federalStateUid) === 6) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 2) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.areaOfTheLandValueDisabled1 = true;
            this.btnDisabled1 = true;
            this.secondAreaInfoShow = false;
            this.addMoreAreaLinkShow = false;
            this.propertyResidentialPurposesDisabled = true;

          } else if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepLeaseholdShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;
          } else if (parseInt(this.propertyData.step2.federalStateUid) === 9) {
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.developmentStateDisabled = true;
            this.propertyResidentialPurposesDisabled = true;
          } else {
            stepBuildingShow = false;
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            stepAgricultureShow = false;
            stepAnimalShow = false;

            this.propertyResidentialPurposesDisabled = true;
            this.totalPropertyPartDisabled = true;

            if (this.taxModel){
              this.step3_property_type = this.$t('properties.step3.property_type_options.' + this.taxModel);
            } else {
              this.step3_property_type = [];
            }
            this.updateInfoBar();
          }
        }
      }

      if (parseInt(this.propertyData.step2.economicEntityType) === 3) {
        stepBuildingShow = false;
        this.stepResidentialShow = false;
        stepNonResidentialShow = false;
        stepLeaseholdShow = false;

        this.developmentStateDisabled = true;
        this.leaseholdDisabled = true;
        this.propertyResidentialPurposesDisabled = true;
        this.totalPropertyPartDisabled = true;
        this.buildingOnForeignGroundDisabled = true;

        this.propertyData.step3.areaOfTheLand1 = '';
        this.propertyData.step3.areaOfTheLand2 = '';
        this.propertyData.step3.areaOfTheLand3 = '';
        this.propertyData.step3.areaOfTheLand4 = '';
        this.areaOfTheLandDisabled = false;

        this.areaOfTheLandValueDisabled1 = true;
        this.btnDisabled1 = true;
        this.secondAreaInfoShow = false;
        this.thirdAreaInfoShow = false;
        this.fourthAreaInfoShow = false;
        this.addMoreAreaLinkShow = false;
      }

      if (this.service.canShowStepBuilding(this.propertyData.step2.federalStateUid)) {
        if (parseInt(this.propertyData.step2.economicEntityType) === 1) {
          stepBuildingShow = false;
        } else {
          stepBuildingShow = true;
        }
        this.stepResidentialShow = false;
        stepNonResidentialShow = false;
      } else {
        stepBuildingShow = false;
        switch (parseInt(this.propertyData.step3.propertyType)) {
          default:
          case NaN:
          case "":
          case 0:
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            break;

          case 1:
            this.stepResidentialShow = false;
            stepNonResidentialShow = false;
            break;

          case 2:
          case 3:
          case 4:
          case 5:
            this.stepResidentialShow = true;
            stepNonResidentialShow = false;
            break;

          case 6:
          case 7:
          case 8:
          case 9:
            this.stepResidentialShow = false;
            stepNonResidentialShow = true;
            break;
        }
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6) {
        this.propertyData.step3.areaOfTheLandValue1 = '';
        this.areaOfTheLandValueDisabled1 = true;
        this.btnDisabled1 = true;

        this.secondAreaInfoShow = false;
        this.addMoreAreaLinkShow = false;
        this.corridorDisabled = true;
      } else {
        this.areaOfTheLandValueDisabled1 = false;
        this.btnDisabled1 = false;

        this.addMoreAreaLinkShow = true;
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6 || parseInt(this.propertyData.step2.federalStateUid) === 9) {
        this.buildingsOnThirdPartyOwnersDisabled = false;
      } else {
        this.buildingsOnThirdPartyOwnersDisabled = true;
      }

      switch (parseInt(this.propertyData.step3.ownershipStructure)) {
        case NaN:
        case "":
        case -1:
        case 0:
        case 1:
        case 2:
        case 3:
          stepCommunityShow = false;
          stepOwnerShow = false;
          break;

        case 4:
          stepCommunityShow = false;
          stepOwnerShow = true;
          this.showPeoplePool = true;
          break;

        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          stepCommunityShow = true;
          stepOwnerShow = true;
          this.showPeoplePool = true;
          break;
      }

      if (parseInt(this.propertyData.step2.economicEntityType) !== 3) {
        stepAgricultureShow = false;
        stepAnimalShow = false;
      } else {
        stepExemptionBenefitShow = false;
        stepBuildingShow = false;
      }

      if(parseInt(this.propertyData.step3.buildingOnForeignGround) === 1 || parseInt(this.propertyData.step3.leasehold) === 1) {
        stepLeaseholdShow = true;
      } else {
        stepLeaseholdShow = false;
      }

      //stepLeaseholdShow must always be false if federal_stat_uid == 7 (HE) => GRDDEV-2539
      //overrules any other rules for stepLeaseholdShow
      if (parseInt(this.propertyData.step2.federalStateUid) === 7) {
        stepLeaseholdShow = false;
      }

      if (parseInt(this.propertyData.step2.federalStateUid) === 6 && parseInt(this.propertyData.step3.buildingsOnThirdPartyOwners) === 1) {
        stepLeaseholdShow = true;
      }

      if (typeof (this.step3_property_type) === 'object' && this.step3_property_type.length > 0 && parseInt(this.propertyData.step2.federalStateUid) !== 2 && parseInt(this.propertyData.step2.federalStateUid) !== 9) {
        if (parseInt(this.propertyData.step2.economicEntityType) === 1) {
          this.step3_property_type = this.step3_property_type.filter(item => item.code === '1' || item.code === '0');
        } else {
          this.step3_property_type = this.step3_property_type.filter(item => item.code !== '1' || item.code === '0');
        }
      }

      this.setStepExemptionBenefitShow(stepExemptionBenefitShow);
      this.setStepCommunityShow(stepCommunityShow);
      this.setStepOwnerShow(stepOwnerShow);
      this.setStepBuildingShow(stepBuildingShow);
      this.setStepResidentialShow(this.stepResidentialShow);
      this.setStepNonResidentialShow(stepNonResidentialShow);
      this.setStepLeaseholdShow(stepLeaseholdShow);
      this.setStepAgricultureShow(stepAgricultureShow);
      this.setStepAnimalShow(stepAnimalShow);

      // if federal state - Bayern / Hamburg or economic_entity_type is 3
      // then hide first Area info block
      if (parseInt(this.propertyData.step2.economicEntityType) === 3 || parseInt(this.propertyData.step2.federalStateUid) === 2 || parseInt(this.propertyData.step2.federalStateUid) === 6) {
        this.firstAreaInfoShow = false;
      } else {
        this.firstAreaInfoShow = true;
      }

      //GRDDEV-2742
      this.showSubAreaComp = [3,4,5,8,10,11,12,13,14,15,16].includes(parseInt(this.propertyData.step2.federalStateUid, 10));

      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        this.maxAreaOfLandValue1 = 999999999999.99;
      } else {
        this.maxAreaOfLandValue1 = 999999.99;
      }

      //GRDDEV-3247 - hide "Zivilschutz" info box in "Bayern", "Niedersachsen" and "Hamburg"
      this.showCivilDefenseInfoBox = [2, 9, 6].indexOf(parseInt(this.propertyData.step2.federalStateUid)) === -1;

      this.refreshSteps();
      this.addressInfoBox();
    },
    closeInfoDlg() {
      this.showInfoDlg = false;
    },
    searchZip(sourceKey) {
      if (sourceKey === 'community') {
        if (this.propertyData.step4.city === '' && this.propertyData.step4.zip !== '') {
          this.zipSearch.community = true;
          this.cityLookup(this.propertyData.step4.zip).then((cities) => {
            this.cityList.community = cities;
            if (cities.length === 1) {
              this.propertyData.step4.city = cities[0].code;
            }
            this.zipSearch.community = false;
          });
        }
      } else if(sourceKey === 'leaseholdAddDialog') {
        if ((typeof this.addModalSaveData.leasehold.city === 'undefined' || this.addModalSaveData.leasehold.city === '') && this.addModalSaveData.leasehold.zip !== '') {
          this.zipSearch.leaseholdAddDialog = true;
          this.cityLookup(this.addModalSaveData.leasehold.zip).then((cities) => {
            this.cityList.leaseholdAddDialog = cities;
            if (cities.length === 1) {
              this.addModalSaveData.leasehold.city = cities[0].code;
            }
            this.zipSearch.leaseholdAddDialog = false;
          });
        }
      } else if(sourceKey === 'leaseholdEditDialog') {
        if ((typeof this.editModalSaveData.leasehold.city === 'undefined' || this.editModalSaveData.leasehold.city === '') && this.editModalSaveData.leasehold.zip !== '') {
          this.zipSearch.leaseholdEditDialog = true;
          this.cityLookup(this.editModalSaveData.leasehold.zip).then((cities) => {
            this.cityList.leaseholdEditDialog = cities;
            if (cities.length === 1) {
              this.editModalSaveData.leasehold.city = cities[0].code;
            }
            this.zipSearch.leaseholdEditDialog = false;
          });
        }
      } else if(sourceKey === 'addOwnerDialog') {
        if ((typeof this.addModalSaveData.owner.city === 'undefined' || this.addModalSaveData.owner.city === '') && this.addModalSaveData.owner.zip !== '') {
          this.zipSearch.addOwnerDialog = true;
          this.cityLookup(this.addModalSaveData.owner.zip).then((cities) => {
            this.cityList.addOwnerDialog = cities;
            if (cities.length === 1) {
              this.addModalSaveData.owner.city = cities[0].code;
            }
            this.zipSearch.addOwnerDialog = false;
          });
        }
      } else if(sourceKey === 'addOwnerDialogRepresentative') {
        if ((typeof this.addModalSaveData.owner.representative.city === 'undefined' || this.addModalSaveData.owner.representative.city === '') && this.addModalSaveData.owner.representative.zip !== '') {
          this.zipSearch.addOwnerDialogRepresentative = true;
          this.cityLookup(this.addModalSaveData.owner.representative.zip).then((cities) => {
            this.cityList.addOwnerDialogRepresentative = cities;
            if (cities.length === 1) {
              this.addModalSaveData.owner.representative.city = cities[0].code;
            }
            this.zipSearch.addOwnerDialogRepresentative = false;
          });
        }
      } else if(sourceKey === 'editOwnerDialog') {
        if ((typeof this.editModalSaveData.owner.city === 'undefined' || this.editModalSaveData.owner.city === '') && this.editModalSaveData.owner.zip !== '') {
          this.zipSearch.editOwnerDialog = true;
          this.cityLookup(this.editModalSaveData.owner.zip).then((cities) => {
            this.cityList.editOwnerDialog = cities;
            if (cities.length === 1) {
              this.editModalSaveData.owner.city = cities[0].code;
            }
            this.zipSearch.editOwnerDialog = false;
          });
        }
      } else if(sourceKey === 'editOwnerDialogRepresentative') {
        if ((typeof this.editModalSaveData.owner.representative.city === 'undefined' || this.editModalSaveData.owner.representative.city === '') && this.editModalSaveData.owner.representative.zip !== '') {
          this.zipSearch.editOwnerDialogRepresentative = true;
          this.cityLookup(this.editModalSaveData.owner.representative.zip).then((cities) => {
            this.cityList.editOwnerDialogRepresentative = cities;
            if (cities.length === 1) {
              this.editModalSaveData.owner.representative.city = cities[0].code;
            }
            this.zipSearch.editOwnerDialogRepresentative = false;
          });
        }
      } else {
        if (this.propertyData.step2.city === '' && this.propertyData.step2.zip !== '') {
          this.zipSearch.basic = true;
          this.cityLookup(this.propertyData.step2.zip).then((cities) => {
            this.cityList.basic = cities;
            if (cities.length === 1) {
              this.propertyData.step2.city = cities[0].code;
            }
            this.zipSearch.basic = false;
          });
        }
      }
    },
    initModalRequiredFields(fields) {
      for (let i = 0; i < fields.length; i++) {
        this.modalSaveRequired[fields[i]] = true;
      }
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_parcel':
          this.setMaxFieldLength(this.propertyData.step2.federalStateUid);
          if (typeof this.modalMandatoryFields.parcel !== 'undefined' && this.modalMandatoryFields.parcel.length > 0) {
            this.initModalRequiredFields(this.modalMandatoryFields.parcel);
          }
          this.addModalSaveData.parcel.community = this.propertyData.step2.community;
          this.addParcelDialog = true;
          this.getPossibleCommunities();
          this.getPossiblesParcels();
          break;
        case 'cm.duplicate_parcel':
          if (args.data[0].prim_uid) {
            let duplicateData = {}
            duplicateData.property_uid = this.property_uid;
            duplicateData.attribute = 'parcel';
            duplicateData.uid = args.data[0].prim_uid;
            this.$modal.show({
              headerText: this.$t('properties.parcel_duplicate_confirm.title'),
              text: this.$t('properties.parcel_duplicate_confirm.message'),
              buttons: {
                ok: this.$t('buttons.save'),
                cancel: this.$t('buttons.cancel')
              },
              onConfirm: () => {
                this.service.duplicateRow(duplicateData, true).then((response) => {
                  if (typeof response.success !== 'undefined' && response.success && response.parcelId) {
                    this.triggerReload(this.parcelGridId);
                    this.refreshSumOfArealand();
                    this.propertyData.prim_uid = this.property_uid;
                    this.propertyData.area_of_the_land_total = this.sumOfAreas;
                    this.service.save(this.propertyData, true).then((response) => {
                      if (typeof response.success !== 'undefined' && response.success) {
                      }
                    });
                    this.$modal.visible = false;
                    this.setPropertyDataChanged(true);
                  }
                });
              }
            });
          }
          break;

        case 'cm.edit_parcel':
          if (args.data[0].prim_uid) {
            this.editParcel(args.data[0].prim_uid);
          }
          break;

        case 'cm.edit_skendata':
          this.openSkenDataModal();
          break;

        case 'cm.delete_parcel':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'parcel';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.parcelGridId);
                  this.refreshSumOfArealand();
                  this.propertyData.prim_uid = this.property_uid;
                  this.propertyData.area_of_the_land_total = this.sumOfAreas;
                  this.service.save(this.propertyData, true).then((response) => {
                    if (typeof response.success !== 'undefined' && response.success) {
                    }
                  });
                  this.resetAddModalData();
                  this.checkSumAreasGlobal();
                  this.$modal.visible = false;
                  this.setPropertyDataChanged(true);
                }
              });
            }
          });
          break;

        case 'cm.import_parcel':
          this.importParcelDialog = true;
          break;
      }
    },
    handleColClick(e) {
      if (e.gridId && parseInt(e.row.prim_uid) > 0) {
        if (e.gridId === this.parcelGridId && (e.field === 'parcel' || e.field === 'community')) {
          this.editParcel(e.row.prim_uid);
        } else if (e.gridId === this.residentialGridId && e.field === 'yearOfConstruction') {
          this.editResidential(e.row.prim_uid);
        } else if (e.gridId === this.ownerGridId) {
          this.editOwner(e.row.prim_uid);
        } else if (e.gridId === this.nonresidentialGridId) {
          this.editNonResidential(e.row.prim_uid);
        } else if (e.gridId === this.leaseholdGridId) {
          this.editLeaseHold(e.row.prim_uid);
        }
      }
    },
    handleOwnerMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_owner':
          if (typeof this.modalMandatoryFields.owner !== 'undefined' && this.modalMandatoryFields.owner.length > 0) {
            this.initModalRequiredFields(this.modalMandatoryFields.owner);
          }
          this.addModalSaveData.owner.countryUid = this.defaultCountryId;
          this.addModalSaveData.owner.representative.countryUid = this.defaultCountryId;
          this.addOwnerDialog = true;
          break;
        case 'cm.edit_owner':
          if (args.data[0].prim_uid) {
            this.editOwner(args.data[0].prim_uid)
          }
          break;

        case 'cm.delete_owner':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'owner';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.ownerGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;

        case 'cm.take_over':
          let getData = {}
          getData.property_uid = this.property_uid;
          this.service.getOtherPropertiesModalData(getData, true).then((response) => {
            if (typeof response.success !== 'undefined' && response.success && response.data) {
              this.propertyOptions = response.data.map(o => {
                return {
                  "name": o.name,
                  "code": o.prim_uid
                }
              });

              this.propertyOptions.unshift({
                "name": "Bitte auswählen",
                "code": "0"
              });
              this.takeOverOwnersDialog = true;
            }
          });
          break;
      }
    },
    selectProperty(e) {
      this.takeOverBtnDisabled = true;
      if(e && parseInt(e.code) > 0) {
        this.takeOverBtnDisabled = false;
      }
    },
    takeOverFromProperty() {
      if (parseInt(this.propertyForOwner) > 0) {
        let getData = {}
        getData.from_property_uid = this.propertyForOwner;
        getData.property_uid = this.property_uid;
        this.service.takeOverOwnerData(getData, true).then((response) => {
          if (typeof response.success !== 'undefined' && response.success) {
            this.triggerReload(this.ownerGridId);
            this.takeOverOwnersDialog = false;
          }
        });
      }
    },
    editOwner(uid) {
      let getData = {}
      getData.property_uid = this.property_uid;
      getData.attribute = 'owner';
      getData.uid = uid;
      this.pro_id = uid;
      this.service.getModalData(getData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success && response.data) {
          this.editModalSaveData.owner = response.data;
          if (typeof this.modalMandatoryFields.owner !== 'undefined' && this.modalMandatoryFields.owner.length > 0) {
            this.initModalRequiredFields(this.modalMandatoryFields.owner);
          }
          this.editOwnerDialog = true;
        }
      });
    },
    editParcel(uid) {
      if (uid) {
        let getData = {}
        getData.property_uid = this.property_uid;
        getData.attribute = 'parcel';
        getData.uid = uid;
        this.setMaxFieldLength(this.propertyData.step2.federalStateUid);
        this.pro_id = uid;
        this.service.getModalData(getData, true).then((response) => {
          if (typeof response.success !== 'undefined' && response.success && response.data) {
            this.editModalSaveData.parcel = response.data;
            if (typeof this.modalMandatoryFields.parcel !== 'undefined' && this.modalMandatoryFields.parcel.length > 0) {
              this.initModalRequiredFields(this.modalMandatoryFields.parcel);
            }

            this.editParcelDialog = true;
            this.getPossibleCommunities();
            this.getPossiblesParcels();
          }
        });
      }
    },
    editResidential(uid) {
      if (uid) {
        let getData = {}
        getData.property_uid = this.property_uid;
        getData.attribute = 'residential';
        getData.uid = uid;
        this.pro_id = uid;
        this.service.getModalData(getData, true).then((response) => {
          if (typeof response.success !== 'undefined' && response.success && response.data) {

            this.editModalSaveData.residential = response.data;
            this.editModalSaveData.residential.garageUnderground.garageUndergroundExemption = this.$api.parse_object_data(response.data.garageUnderground.garageUndergroundExemption);
            this.editModalSaveData.residential.garageUnderground.garageUndergroundBenefit = this.$api.parse_object_data(response.data.garageUnderground.garageUndergroundBenefit);

            this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption = this.$api.parse_object_data(response.data.apartmentsBelow60.apartmentsBelow60Exemption);
            this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit = this.$api.parse_object_data(response.data.apartmentsBelow60.apartmentsBelow60Benefit);

            this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption = this.$api.parse_object_data(response.data.apartmentsBetween60100.apartmentsBetween60100Exemption);
            this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit = this.$api.parse_object_data(response.data.apartmentsBetween60100.apartmentsBetween60100Benefit);

            this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption = this.$api.parse_object_data(response.data.apartmentsAbove100.apartmentsAbove100Exemption);
            this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit = this.$api.parse_object_data(response.data.apartmentsAbove100.apartmentsAbove100Benefit);

            this.editModalSaveData.residential.apartmentsOther.apartmentsOtherExemption = this.$api.parse_object_data(response.data.apartmentsOther.apartmentsOtherExemption);
            this.editModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit = this.$api.parse_object_data(response.data.apartmentsOther.apartmentsOtherBenefit);

            this.editModalSaveData.residential.usableSpaces = this.$api.parse_object_data(response.data.usableSpaces);
            if (this.editModalSaveData.residential.usableSpaces.length > 0) {
              for (let index = 0; index < this.editModalSaveData.residential.usableSpaces.length; index++) {
                this.editModalSaveData.residential.usableSpaces[index]['usableSpacesExemption'] = this.$api.parse_object_data(this.editModalSaveData.residential.usableSpaces[index].usableSpacesExemption);
                this.editModalSaveData.residential.usableSpaces[index]['usableSpacesBenefit'] = this.$api.parse_object_data(this.editModalSaveData.residential.usableSpaces[index].usableSpacesBenefit);
              }
            }

            this.editResidentialDialog = true;
          }
        });
      }
    },
    editNonResidential(uid) {
      let getData = {}
      getData.property_uid = this.property_uid;
      getData.attribute = 'nonresidential';
      getData.uid = uid;
      this.pro_id = uid;
      this.service.getModalData(getData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success && response.data) {
          if (response.data.benefits) {
            response.data.benefits = this.$api.parse_object_data(response.data.benefits);
          } else {
            response.data.benefits = [];
          }
          if (response.data.exemptions) {
            response.data.exemptions = this.$api.parse_object_data(response.data.exemptions);
          } else {
            response.data.exemptions = [];
          }

          this.editModalSaveData.nonResidentials = {...response.data};
          this.editNonresidentialDialog = true;
        }
      });
    },
    handleBuildingPartsColClick(e) {
      if(e.row.prim_uid) {
        this.editBuildingPart(e.row.prim_uid);
      }
    },
    handleBuildingPartsMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_buildingpart':
          this.addBuildingPartDialog = true;
          break;
        case 'cm.edit_buildingpart':
          if (args.data[0].prim_uid) {
            this.editBuildingPart(args.data[0].prim_uid);
          }
          break;

        case 'cm.delete_buildingpart':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'buildingpart';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.buildingPartsGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;

        case 'cm.building_service':
          this.buildingServiceDialog = true;
          break;
      }
    },
    editBuildingPart(id) {
      let getData = {}
      getData.property_uid = this.property_uid;
      getData.attribute = 'buildingpart';
      getData.uid = id;
      this.pro_id = id;
      this.service.getModalData(getData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success && response.data) {
          if (response.data.benefits) {
            response.data.benefits = this.$api.parse_object_data(response.data.benefits);
          } else {
            response.data.benefits = [];
          }
          if (response.data.exemptions) {
            response.data.exemptions = this.$api.parse_object_data(response.data.exemptions);
          } else {
            response.data.exemptions = [];
          }
          this.editModalSaveData.buildingPart = {...response.data};

          this.editBuildingPartDialog = true;
        }
      });
    },
    handleResidentialMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_residential':
          this.addResidentialDialog = true;
          break;
        case 'cm.edit_residential':
          if (args.data[0].prim_uid) {
            this.editResidential(args.data[0].prim_uid);
          }
          break;

        case 'cm.delete_residential':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'residential';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {

                  if (typeof response.mandatoryFields !== 'undefined' && typeof response.mandatoryFields.yearOfConstruction !== 'undefined') {
                    this.step6FirstRowYearOfConstruction = response.mandatoryFields.yearOfConstruction;
                    this.initMandatoryFields(this.taxModel);
                  }

                  this.triggerReload(this.residentialGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;

        case 'cm.building_service':
          this.buildingServiceDialog = true;
          break;
      }
    },

    // ***
    handleNonresidentialMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_nonresidential':
          this.resetAddModalData();
          this.addNonresidentialDialog = true;
          break;
        case 'cm.edit_nonresidential':
          if (args.data[0].prim_uid) {
            this.editNonResidential(args.data[0].prim_uid);
          }
          break;

        case 'cm.delete_nonresidential':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'nonresidential';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.nonresidentialGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;

        case 'cm.building_service':
          this.buildingServiceDialog = true;
          break;
      }
    },
    handleLeaseholdMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_leasehold':
          this.addLeaseholdDialog = true;
          break;
        case 'cm.edit_leasehold':
          if (args.data[0].prim_uid) {
            this.editLeaseHold(args.data[0].prim_uid);
          }
          break;

        case 'cm.delete_leasehold':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'leasehold';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.leaseholdGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;
      }
    },
    editLeaseHold(uid) {
      let getData = {}
      getData.property_uid = this.property_uid;
      getData.attribute = 'leasehold';
      getData.uid = uid;
      this.pro_id = uid;
      this.service.getModalData(getData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success && response.data) {
          this.editModalSaveData.leasehold = response.data;
          this.editLeaseholdDialog = true;
        }
      });

    },
    isDisabledLuFParcelReferenceCheckbox(parcelIndex) {
      let isDisabled =
          this.propertyData
          && this.propertyData.step2
          && typeof this.propertyData.step2.street === 'string'
          && this.propertyData.step2.street.trim() !== ''
          && typeof this.propertyData.step2.houseNumber === 'string'
          && this.propertyData.step2.houseNumber.trim() !== '';

      if (!isDisabled) {
        let currentLuF = undefined;
        if (this.addParcelsLandAndForestryDialog === true) {
          currentLuF = this.addModalSaveData;
        } else if (this.editParcelsLandAndForestryDialog === true) {
          currentLuF = this.editModalSaveData;
        }

        if (typeof currentLuF !== "undefined" &&
            typeof currentLuF.parcels === "object" &&
            typeof currentLuF.parcels[parcelIndex] === "object" &&
            typeof currentLuF.parcels[parcelIndex].isReference !== "undefined")
        {
          if (currentLuF.parcels[parcelIndex].isReference === false)
          {
            if (this.isReferenceParcelInfo.parcelslandandforestry_uid != 0) {
              if (typeof currentLuF.id === "undefined" ||
                  (typeof currentLuF.id !== "undefined" && currentLuF.id != this.isReferenceParcelInfo.parcelslandandforestry_uid)
              ) {
                isDisabled = true;
              }
            }
            if (!isDisabled) {
              let foundIndex = -1;
              for (const [key, parcel] of Object.entries(currentLuF.parcels)) {
                if (typeof parcel.isReference === 'boolean' && parcel.isReference === true) {
                  foundIndex = key;
                  break;
                }
              }
              if (foundIndex !== -1 && foundIndex != parcelIndex) {
                isDisabled = true;
              }
            }
          }
        }
      }

      return isDisabled;
    },
    tweakIsReferenceLuFParcels(data) {
      if (
        typeof this.propertyData.step2.street === 'string'
        && this.propertyData.step2.street.trim() !== ''
        && typeof this.propertyData.step2.houseNumber === 'string'
        && this.propertyData.step2.houseNumber.trim() !== ''
        && typeof data === 'object'
        && typeof data.parcels === 'object'
      ) {
        for (const [key, parcel] of Object.entries(data.parcels)) {
          if (typeof parcel.isReference === 'boolean' && parcel.isReference === true) {
            data.parcels[key].isReference = false;
          }
        }
      }
    },
    tweakLuFParcelsUsageTypeDisabled(data) {
      if (
          typeof data === 'object'
          && typeof data.parcels === 'object'
      ) {
        for (const [parcelIndex, parcel] of Object.entries(data.parcels)) {
          if (typeof data.parcels[parcelIndex].usageTypes === 'object') {
            for (const [itemIndex, itemUsageType] of Object.entries(data.parcels[parcelIndex].usageTypes)) {
              if (typeof itemUsageType.usageType === 'undefined' || isNaN(itemUsageType.usageType) || itemUsageType.usageType === '') {
                itemUsageType.usageType = '0';
              }
              this.checkEditUsageType(parcelIndex, itemIndex, itemUsageType.usageType);
            }
          }
        }
      }
    },
    handleParcelsLandAndForestryMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_parcelslandandforestry':
          this.resetAddModalData();
          this.addParcelsLandAndForestryDialog = true;
          break;
        case 'cm.edit_parcelslandandforestry':
          if (args.data[0].prim_uid) {
            let getData = {}
            getData.property_uid = this.property_uid;
            getData.attribute = 'parcelslandandforestry';
            getData.uid = args.data[0].prim_uid;
            this.pro_id = args.data[0].prim_uid;
            this.service.getModalData(getData, true).then((response) => {
              if (typeof response.success !== 'undefined' && response.success && response.data) {
                this.editModalSaveData.parcelsLandAndForestry = response.data;
                this.tweakLuFParcelsUsageTypeDisabled(this.editModalSaveData.parcelsLandAndForestry);
                this.tweakIsReferenceLuFParcels(this.editModalSaveData.parcelsLandAndForestry);
                this.propertyGemeindeFederalStateId = response.data.federalStateUid;
                this.editParcelsLandAndForestryDialog = true;
              }
            });

          }
          break;

        case 'cm.delete_parcelslandandforestry':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              this.deleteData.uids = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  this.deleteData.uids.push(args.data[i].prim_uid);
                }
              }
              this.deleteData.attribute = 'parcelslandandforestry';
              this.deleteData.action = 'delete';
              this.deleteData.property_uid = this.property_uid;
              this.service.saveModalData(this.deleteData, true).then((response) => {
                if (typeof response.success !== 'undefined' && response.success) {
                  this.triggerReload(this.parcelsLandAndForestryGridId);
                  this.$modal.visible = false;
                }
              });
            }
          });
          break;
        case 'cm.import_parcelslandandforestry':
          this.openImportDialog('luf');
          break;

      }
    },
    openImportDialog(type) {
      if(type === 'luf') {
        this.importModalLabel = this.$t('properties.import.info_land_and_forestry');
        this.importModalLabel2 = this.$t('properties.import.info_land_and_forestry_2');
        this.importModalLabel3 = this.$t('properties.import.info_land_and_forestry_3');
        this.importModalLink = this.$t('properties.import.link_land_and_forestry');
        this.importModalType = 'luf';
        this.importModal = true;
      } else if(type === 'animals') {
        this.importModalLabel = this.$t('properties.import.info_animals');
        this.importModalLabel2 = '';
        this.importModalLabel3 = '';
        this.importModalLink = this.$t('properties.import.link_animals');
        this.importModalType = 'animals';
        this.importModal = true;
      }
    },
    handleLufImport() {
      this.$loading.show();
      this.importModalData(this.importModalType);
    },
    handleParcelsLandAndForestryColClick(e) {
      if (parseInt(e.row.prim_uid) > 0) {
        if (e.row.prim_uid) {
          let getData = {}
          getData.property_uid = this.property_uid;
          getData.attribute = 'parcelslandandforestry';
          getData.uid = e.row.prim_uid;
          this.pro_id = e.row.prim_uid;
          this.service.getModalData(getData, true).then((response) => {
            if (typeof response.success !== 'undefined' && response.success && response.data) {
              this.editModalSaveData.parcelsLandAndForestry = response.data;
              this.tweakLuFParcelsUsageTypeDisabled(this.editModalSaveData.parcelsLandAndForestry);
              this.tweakIsReferenceLuFParcels(this.editModalSaveData.parcelsLandAndForestry);
              this.propertyGemeindeFederalStateId = response.data.federalStateUid;
              this.editParcelsLandAndForestryDialog = true;
            }
          });

        }
      }
    },
    handleParcelsLandAndForestryExtraDataLoaded(extraData) {
      this.isReferenceParcelInfo.parcelslandandforestry_uid =
          (typeof extraData !== 'undefined') && (typeof extraData.parcelslandandforestry_uid !== 'undefined') ? parseInt(extraData.parcelslandandforestry_uid, 10) : 0;
      this.isReferenceParcelInfo.parcelslandandforestry_parcel_uid =
          (typeof extraData !== 'undefined') && (typeof extraData.parcelslandandforestry_parcel_uid !== 'undefined') ? parseInt(extraData.parcelslandandforestry_parcel_uid, 10) : 0;
    },
    handleDeclarationMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_declaration':
          this.setClientId(this.propertyData.step1.clientUid);
          this.setPropertyId(this.property_uid);
          this.setFederalStateId(this.propertyData.step2.federalStateUid);
          this.$router.push({
            name: "Declaration",
            params: {
              'comp': 'AddDeclaration',
              'declarationNumber': this.propertyData.step2.reference,
              'declarationRelevantMarkedByUser': this.propertyData.declarationRelevantMarkedByUser,
              'nextDeclarationType': this.propertyData.nextDeclarationType,
              'nextDeclarationReferenceDate': this.propertyData.nextDeclarationReferenceDate
            }
          });
          break;
        case 'cm.edit_declaration':
          if (args.data[0].prim_uid) {
            this.$router.push("/declaration/edit?uid=" + args.data[0].prim_uid)
          }
          break;

        case 'cm.delete_declaration':
          this.$modal.show({
            headerText: this.$t('general.grid.delete_record'),
            text: this.$t('general.grid.delete_confirm'),
            showClose: true,
            canEscape: true,
            onConfirm: () => {
              let transmittedDeclarationIds = [];
              this.deleteData = [];
              if (args.data.length > 0) {
                for (let i = 0; i < args.data.length; i++) {
                  if (
                    args.data[i].elster_transferred_at !== ''
                    || (
                      args.data[i].is_declaration_without_submission === '1'
                      && args.data[i].has_submitted_elster_message === '1'
                    )
                  ) {
                    transmittedDeclarationIds.push(args.data[i].uid);
                  } else {
                    this.deleteData.push(args.data[i]);
                  }
                }
              }

              this.$api.trigger('declaration/delete', {
                  records: this.deleteData
                }).then((response) => {
                  this.$modal.visible = false;
                  if (transmittedDeclarationIds.length > 0) {
                    let transmittedMessage = this.$t('general.grid.cannot_delete_confirm').replace('%lastDeclarationUids%', transmittedDeclarationIds.join(", "));
                    this.$api.showToast(transmittedMessage, 'error');
                  }

                  if (response.data.success) {
                    this.triggerReload(this.declarationGridId);
                  } else {
                    if (transmittedDeclarationIds.length == 0) {
                      this.$api.showToast(response.data.message, 'error');
                    }
                  }
              });
            }
          });
          break;

        default:
          this.$emit('menu-clicked', args);
      }
    },
    handleDeclarationColClick(e) {
      if (e.field === 'uid' && parseInt(e.row.prim_uid) > 0) {
        this.$router.push("/declaration/edit?uid=" + parseInt(e.row.prim_uid));
      } else if (parseInt(e.row.prim_uid) > 0) {
        this.$router.push("/declaration/edit?uid=" + parseInt(e.row.prim_uid));
      }
    },
    addMoreAddModalGarageUndergroundExemption() {
      this.addModalSaveData.residential.garageUnderground.garageUndergroundExemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalGarageUndergroundBenefit() {
      this.addModalSaveData.residential.garageUnderground.garageUndergroundBenefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreAddModalApartmentsBelow60Exemption() {
      this.addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalApartmentsBelow60Benefit() {
      this.addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreAddModalApartmentsBetween60100Exemption() {
      this.addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalApartmentsBetween60100Benefit() {
      this.addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreAddModalApartmentsAbove100Exemption() {
      this.addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalApartmentsAbove100Benefit() {
      this.addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreAddModalApartmentsOtherExemption() {
      this.addModalSaveData.residential.apartmentsOther.apartmentsOtherExemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalApartmentsOtherBenefit() {
      this.addModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },


    addMoreAddModalUsableSpaces() {
      this.addModalSaveData.residential.usableSpaces.push({
        locationOfTheRoomsDesignation: '',
        usageType: '',
        totalSpace: '0',
        usableSpacesBenefit: [{
          benefit: '0',
          discountedArea: ''
        }],
        usableSpacesExemption: [{
          exemption: '0',
          taxFreeArea: ''
        }]
      });
    },
    addMoreAddModalUsableSpacesExemption(usableSpaceIndex) {
      this.addModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesExemption.push({
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreAddModalUsableSpacesBenefit(usableSpaceIndex) {
      this.addModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesBenefit.push({
        benefit: '0',
        discountedArea: ''
      });
    },
    handleDeleteAddModalGarageUndergroundExemption(index) {
      this.addModalSaveData.residential.garageUnderground.garageUndergroundExemption.splice(index, 1);
    },
    handleDeleteAddModalGarageUndergroundBenefit(index) {
      this.addModalSaveData.residential.garageUnderground.garageUndergroundBenefit.splice(index, 1);
    },
    handleDeleteAddModalApartmentsBelow60Exemption(index) {
      this.addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption.splice(index, 1);
    },
    handleDeleteAddModalApartmentsBelow60Benefit(index) {
      this.addModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit.splice(index, 1);
    },
    handleDeleteAddModalApartmentsBetween60100Exemption(index) {
      this.addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption.splice(index, 1);
    },
    handleDeleteAddModalApartmentsBetween60100Benefit(index) {
      this.addModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit.splice(index, 1);
    },
    handleDeleteAddModalApartmentsAbove100Exemption(index) {
      this.addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption.splice(index, 1);
    },
    handleDeleteAddModalApartmentsAbove100Benefit(index) {
      this.addModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit.splice(index, 1);
    },
    handleDeleteAddModalApartmentsOtherExemption(index) {
      this.addModalSaveData.residential.apartmentsOther.apartmentsOtherExemption.splice(index, 1);
    },
    handleDeleteAddModalApartmentsOtherBenefit(index) {
      this.addModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit.splice(index, 1);
    },
    handleDeleteAddModalUsableSpaces(index) {
      this.addModalSaveData.residential.usableSpaces.splice(index, 1);
    },
    handleDeleteAddModalUsableSpacesExemption(usableSpaceIndex, exemptionIndex) {
      this.addModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesExemption.splice(exemptionIndex, 1);
    },
    handleDeleteAddModalUsableSpacesBenefit(usableSpaceIndex, benefitIndex) {
      this.addModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesBenefit.splice(benefitIndex, 1);
    },
    addMoreEditModalGarageUndergroundExemption() {
      this.editModalSaveData.residential.garageUnderground.garageUndergroundExemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreEditModalGarageUndergroundBenefit() {
      this.editModalSaveData.residential.garageUnderground.garageUndergroundBenefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreEditModalApartmentsBelow60Exemption() {
      this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreEditModalApartmentsBelow60Benefit() {
      this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreEditModalApartmentsBetween60100Exemption() {
      this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreEditModalApartmentsBetween60100Benefit() {
      this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreEditModalApartmentsAbove100Exemption() {
      this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreEditModalApartmentsAbove100Benefit() {
      this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },
    addMoreEditModalApartmentsOtherExemption() {
      this.editModalSaveData.residential.apartmentsOther.apartmentsOtherExemption.push({
        locationOfTheRoomsDesignation: '',
        exemption: '0',
        taxFreeArea: ''
      });
    },
    addMoreEditModalApartmentsOtherBenefit() {
      this.editModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit.push({
        locationOfTheRoomsDesignation: '',
        benefit: '0',
        discountedArea: ''
      });
    },

    addMoreEditModalUsableSpaces() {
      this.editModalSaveData.residential.usableSpaces.push({
        locationOfTheRoomsDesignation: '',
        usageType: '',
        totalSpace: '0',
        usableSpacesBenefit: [{
          benefit: '0',
          discountedArea: ''
        }],
        usableSpacesExemption: [{
          exemption: '0',
          taxFreeArea: ''
        }]
      });
    },

    addMoreEditModalUsableSpacesExemption(usableSpaceIndex) {
      this.editModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesExemption.push({
        exemption: '0',
        taxFreeArea: ''
      });
    },

    addMoreEditModalUsableSpacesBenefit(usableSpaceIndex) {
      this.editModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesBenefit.push({
        benefit: '0',
        discountedArea: ''
      });
    },

    handleDeleteEditModalGarageUndergroundExemption(index) {
      this.editModalSaveData.residential.garageUnderground.garageUndergroundExemption.splice(index, 1);
    },
    handleDeleteEditModalGarageUndergroundBenefit(index) {
      this.editModalSaveData.residential.garageUnderground.garageUndergroundBenefit.splice(index, 1);
    },
    handleDeleteEditModalApartmentsBelow60Exemption(index) {
      this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Exemption.splice(index, 1);
    },
    handleDeleteEditModalApartmentsBelow60Benefit(index) {
      this.editModalSaveData.residential.apartmentsBelow60.apartmentsBelow60Benefit.splice(index, 1);
    },
    handleDeleteEditModalApartmentsBetween60100Exemption(index) {
      this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Exemption.splice(index, 1);
    },
    handleDeleteEditModalApartmentsBetween60100Benefit(index) {
      this.editModalSaveData.residential.apartmentsBetween60100.apartmentsBetween60100Benefit.splice(index, 1);
    },
    handleDeleteEditModalApartmentsAbove100Exemption(index) {
      this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Exemption.splice(index, 1);
    },
    handleDeleteEditModalApartmentsAbove100Benefit(index) {
      this.editModalSaveData.residential.apartmentsAbove100.apartmentsAbove100Benefit.splice(index, 1);
    },
    handleDeleteEditModalApartmentsOtherExemption(index) {
      this.editModalSaveData.residential.apartmentsOther.apartmentsOtherExemption.splice(index, 1);
    },
    handleDeleteEditModalApartmentsOtherBenefit(index) {
      this.editModalSaveData.residential.apartmentsOther.apartmentsOtherBenefit.splice(index, 1);
    },
    handleDeleteEditModalUsableSpaces(index) {
      this.editModalSaveData.residential.usableSpaces.splice(index, 1);
    },
    handleDeleteEditModalUsableSpacesExemption(usableSpaceIndex, exemptionIndex) {
      this.editModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesExemption.splice(exemptionIndex, 1);
    },
    handleDeleteEditModalUsableSpacesBenefit(usableSpaceIndex, benefitIndex) {
      this.editModalSaveData.residential.usableSpaces[usableSpaceIndex].usableSpacesBenefit.splice(benefitIndex, 1);
    },
    handleDeleteItem(index, itemIndex, type, action = null) {
      let key;
      let keyParcels;
      if (action === null) {
        if (type === "usage") {
          this.addModalSaveData.parcelsLandAndForestry.parcels[index].usageTypes.splice(itemIndex, 1);
        } else {
          this.addModalSaveData.parcelsLandAndForestry.parcels.splice(itemIndex, 1);
        }
      } else {
        if (type === "usage") {
          const objArrayUsageTypes = [];
          for (key of Object.keys(this.editModalSaveData.parcelsLandAndForestry.parcels[index].usageTypes)) {
            if (key !== itemIndex) {
              objArrayUsageTypes.push(this.editModalSaveData.parcelsLandAndForestry.parcels[index].usageTypes[key])
            }
          }
          this.editModalSaveData.parcelsLandAndForestry.parcels[index].usageTypes = objArrayUsageTypes;
        } else {
          const objArrayParcels = [];
          if (Object.keys(this.editModalSaveData.parcelsLandAndForestry.parcels).length === 1) {
            this.addItemParcelItem('edit');
          }
          for (keyParcels of Object.keys(this.editModalSaveData.parcelsLandAndForestry.parcels)) {
            if (parseInt(keyParcels) !== parseInt(itemIndex)) {
              objArrayParcels.push(this.editModalSaveData.parcelsLandAndForestry.parcels[parseInt(keyParcels)])
            }
          }
          this.editModalSaveData.parcelsLandAndForestry.parcels = objArrayParcels;
        }
      }
    },
    addItemParcelItem(action = null) {
      if (action == null) {
        this.addModalSaveData.parcelsLandAndForestry.parcels.push({
          corridor: '',
          counter: '',
          denominator: '',
          areaOfTheLand: '',
          district: '',
          districtNumber: '',
          isReference: false,
          usageTypes: [{
            usageType: '0',
            areaOfTheLand: '',
            yieldMeasurementNumber: '',
            areaOfFarmBuildings: '',
            flowRate: '',
            exemption: '',
            favoredArea: '',
            areaOfTheLandDisabled: false,
            yieldMeasurementNumberDisabled: true,
            areaOfFarmBuildingDisabled: true,
            flowRateDisabled: true,
            showSection: true
          }],
        });
      } else {
        const objParcels = [];
        for (let key of Object.keys(this.editModalSaveData.parcelsLandAndForestry.parcels)) {
          if (this.editModalSaveData.parcelsLandAndForestry.parcels[key] !== '') {
            objParcels.push(this.editModalSaveData.parcelsLandAndForestry.parcels[key])
          }
        }
        this.editModalSaveData.parcelsLandAndForestry.parcels = objParcels;
        this.editModalSaveData.parcelsLandAndForestry.parcels.push({
          corridor: '',
          counter: '',
          denominator: '',
          areaOfTheLand: '',
          district: '',
          districtNumber: '',
          showParcelSection: true,
          isReference: false,
          usageTypes: [{
            usageType: '0',
            areaOfTheLand: '',
            yieldMeasurementNumber: '',
            areaOfFarmBuildings: '',
            flowRate: '',
            exemption: '',
            favoredArea: '',
            areaOfTheLandDisabled: false,
            yieldMeasurementNumberDisabled: true,
            areaOfFarmBuildingDisabled: true,
            flowRateDisabled: true,
            showSection: true
          }],
        });
      }
    },
    addItemUsageTypeMore(parcelIndex, action = null) {
      if (action == null) {
        this.addModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes.push({
          usageType: '0',
          areaOfTheLand: '',
          yieldMeasurementNumber: '',
          areaOfFarmBuildings: '',
          flowRate: '',
          exemption: '0',
          favoredArea: '',
          areaOfTheLandDisabled: false,
          yieldMeasurementNumberDisabled: true,
          areaOfFarmBuildingDisabled: true,
          flowRateDisabled: true,
        });
      } else {
        const objUsageTypes = [];
        for (let key of Object.keys(this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes)) {
          if (this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[key] !== '') {
            objUsageTypes.push(this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[key])
          }
        }
        this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes = objUsageTypes;
        this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes.push({
          usageType: '0',
          areaOfTheLand: '',
          yieldMeasurementNumber: '',
          areaOfFarmBuildings: '',
          flowRate: '',
          exemption: '0',
          favoredArea: '',
          areaOfTheLandDisabled: false,
          yieldMeasurementNumberDisabled: true,
          areaOfFarmBuildingDisabled: true,
          flowRateDisabled: true,
          showSection: true
        });
      }
    },

    // Reset values of disabled fields
    resetValueOfDisabledFields(data) {
      let saveData = data;
      if (typeof saveData.parcelsLandAndForestry.parcels !== 'undefined') {
        if (typeof saveData.parcelsLandAndForestry.parcels === 'object') {
          saveData.parcelsLandAndForestry.parcels = this.$api.parse_object_data(saveData.parcelsLandAndForestry.parcels);
        }

        saveData.parcelsLandAndForestry.parcels = saveData.parcelsLandAndForestry.parcels.map((parcel) => {
          if (this.step9CorridorDisabled === true) {
            parcel.corridor = '';
          }

          if (this.step9LandRegisterSheetDisabled === true) {
            parcel.landRegisterSheet = '';
          }

          if (typeof parcel.usageTypes !== 'undefined') {
            if (typeof parcel.usageTypes === 'object') {
              parcel.usageTypes = this.$api.parse_object_data(parcel.usageTypes);
            }

            parcel.usageTypes = parcel.usageTypes.map((usageType) => {
              if (typeof usageType.areaOfFarmBuildingDisabled !== 'undefined' && usageType.areaOfFarmBuildingDisabled === true) {
                usageType.areaOfFarmBuildings = '';
              }

              if (typeof usageType.areaOfTheLandDisabled !== 'undefined' && usageType.areaOfTheLandDisabled === true) {
                usageType.areaOfTheLand = '';
              }

              if (typeof usageType.yieldMeasurementNumberDisabled !== 'undefined' && usageType.yieldMeasurementNumberDisabled === true) {
                usageType.yieldMeasurementNumber = '';
              }

              if (typeof usageType.flowRateDisabled !== 'undefined' && usageType.flowRateDisabled === true) {
                usageType.flowRate = '';
              }

              if (this.parcelsLandAndForestryExemptionDisabled === true) {
                usageType.exemption = '';
              }

              if (this.favoredAreaDisabled === true) {
                usageType.exemption_area = '';
              }

              return usageType;
            });
          }

          return parcel;
        });
      }

      return saveData;
    },
    closeAddParcelsLandAndForestryDialog() {
      this.addParcelsLandAndForestryDialog = false;
      this.resetAddModalData();
    },
    closeEditParcelsLandAndForestryDialog() {
      this.editParcelsLandAndForestryDialog = false;
      this.resetEditModalData();
    },
    addModalData(attribute) {
      if (this.isReadOnly) {
        return;
      }

      // Reset validation status
      for (let attr in this.addModalSaveErrors) {
        this.addModalSaveErrors[attr].invalid = false;
      }
      this.$v.$touch();

      if (attribute === 'parcel' && !this.validateParcel(this.addModalSaveData)) {
        return false;
      }

      if (attribute === 'owner' && !this.validateOwner(this.addModalSaveData.owner)) {
        return false;
      }

      if (attribute === 'nonresidential' && !this.validateNonResidential(this.addModalSaveData.nonResidentials)) {
        return false;
      }

      if (attribute === 'parcelslandandforestry') {
        if (this.addModalSaveData.parcelsLandAndForestry.federalStateUid === "0" || this.addModalSaveData.parcelsLandAndForestry.federalStateUid === "") {
          this.errors.federalStateUid.invalid = true;
          return; // if any of the validations fail, we don't move on
        } else {
          this.errors.federalStateUid.invalid = false;
        }
      }

      if (attribute === 'buildingpart') {
        if (this.addModalSaveData.buildingPart.livingSpaceDescription === "") {
          this.errors.livingSpaceDescription.invalid = true;
          return; // if any of the validations fail, we don't move on
        } else {
          this.errors.livingSpaceDescription.invalid = false;
        }
        if (this.addModalSaveData.buildingPart.livingSpaceArea === "" && this.addModalSaveData.buildingPart.usableSpaceArea === "") {
          this.errors.livingSpaceArea.invalid = true;
          return; // if any of the validations fail, we don't move on
        } else {
          this.errors.livingSpaceArea.invalid = false;
        }
      }

      if (attribute === 'parcel') {
        if (this.addModalSaveData.parcel.community !== '') {
          if (this.communityList.length > 0) {
            let matchedIndex = this.communityList.findIndex(o => o.code === this.addModalSaveData.parcel.community);
            if (matchedIndex < 0) {
              this.$api.showToast(this.$t('properties.community_error.detail_tab', {"current_field_value": this.addModalSaveData.parcel.community}), "error");
              return false;
            }
          } else {
            this.$api.showToast(this.$t('properties.community_error.detail_tab', {"current_field_value": this.addModalSaveData.parcel.community}), "error");
            return  false;
          }

          // Send federal state uid additionally, to validate in backend for unsaved property detail
          this.addModalSaveData.parcel.federalStateUid = this.propertyData.step2.federalStateUid;
        }

        if (this.corridorDisabled) {
          this.addModalSaveData.parcel.corridor = '';
        }
      }

      if (attribute === 'parcelslandandforestry') {
        if (this.addModalSaveData.parcelsLandAndForestry.community !== '') {
          if (this.communityListLandAndForestry.length > 0) {
            let matchedIndex = this.communityListLandAndForestry.findIndex(o => o.code === this.addModalSaveData.parcelsLandAndForestry.community);
            if (matchedIndex < 0) {
              this.$api.showToast(this.$t('properties.community_error.detail_tab', {"current_field_value": this.addModalSaveData.parcelsLandAndForestry.community}), "error");
              return false;
            }
          } else {
            this.$api.showToast(this.$t('properties.community_error.agriculture_tab', {"current_field_value": this.addModalSaveData.parcelsLandAndForestry.community}), "error");
            return  false;
          }
        }
      }

      this.addModalSaveData = this.resetValueOfDisabledFields(this.addModalSaveData);

      this.addModalSaveData.attribute = attribute;
      this.addModalSaveData.action = 'add';
      this.addModalSaveData.property_uid = this.property_uid;
      this.addModalSaveData.federal_state_uid = this.propertyData.step2.federalStateUid;
      this.addModalSaveData.step2_exemption = this.propertyData.step2.exemption;
      this.addModalSaveData.property_type = this.propertyData.step3.propertyType;

      this.service.saveModalData(this.addModalSaveData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {

          if (attribute === 'parcel') {
            this.addParcelDialog = false;
            this.triggerReload(this.parcelGridId);
            this.refreshSumOfArealand();
          } else if (attribute === 'owner') {
            this.addOwnerDialog = false;
            this.triggerReload(this.ownerGridId);
          } else if (attribute === 'buildingpart') {
            this.addBuildingPartDialog = false;
            this.triggerReload(this.buildingPartsGridId);
          } else if (attribute === 'residential') {
            this.addResidentialDialog = false;
            if (typeof response.mandatoryFields !== 'undefined' && typeof response.mandatoryFields.yearOfConstruction !== 'undefined') {
              this.step6FirstRowYearOfConstruction = response.mandatoryFields.yearOfConstruction;
              this.initMandatoryFields(this.taxModel);
            }
            this.triggerReload(this.residentialGridId);
          } else if (attribute === 'nonresidential') {
            this.closeNonResidential('add');
            this.triggerReload(this.nonresidentialGridId);
          } else if (attribute === 'leasehold') {
            this.addLeaseholdDialog = false;
            this.triggerReload(this.leaseholdGridId);
          } else if (attribute === 'parcelslandandforestry') {
            this.addParcelsLandAndForestryDialog = false;
            this.triggerReload(this.parcelsLandAndForestryGridId);
          } else if (attribute === 'declaration') {
            this.addDeclarationDialog = false;
            this.triggerReload(this.declarationGridId);
          }
          this.setPropertyDataChanged(true);
          this.resetAddModalData();
        }
      })
    },
    checkUsageType(parcelIndex, itemIndex, val) {
      this.addModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].areaOfTheLandDisabled = !this.isInArray(val, this.validAreaOfTheLand);
      this.addModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].yieldMeasurementNumberDisabled = !this.isInArray(val, this.validYieldMeasurementNumber);
      this.addModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].areaOfFarmBuildingDisabled = !this.isInArray(val, this.validAreaOfFarmBuilding);
      this.addModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].flowRateDisabled = !this.isInArray(val, this.validFlowRate);
    },
    checkEditUsageType(parcelIndex, itemIndex, val) {
      this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].areaOfTheLandDisabled = !this.isInArray(val, this.validAreaOfTheLand);
      this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].yieldMeasurementNumberDisabled = !this.isInArray(val, this.validYieldMeasurementNumber);
      this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].areaOfFarmBuildingDisabled = !this.isInArray(val, this.validAreaOfFarmBuilding);
      this.editModalSaveData.parcelsLandAndForestry.parcels[parcelIndex].usageTypes[itemIndex].flowRateDisabled = !this.isInArray(val, this.validFlowRate);
    },
    validateNonResidential({yearOfConstruction, buildingType, areaOfTheLand}, isEdit = false) {
      let yearOfConstructionInvalid = (yearOfConstruction === '');
      let buildingTypeInvalid = (buildingType === '');
      let grossAreaInvalid = (areaOfTheLand === '');

      let hasError = false;
      hasError = yearOfConstructionInvalid;
      hasError = (hasError || buildingTypeInvalid);
      hasError = (hasError || grossAreaInvalid);

      if (isEdit) {
        this.editModalSaveErrors.nonResidentialYearOfConstruction.invalid = yearOfConstructionInvalid;
        this.editModalSaveErrors.nonResidentialBuildingType.invalid = buildingTypeInvalid;
        this.editModalSaveErrors.nonResidentialAreaOfTheLand.invalid = grossAreaInvalid;
      } else {
        this.addModalSaveErrors.nonResidentialYearOfConstruction.invalid = yearOfConstructionInvalid;
        this.addModalSaveErrors.nonResidentialBuildingType.invalid = buildingTypeInvalid;
        this.addModalSaveErrors.nonResidentialAreaOfTheLand.invalid = grossAreaInvalid;
      }

      return !hasError;
    },
    validateOwner(owner, isEdit = false) {
      let firstNameInvalid = (owner.firstName === '');

      let hasError = false;
      hasError = firstNameInvalid;

      if (isEdit) {
        this.editModalSaveErrors.firstName.invalid = firstNameInvalid;
      } else {
        this.addModalSaveErrors.firstName.invalid = firstNameInvalid;
      }

      return !hasError;
    },
    validateParcel(SaveData, isEdit = false) {
      let parcelInvalid = (SaveData.parcel.parcel === '');
      let hasError = parcelInvalid;

      if (
        (!isEdit && this.addModalParcelLengthError)
        || (isEdit && this.editModalParcelLengthError)
      ) {
        hasError = true;
        this.$api.showToast(this.$t('properties.parcel_length_error_popup'), 'error');
      }

      if (isEdit) {
        this.editModalSaveErrors.parcel.invalid = parcelInvalid;
      } else {
        this.addModalSaveErrors.parcel.invalid = parcelInvalid;
      }

      return !hasError;
    },
    editModalData(attribute) {
      if (this.isReadOnly) {
        return;
      }

      // Reset validation status
      for (let attr in this.editModalSaveErrors) {
        this.editModalSaveErrors[attr].invalid = false;
      }
      this.$v.$touch();

      if (attribute === 'buildingpart') {
        if (this.editModalSaveData.buildingPart.livingSpaceDescription === "") {
          this.errors.livingSpaceDescription.invalid = true;
          return; // if any of the validations fail, we don't move on
        } else {
          this.errors.livingSpaceDescription.invalid = false;
        }
        if (this.editModalSaveData.buildingPart.livingSpaceArea === "" && this.editModalSaveData.buildingPart.usableSpaceArea === "") {
          this.errors.livingSpaceArea.invalid = true;
          return; // if any of the validations fail, we don't move on
        } else {
          this.errors.livingSpaceArea.invalid = false;
        }
      }

      if (attribute === 'parcel' && !this.validateParcel(this.editModalSaveData, true)) {
        return false;
      }

      if (attribute === 'owner' && !this.validateOwner(this.editModalSaveData.owner)) {
        return false;
      }

      if (attribute === 'nonresidential' && !this.validateNonResidential(this.editModalSaveData.nonResidentials, true)) {
        return false;
      }

      if (attribute === 'parcel') {
        if (this.editModalSaveData.parcel.community !== '') {
          if (this.communityList.length > 0) {
            let matchedIndex = this.communityList.findIndex(o => o.code === this.editModalSaveData.parcel.community);
            if (matchedIndex < 0) {
              this.$api.showToast(this.$t('properties.community_error.detail_tab', {"current_field_value": this.editModalSaveData.parcel.community}), "error");

              return false;
            }
          } else {
            this.$api.showToast(this.$t('properties.community_error.detail_tab', {"current_field_value": this.editModalSaveData.parcel.community}), "error");

            return  false;
          }

          // Send federal state uid additionally, to validate in backend for unsaved property detail
          this.editModalSaveData.parcel.federalStateUid = this.propertyData.step2.federalStateUid;
        }

        if (this.corridorDisabled) {
          this.editModalSaveData.parcel.corridor = '';
        }
      }

      if (attribute === 'parcelslandandforestry') {
        if (this.editModalSaveData.parcelsLandAndForestry.community !== '') {
          if (this.communityListLandAndForestry.length > 0) {
            let matchedIndex = this.communityListLandAndForestry.findIndex(o => o.code === this.editModalSaveData.parcelsLandAndForestry.community);
            if (matchedIndex < 0) {
              this.$api.showToast(this.$t('properties.community_error.agriculture_tab', {"current_field_value": this.editModalSaveData.parcelsLandAndForestry.community}), "error");
              return false;
            }
          } else {
            this.$api.showToast(this.$t('properties.community_error.agriculture_tab', {"current_field_value": this.editModalSaveData.parcelsLandAndForestry.community}), "error");
            return  false;
          }
        }
      }

      this.editModalSaveData = this.resetValueOfDisabledFields(this.editModalSaveData);

      this.editModalSaveData.attribute = attribute;
      this.editModalSaveData.action = 'edit';
      this.editModalSaveData.property_uid = this.property_uid;
      this.editModalSaveData.id = this.pro_id;
      this.editModalSaveData.federal_state_uid = this.propertyData.step2.federalStateUid;
      this.editModalSaveData.step2_exemption = this.propertyData.step2.exemption;
      this.editModalSaveData.property_type = this.propertyData.step3.propertyType;

      this.service.saveModalData(this.editModalSaveData, true).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {

          if (attribute === 'parcel') {
            this.editParcelDialog = false;
            this.triggerReload(this.parcelGridId);
            this.refreshSumOfArealand();
          } else if (attribute === 'owner') {
            this.editOwnerDialog = false;
            this.triggerReload(this.ownerGridId);
          } else if (attribute === 'buildingpart') {
            this.editBuildingPartDialog = false;
            this.triggerReload(this.buildingPartsGridId);
          } else if (attribute === 'residential') {
            this.editResidentialDialog = false;
            if (typeof response.mandatoryFields !== 'undefined' && typeof response.mandatoryFields.yearOfConstruction !== 'undefined') {
              this.step6FirstRowYearOfConstruction = response.mandatoryFields.yearOfConstruction;
              this.initMandatoryFields(this.taxModel);
            }
            this.triggerReload(this.residentialGridId);
          } else if (attribute === 'nonresidential') {
            this.closeNonResidential('edit')
            this.triggerReload(this.nonresidentialGridId);
          } else if (attribute === 'leasehold') {
            this.editLeaseholdDialog = false;
            this.triggerReload(this.leaseholdGridId);
          } else if (attribute === 'parcelslandandforestry') {
            if (this.editModalSaveData.parcelsLandAndForestry.federalStateUid === "0" || this.editModalSaveData.parcelsLandAndForestry.federalStateUid === "") {
              this.errors.federalStateUid.invalid = true;
              return; // if any of the validations fail, we don't move on
            } else {
              this.errors.federalStateUid.invalid = false;
            }
            this.editParcelsLandAndForestryDialog = false;
            this.triggerReload(this.parcelsLandAndForestryGridId);
          } else if (attribute === 'declaration') {
            this.editDeclarationDialog = false;
            this.triggerReload(this.declarationGridId);
          }

          this.resetEditModalData();
        }
      })
    },
    resetAddModalData() {
      this.addModalSaveData = this.getDefaultAddModal();
    },
    async uploadFile(attribute) {
      this.$loading.show();
      if (await this.service.csvUpload(this.selectedFile, this.propertyData.step1.clientUid)) {
        if (this.excelData.length > process.env.VUE_APP_CLIENT_IMPORT_LIMIT) {
          this.$loading.hide();
          if (attribute === 'parcel') {
            this.importParcelDialog = false;
          }
          this.$toast.error(this.$t('clients.import.limit_error'));
        } else {
          this.setImportData(this.excelData);
          this.setImportAttr(attribute);
          this.setPropertyUid(this.property_uid);

          let propertyService = new PropertyService();
          propertyService.csvImport(this.propertyData.step1.clientUid).then((response) => {
            if(response.data.success && response.data.jobId) {
              setTimeout(() => {
                this.checkImportStatus(response.data.jobId);
              }, 30000);
            } else {
              this.$loading.hide();
              if (attribute === 'parcel') {
                this.importParcelDialog = false;
              }
              this.$api.showToast(response.data.message ? response.data.message : response.data.error_message, "error");
            }
          }).catch((error) => {
            this.$loading.hide();
            if (attribute === 'parcel') {
              this.importParcelDialog = false;
            }
            this.$api.showToast(error, "error")
          });
        }
      } else {
        this.$loading.hide();
      }
    },
    async importFile(attribute) {
      if (this.selectedFile.length > process.env.VUE_APP_CLIENT_IMPORT_LIMIT) {
        this.$loading.hide();
        this.importModal = false;
        this.$toast.error(this.$t('clients.import.limit_error'));
      } else {
        this.$loading.show();
        let file_name = 'property_data.xlsx';
        if(this.fileExtension === 'csv') {
          file_name = 'property_data.csv';
        } else if(this.fileExtension === 'xls') {
          file_name = 'property_data.xls';
        }
        this.s3Service.upload({
          data: this.excelData,
          fileName: file_name,
          prefix: 'properties/',
          contentType: this.excelData.type
        }).then((res) => {
          if (res.success) {
            this.setImportAttr(attribute);
            this.setPropertyUid(this.property_uid);

            let propertyService = new PropertyService();
            this.stopObserver();
            propertyService.csvImport(this.propertyData.step1.clientUid, res.s3Path).then((response) => {
              this.handleImportResponse(response);
            }).catch((error) => {
              this.$loading.hide();
              this.importModal = false;
              this.$api.showToast(error, "error")
            });
          } else {
            this.$loading.hide();
            this.$api.showToast(this.$t('general.errors.unknownError'), "error")
          }
        }).catch(() => {
          this.$loading.hide();
          this.$api.showToast(this.$t('general.errors.unknownError'), "error")
        });
      }
    },
    closeImportErrorDlg() {
      this.showImportError = false;
      this.importErrors = [];
    },
    handleImportResponse(response) {
      if (response.data.success && response.data.jobId) {
        setTimeout(() => {
          this.checkImportStatus(response.data.jobId);
        }, 30000);
      } else {
        this.$loading.hide();
        this.importModal = false;
        this.$api.showToast(response.data.message ? response.data.message : response.data.error_message, "error");
      }
    },
    checkImportStatus(jobId) {
      let attribute = this.getImportAttr();
      this.$api.trigger('property/check_import_status', {"jobId": jobId}).then((response) => {
        if(response.data.success) {
          if(response.data.status === 'completed') {
            this.$loading.hide();
            let importResult = response.data.importResult;
            if(importResult.success) {
              this.setImportData([]);
              if (attribute === 'parcel') {
                this.importParcelDialog = false;
                this.triggerReload(this.parcelGridId);
              } else if (attribute === 'luf') {
                this.importModal = false;
                this.triggerReload(this.parcelsLandAndForestryGridId);
                this.getPropertyData();
                this.$toast.success(this.$t('properties.parcel.luf_imported_successfully').toString())
              } else if (attribute === 'animals') {
                this.importModal = false;
                this.getPropertyData();
                this.$toast.success(this.$t('properties.parcel.luf_imported_successfully').toString())
              }
            } else if (importResult.batchErrors) {
              this.showImportError = true;
              this.importErrors = this.$api.parse_object_data(importResult.batchErrors);
              this.triggerReload('property_import_errors');
            } else if (importResult.importErrors) {
              let errorMessage = '';

              let invalidRowsObj = importResult.importErrors;
              if (typeof invalidRowsObj === 'object') {
                for (let key in invalidRowsObj) {
                  if (invalidRowsObj.hasOwnProperty(key)) {
                    let valObj = invalidRowsObj[key];
                    if (typeof valObj === 'object') {
                      let valStr = '';
                      let valArray = [];
                      for (let key1 in valObj) {
                        if (valObj.hasOwnProperty(key1)) {
                          valArray.push(valObj[key1]);
                        }
                      }

                      if (valArray.length > 0) {
                        valStr = valArray.join(", ");
                      }

                      if (valStr !== '') {
                        errorMessage += this.$t('properties.excel_import_invalid').replace('%fieldName%', valStr).replace('%rowNumber%', parseInt(key) + 2) + "\n";
                      }
                    }
                  }
                }
              }

              if (errorMessage !== '') {
                this.$api.showToast(errorMessage, "error");
              }
            } else if(typeof importResult.error_message === 'string') {
              this.$api.showToast(importResult.error_message, "error");
            } else if(typeof importResult.error_message === 'object') {
              if (importResult.sheet_count == 1) {
                this.setImportData(this.excelData[0]);
                this.setExcelError(importResult.error_message);
                if (attribute === 'parcel') {
                  this.importParcelDialog = false;
                  this.triggerReload(this.parcelGridId);
                }
              } else {
                let message = this.$t('properties.excel_upload_error');
                Object.keys(importResult.error_message).map(function (value, key) {
                  message = message + " " + this.$t('properties.sheet') + ": " + importResult.error_message[key].title + " " + this.$t('properties.column') + ": " + importResult.error_message[key].unmatched_header;
                });
                this.$api.showToast(message, "error");
              }
            }
          } else {
            setTimeout(() => {
              this.checkImportStatus(jobId);
            }, 30000);
          }
        } else {
          this.$loading.hide();
          this.$api.showToast(error, "error")
        }
      })
    },
    fileSelect(e) {
      if (e.files.length > 0) {
        let file = e.files[0];
        this.excelData = file;
        let fileName = file.name;
        this.selectedFile = e.files;
        this.fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
      }
    },
    importModalData(attribute) {
      if (this.selectedFile.length > 0) {
        this.importFile(attribute);
      } else {
        this.$toast.error(this.$t('clients.import.choose_file'));
      }
    },
    checkTabExistence(slug) {
      let index = this.availableTabs.findIndex(item => item.slug === slug);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    },
    toggleClick(args) {
      /**
       * @param {{version_number:string}} args
       */
      let index = this.protocols.findIndex(item => item.version_number === args.version_number)  ///need a unique id to match this condition may fail
      this.protocols[index].showDetail = !this.protocols[index].showDetail;
    },
    editClient() {
      if (this.propertyData.step1.clientUid) {
        this.$router.push('/client/edit?uid=' + this.propertyData.step1.clientUid)
      }
    },
    saveChip() {
      const {chips, currentInput, set} = this;
      ((set && chips.indexOf(currentInput) === -1) || !set) && chips.push(currentInput);
      this.currentInput = '';
    },
    getInitials(chip) {
      let matches = chip.match(/\b(\w)/g);
      return matches.join('')
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    backspaceDelete({which}) {
      which === 8 && this.currentInput === '' && this.chips.splice(this.chips.length - 1);
    },
    setMaxFieldLength(federalStateUid) {
      const arr = ['2', '6', '7', '9'];
      this.maxlength = arr.includes(federalStateUid) ? 9 : 15;
      this.maxFloatField = arr.includes(federalStateUid) ? 12 : 11;
      this.decimalNumber = arr.includes(federalStateUid) ? 5 : 4;
    },
    addMoreBenefit(modalType, action = null) {
      if (action === null) {
        if (modalType === "buildingPart") {
          this.addModalSaveData.buildingPart.benefits.push({
            benefitDescription: '',
            benefitLivingSpaceArea: '',
            benefitUsableSpaceArea: '',
            benefit: '0',
            showSection: true
          });
        } else {
          this.addModalSaveData.nonResidentials.benefits.push({
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
            showSection: true
          });
        }
      } else {
        if (modalType === "buildingPart") {
          this.editModalSaveData.buildingPart.benefits.push({
            benefitDescription: '',
            benefitLivingSpaceArea: '',
            benefitUsableSpaceArea: '',
            benefit: '0',
            showSection: true
          });
        } else {
          this.editModalSaveData.nonResidentials.benefits.push({
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
            showSection: true
          });
        }
      }
    },
    addMoreExemption(modalType, action = null) {
      if (action === null) {
        if (modalType === "buildingPart") {
          this.addModalSaveData.buildingPart.exemptions.push({
            exemptionDescription: '',
            exemptionLivingSpaceArea: '',
            exemptionUsableSpaceArea: '',
            exemption: '0'
          });
        } else {
          this.addModalSaveData.nonResidentials.exemptions.push({
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          });
        }
      } else {
        if (modalType === "buildingPart") {
          this.editModalSaveData.buildingPart.exemptions.push({
            exemptionDescription: '',
            exemptionLivingSpaceArea: '',
            exemptionUsableSpaceArea: '',
            exemption: '0',
            showSection: true
          });
        } else {
          this.editModalSaveData.nonResidentials.exemptions.push({
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
            showSection: true
          });
        }
      }
    },
    handleDeleteBuildingPart(itemIndex, type, action = null) {
      if (action === null) {
        if (type === "exemption") {
          this.addModalSaveData.buildingPart.exemptions.splice(itemIndex, 1);
        } else {
          this.addModalSaveData.buildingPart.benefits.splice(itemIndex, 1);
        }
      } else {
        if (type === "exemption") {
          this.editModalSaveData.buildingPart.exemptions.splice(itemIndex, 1);
        } else {
          this.editModalSaveData.buildingPart.benefits.splice(itemIndex, 1);
        }
      }
    },
    handleDeleteNonRes(itemIndex, type, action = null) {
      if (action === null) {
        if (type === "exemption") {
          this.addModalSaveData.nonResidentials.exemptions.splice(itemIndex, 1);
        } else {
          this.addModalSaveData.nonResidentials.benefits.splice(itemIndex, 1);
        }
      } else {
        if (type === "exemption") {
          this.editModalSaveData.nonResidentials.exemptions.splice(itemIndex, 1);
        } else {
          this.editModalSaveData.nonResidentials.benefits.splice(itemIndex, 1);
        }
      }
    },
    handleDelete(itemIndex, type, modalType, action = null) {
      if (action === null) {
        if (type === "exemption") {
          if (modalType === "buildingPart") {
            this.addModalSaveData.buildingPart.exemptions.splice(itemIndex, 1);
          } else {
            this.addModalSaveData.nonResidentials.exemptions.splice(itemIndex, 1);
          }
        } else {
          if (modalType === "buildingPart") {
            this.addModalSaveData.buildingPart.benefits.splice(itemIndex, 1);
          } else {
            this.addModalSaveData.nonResidentials.benefits.splice(itemIndex, 1);
          }
        }
      } else {
        if (type === "exemption") {
          //var objArrayExemptions = [];
          if (modalType === "buildingPart") {
            for (let keybuildingPart of Object.keys(this.editModalSaveData.buildingPart.exemptions)) {
              if (keybuildingPart === itemIndex) {
                delete this.editModalSaveData.buildingPart.exemptions[keybuildingPart];
              }
            }
          } else {
            for (let keynonResidentials of Object.keys(this.editModalSaveData.nonResidentials.exemptions)) {
              if (keynonResidentials === itemIndex) {
                delete this.editModalSaveData.nonResidentials.exemptions[keynonResidentials];
              }
            }
          }
        } else {
          //const objArrayBenefits = [];
          if (modalType === "buildingPart") {
            for (let keybuildingPart2 of Object.keys(this.editModalSaveData.buildingPart.benefits)) {
              if (keybuildingPart2 === itemIndex) {
                delete this.editModalSaveData.buildingPart.benefits[keybuildingPart2];
              }
            }
          } else {
            for (let keynonResidentials of Object.keys(this.editModalSaveData.nonResidentials.benefits)) {
              if (keynonResidentials === itemIndex) {
                delete this.editModalSaveData.nonResidentials.benefits[keynonResidentials];
              }
            }
          }
        }
      }
    },
    isInArray(value, array) {
      return array.indexOf(value) > -1;
    },
    copyAddressFromClient(checked) {
      if (checked) {
        if (this.propertyData.step1.clientUid) {
          let clientData = this.getClientData();
          if (clientData) {
            this.propertyData.step4.name = '';
            if (clientData.clientType === 'private') {
              this.propertyData.step4.name = clientData.private.first_name + ' ' + clientData.private.last_name;
            } else if (clientData.clientType === 'business') {
              this.propertyData.step4.name = clientData.business.company_name;
            }
            this.propertyData.step4.street = clientData.contact.street;
            this.propertyData.step4.houseNumber = clientData.contact.house_number;
            this.propertyData.step4.zip = clientData.contact.postcode;
            this.propertyData.step4.postbox = clientData.contact.po_box;
            this.propertyData.step4.city = clientData.contact.city;
            this.propertyData.step4.countryUid = clientData.contact.country;
            this.propertyData.step4.salutation = clientData.private.salutation;
          }
        }
      }
    },
    fillAddressFromClient() {
      if (this.propertyData.step1.clientUid) {
        let clientData = this.getClientData();
        if (clientData) {
          this.addModalSaveData.owner.salutation = '0';
          let matchedSalutation = this.$t('properties.step4.client_salutation_mapping').filter(o => o.client_code === (clientData.clientType === 'private' ? clientData.private.salutation : clientData.business.salutation));
          if(matchedSalutation.length > 0) {
            this.addModalSaveData.owner.salutation = matchedSalutation[0].code;
          }
          if (clientData.clientType === 'private') {
            this.addModalSaveData.owner.title = clientData.private.title;
            this.addModalSaveData.owner.dateOfBirth = '';
            if (clientData.private.dob) {
              let d = new Date(clientData.private.dob);
              if (d instanceof Date && !isNaN(d)) {
                this.addModalSaveData.owner.dateOfBirth = d;
              }
            }
            this.addModalSaveData.owner.firstName = clientData.private.first_name;
            this.addModalSaveData.owner.lastName = clientData.private.last_name;
            this.addModalSaveData.owner.taxNumber = clientData.private.tax_number;
            this.addModalSaveData.owner.taxIdentificationNumber = clientData.private.client_number;
          } else if (clientData.clientType === 'business') {
            this.addModalSaveData.owner.firstName = clientData.business.company_name;
            this.addModalSaveData.owner.taxNumber = clientData.business.tax_number;
          }

          this.addModalSaveData.owner.street = clientData.contact.street;
          this.addModalSaveData.owner.houseNumber = clientData.contact.house_number;
          this.addModalSaveData.owner.phone = clientData.contact.phone;
          this.addModalSaveData.owner.zip = clientData.contact.postcode;
          this.addModalSaveData.owner.postbox = clientData.contact.po_box;
          this.addModalSaveData.owner.city = clientData.contact.city;
          this.addModalSaveData.owner.countryUid = clientData.contact.country;
        }
      }
    },
    fillAddressFromClientLh() {
      if (this.propertyData.step1.clientUid) {
        let clientData = this.getClientData();
        if (clientData) {
          if (clientData.clientType === 'private') {
            this.addModalSaveData.leasehold.formOfAddress = clientData.private.salutation;
            this.addModalSaveData.leasehold.titleAcademicDegree = clientData.private.title;
            this.addModalSaveData.leasehold.firstName = clientData.private.first_name;
            this.addModalSaveData.leasehold.lastName = clientData.private.last_name;
          } else if (clientData.clientType === 'business') {
            this.addModalSaveData.leasehold.salutation = clientData.business.salutation;
            this.addModalSaveData.leasehold.firstName = clientData.business.company_name;
          }

          this.addModalSaveData.leasehold.street = clientData.contact.street;
          this.addModalSaveData.leasehold.houseNumber = clientData.contact.house_number;
          this.addModalSaveData.leasehold.telephoneNumber = clientData.contact.phone;
          this.addModalSaveData.leasehold.zip = clientData.contact.postcode;
          this.addModalSaveData.leasehold.postbox = clientData.contact.po_box;
          this.addModalSaveData.leasehold.city = clientData.contact.city;
          this.addModalSaveData.leasehold.countryUid = clientData.contact.country;
        }
      }
    },
    openSkenDataModal() {
      if (parseInt(this.propertyData.step2.federalStateUid) === 1) {
        this.showFeatureForBWModal = true;
      } else {
        this.toggleDataAcquisitionModal = !this.toggleDataAcquisitionModal;
      }
    },
    async isValidAccessforSkendata() {
      const isTrialAccount = this.$store.state.user.currentUser.account_pay_type === 'trial';
      const isDataAcquisitionActive = parseInt(this.$store.state.user.currentUser.settings.data_acquisition) === 1;
      this.editSkendataParcelId = 0;

      if (isTrialAccount) {
        this.toggleDataAcquisitionModal = false;
        this.toggleDataAcquisitionAboRequired = true;
        return false;
      }

      if (!isDataAcquisitionActive) {
        this.toggleDataAcquisitionModal = false;
        this.toggleDataAcquisitionModalServiceNotActive = true;
        return false;
      }

      const dataAcquisitionData = await this.userService.getDataAcquisitionData();
      if (dataAcquisitionData === []) {
        return false;
      }

      const userCostsLimit =  parseFloat(this.$store.getters['user/getCurrentUser'].settings.data_acquisition_limit_amount.replace(',','.'));
      this.userLimitAmount =  new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(userCostsLimit);
      const isCostsLimitActive = parseInt(this.$store.state.user.currentUser.settings.data_acquisition_limit) === 1;
      const userMonthCosts = parseInt(dataAcquisitionData.total_costs);

      if (isCostsLimitActive && (userCostsLimit <= userMonthCosts)) {
        this.toggleDataAcquisitionModalOverLimit = true;
        this.toggleDataAcquisitionModal = false;

        return  false;
      }

      return true;
    },
    async confirmOpenDataAacquisitionAPI() {
      if (await this.isValidAccessforSkendata() === true) {
        let address = '';
        let property_uid = 0;
        let user_identifier = 0;
        let account_uid = 0;
        let user_uid = 0;
        let parcel_id = this.editSkendataParcelId;
        let process_id = '';

        if (this.$route.fullPath.includes('uid')) {
          property_uid = this.$route.query.uid;
          address = this.propertyData.step2.street + ' ' +
              this.propertyData.step2.houseNumber + ' ' +
              this.propertyData.step2.zip + ' ' +
              this.propertyData.step2.city;

          account_uid = this.$store.state.user.currentUser.account_uid;
          user_uid = this.$store.state.user.currentUser.id;
          user_identifier = account_uid + user_uid + property_uid;

          // get
          this.$api.trigger(
              'property/skendata_get_process',
              {
                'property_uid': property_uid,
                'account_uid': account_uid,
                'user_uid': user_uid
                //'parcel_id': parcel_id
              }
          ).then((response) => {
            if (response) {
              if (response.data.success) {
                process_id = response.data.process_id;
                user_identifier = response.data.user_id;
              }
              if (address.toString() !== '' && property_uid > 0 && user_identifier > 0) {
                this.$api.trigger(
                    'skendata/fulfillment',
                    {
                      'property_uid': property_uid,
                      'address': address,
                      'user_identifier': user_identifier,
                      'parcel_id': parcel_id,
                      'process_id': process_id
                    }
                )
                  .then((response) => {
                    if (response) {
                      if (response.data.link) {
                        let fulfillment_link = response.data.link.toString();
                        window.open(fulfillment_link, '_self');
                        //window.open(fulfillment_link, '_blank');
                      }
                      if (response.data.error) {
                        this.$toast.error(response.data.error)
                      }
                    }
                  });
              }
            }
          });
        }
      }
    },
    sendSkendataToApi() {
      if (this.$route.query.fulfillment_id) {
        this.toggleDataAcquisitionLoader = true;
        let fulfillment_id = this.$route.query.fulfillment_id;
        let property_uid = this.$route.query.uid;

        let property_address = this.$store.state.property.data.step2.street + ' ' +
            this.$store.state.property.data.step2.houseNumber + ' ' +
            this.$store.state.property.data.step2.zip + ' ' +
            this.$store.state.property.data.step2.city;

        this.skenDataAddress = property_address;
        // get skendata fulfillment data and save skendata in gsd db
        this.$api.trigger(
            'skendata/save_fulfillment',
            {
              'property_uid': property_uid,
              'fulfillment_id': fulfillment_id
            }
        )
            .then((response) => {
              if (response) {
                /**
                 * @param {{error_key:string}} response.data
                 */
                if (response.data.success === false) {
                  if (response.data.error.includes("Client error")) {
                    // Action cancelled - nothing to do here
                    this.toggleDataAcquisitionLoader = false;
                  } else if (response.data.error_key) {
                    this.$toast.error(response.data.message);
                  }
                } else {
                  this.$api.trigger(
                      'property/save_skendata',
                      {
                        'skendata': response.data.skendata,
                        'account_uid': response.data.account_uid,
                        'user_uid': response.data.user_uid,
                        'data': response.data.data,
                        'address': property_address
                      }
                  ).then((response) => {
                    this.toggleDataAcquisitionLoader = false;
                    if (response.data.success) {
                      this.$toast.success(this.$t('properties.parcel.added_successfully').toString())
                      this.activeTab = 'property';
                      this.checkTabExistence('property');
                      this.$api.get_user();
                      this.startEdit();
                    } else {
                      this.$toast.error(response.data.message);
                    }
                  });
                }
              }
            });
      }
    },
    changeOwnershipStructure(val) {
      if (val !== parseInt(this.propertyData.step3.ownershipStructure)) {
        this.propertyData.step3.ownershipStructure = String(val);
      }
    },
    selectedOwnershipStructure(e) {
      if (e.code !== '0' && this.$refs.compDeviatingOwner) {
        this.$refs.compDeviatingOwner.hideDeviatingOwnerSection();
      }
    },
    handleElsterValidationResultDialogClose() {
      this.elsterValidationResultDialog = false;
    },
    showElsterValidationError() {
      if(this.elsterValidationErrors.length > 0 || this.elsterValidationHints.length > 0) {
        this.elsterValidationResultDialog = true;
      } else {
        this.$api.showToast(this.$t('general.errors.elsterValidationError'), "error")
      }
    },
    showSettingsNextDeclarationDialog() {
      this.nextDeclarationType = (this.propertyData.nextDeclarationType === '') ? '0' : this.propertyData.nextDeclarationType;
      this.nextDeclarationReferenceDate = this.propertyData.nextDeclarationReferenceDate;
      this.settingsNextDeclarationDialog = true;
    },

    furtherBuildingService() {
      window.open("https://www.gebaeudeflaechen.app/", "_blank");
    },

    useParcelAsReferenceParcelAddCheck(isCheck) {
      this.useParcelAsReferenceParcelAddChecked = !!isCheck;
    },

    useParcelAsReferenceParcelAddChange(itemParcel) {
      for (const key in this.addModalSaveData.parcelsLandAndForestry.parcels) {
        this.addModalSaveData.parcelsLandAndForestry.parcels[key].isReference = false;
      }

      if (this.useParcelAsReferenceParcelAddChecked) {
        for (const key in this.addModalSaveData.parcelsLandAndForestry.parcels) {
          if (this.addModalSaveData.parcelsLandAndForestry.parcels[key] === itemParcel) {
            this.addModalSaveData.parcelsLandAndForestry.parcels[key].isReference = true;
          }
        }
        this.useParcelAsReferenceParcelAddCheck(false);
      }
    },

    useParcelAsReferenceParcelEditCheck(isCheck) {
      this.useParcelAsReferenceParcelEditChecked = !!isCheck;
    },

    useParcelAsReferenceParcelEditChange(itemParcel) {
      for (const key in this.editModalSaveData.parcelsLandAndForestry.parcels) {
        this.editModalSaveData.parcelsLandAndForestry.parcels[key].isReference = false;
      }

      if (this.useParcelAsReferenceParcelEditChecked) {
        for (const key in this.editModalSaveData.parcelsLandAndForestry.parcels) {
          if (this.editModalSaveData.parcelsLandAndForestry.parcels[key] === itemParcel) {
            this.editModalSaveData.parcelsLandAndForestry.parcels[key].isReference = true;
          }
        }
        this.useParcelAsReferenceParcelEditCheck(false);
      }
    },

    selectParcelDistrictAdd(e) {
      this.selectedParcelDistrictObj = e;
    },
    changeParcelDistrictAdd(itemParcel) {
      if (this.selectedParcelDistrictObj) {
        for (const key in this.addModalSaveData.parcelsLandAndForestry.parcels) {
          if (this.addModalSaveData.parcelsLandAndForestry.parcels[key] === itemParcel) {
            this.addModalSaveData.parcelsLandAndForestry.parcels[key].district_number = this.selectedParcelDistrictObj.code;
          }
        }
        this.selectedParcelDistrictObj = null;
      }
    },

    selectParcelDistrictEdit(e) {
      this.selectedParcelDistrictObj = e;
    },
    changeParcelDistrictEdit(itemParcel) {
      if (this.selectedParcelDistrictObj) {
        for (const key in this.editModalSaveData.parcelsLandAndForestry.parcels) {
          if (this.editModalSaveData.parcelsLandAndForestry.parcels[key] === itemParcel) {
            this.editModalSaveData.parcelsLandAndForestry.parcels[key].district_number = this.selectedParcelDistrictObj.code;
          }
        }
        this.selectedParcelDistrictObj = null;
      }
    },

    showNextDeclarationTypeDialog() {
      this.nextDeclarationTypeDialog = true;
    },

    showCancelNextDeclarationTypeDialog() {
      this.cancelNextDeclarationTypeDialog = true;
    },

    archivePropertyModalShow() {
      this.$modal.show({
        text: this.$t('properties.archive_info_text'),
        canEscape: true,
        buttons: {
          ok: this.$t('general.yes'),
          cancel: this.$t('general.no'),
          delete: false
        },
        onConfirm: () => {
          this.$modal.visible = false;
          this.archiveProperty();
        }
      });
    },

    archiveProperty() {
      this.service.setArchiveProperty(this.property_uid, 1).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.propertyData.archived = 1;

          this.updateInitialPropertyJsonData();
        }
      });
    },

    restorePropertyModalShow() {
      this.$modal.show({
        text: this.$t('properties.restore_info_text'),
        canEscape: true,
        buttons: {
          ok: this.$t('general.yes'),
          cancel: this.$t('general.no'),
          delete: false
        },
        onConfirm: () => {
          this.$modal.visible = false;
          this.restoreProperty();
        }
      });
    },

    restoreProperty() {
      this.service.setArchiveProperty(this.property_uid, 0).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.propertyData.archived = 0;

          this.updateInitialPropertyJsonData();
        }
      });
    },

    selectNextDeclarationType() {
      if (this.nextDeclarationType === '1002') { // Zurechnungsfortschreibung
        if (this.lastDeclarationUid > 0) {
          this.$router.push('/declaration/edit?uid=' + this.lastDeclarationUid + '&redirect=attribution_perpetuation');
        }
      } else {
        this.service.selectNextDeclarationType(this.property_uid, this.nextDeclarationType).then((response) => {
          if (typeof response.success !== 'undefined' && response.success) {
            this.nextDeclarationTypeDialog = false;
            this.showNextDeclarationTypeBtn = false;
            this.showCancelNextDeclarationTypeBtn = true;

            this.propertyData.declarationRelevantMarkedByUser = 1;
            this.propertyData.nextDeclarationType = this.nextDeclarationType;

            this.updateInitialPropertyJsonData();

            this.$api.showToast(this.$t('properties.relevant.info-1'));
          }
        });
      }
    },
    settingsNextDeclaration() {
      this.service.settingsNextDeclaration(this.property_uid, this.nextDeclarationType, this.nextDeclarationReferenceDate)
        .then((response) => {
          if (typeof response.success !== 'undefined' && response.success) {
            this.settingsNextDeclarationDialog = false;
            this.propertyData.declarationRelevantMarkedByUser = 1;
            this.propertyData.nextDeclarationType = this.nextDeclarationType;
            this.propertyData.nextDeclarationReferenceDate = this.nextDeclarationReferenceDate;

            this.updateInitialPropertyJsonData();

            this.$api.showToast(this.$t('properties.relevant.info-1'));
          }
        });
    },
    settingsNextDeclarationAttributePerpetuation() {
      if (this.lastDeclarationUid > 0) {
        this.$router.push(`/declaration/edit?uid=${this.lastDeclarationUid}&redirect=attribution_perpetuation&property_uid=${this.property_uid}`);
      }
    },

    cancelNextDeclarationType() {
      this.service.cancelNextDeclarationType(this.property_uid).then((response) => {
        if (typeof response.success !== 'undefined' && response.success) {
          this.cancelNextDeclarationTypeDialog = false;
          this.showNextDeclarationTypeBtn = true;
          this.showCancelNextDeclarationTypeBtn = false;

          this.propertyData.declarationRelevantMarkedByUser = 0;
          this.propertyData.nextDeclarationType = '0';
          this.nextDeclarationType = '0';
          this.propertyData.nextDeclarationReferenceDate = '';
          this.nextDeclarationReferenceDate = '';

          this.updateInitialPropertyJsonData();

          this.$api.showToast(this.$t('properties.relevant.info-2'));
        }
      });
    },

    calculateAreaOfTheLandCivilDefenseUsageAndLiving(val, modal, field) {
      let areaOfTheLandCivilDefense = parseInt(this[modal].buildingPart.areaOfTheLandCivilDefense);
      areaOfTheLandCivilDefense = isNaN(areaOfTheLandCivilDefense) ? 0 : areaOfTheLandCivilDefense;

      let fieldValue = parseInt(val);
      fieldValue = isNaN(fieldValue) ? 0 : fieldValue;

      if (areaOfTheLandCivilDefense < fieldValue) {
        if (field === 'areaOfTheLandCivilDefenseLiving') {
          this[modal].buildingPart.areaOfTheLandCivilDefenseUsage = areaOfTheLandCivilDefense;
        }

        if (field === 'areaOfTheLandCivilDefenseUsage') {
          this[modal].buildingPart.areaOfTheLandCivilDefenseLiving = areaOfTheLandCivilDefense;
        }

        event.preventDefault();
      }

      let areaOfTheLandCivilDefenseUsageOrLiving = areaOfTheLandCivilDefense - fieldValue;
      if (areaOfTheLandCivilDefenseUsageOrLiving < 0) {
        areaOfTheLandCivilDefenseUsageOrLiving = 0;
      }

      this[modal].buildingPart[field] = areaOfTheLandCivilDefenseUsageOrLiving;
    },

    updateInitialPropertyJsonData() {
      let initialPropertyDataStringJson = JSON.parse(this.initialPropertyDataString);

      initialPropertyDataStringJson.declarationRelevantMarkedByUser = this.propertyData.declarationRelevantMarkedByUser;
      initialPropertyDataStringJson.nextDeclarationType = this.propertyData.nextDeclarationType;
      initialPropertyDataStringJson.nextDeclarationReferenceDate = this.propertyData.nextDeclarationReferenceDate;
      initialPropertyDataStringJson.archived = this.propertyData.archived;

      this.initialPropertyDataString = JSON.stringify(initialPropertyDataStringJson);
    },

    fetchClientPersonData() {
      let getData = {
        parentType: 'client',
        parentId: this.propertyData.clientUid,
      }

      this.clientService.getPersonData(getData, true).then((list) => {
        this.saveClientPersons(list);
      });
    },

    selectClientPerson(item) {
      if (item.data.salutation === 'mr') {
        this.addModalSaveData.owner.salutation = '2';
      } else if (item.data.salutation === 'mrs') {
        this.addModalSaveData.owner.salutation = '3';
      } else if (item.data.salutation === '-') {
        this.addModalSaveData.owner.salutation = '1';
      } else {
        this.addModalSaveData.owner.salutation = '0';
      }

      this.addModalSaveData.owner.title = item.data.title;
      this.addModalSaveData.owner.firstName = item.data.first_name;
      this.addModalSaveData.owner.lastName = item.data.last_name;
      this.addModalSaveData.owner.street = item.data.street;
      this.addModalSaveData.owner.houseNumber = item.data.house_number;
      this.addModalSaveData.owner.postbox = item.data.postbox;
      this.addModalSaveData.owner.zip = item.data.zip;
      this.addModalSaveData.owner.city = item.data.city;
      this.addModalSaveData.owner.countryUid = item.data.country_uid;
      this.addModalSaveData.owner.taxNumber = item.data.tax_number;
      this.addModalSaveData.owner.taxIdentificationNumber = item.data.identification_number;
      this.addModalSaveData.owner.dateOfBirth = item.data.date_of_birth;
      this.addModalSaveData.owner.phone = item.data.phone;
    },

    selectClientRepresentativePerson(item) {
      if (item.data.salutation === 'mr') {
        this.addModalSaveData.owner.representative.salutation = '2';
      } else if (item.data.salutation === 'mrs') {
        this.addModalSaveData.owner.representative.salutation = '3';
      } else if (item.data.salutation === '-') {
        this.addModalSaveData.owner.representative.salutation = '1';
      } else {
        this.addModalSaveData.owner.representative.salutation = '0';
      }

      this.addModalSaveData.owner.representative.title = item.data.title;
      this.addModalSaveData.owner.representative.firstName = item.data.first_name;
      this.addModalSaveData.owner.representative.lastName = item.data.last_name;
      this.addModalSaveData.owner.representative.street = item.data.street;
      this.addModalSaveData.owner.representative.houseNumber = item.data.house_number;
      this.addModalSaveData.owner.representative.postbox = item.data.postbox;
      this.addModalSaveData.owner.representative.zip = item.data.zip;
      this.addModalSaveData.owner.representative.city = item.data.city;
      this.addModalSaveData.owner.representative.countryUid = item.data.country_uid;
      this.addModalSaveData.owner.representative.dateOfBirth = item.data.date_of_birth;
      this.addModalSaveData.owner.representative.phone = item.data.phone;
    },
    closeNonResidential(type) {
      for (let key in this.modalSaveErrors) {
        if (key.startsWith('nonResidential')) {
          this.modalSaveErrors[key].invalid = false;
        }
      }

      if (type === 'add') {
        this.addNonresidentialDialog = false;
      }

      if (type === 'edit') {
        this.editNonresidentialDialog = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
}

.label-chk {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
}

html, body {
  height: 100%;
  min-height: 100%;
  margin:0;
  padding:0;
}

.button-label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.missing-fields {
  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;
  }
}

.info-msg {
  background-color: #f2f2f2;
  border-top: 1px solid #dfdfdf;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;
    white-space: nowrap;

    &.underline {
      text-decoration: underline;

    }
  }

  .border-left {
    border-left: 1px solid #dbdbdb;
  }

  .border-left-small {
    border-left: 1px solid theme('colors.muted_black');
  }
}

.white-space-normal{
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.auto-width {
  width: auto !important;
}
.info-msg-label-wrap {
  background-color: #f2f2f2;
  border-top: 1px solid #dfdfdf;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;

    &.underline {
      text-decoration: underline;

    }
  }

  .border-left {
    border-left: 1px solid #dbdbdb;
  }

  .border-left-small {
    border-left: 1px solid theme('colors.muted_black');
  }
}

.myinput.large {
  height: 20px;
  width: 20px;
}

.myinput.large[type="checkbox"]:before {
  width: 20px;
  height: 20px;
}

.myinput.large[type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.chip-container {

  border: 2px solid #7a7a7a;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    display: flex;
    align-items: center;


    height: 22px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;

    img {
      cursor: pointer;
      margin-left: 8px;
      font-size: 12px;
      font-style: normal;

      width: 8px;
      height: 8px;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial', sans-serif;
      color: #333333;
      text-align: center;
      line-height: normal;
    }
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {
    @include segoe-regular;
    color: #7b7c7c;
    font-size: 12px;
  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

//properties
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  &:hover {
    .icontable {
      visibility: visible;
    }

  }

  .icontable {
    visibility: collapse;
  }
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

.title {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;

}

input[type=radio] {
  border: 0;
  width: 2em;
  height: 25px;
}

input[type=checkbox] {
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
}

.chkclass {
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
}

.textradio {

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
  margin-bottom: 15px;
}

.text1 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
  margin-bottom: 5px;
}

.sub-heading {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.plus {
  width: 29px;
  height: 29px;
  box-sizing: border-box;

}

.btext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: right;
  line-height: 20px;
  font-size: 15px;
}

.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.map {
  width: 33px;
  height: 33px;
  box-sizing: border-box;
  margin-left: 10px;

}

.grey-text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  line-height: normal;
  font-size: 15px;


}
.grey-right-text {
  @extend .grey-text;
  text-align: right;
}

.grey-left-text {
  @extend .grey-text;
  text-align: left;

}

.max-h-700 {
  max-height: 700px;
}

.max-width-half {
  max-width: calc(50% - (20px));
}

.tooltip-content {
  background-color: $primary;
  border-radius: 5px;
  padding: 10px;
  max-width: 350px;
  display: inline-table;
  text-align: left;
  color: white;
}

.link {
  text-decoration: underline;
  text-underline-position: under;
}

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.box {
  height: auto;
  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px !important;

  .text {
    margin-bottom: 0 !important;
  }
}

.box1 {
  width: 50%;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.box-no-margin-bottom {
  @extend .box;
  margin-bottom: 0 !important;
}
.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text-info {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.max-width-one-of-third {
  max-width: calc(33% - (20px));
}

.max-width-two-of-third {
  max-width: calc(66% - (6px));
}

.add-more-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.delete-icon {
  cursor: pointer;
  height: 25px;
}

.display-none {
  display: none;
}

.w-half {
  width: 50%;
}

.display-none {
  display: none;
}

.content-width-half {
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-width-half > div {
  width: 48.25%;
}

.content-width-half > .custom-max-width {
  margin-left: 0 !important;
}

.flex-wrap-wrap{
  flex-wrap: wrap;
}

.margin-top-15{
  margin-top: 15px;
}

.half__div {

  justify-content: space-between;
}

.half__div > div {
  width: 49%;
}

.half__div__right {
  margin-left: 0 !important;
}

.custom-width {
  max-width: 420px !important;
}

.custom-width-half {
  max-width: 640px !important;
}

.redtext {
  color: red !important;
}


#draggable-container {
  max-height: 900px !important;
}

.button-external {
  width: 296px;
  height: 32px;
  padding: 8px;
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: center;
  line-height: 20px;
  text-decoration: none !important;
  text-underline: none !important;
}

.link {
  cursor: pointer;
  text-decoration: underline;

  a.primary-color {
    color: $primary;
  }
}

ul.ul-list {
  padding: 0;
  margin: 20px 0 0 20px;
}

ul.ul-list li {
  font-size: 15px;
  margin-bottom: 8px;
  list-style: disc;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
}

.elster-dlg {
  label {
    color: #333333 !important;
  }

  .textbold {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 700;
    color: theme('colors.muted_black');
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 15px;
  }
}

.elster-result-header {
  p {
    text-align: center !important;
  }
}

ul.list {
  padding: 0;
  margin: 8px 0 0 38px;
}

ul.list li {
  font-size: 13px;
  margin-bottom: 8px;
  list-style: disc;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
}

.text-bold {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
}

.download-template-link {
  cursor: pointer;
  text-decoration: underline;
  color: #333333;
}

::v-deep .table-container {
  height: auto !important;
}

.relative {
  position: relative;
}
.wrapper{
  position: relative;
  //
  display:flex;
  min-height:100vh;
  flex-direction:column;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}
</style>
